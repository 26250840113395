var v = Object.defineProperty;
var g = Object.getOwnPropertyDescriptor;
var o = (n, i, t, s) => {
  for (var r = s > 1 ? void 0 : s ? g(i, t) : i, p = n.length - 1, h; p >= 0; p--) (h = n[p]) && (r = (s ? h(i, t, r) : h(r)) || r);
  return s && r && v(i, t, r), r;
};
import { LitElement as S, html as T } from "lit";
import { property as l, queryAssignedNodes as C, state as I } from "lit/decorators.js";
import N from "animejs";
import { unsafeCSS as w } from "lit";
import x from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as y } from "lit-element/lit-element.js";
var _ = y`:host{display:inline}.mc-toast .content{display:none}`;
var f = [x, w(_)];
import { isServer as m } from "lit";
var E = `
  .mc-toast-container {
    padding: var(--mds_foundations_toast_padding);
    gap: var(--mds_foundations_toast_gap);
    width: var(--mds_foundations_toast_width);
    min-width: var(--mds_foundations_toast_min-width);
    max-width: var(--mds_foundations_toast_max-width);
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 50;
  }
  @media screen and (max-width: 700px) {
    .mc-toast-container {
      width: 100% !important;
    }
  }
  .mc-toast-container mc-card,
  .mc-toast-container mc-notification {
    border-radius: var(--mds_brand_border_large_radius);
    box-shadow: var(--mds_brand_appearance_shadow_high_first-layer_offset-x) var(--mds_brand_appearance_shadow_high_first-layer_offset-y) var(--mds_brand_appearance_shadow_high_first-layer_blur-radius) var(--mds_brand_appearance_shadow_high_first-layer_spread-radius) var(--mds_brand_appearance_shadow_high_first-layer_color), var(--mds_brand_appearance_shadow_high_second-layer_offset-x) var(--mds_brand_appearance_shadow_high_second-layer_offset-y) var(--mds_brand_appearance_shadow_high_second-layer_blur-radius) var(--mds_brand_appearance_shadow_high_second-layer_spread-radius) var(--mds_brand_appearance_shadow_high_second-layer_color), var(--mds_brand_appearance_shadow_high_third-layer_offset-x) var(--mds_brand_appearance_shadow_high_third-layer_offset-y) var(--mds_brand_appearance_shadow_high_third-layer_blur-radius) var(--mds_brand_appearance_shadow_high_third-layer_spread-radius) var(--mds_brand_appearance_shadow_high_third-layer_color);
  }
  .mc-toast-container mc-notification::part(notification):hover {
    box-shadow: var(--mds_brand_appearance_shadow_high_first-layer_offset-x) var(--mds_brand_appearance_shadow_high_first-layer_offset-y) var(--mds_brand_appearance_shadow_high_first-layer_blur-radius) var(--mds_brand_appearance_shadow_high_first-layer_spread-radius) var(--mds_brand_appearance_shadow_high_first-layer_color), var(--mds_brand_appearance_shadow_high_second-layer_offset-x) var(--mds_brand_appearance_shadow_high_second-layer_offset-y) var(--mds_brand_appearance_shadow_high_second-layer_blur-radius) var(--mds_brand_appearance_shadow_high_second-layer_spread-radius) var(--mds_brand_appearance_shadow_high_second-layer_color), var(--mds_brand_appearance_shadow_high_third-layer_offset-x) var(--mds_brand_appearance_shadow_high_third-layer_offset-y) var(--mds_brand_appearance_shadow_high_third-layer_blur-radius) var(--mds_brand_appearance_shadow_high_third-layer_spread-radius) var(--mds_brand_appearance_shadow_high_third-layer_color);
    transition-duration: var(--mds_foundations_toast_transition-duration);
    transition-property: var(--mds_foundations_toast_transition-properties);
    transition-timing-function: var(--mds_foundations_toast_transition-timing);
  }
  .mc-toast-container.top-left {
    top: 0;
    left: 0;
  }
  .mc-toast-container.top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .mc-toast-container.top-right {
    top: 0;
    right: 0;
  }
  .mc-toast-container.bottom-left {
    bottom: 0;
    left: 0;
  }
  .mc-toast-container.bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .mc-toast-container.bottom-right {
    bottom: 0;
    right: 0;
  }
`,
  c = !1,
  u = () => {
    if (!m) {
      let n = document.head || document.getElementsByTagName("head")[0],
        i = document.createElement("style");
      i.type = "text/css", i.appendChild(document.createTextNode(E)), n.appendChild(i), c = !0;
    }
  },
  d = n => m ? Object.assign({}) : Object.assign(document.createElement("div"), {
    className: `mc-toast-container ${n}`,
    ariaLive: "polite"
  }),
  e = {
    "top-left": d("top-left"),
    "top-center": d("top-center"),
    "top-right": d("top-right"),
    "bottom-left": d("bottom-left"),
    "bottom-center": d("bottom-center"),
    "bottom-right": d("bottom-right")
  },
  b = !m && typeof window.matchMedia == "function" ? window.matchMedia("(prefers-reduced-motion: reduce)") : null;
import "@maersk-global/mds-components-core-notification";
var a = class extends S {
  constructor() {
    super(...arguments);
    this.timerId = null;
    this.start = 0;
    this.remaining = 0;
    this.animeDuration = b?.matches ? 0 : 250;
    this.mcClosableComponent = null;
    this.mcClosableComponentCloned = !1;
    this.allowedClosableComponents = ["mc-notification"];
    this.closeEventDispatched = !1;
    this.fit = "medium";
    this.appearance = "neutral-default";
    this.position = "top-right";
    this.width = "auto";
    this.duration = 5e3;
    this.open = !1;
  }
  static get styles() {
    return f;
  }
  render() {
    return T`<div class="mc-toast"><slot name="trigger" @slotchange="${this.onTriggerSlotChange}"></slot><slot class="content" @slotchange="${this.onSlotChange}"></slot></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), c || u();
  }
  willUpdate(t) {
    super.willUpdate(t), t.has("open") && typeof t.get("open") < "u" && (this.open ? this.show() : this.hide());
  }
  onTriggerSlotChange() {
    this.trigger && this.trigger.length > 0 && this.trigger[0].addEventListener("click", () => this.show());
  }
  onSlotChange() {
    if (this.open && this.timerId === null && this.show(), !this.content) {
      console.warn("Could not set a content element. Toast needs a closable element in a default slot to function properly");
      return;
    }
  }
  show() {
    this.remaining = this.duration, this.createToastContainer(), this.mcClosableComponent && (this.attachEventsToClosableComponent(), this.animation("in"), this.resume());
  }
  hide() {
    this.mcClosableComponent && (this.dispatchCloseEvent(), this.animation("out", () => {
      this.destroyToastContainer(), this.pause();
    }));
  }
  pause() {
    this.timerId && (clearTimeout(this.timerId), this.timerId = null, this.remaining -= Date.now() - this.start);
  }
  resume() {
    this.timerId || (this.start = Date.now(), this.timerId = setTimeout(() => {
      this.hide();
    }, this.remaining));
  }
  animation(t, s) {
    N({
      targets: this.mcClosableComponent,
      opacity: t === "in" ? [0, 1] : 0,
      scale: t === "in" ? [.5, 1] : .8,
      duration: this.animeDuration,
      easing: "easeInOutExpo",
      complete: () => {
        s && s();
      }
    });
  }
  dispatchCloseEvent() {
    this.closeEventDispatched || (this.dispatchEvent(new CustomEvent("close", {
      detail: "Close"
    })), this.closeEventDispatched = !0);
  }
  attachEventsToClosableComponent() {
    this.closeEventDispatched = !1, this.mcClosableComponent?.addEventListener("focus", () => this.pause()), this.mcClosableComponent?.addEventListener("mouseover", () => this.pause()), this.mcClosableComponent?.addEventListener("blur", () => this.resume()), this.mcClosableComponent?.addEventListener("mouseout", () => this.resume()), this.mcClosableComponent?.addEventListener("close", () => this.hide());
  }
  createToastContainer() {
    e[this.position]?.parentElement === null && (e[this.position].classList.add(`${this.fit}`), this.width && (e[this.position].style.width = `${this.width}px`), document.body.append(e[this.position])), this.mcClosableComponentCloned || (this.mcClosableComponent = this.content.filter(t => this.allowedClosableComponents.find(s => s === t.nodeName.toLowerCase()))[0], this.mcClosableComponent && this.cloneClosableComponent());
  }
  containerInDom() {
    return e[this.position] instanceof Element && document.body.contains(e[this.position]);
  }
  destroyToastContainer() {
    this.open = !1, this.mcClosableComponentCloned = !1, this.containerInDom() && (this.mcClosableComponent?.parentNode && e[this.position].hasChildNodes() && (e[this.position].removeChild(this.mcClosableComponent), this.appendChild(this.mcClosableComponent), this.mcClosableComponent = null), e[this.position].children.length === 0 && e[this.position].remove());
  }
  cloneClosableComponent() {
    this.mcClosableComponent && (this.mcClosableComponent.closable = !0, this.mcClosableComponent.fit = this.fit, this.mcClosableComponent.appearance = this.appearance, this.mcClosableComponent.handleClose = () => this.hide(), this.containerInDom() && e[this.position].appendChild(this.mcClosableComponent), this.mcClosableComponentCloned = !0);
  }
};
o([I()], a.prototype, "closeEventDispatched", 2), o([C({
  slot: "",
  flatten: !0
})], a.prototype, "content", 2), o([C({
  slot: "trigger",
  flatten: !0
})], a.prototype, "trigger", 2), o([l({
  type: String
})], a.prototype, "fit", 2), o([l({
  type: String
})], a.prototype, "appearance", 2), o([l({
  type: String
})], a.prototype, "position", 2), o([l({
  type: String
})], a.prototype, "width", 2), o([l({
  type: Number
})], a.prototype, "duration", 2), o([l({
  type: Boolean,
  reflect: !0
})], a.prototype, "open", 2);
customElements.get("mc-toast") || customElements.define("mc-toast", a);
export { a as McToast };