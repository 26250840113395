var _ = Object.defineProperty;
var g = Object.getOwnPropertyDescriptor;
var e = (d, s, r, a) => {
  for (var l = a > 1 ? void 0 : a ? g(s, r) : s, m = d.length - 1, p; m >= 0; m--) (p = d[m]) && (l = (a ? p(s, r, l) : p(l)) || l);
  return a && l && _(s, r, l), l;
};
import { LitElement as y, html as n } from "lit";
import { classMap as c } from "lit/directives/class-map.js";
import { ifDefined as o } from "lit/directives/if-defined.js";
import { property as i, query as x } from "lit/decorators.js";
import { FormField as $, setHostCssClass as S, DisabledState as C } from "@maersk-global/mds-components-utils";
import { inputStyles as w } from "@maersk-global/mds-components-core-input";
import { unsafeCSS as b } from "lit";
import { css as f } from "lit-element/lit-element.js";
var h = f`.mc-input.small .field{min-height:var(--mds_core_textarea_small_min-height);height:auto}.mc-input.small .field .input{padding:var(--mds_core_textarea_small_padding-top) var(--mds_core_textarea_small_padding-right) var(--mds_core_textarea_small_padding-bottom) var(--mds_core_textarea_small_padding-left)}.mc-input.medium .field{min-height:var(--mds_core_textarea_medium_min-height);height:auto}.mc-input.medium .field .input{padding:var(--mds_core_textarea_medium_padding-top) var(--mds_core_textarea_medium_padding-right) var(--mds_core_textarea_medium_padding-bottom) var(--mds_core_textarea_medium_padding-left)}.mc-input.large .field{min-height:var(--mds_core_textarea_large_min-height);height:auto}.mc-input.large .field .input{padding:var(--mds_core_textarea_large_padding-top) var(--mds_core_textarea_large_padding-right) var(--mds_core_textarea_large_padding-bottom) var(--mds_core_textarea_large_padding-left)}.mc-input textarea.input{width:100%;resize:vertical;transition:none}.mc-input .character-counter{color:var(--mds_core_textarea_counter_text-color);margin-top:var(--mds_core_textarea_counter_margin-top);font-family:var(--mds_brand_typography_text_font-family),var(--mds_brand_typography_text_font-family-fallback);font-size:var(--mds_brand_typography_text_small_mobile_font-size);line-height:var(--mds_brand_typography_text_small_mobile_line-height)}@media screen and (min-width:1000px){.mc-input .character-counter{font-size:var(--mds_brand_typography_text_small_desktop_font-size);line-height:var(--mds_brand_typography_text_small_desktop_line-height)}}.mc-input .character-counter{font-style:var(--mds_brand_typography_text_small_normal_font-style);font-weight:var(--mds_brand_typography_text_small_normal_font-weight)}.mc-input .footer{display:flex;justify-content:space-between}`;
import v from "@maersk-global/mds-design-tokens/implementation/core/textarea/js/design-tokens-px.css.js";
var u = [v, b(h)];
import "@maersk-global/mds-components-core-error";
import "@maersk-global/mds-components-core-hint";
import "@maersk-global/mds-components-core-label";
var t = class extends C($(y)) {
  constructor() {
    super(...arguments);
    this.charCounter = !1;
    this.controlType = "textarea";
    this.focused = !1;
    this.cols = 20;
    this.errormessage = "";
    this.fit = "medium";
    this.hiddenlabel = !1;
    this.hint = "";
    this.id = "";
    this.invalid = !1;
    this.label = "Label";
    this.labelposition = "top";
    this.maxlength = -1;
    this.minlength = -1;
    this.name = "";
    this.placeholder = "";
    this.readonly = !1;
    this.required = !1;
    this.rows = 2;
    this.width = "auto";
  }
  get inputId() {
    return `${this.id ?? "field"}-input`;
  }
  static get styles() {
    return [w, u];
  }
  render() {
    this.maxlength && this.maxlength !== -1 ? this.charCounter = !0 : (this.maxlength = void 0, this.charCounter = !1);
    let r = this.charCounter && this.maxlength !== -1,
      a = {
        [`${this.fit}`]: !0,
        [`${this.labelposition}`]: !0
      };
    return n`<div class="mc-input ${c(a)}">${this.renderLabel()}<div class="container ${c(a)}"><div class="inner"><div class="field" style="${this.width === "auto" ? "" : `width:${this.width}%`}">${this.renderInput()}</div></div><div class="footer"><div>${this.renderError()} ${this.renderHint()}</div>${this.renderCharCounter(r)}</div></div></div>`;
  }
  renderInput() {
    let r = this.minlength === -1 ? void 0 : this.minlength,
      a = this.maxlength === -1 ? void 0 : this.maxlength;
    return n`<textarea part="textarea" aria-labelledby="label" aria-describedby="hint" class="input ${S(this.classList, ["hover", "focus", "active"])}" .value="${this.value || ""}" rows="${this.rows}" cols="${this.cols}" ?disabled="${this.disabled}" ?invalid="${this.invalid}" id="${this.inputId}" aria-invalid="${o(this.invalid ? this.invalid : void 0)}" placeholder="${this.placeholder}" ?required="${this.required}" ?readonly="${this.readonly}" minlength="${o(r)}" maxlength="${o(a)}" name="${o(this.name === "" ? void 0 : this.name)}" @input="${this.handleInputChange}" @blur="${this.onInputBlur}">
    </textarea>`;
  }
  renderLabel() {
    return n`<label class="${this.hiddenlabel ? "hiddenlabel" : ""}" for="${this.inputId}"><mc-label exportparts="label" id="label" .label="${this.label}" .fit="${this.fit}" ?hiddenlabel="${this.hiddenlabel}" .labelposition="${this.labelposition}"><slot name="label">${this.label}</slot></mc-label></label>`;
  }
  renderError() {
    return n`<mc-error id="invalid" .errormessage="${this.errormessage}" .fit="${this.fit}" ?invalid="${this.invalid}"><slot name="errormessage">${this.errormessage}</slot></mc-error>`;
  }
  renderHint() {
    return n`<mc-hint id="hint" .hint="${this.hint}" .fit="${this.fit}"><slot name="hint">${this.hint}</slot></mc-hint>`;
  }
  renderCharCounter(r) {
    if (!this.maxlength) return null;
    let a = Math.min(this.value ? this.value.length : 0, this.maxlength);
    return r ? n`<span class="character-counter" data-cy="character-counter">${a} / ${this.maxlength}</span>` : null;
  }
  focus() {
    let r = new FocusEvent("focus");
    this.textarea?.dispatchEvent(r), this.textarea?.focus();
  }
  onInputFocus() {
    this.focused = !0;
  }
  blur() {
    let r = new FocusEvent("blur");
    this.textarea?.dispatchEvent(r), this.textarea?.blur();
  }
  onInputBlur() {
    this.focused = !1;
  }
  handleInputChange() {
    this.textarea && (this.value = this.textarea.value);
  }
};
e([x("textarea")], t.prototype, "textarea", 2), e([i({
  type: Number
})], t.prototype, "cols", 2), e([i({
  type: String
})], t.prototype, "errormessage", 2), e([i({
  type: String
})], t.prototype, "fit", 2), e([i({
  type: Boolean
})], t.prototype, "hiddenlabel", 2), e([i({
  type: String
})], t.prototype, "hint", 2), e([i({
  type: String,
  reflect: !0
})], t.prototype, "id", 2), e([i({
  type: Boolean,
  reflect: !0
})], t.prototype, "invalid", 2), e([i({
  type: String
})], t.prototype, "label", 2), e([i({
  type: String
})], t.prototype, "labelposition", 2), e([i({
  type: Number
})], t.prototype, "maxlength", 2), e([i({
  type: Number
})], t.prototype, "minlength", 2), e([i({
  type: String,
  reflect: !0
})], t.prototype, "name", 2), e([i({
  type: String
})], t.prototype, "placeholder", 2), e([i({
  type: Boolean
})], t.prototype, "readonly", 2), e([i({
  type: Boolean,
  reflect: !0
})], t.prototype, "required", 2), e([i({
  type: Number
})], t.prototype, "rows", 2), e([i({
  type: String,
  reflect: !0
})], t.prototype, "value", 2), e([i({
  type: String
})], t.prototype, "width", 2);
customElements.get("mc-textarea") || customElements.define("mc-textarea", t);
export { t as McTextarea };