<div class="pure-g" style="margin-top:-30px">
    <div class="pure-g pure-u-md-20-24 pure-u-lg-21-24" style="overflow: auto;text-wrap:nowrap;">
        <div style="width:90px;display: inline-block;font-size: 0.9rem;"></div>
      
    </div>
    <div class="pure-u-md-4-24 pure-u-lg-3-24 right">
        
        <mc-button i18n-label="Button" label="Add New" variant="filled" appearance="primary"
            fit="small" (click)="onAddValidationClick()"></mc-button>
            @if(showAddModal){
            <mc-modal  backdropcloseactiondisabled [open]="showAddModal" (closed)="closeAddModal()" heading="Add New Validation" fit="small"
            dimension="medium">
            <app-flag-on-off-add-validation (onClickEvent)="onClickEvent($event)"  [flagAddForm]="addFormGroup"></app-flag-on-off-add-validation>
              
        </mc-modal>
    }
    </div>
</div>
<div class="pure-g m-t-8 quick-filter-box">
    <div class="pure-g pure-u-md-21-24" style="display: flex;align-items: center;">
        <div class="pure-u-md-24-24 p-l-4 applied-filter-holder">
            @for (taggedFilter of taggedFilters; track taggedFilter.key){

            {{taggedFilter.key}}

            @if(isArrayType(taggedFilter.value)){

            @if(taggedFilter.key=="Validation" && getTaggedValidation()){
            @for(taggedValidation of getTaggedValidation() ; track taggedValidation){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedValidation().indexOf(taggedValidation))">{{getTaggedFilterValue(taggedFilter,
                    getTaggedValidation().indexOf(taggedValidation))}}</mc-tag>
            }
            }
            @else if(taggedFilter.key=="Region" && getTaggedRegion()){
            @for(taggedRegion of getTaggedRegion() ; track taggedRegion){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedRegion().indexOf(taggedRegion))">{{getTaggedFilterValue(taggedFilter,
                    getTaggedRegion().indexOf(taggedRegion))}}</mc-tag>
            }
            }
            @else if (taggedFilter.key=="Shops" && getTaggedShop()) {
            @for(taggedShop of getTaggedShop() ; track taggedShop){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedShop().indexOf(taggedShop))">{{getTaggedFilterValue(taggedFilter,
                getTaggedShop().indexOf(taggedShop))}}</mc-tag>
            }
            }

            @else if (taggedFilter.key=="Cluster" && getTaggedCountry()) {
                @for(taggedCountry of getTaggedCountry() ; track taggedCountry){
                <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                    (click)="unselectSpecificFilter(taggedFilter,getTaggedCountry().indexOf(taggedCountry))">{{getTaggedFilterValue(taggedFilter,
                        getTaggedCountry().indexOf(taggedCountry))}}</mc-tag>
                }
                }
            }
            @else{
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter)">{{getTaggedFilterValue(taggedFilter)}}</mc-tag>
            }
            }
            @if(taggedFilters.length>0){
            <a>
                <mc-button class="clear-btn" variant="plain" appearance="primary" fit="small" padding="default"
                    (click)="clearAllSelectedFilters()">
                    Clear All
                </mc-button>
            </a>
            }
        </div>
    </div>
    <div class="pure-u-md-3-24 utility-buttons">
         <div>
            <mc-button class="utility-button download" label="Button " variant="filled" appearance="neutral"
                fit="medium" icon="tray-arrow-down" hiddenlabel (click)="onDownloadClick()" padding="default">
            </mc-button>
        </div> 
        <div class="pure-g">
            <mc-tooltip appearance="neutral-default" fit="small" position="bottom-center">
                <mc-button class="utility-button" slot="trigger" label="Button" icon="sliders-horizontal"
                    variant="filled" appearance="neutral" hiddenlabel padding="default" fit="medium"
                    (click)="showAdvanceFiltersModal()">
                </mc-button>
                <span>Filters</span>
            </mc-tooltip>
            <mc-modal [open]="showAdvanceFilters"  (closed)="closeAdvanceFiltersModal()" heading="Filters" fit="small"
                dimension="medium">
                <app-flag-on-off-advance-filter [flagFilterForm]="filterFormGroup.controls.flagFilterForm" (onClickEvents)="onClickEvents($event)"></app-flag-on-off-advance-filter>app-flag-on-off-advance-filter>
            </mc-modal>
        </div>
    </div>
</div>