import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RepairLineItemFormNoAttachmentAndParts } from '../../../../form-model/cwo-repair-line-item.form.model';
import { Shop } from '../../../../models/shop.model';
import { CodeDescription } from '../../../../models/code-description.model';
import { Repair } from '../../../../models/repair.model';
import { RepairLocation } from '../../../../models/repair-location.model';
import { getTPIChar, ThirdPartyIndicator } from '../../../../models/tpi.model';
import { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-typeahead'
import { combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { utils } from '../../../../helper/utils';
import { ApplicationtTextService } from '../../../../services/application-text.service';
import { ControlValidationService } from '../../../../services/control-validation.service';
import { typeaheadCodeExistValidator } from '../../../../shared-validators/typeahead-code-exist.validator';

@Component({
    selector: 'app-bulk-work-order-repair-line-item',
    imports: [ReactiveFormsModule],
    templateUrl: './bulk-work-order-repair-line-item.component.html',
    styleUrl: './bulk-work-order-repair-line-item.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderRepairLineItemComponent implements OnInit {
	@Input({ required: true }) repairLineItemForm!: FormGroup<RepairLineItemFormNoAttachmentAndParts>;
	index = input.required<number>();
	shop = input.required<Shop | null>();
	controlId = input.required<string>();
	damages = input.required<CodeDescription[]>();
	repairs = input.required<Repair[]>();
	repairLocations = input.required<RepairLocation[] | null>();
	tpiCodes = input.required<ThirdPartyIndicator[] | null>();
	@Output() repairLineItemRemoved = new EventEmitter<number>();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	// Controls data
	damageCodesTypeahead: IMcTypeaheadData[] = []
	repairCodesTypeahead: IMcTypeaheadData[] = []
	repairLocationCodesTypeahead: IMcTypeaheadData[] = []
	tpiCodesTypeahead: IMcTypeaheadData[] = []
	constructor(public appTextSvc:ApplicationtTextService,public ctrlValidSvc:ControlValidationService) {

	}
	ngOnInit(): void {
		this.damageCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.damages());
		this.repairCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairs());
		this.repairLocationCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairLocations() ?? []);
		this.tpiCodesTypeahead = this.mapTPICodeToTypeaheadData(this.tpiCodes() ?? []);
		this.addValidatorsToRepairCode();
		this.addValidatorsToRepairLocationCode();
		this.addValidatorsToDamageCode();
		this.addValidatorsToTpiCode();
		this.calculateRepairItemTotalCost();
	}

	onRemoveRepairLineItemClicked() {
		this.repairLineItemRemoved.emit(this.index());
	}
	/**
	   * @description Event handler when the repair code is selected from the typeahead control
	   * @param event selected element from the typeahead control in template
	   * Resets the part array and parts form array control if the selected repair code does not have any parts
	   * Add validators and set value of Man Hr/Pc, pieces , and mat cost/pc control of a repair line item
	   * Checks if the selected repair code has any part associated with it, if yes, fetches the parts
	   */
	onRepairCodeOptionSelected(event: FocusEvent) {
		const repairCode = (event.target as HTMLInputElement).value;
		const selectedRepair = this.repairs().find((repair) => repair.code === repairCode);
		if (selectedRepair) {
			this.addRequiredAndMaxValidators(selectedRepair);
			this.repairLineItemForm.controls.pieces.setValue(1);
			this.repairLineItemForm.controls.manHoursPerPiece.setValue(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxLabourHours));
			this.repairLineItemForm.controls.materialCostPerPiece.setValue(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxMaterialCost));
		}
	}
	private addRequiredAndMaxValidators(
		selectedRepair: Repair
	) {
		let piecesControl = this.repairLineItemForm.controls.pieces;
		let materialCostPerPieceControl = this.repairLineItemForm.controls.materialCostPerPiece;
		let manHoursPerPieceControl = this.repairLineItemForm.controls.manHoursPerPiece;

		piecesControl.clearValidators();
		piecesControl.addValidators([Validators.required, Validators.pattern(/^(0|[1-9]\d*)$/), Validators.max(selectedRepair.maxQuantity)]);
		piecesControl.updateValueAndValidity();

		materialCostPerPieceControl.clearValidators();
		materialCostPerPieceControl.addValidators([Validators.required, Validators.pattern(/^\d*\.?\d{1,2}$/), Validators.max(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxMaterialCost))]);
		materialCostPerPieceControl.updateValueAndValidity();

		manHoursPerPieceControl.clearValidators();
		manHoursPerPieceControl.addValidators([Validators.required, Validators.pattern(/^\d*\.?\d{1,2}$/), Validators.max(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxLabourHours))]);
		
	}
	private mapCodeDescriptionToTypeaheadData(data: CodeDescription[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: codeDescription.code,
				sublabel: codeDescription.description,
				value: codeDescription.code,
			};
		});
	}

	private mapTPICodeToTypeaheadData(data: ThirdPartyIndicator[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: getTPIChar(codeDescription.code),
				sublabel: codeDescription.displayText,
				value: codeDescription.code,
			};
		});
	}

	private calculateRepairItemTotalCost() {
		combineLatest([
			this.repairLineItemForm.controls.pieces.valueChanges,
			this.repairLineItemForm.controls.materialCostPerPiece.valueChanges]
		)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([pieces, materialCostPerPiece]) => {
				this.repairLineItemForm.controls.itemTotalCost.setValue(utils.numberFormatter.format(pieces * materialCostPerPiece));
			});
	}

	private addValidatorsToDamageCode() {
		let damageCode = this.repairLineItemForm.controls.damageCode;
		damageCode.clearValidators();
		damageCode.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.damageCodesTypeahead),
		]);
		damageCode.updateValueAndValidity();
	}

	/**
	 *
	 * @param repairLineItem the line item to which this repair code belongs to
	 * @description Add validators to the repair code control
	 */
	private addValidatorsToRepairCode() {
		let repairCodeControl = this.repairLineItemForm.controls.repairCode;
		repairCodeControl.clearValidators();
		repairCodeControl.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.repairCodesTypeahead)]);
		// 	containerGradeValidator(this.equipment(), this.containerGrades(), this.mode()),
		// 	repairSuspendedValidator(this.repairs()),
		// 	ptiStatusValidator(this.index, this.equipment(), this.toastrService,this.appTextSvc),
		// ]);

		// if (this.equipment()?.isLastMoveGateInEmpty) {
		// 	this.sharedDataService.getPastRepairs().subscribe(pastRepairs=>{
		// 		if(pastRepairs.length>0){
		// 		repairCodeControl.addValidators(duplicateRepairCodeValidator(pastRepairs,this.toastrService,this.appTextSvc));
		// 		repairCodeControl.updateValueAndValidity();
		// 		}
		// 	})
			
		// }
		
		// if (!this.equipment()?.isContainerEmpty) {
		// 	repairCodeControl.addValidators(nonEmptyReeferRepairWarning(this.index, this.equipment(), this.repairs(), this.toastrService, this.appTextSvc));
		// }
		// repairCodeControl.updateValueAndValidity();
	}

	/**
	 *
	 * @param repairLineItem the line item to which this repair location code belongs to
	 * @description Add validators to the repair location code control
	 */
	private addValidatorsToRepairLocationCode() {
		let repairLocationCode = this.repairLineItemForm.controls.repairLocationCode;
		repairLocationCode.clearValidators();
		repairLocationCode.addValidators([
			Validators.required,
			typeaheadCodeExistValidator(this.repairLocationCodesTypeahead),
		]);
		repairLocationCode.updateValueAndValidity();
	}

	/**
	 *
	 * @param repairLineItem the line item to which this tpi code belongs to
	 * @description Add validators to the tpi code control
	 */
	private addValidatorsToTpiCode() {
		let tpiCode = this.repairLineItemForm.controls.tpiCode;
		tpiCode.clearValidators();
		tpiCode.addValidators([Validators.required, typeaheadCodeExistValidator(this.tpiCodesTypeahead)]);
		tpiCode.updateValueAndValidity();
	}

}
