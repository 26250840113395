var b = Object.defineProperty;
var c = Object.getOwnPropertyDescriptor;
var r = (p, u, t, i) => {
  for (var e = i > 1 ? void 0 : i ? c(u, t) : u, n = p.length - 1, m; n >= 0; n--) (m = p[n]) && (e = (i ? m(u, t, e) : m(e)) || e);
  return i && e && b(u, t, e), e;
};
import { html as a } from "lit";
import { property as l } from "lit/decorators.js";
import { unsafeCSS as v } from "lit";
import { css as f } from "lit-element/lit-element.js";
var o = f`.mc-input .field{display:flex;border-radius:0!important;margin-left:var(--mds_core_number-stepper_input_margin-left);margin-right:var(--mds_core_number-stepper_input_margin-right)}.mc-input .field:has(.active),.mc-input .field:has(.hover),.mc-input .field:has(:active),.mc-input .field:has(:hover),.mc-input .field:has(input.focus),.mc-input .field:has(input:focus){z-index:1}.mc-input .input{border-radius:0!important;text-align:center}.mc-input mc-button.button--minus::part(button){border-bottom-right-radius:0;border-top-right-radius:0}.mc-input mc-button.button--plus::part(button){border-bottom-left-radius:0;border-top-left-radius:0}.button.focus,.button:focus{z-index:2}:host([invalid]) .mc-input .field{z-index:3}`;
import x from "@maersk-global/mds-design-tokens/implementation/core/number-stepper/js/design-tokens-px.css.js";
var d = [x, v(o)];
import { McInput as S } from "@maersk-global/mds-components-core-input";
var h = "^-?[0-9]*\\.?[0-9]*$",
  s = class extends S {
    constructor() {
      super();
      this.minuslabel = "minus";
      this.pluslabel = "plus";
      this.type = "text", this.inputmode = "numeric", this.pattern = h;
    }
    get maxdisabled() {
      return !!(this.disabled || this.max && this.value && this.value !== "" && Number(this.value) >= Number(this.max));
    }
    get mindisabled() {
      return !!(this.disabled || this.min && this.value && this.value !== "" && Number(this.value) <= Number(this.min));
    }
    connectedCallback() {
      super.connectedCallback(), this.addEventListener("beforeinput", this.onBeforeInput);
    }
    static get styles() {
      return [...super.styles, d];
    }
    renderPrefixElement() {
      let t = super.renderPrefixElement();
      return a`${this.renderMinusButton()}${t}`;
    }
    renderSuffixElement() {
      let t = super.renderSuffixElement();
      return a`${t}${this.renderPlusButton()}`;
    }
    renderMinusButton() {
      return a`<mc-button .fit="${this.fit}" label="${this.minuslabel}" hiddenlabel .disabled="${this.readonly}" @click="${this.handleMinusClick}" ?disabled="${this.mindisabled}" icon="minus" appearance="neutral" class="button button--minus" data-cy="minus" disablediconslot disabledlabelslot></mc-button>`;
    }
    renderPlusButton() {
      return a`<mc-button .fit="${this.fit}" label="${this.pluslabel}" hiddenlabel .disabled="${this.readonly}" @click="${this.handlePlusClick}" ?disabled="${this.maxdisabled}" icon="plus" appearance="neutral" class="button button--plus" data-cy="plus" disablediconslot disabledlabelslot></mc-button>`;
    }
    onBeforeInput(t) {
      if (this.inputmode === "numeric") {
        let i = new RegExp(h, "g");
        t.data && !i.test(t.data) && t.preventDefault();
      }
    }
    onInputFocus() {
      super.onInputFocus(), super.select();
    }
    handleMinusClick() {
      this.handleStepperClick("min");
    }
    handlePlusClick() {
      this.handleStepperClick("max");
    }
    handleStepperClick(t) {
      let i = Number(this.value),
        e;
      if ((this.value || this.value === 0) && !Number.isNaN(i)) {
        if (this.min && this.min !== "" && i < Number(this.min)) e = this.min;else if (this.max && this.max !== "" && i > Number(this.max)) e = this.max;else if (this.step) {
          let n = this.getStepPrecision();
          e = t === "min" ? (i - Number(this.step)).toFixed(n) : (i + Number(this.step)).toFixed(n);
        } else e = t === "min" ? i - 1 : i + 1;
      } else this.min && this.min !== "" ? e = this.min : e = 0;
      (!this[t] || this[t] === "" || this[t] !== "" && t === "max" && Number(e) <= Number(this.max) || t === "min" && Number(e) >= Number(this.min)) && ((t === "min" && this[t] && Number(e) <= Number(this[t]) || t === "max" && this[t] && Number(e) >= Number(this[t])) && (e = this[t]), this.inputElement && (this.inputElement.value = `${e}` || ""), this.handleInputChange(), this.blur(), this.dispatchEvent(new InputEvent("input")));
    }
    getStepPrecision() {
      if (!this.step) return 0;
      let t = this.step.toString(),
        i = t.indexOf(".");
      return i === -1 ? 0 : t.substr(i + 1).length;
    }
  };
r([l({
  type: String
})], s.prototype, "max", 2), r([l({
  type: String
})], s.prototype, "min", 2), r([l({
  type: String
})], s.prototype, "minuslabel", 2), r([l({
  type: String
})], s.prototype, "pluslabel", 2), r([l({
  type: String
})], s.prototype, "step", 2);
customElements.get("mc-number-stepper") || customElements.define("mc-number-stepper", s);
export { s as McNumberStepper };