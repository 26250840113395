import { AemsFile } from '../../../models/aems-file.model';
import { ToastrService } from '../../../services/toastr-service';
import { UserMessages } from '../../../helper/user-messages';
import { utils } from '../../../helper/utils';
import { ApplicationtTextService } from '../../../services/application-text.service';
export class AemsFileValidation {
  private incomingFiles: File[];
  constructor(files: File[], private toastrService: ToastrService, private appTextSvc: ApplicationtTextService) {
    this.incomingFiles = files;
  }
  validateFileSize(): AemsFileValidation {
    var allowedFiles = this.incomingFiles.filter(
      (file) => file.size <= 5 * 1000 * 1000
    );
    if (allowedFiles.length !== this.incomingFiles.length) {
      this.incomingFiles = allowedFiles;
      this.toastrService.showToastr(this.appTextSvc.FileSizeExceeded, 'warning');
    }
    return this;
  }
  validateFileCount(existingNumberOfFile: number): AemsFileValidation {
    if (this.incomingFiles.length + existingNumberOfFile > 5) {
      this.incomingFiles = [];
      this.toastrService.showToastr(this.appTextSvc.MaxFilesUpload, 'warning');
    }

    return this;
  }
  validateDuplicateFiles(existingFiles: AemsFile[]): AemsFileValidation {
    if (utils.checkForDuplicates(this.incomingFiles, 'name')) {
      this.toastrService.showToastr(this.appTextSvc.DuplicateFile, 'warning');
      this.incomingFiles = [];
    }
    this.incomingFiles.forEach((file) => {
      if (existingFiles.find((f) => f?.file?.name === file.name)) {
        this.incomingFiles = [];
        this.toastrService.showToastr(this.appTextSvc.DuplicateFile, 'warning');
      }
    });
    return this;
  }

  validateFileExtension(): AemsFileValidation {
    var allowedFiles = this.incomingFiles.filter((file) =>
      utils.allowedFileExtensions.includes(
        file.name.substring(file.name.lastIndexOf('.') + 1)?.toLowerCase() ?? ''
      )
    );
    if (allowedFiles.length !== this.incomingFiles.length) {
      this.incomingFiles = allowedFiles;
      this.toastrService.showToastr(this.appTextSvc.InvalidFileExtension, 'warning');
    }
    return this;
  }

  get(): File[] {
    return this.incomingFiles;
  }
}
