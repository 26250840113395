import { unsafeCSS as s } from "lit";
import i from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as r } from "lit-element/lit-element.js";
var e = r`:host([orientation=vertical]) .mc-list{height:100%;max-height:100%}:host([orientation=horizontal]) .mc-list{max-width:100%;min-width:unset}.mc-list{background-color:var(--mds_core_segmented-control_background-color);border:var(--mds_core_segmented-control_border-width) var(--mds_core_segmented-control_border-color) var(--mds_core_segmented-control_border-style);gap:var(--mds_core_segmented-control_gap);overflow:auto;scroll-behavior:smooth;scroll-snap-type:both mandatory;-ms-overflow-style:none}.mc-list::-webkit-scrollbar{display:none}.mc-list.large,.mc-list.medium,.mc-list.small{padding:0}`;
import l from "@maersk-global/mds-design-tokens/implementation/core/segmented-control/js/design-tokens-px.css.js";
import m from "@maersk-global/mds-design-tokens/implementation/core/list-item/js/design-tokens-px.css.js";
var o = [i, l, m, s(e)];
import "@maersk-global/mds-components-core-segmented-control-item";
import { McList as a } from "@maersk-global/mds-components-core-list";
var t = class extends a {
  static get styles() {
    return [super.styles, o];
  }
  constructor() {
    super(), this.type = "single", this.width = "auto", this.disablecircularkeynav = !0, this.keynavigationtype = "tab", this.disablehandlemousemove = !0, this.scrollToSelectedValue = !0, this.orientation = "horizontal";
  }
};
customElements.get("mc-segmented-control") || customElements.define("mc-segmented-control", t);
export { t as McSegmentedControl };