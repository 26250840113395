<div class="sidebar" [class.sidebar-visible]="toggleMenu()">
    <a [routerLink]="['workorders']" routerLinkActive="active">
        <mc-icon icon="container-hook"></mc-icon>
        <span class="m-l-8" i18n>Work Orders</span></a>
    <a (click)="toggleReports()">
        <mc-icon icon="folder"></mc-icon>
        <span class="m-l-8" i18n>Reports</span>
        @if(isReportsSubMenuOpen){
        <mc-icon icon="chevron-up"></mc-icon>
        }
        @else{
        <mc-icon icon="chevron-down"></mc-icon>
        }

    </a>
    @if(isReportsSubMenuOpen){
    <a routerLink="/reports/workorders-export" routerLinkActive="active">
        <span class="m-l-24" i18n>Export Workorders</span></a>
    @if(authPolicy.canViewVSCReports){
    <a routerLink="/reports/vsc" routerLinkActive="active">
        <span class="m-l-24" i18n>VSC</span></a>
    }
    }


    @if(enableManualWorkOrderApproval()){
    @if(authPolicy.canViewManualWorkOrder){
    <a [routerLink]="['approvals']" routerLinkActive="active">
        <mc-icon icon="stamp"></mc-icon>
        <span class="m-l-8" i18n>Approvals</span></a>
    }

    @if(authPolicy.canViewApprovalSettings){
    <a (click)="toggleSubMenu()"><mc-icon icon="cog"></mc-icon>
        <span class="m-l-8" i18n>Approval Settings</span>
        @if(isApprovalSubMenuOpen){
        <mc-icon icon="chevron-up"></mc-icon>
        }
        @else{
        <mc-icon icon="chevron-down"></mc-icon>
        }
    </a>
    @if(isApprovalSubMenuOpen){
    <a routerLink="/approvalSettings/disableValidation" routerLinkActive="active">
        <span class="m-l-24" i18n>Disable Validations</span></a>
    <a routerLink="/approvalSettings/masterDataConfig" routerLinkActive="active">
        <span class="m-l-24" i18n>Master Data Configuration</span></a>

    }
    }
    }
    @if(authPolicy.isAppSupportAdmin){
    <a [routerLink]="['Retrigger']" routerLinkActive="active">
        <mc-icon icon="arrow-clockwise"></mc-icon>
        <span class="m-l-8" i18n>Retrigger</span></a>
    }
    <footer class="version">
        Version: {{appVersion}}
    </footer>
    <footer>
        &#9400;A.P Moller-Maersk
    </footer>
</div>