import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Equipment } from "../../../../models/equipment.model";
import { ContainerGrade } from "../../../../models/container-grade.model";
import { Mode } from "../../../../models/mode.model";

/**
 * Maersk containers are assigned grades based on the condition of the containers
 * The grades are
 * E-General cargo shipments
 * K-General cargo - Clean container/Premium shipments
 * M-Food grade type shipments
 * S-Flexi-bags shipments and Premium Food grade
 * As evident Q is the worst grade possible and is used to carry scrap cargo shipments
 * The container grade validation makes sure that there is no food grade upgrade request for grade type Q as they
 * cannot and should not be upgraded to food grade and should be used for scrap and general cargo shipments only
 * which are grades E,K and Q
 * Bottomline grade Q containers can only be upgraded to grade E or K
 * @param equipment
 * @param containerGrades 
 * @param mode 
 * @returns 
 */
export function containerGradeValidator(
    equipment: Equipment | null,
    containerGrades : ContainerGrade[], 
    mode: Mode | null
  ): ValidatorFn {
    return (control : AbstractControl) => {
      let enteredValue=control.value as string;
      const allowedMode : string = "03";
      if(!enteredValue){
        return null;
      }
      let filteredGrade = containerGrades.find(x => {
        return x.code === equipment?.gradeCode;
      });  
      if(filteredGrade?.repairMode === allowedMode && mode?.code === allowedMode){
      return filteredGrade?.notApplicableRepairCodes.find(x => x == enteredValue) ? { containerGradeValidator: { value: control.value }} : null ;
      }
      return null;
    }
}