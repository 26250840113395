import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlagOnOffFilterForm } from '../../../form-model/flag-on-off-filter.form.model';
import { AuthPolicies } from '../../../auth/auth-policies';
import { Shop } from '../../../models/shop.model';
import { catchError, debounceTime, distinctUntilChanged, firstValueFrom, ReplaySubject, takeUntil, timer } from 'rxjs';
import { FlagConfigFIlter } from '../../../models/flag-config-filter.model';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdvanceFilterComponent } from '../../list-work-orders/advance-filter/advance-filter.component';
import { FlagOnOffAdvanceFilterComponent } from '../flag-on-off-advance-filter/flag-on-off-advance-filter.component';
import { FlagOnOffAddValidationComponent } from '../flag-on-off-add-validation/flag-on-off-add-validation.component';
import { FlagOnOffAddForm } from '../../../form-model/flag-on-off-add-form.model';
type FilterValue = string |boolean | RuleValidation | Cluster[]| ShopCode[]|RuleValidation[]|Loc[] |Loc|Cluster|ShopCode;
import * as XLSX from 'xlsx';
import { Cluster, Loc, ShopCode } from '../../../models/loc.model';
import { RuleValidation } from '../../../models/rule-validation.model';
import { FlagOnOffConfigService } from '../flag-on-off-config.service';
import { flagOnOffHeader } from '../../../models/flag-on-off.model';

@Component({
    selector: 'app-flag-on-off-filter',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FlagOnOffAdvanceFilterComponent, FlagOnOffAddValidationComponent],
    templateUrl: './flag-on-off-filter.component.html',
    styleUrl: './flag-on-off-filter.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlagOnOffFilterComponent {
  @Input({ required: true }) filterFormGroup!: FormGroup<{
    flagFilterForm: FormGroup<FlagOnOffFilterForm>;
  }>;
  showAdvanceFilters: boolean = false;
  showAddModal: boolean = false;

  savedFilter!: FormGroup<FlagOnOffFilterForm>;
  taggedFilters: { key: string; value: FilterValue }[] = [];
  addFormGroup:FormGroup;
  tableData:flagOnOffHeader[]=[];
 
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private authPolicies: AuthPolicies, private formBuilder: FormBuilder,private flagOnOffConfigService: FlagOnOffConfigService) {
    this.addFormGroup = this.formBuilder.group({
      flagAddForm: this.formBuilder.group<FlagOnOffAddForm>({} as FlagOnOffAddForm),
    });
  }
  ngOnInit() {
    this.subscribeToFormChanges();
  }

  async onDownloadClick() {
    this.tableData=await firstValueFrom(this.flagOnOffConfigService.onDownloadValidation()
    .pipe(takeUntil(this.destroyed$),
     catchError(err => {
      throw err
    })));
    const flattenedData = this.tableData.map(item => ({
      ...item,
      Shop: item.Shop
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ruleConfiguration.xlsx');
  }

  private subscribeToFormChanges() {
    this.filterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.value) {
          this.showAdvanceFilters = false;
          this.updateSelectedFiltersSection();
        }
      });
  }
  updateSelectedFiltersSection() {
    this.markAsTagged("Region", this.filterFormGroup.controls.flagFilterForm.controls.location.value);
    this.markAsTagged("Cluster", this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value);
    this.markAsTagged("Shops", this.filterFormGroup.controls.flagFilterForm.controls.shop.value);
    this.markAsTagged("Validation", this.filterFormGroup.controls.flagFilterForm.controls.validation.value);
    this.updateStatusCountAndTag();

  }
  private updateStatusCountAndTag() {
    const filters: FlagConfigFIlter = {};
    if (this.filterFormGroup.controls.flagFilterForm.controls.location.value) {
      filters.location = this.filterFormGroup.controls.flagFilterForm.controls.location.value;
    }
    if (this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value) {
      filters.countrycluster = this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value;
    }
    if (this.filterFormGroup.controls.flagFilterForm.controls.shop.value) {
      filters.shop = this.filterFormGroup.controls.flagFilterForm.controls.shop.value;
    }
    if (this.filterFormGroup.controls.flagFilterForm.controls.validation.value) {
      filters.validation = this.filterFormGroup.controls.flagFilterForm.controls.validation.value;
    }
  }

  markAsTagged(key: string, newValue: FilterValue | null) {
    const currentValue = this.getFilterValue(key);
    if (currentValue !== newValue) {
      this.removeFilter(key);
      if (newValue !==false && newValue !== null && newValue !== '' && (!Array.isArray(newValue) || newValue.length > 0)) {
        this.taggedFilters.unshift({ key, value: newValue });
      }
    }
  }

  removeFilter(key: string) {
    this.taggedFilters = this.taggedFilters.filter(filter => filter.key !== key);
  }

  getFilterValue(key: string) {
    const filter = this.taggedFilters.find(filter => filter.key === key);
    return filter ? filter.value : null;
  }

  showAdvanceFiltersModal() {
    this.showAdvanceFilters = true;
    this.saveCurrentFilters();
  }

  closeAdvanceFiltersModal() {
    if (this.showAdvanceFilters) {
      this.showAdvanceFilters = false;
      this.filterFormGroup.controls.flagFilterForm.reset(this.savedFilter.value);
    }
    this.updateSelectedFiltersSection();
    this.filterFormGroup.controls.flagFilterForm.controls.shop.enable();
  }

  clearAllSelectedFilters() {
    this.filterFormGroup.reset();
    this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }

  private saveCurrentFilters(): void {
    this.savedFilter = this.formBuilder.group({
      location: this.formBuilder.control<Loc[] | null>(this.filterFormGroup.controls.flagFilterForm.controls.location.value),
      countrycluster: this.formBuilder.control<Cluster[] | null>(this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value),
      validation: this.formBuilder.control<RuleValidation | null>(this.filterFormGroup.controls.flagFilterForm.controls.validation.value),
      shop: this.formBuilder.control<ShopCode[] | null>(this.filterFormGroup.controls.flagFilterForm.controls.shop.value),
      applyFlagFilter: this.formBuilder.control<boolean | null>(this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.value),

    });
  }
  unselectSpecificFilter(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): void {
    switch (taggedFilter.key) {
      case "Region":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let region = taggedFilter.value as Loc[]
          const updatedRegion:Loc[]= region.filter((_: Loc, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.flagFilterForm.controls.location.setValue(updatedRegion.length ? updatedRegion : null);
        }
        break;

      case "Cluster":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let cluster = taggedFilter.value as Cluster[]
          const updatedCluster:Cluster[]= cluster.filter((_: Cluster, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.setValue(updatedCluster.length ? updatedCluster : null);
        }
        break;

      case "Validation":
        this.filterFormGroup.controls.flagFilterForm.controls.validation.setValue(null);
        break;

      case "Shops":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let shops = taggedFilter.value as ShopCode[]
          const updatedShops: ShopCode[] = shops.filter((_: ShopCode, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.flagFilterForm.controls.shop.setValue(updatedShops.length ? updatedShops : null);
        }
        break;

      default:
        break;
    }
    this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }


  getTaggedFilterValue(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): FilterValue {
    switch (taggedFilter.key) {
      case "Region":
        let region = taggedFilter.value as Loc[]
        return region[itemNumber];
      case "Cluster":
        let cluster = taggedFilter.value as Cluster[]
        return cluster[itemNumber];

      case "Validation":
        let rule = taggedFilter.value as RuleValidation
        return rule.ruleName;

      case "Shops": 
        let shops = taggedFilter.value as ShopCode[]
        return shops[itemNumber];

      default:
        return '';
    }
  }

  getTaggedRegion(): Loc[] {
    const regionTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Region");
    return regionTag && Array.isArray(regionTag.value) ? regionTag.value as Loc[] : [];
  }

  getTaggedCountry(): Cluster[] {
    const country = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Cluster");
    return country && Array.isArray(country.value) ? country.value as Cluster[] : [];
  }
  getTaggedValidation(): RuleValidation[] {
    const validation = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Validation");
    return validation && Array.isArray(validation.value) ? validation.value as RuleValidation[] : [];
  }
  getTaggedShop(): ShopCode[] {
    const shop = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Shops");
    return shop && Array.isArray(shop.value) ? shop.value as ShopCode[] : [];
  }


  isArrayType(value: FilterValue): boolean {
    return Array.isArray(value);
  }

  public onClickEvent(addClick: boolean) {
   if(addClick==true){
     this.closeAddModal();
   }
  }
  public onClickEvents(addClick: boolean) {
    if(addClick==true){
      timer(1000).subscribe(x => {
        this.closeAdvance();
      })
    }
   }

   closeAdvance(){
    this.updateSelectedFiltersSection();
    this.showAdvanceFilters=false;
    
   }
   
  onAddValidationClick() {
    this.showAddModal = true;
  }

  closeAddModal() {
    this.showAddModal = false;
  }
}
