import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MasterHeaderForm } from '../../../form-model/master-header.form.model';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MasterCategory, MasterConfiguration } from '../../../models/master-category.model';
import { catchError, firstValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { ToastrService } from '../../../services/toastr-service';
import { MasterDataConfigService } from '../master-data-config.service';

@Component({
    selector: 'app-master-data-header',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    templateUrl: './master-data-header.component.html',
    styleUrl: './master-data-header.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterDataHeaderComponent {
  @Input({ required: true }) masterHeaderForm!: FormGroup<MasterHeaderForm>;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  @Output() valueChange= new EventEmitter<number>();

  categoryList: MasterCategory[] = []
  configList: MasterConfiguration[] = [];
  getCategoryCall: APICallStatus = "NOT_STARTED";
  getConfigCall: APICallStatus = "NOT_STARTED";
  constructor(private formBuilder: FormBuilder, private masterService: MasterDataConfigService,
    private toastrService: ToastrService) {
  }
  ngOnInit() {
    this.addControlsToTheFormGroup();
    this.masterHeaderForm.controls['configurationFile'].disable();
    this.getAllCategory();
  }

  private addControlsToTheFormGroup() {
    this.masterHeaderForm.addControl('category', this.formBuilder.nonNullable.control<MasterCategory>({}));
    this.masterHeaderForm.addControl('configurationFile', this.formBuilder.nonNullable.control<MasterConfiguration>({}));

  }


  getAllCategory() {
    this.masterService.getAllCategoryList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getCategoryCall = "FAILED";
          this.toastrService.showToastr("Unable to get Category details", "error");
          throw err;
        })).subscribe((data) => {
          this.categoryList = data;
          this.getCategoryCall = "COMPLETED_SUCCESFULLY";
        })
  }

  onCategorySelected(event: Event) {
    let customEvent = event as CustomEvent;
    if (customEvent.detail.value) {
      this.getFileByCategory(customEvent.detail.value);
      this.masterHeaderForm.controls['configurationFile'].reset();
      this.masterHeaderForm.controls['configurationFile'].enable();

    } else {
      this.masterHeaderForm.controls['configurationFile'].reset();
      this.masterHeaderForm.controls['configurationFile'].disable();
    }

  }
  async getFileByCategory(category: string) {
    this.configList = await firstValueFrom(this.masterService.getConfigFileByCategory(category)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getConfigCall = "FAILED";
          this.toastrService.showToastr("Unable to get config details", "error");
          throw err;
        })));
  }
  emitValue(){
    this.valueChange.emit(this.masterHeaderForm.controls['configurationFile'].value.tableID);
    
  }
}
