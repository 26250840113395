var p = Object.defineProperty;
var u = Object.getOwnPropertyDescriptor;
var s = (m, a, t, e) => {
  for (var i = e > 1 ? void 0 : e ? u(a, t) : a, o = m.length - 1, n; o >= 0; o--) (n = m[o]) && (i = (e ? n(a, t, i) : n(i)) || i);
  return e && i && p(a, t, i), i;
};
import { html as f, LitElement as k, isServer as l } from "lit";
import { property as y, state as h } from "lit/decorators.js";
import g from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
var c = [g];
import "@maersk-global/mds-components-core-button";
import { localStore as d } from "@maersk-global/shared-js";
var r = class extends k {
  constructor() {
    super();
    this.LSThemeCategory = "mds-theme-switch";
    this.LSThemeKey = "theme";
    this.theme = "auto";
    this.fit = "medium";
    this.nextTheme = "dark";
    this.icon = "moon";
    if (!l) {
      let t = d.getItem(this.LSThemeKey, this.LSThemeCategory);
      t && this.theme !== t && (this.theme = t);
    }
  }
  connectedCallback() {
    super.connectedCallback(), this.styleLight = document.querySelector("link[rel=stylesheet][media*=prefers-color-scheme][media*=light]"), this.styleDark = document.querySelector("link[rel=stylesheet][media*=prefers-color-scheme][media*=dark]"), this.switchTheme(this.theme), l || (window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => this.onSystemColorSchemeChange()), this.browserScheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"), this.fireChangeEvent();
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.styleLight = null, this.styleDark = null, l || window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", () => this.onSystemColorSchemeChange());
  }
  static get styles() {
    return c;
  }
  onSystemColorSchemeChange() {
    this.browserScheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light", this.fireChangeEvent();
  }
  willUpdate(t) {
    super.willUpdate(t), (t.has("theme") || t.has("browserScheme")) && (this.theme === "auto" ? this.nextTheme = this.browserScheme === "dark" ? "light" : "dark" : this.nextTheme = this.theme !== "dark" ? "dark" : "light", this.icon = this.nextTheme === "dark" ? "moon" : "sun");
  }
  get label() {
    let t = {
        light: {
          en: "light",
          fr: "clair",
          de: "hell",
          zh_cn: "\u6D45\u8272",
          zh_tw: "\u6DFA\u8272",
          ja: "\u30E9\u30A4\u30C8",
          ko: "\uB77C\uC774\uD2B8",
          ru: "\u0441\u0432\u0435\u0442\u043B\u044B\u0439",
          pt_br: "claro",
          es_mx: "claro",
          it: "chiaro"
        },
        dark: {
          en: "dark",
          fr: "sombre",
          de: "dunkel",
          zh_cn: "\u6DF1\u8272",
          zh_tw: "\u6DF1\u8272",
          ja: "\u30C0\u30FC\u30AF",
          ko: "\uB2E4\uD06C",
          ru: "\u0442\u0435\u043C\u043D\u044B\u0439",
          pt_br: "escuro",
          es_mx: "oscuro",
          it: "scuro"
        }
      },
      e = this.nextTheme;
    l || (e = t[this.nextTheme][document.documentElement.lang] || this.nextTheme);
    let i = {
      en: `Switch to ${e} theme`,
      fr: `Passer au th\xE8me ${e}`,
      de: `Wechseln Sie zum ${e}-Thema`,
      zh_cn: `\u5207\u6362\u5230${e}\u4E3B\u9898`,
      zh_tw: `\u5207\u63DB\u5230${e}\u4E3B\u984C`,
      ja: `${e}\u30C6\u30FC\u30DE\u306B\u5207\u308A\u66FF\u3048`,
      ko: `${e} \uD14C\uB9C8\uB85C \uC804\uD658`,
      ru: `\u041F\u0435\u0440\u0435\u043A\u043B\u044E\u0447\u0438\u0442\u044C\u0441\u044F \u043D\u0430 ${e} \u0442\u0435\u043C\u0443`,
      pt_br: `Mudar para o tema ${e}`,
      es_mx: `Cambiar al tema ${e}`,
      it: `Passa al tema ${e}`
    };
    return l ? i.en : i[document.documentElement.lang] || i.en;
  }
  render() {
    return f`<mc-button appearance="neutral" variant="plain" .icon="${this.icon}" @click="${this.changeTheme}" aria-label="${this.label}" hiddenlabel .fit="${this.fit}">${this.label}</mc-button>`;
  }
  changeTheme() {
    this.theme = this.nextTheme, this.switchTheme(this.theme), this.fireChangeEvent(), d.setItem(this.LSThemeKey, this.theme, this.LSThemeCategory);
  }
  fireChangeEvent() {
    this.dispatchEvent(new CustomEvent("change", {
      detail: {
        theme: this.theme
      }
    }));
  }
  switchTheme(t) {
    if (!this.styleLight || !this.styleDark) return;
    let e = t === "light" ? "all" : "not all",
      i = t === "dark" ? "all" : "not all";
    t === "auto" && (e = "(prefers-color-scheme: light)", i = "(prefers-color-scheme: dark)"), this.styleLight.media = e, this.styleDark.media = i;
  }
};
s([h()], r.prototype, "theme", 2), s([y({
  type: String
})], r.prototype, "fit", 2), s([h()], r.prototype, "styleLight", 2), s([h()], r.prototype, "styleDark", 2), s([h()], r.prototype, "browserScheme", 2), s([h()], r.prototype, "nextTheme", 2), s([h()], r.prototype, "icon", 2);
customElements.get("mc-theme-switch") || customElements.define("mc-theme-switch", r);
export { r as McThemeSwitch };