import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input, signal } from '@angular/core';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-button';

@Component({
    selector: 'app-button-with-confirmation',
    imports: [],
    templateUrl: './button-with-confirmation.component.html',
    styleUrl: './button-with-confirmation.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonWithConfirmationComponent {

  loading = signal<boolean>(false);
  label = input.required<string>();
  fit = input<"small" | "medium" | "large">("small");
  appearance = input<"primary" | "secondary" | "neutral" | "inverse" | "error">("primary");
  variant = input<"filled" | "outlined" | "plain">("filled");
  hiddenlabel = input<boolean>(false);
  icon = input<string>("");
  modalBody = input.required<string>();
  disabled=input<boolean>(false);
  modalHeading = input.required<string>();
  confirmButtonLabel = input.required<string>();
  cancelButtonLabel = input.required<string>();
  onConfirm = input.required<() => void>();
  onCancel = input<() => void>(() => { });
  showModal = signal<boolean>(false);

  openModal(): void {
    this.showModal.set(true);
  }
  close(): void {
    this.showModal.set(false);
  }
  confirm(): void {
    this.onConfirm()();
    this.close();
  }
  cancel(): void {
    this.onCancel()();
    this.close();
  }
}
