import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit, Renderer2, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RepairLineItemsFormBulk } from '../../../form-model/damage-and-repair.form.model';
import { RepairLineItemFormNoAttachmentAndParts } from '../../../form-model/cwo-repair-line-item.form.model';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';
import { catchError, combineLatest, forkJoin, ReplaySubject, Subscription, take, takeUntil, timer } from 'rxjs';
import { MasterDataService } from '../../../services/master-data.service';
import { Repair } from '../../../models/repair.model';
import { CodeDescription } from '../../../models/code-description.model';
import { CommonModule } from '@angular/common';
import { BulkWorkOrderRepairLineItemComponent } from './bulk-work-order-repair-line-item/bulk-work-order-repair-line-item.component';
import { RepairLocation } from '../../../models/repair-location.model';
import { getTPIText, ThirdPartyIndicator } from '../../../models/tpi.model';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { Shop } from '../../../models/shop.model';
import { LoaderComponent } from '../../../shared-components/loader/loader.component';
import { BulkWorkOrderRepairLineItem } from '../../../models/bulk-work-order/bulk-work-order-repair-line-item.model';
import { reapirCodeAndRepairLocationUniqueValidator } from '../../../shared-validators/repair-code-and-repair-location-unique.validator'
import { mutualExclusiveRepairCodeValidator } from '../../../shared-validators/mutual-exclusive-repair-code.validator';
import { RepairExclusion } from '../../../models/repair-exclusion.model';
import { ErrorComponent } from '../../../shared-components/error/error.component';
@Component({
    selector: 'app-bulk-work-order-repair-line-items',
    imports: [ReactiveFormsModule, CommonModule, BulkWorkOrderRepairLineItemComponent, LoaderComponent,ErrorComponent],
    templateUrl: './bulk-work-order-repair-line-items.component.html',
    styleUrl: './bulk-work-order-repair-line-items.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BulkWorkOrderRepairLineItemsComponent implements OnInit, OnDestroy {
  repairLineItemsForms!: FormGroup<RepairLineItemsFormBulk>;
  repairs = signal<Repair[]>([]);
  damages = signal<CodeDescription[]>([]);
  repairLocations = signal<RepairLocation[]>([]);
  tpiCodes = signal<ThirdPartyIndicator[]>([]);
  modeAndShopSelected = signal(false);
  apiCallsForMasterData = signal<APICallStatus>("NOT_STARTED");
  selectedShop = signal<Shop | null>(null);
  repairExclusions:RepairExclusion[]=[];
  private repairAndDamageCodesApiCallSubscription: Subscription | undefined;
  constructor(public bulkWorkOrderSharedDataService: BulkWorkOrderSharedDataService,
    public masterDataService: MasterDataService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2) {
      this.bulkWorkOrderSharedDataService.Reset.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.initializeOrResetRepairLineItemsForm();
        this.repairLineItems.push(this.getSingleRepairLineItem());
      });
  }
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  ngOnInit(): void {
    combineLatest([this.bulkWorkOrderSharedDataService.Mode, this.bulkWorkOrderSharedDataService.Shop]).
      pipe(takeUntil(this.destroyed$)).
      subscribe(([mode, shop]) => {
        if (mode && shop) {
          this.selectedShop.set(shop);
          this.modeAndShopSelected.set(true);
          this.apiCallsForMasterData.set("IN_PROGRESS");
          this.initializeOrResetRepairLineItemsForm();
          this.repairAndDamageCodesApiCallSubscription?.unsubscribe();
          timer(100).pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.repairAndDamageCodesApiCallSubscription = forkJoin(
              [this.masterDataService.getRepairCodes(mode.code, shop.maintenanceShopCode, shop.maintenanceShopCurrency.exchangeRateFromUsd),
              this.masterDataService.getDamageCodes(mode.damageType),
              this.masterDataService.getRepairLocations().pipe(take(1)),
              this.masterDataService.getRepairTpiCodes(),
              this.masterDataService.getRepairExclusions(mode.code)]).
              pipe(catchError((error) => {
                console.error("Error while fetching repair and damage codes", error);
                throw error;
              }), takeUntil(this.destroyed$)).
              subscribe(([repairs, damages, repairLocations, tpiCodes,exclusionCodes]) => {
                this.repairs.set(this.filterRepairsForBulkWorkOrder(repairs));
                this.damages.set(damages);
                this.repairLocations.set(repairLocations.filter(x=>x.damageType===mode.damageType));
                this.tpiCodes.set(tpiCodes);
                this.apiCallsForMasterData.set("COMPLETED_SUCCESFULLY");
                this.repairLineItems.push(this.getSingleRepairLineItem());
                this.repairExclusions=exclusionCodes;
                this.addValidatorsToRepairLineItemsFormGroup();
              });
          });

        }
      });



  }
  get repairLineItems(): FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>> {
    return this.repairLineItemsForms.get('repairLineItems') as FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>>;
  }

  private addValidatorsToRepairLineItemsFormGroup():void{
    this.repairLineItemsForms.clearValidators();
    this.repairLineItemsForms.addValidators([reapirCodeAndRepairLocationUniqueValidator(), mutualExclusiveRepairCodeValidator(this.repairExclusions)]);

  }
  private filterRepairsForBulkWorkOrder(repairs: Repair[]): Repair[] {
    const allowedRepairs=["0910","0911","0912","0913","0914","0915","0922","0923","0924","0925","0940","0942","0948",];
    return repairs.filter(x => allowedRepairs.includes(x.code) && x.isActive===true && x.isSuspended===false);
  }
  private initializeOrResetRepairLineItemsForm(): void {
    this.reset();
    this.subscribeToChangeAndWriteDataToSharedService();
    this.subscribeToFromStatusChange();
  }

  private reset():void{
    this.repairLineItemsForms = new FormGroup<RepairLineItemsFormBulk>({
      repairLineItems: new FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>>([])
    });
    this.repairLineItemsForms.controls.repairLineItems.reset();
    this.bulkWorkOrderSharedDataService.BulkWorkOrderRepairLineItems = [];
  }

  private subscribeToChangeAndWriteDataToSharedService(): void {
    this.repairLineItemsForms.controls.repairLineItems.valueChanges.subscribe((val) => {
      const lineItemsWithMandatoryFields=val?.filter(x=>x.repairCode && x.repairLocationCode && x.damageCode && x.tpiCode)
      const value: BulkWorkOrderRepairLineItem[] = lineItemsWithMandatoryFields.map(repairLineItem => {
        const excludePreparationHours = this.repairs().find(x => x.code === repairLineItem.repairCode)?.excludePreparationHours ?? true;
        return {
          repairQuantity: repairLineItem.pieces ?? 0,
          manHoursPerPiece: repairLineItem.manHoursPerPiece ?? 0,
          excludePreparationHours: excludePreparationHours,
          materialCostPerPiece: repairLineItem.materialCostPerPiece ?? 0,
          equipmentDamageCode: repairLineItem.damageCode ?? "",
          maintenanceTypeCode: repairLineItem.repairCode ?? "",
          maintenanceRepairLocationCode: repairLineItem.repairLocationCode ?? "",
          thirdPartyIndicator: getTPIText(repairLineItem.tpiCode ?? "")
        }
      });
      this.bulkWorkOrderSharedDataService.BulkWorkOrderRepairLineItems = value;
    });
  }

  private subscribeToFromStatusChange():void{
    this.repairLineItemsForms.controls.repairLineItems.statusChanges.subscribe((status)=>{
      this.bulkWorkOrderSharedDataService.RepairLineItemFormStatus = status;
    })
  }

  private getSingleRepairLineItem(): FormGroup<RepairLineItemFormNoAttachmentAndParts> {
    let repairLineItem = this.formBuilder.nonNullable.group<RepairLineItemFormNoAttachmentAndParts>({
      damageCode: this.formBuilder.nonNullable.control<string>(''),
      repairCode: this.formBuilder.nonNullable.control<string>(''),
      repairLocationCode: this.formBuilder.nonNullable.control<string>(''),
      tpiCode: this.formBuilder.nonNullable.control<string>(''),
      pieces: this.formBuilder.nonNullable.control<number>(0),
      materialCostPerPiece: this.formBuilder.nonNullable.control<number>(0),
      manHoursPerPiece: this.formBuilder.nonNullable.control<number>(0),
      itemTotalCost: this.formBuilder.nonNullable.control<string>({ value: '0', disabled: true })
    }) as FormGroup<RepairLineItemFormNoAttachmentAndParts>;
    return repairLineItem;
  }
  onRemoveRepairLineItem(index: number) {

    this.repairLineItems.removeAt(index);
  }
  onAddRepairLineItemClicked() {
    this.repairLineItems.push(this.getSingleRepairLineItem());
    let length = this.repairLineItems.length - 1;
    if (length > 0) {
      timer(200).subscribe(x => {
        let id = `#repair-line-item${length}-damage-code`;
        let element = this.renderer.selectRootElement(id);
        element.focus();
      });
    }
  }
}
