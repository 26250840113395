import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { catchError, ReplaySubject, takeUntil } from 'rxjs';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { MasterDataConfigService } from '../master-data-config.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EditRequest, MasterDataColumn, MasterDataGrid, RowValue } from '../../../models/master-data.model';
import { MasterHeaderForm } from '../../../form-model/master-header.form.model';
import { ToastrService } from '../../../services/toastr-service';
import { utils } from '../../../helper/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-master-data-grid',
    imports: [NgxSkeletonLoaderModule, RouterModule, CommonModule, FormsModule, ReactiveFormsModule],
    templateUrl: './master-data-grid.component.html',
    styleUrl: './master-data-grid.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterDataGridComponent {
  data!: MasterDataGrid;
  firstPage: number | undefined;
  previousPage: number | undefined;
  currentPage: number = 1;
  nextPage: number | undefined;
  lastPage: number | undefined;
  totalItemCount: number | undefined;
  apiVersion: string | undefined;
  pageLimit: number = 20;
  sort: string | undefined;
  getMasterConfigApiCallStatus: APICallStatus = "NOT_STARTED";
  tableID: number | undefined;
  openEditDialog: boolean = false;
  actionCols!: Array<TableColumn>;
  columns!: Array<TableColumn>;
  headers: string[] = [];
  showHidden: boolean = false;
  dataRecords!: { id: number; }[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  masterDataEditForm: FormGroup;
  @Input({ required: true }) masterFormGroup!: FormGroup<{
    masterHeaderForm: FormGroup<MasterHeaderForm>;
    masterFilterForm: FormGroup;
  }>
  fieldforms!: Array<{ name: string; value: string | number }>;
  entireRow!: RowValue
  @Input() receivedFileChangeValue: number = 0;
  @Input() receivedValue: boolean = false;
  filterParam: FormGroup | undefined;
  newFilter: { [key: string]: string | boolean | string[] | number } | { FilterId: string; FilterValue: string }[] = {};
  submitEdit:boolean=false;
  showEdit:boolean=false;
  displayErrMsg: boolean = false;
  uploadErrList: Array<HttpErrorResponse> = [];


  constructor(private masterDataConfigService: MasterDataConfigService, private formBuilder: FormBuilder, private toasterService: ToastrService) {
    this.masterDataEditForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.tableID = this.masterFormGroup.controls.masterHeaderForm.controls['configurationFile'].value.tableID;
    this.sort = `id;asc`;
  }

  private subscribeToFormChanges() {
    this.masterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.masterFormGroup && this.masterFormGroup.controls.masterFilterForm && this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter']) {
          if (this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].value)
            this.onApplyFilter()
          this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].setValue(false);

        }

      });
    if (this.masterFormGroup && this.masterFormGroup.controls.masterFilterForm && this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter']) {
      if (this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].value)
        this.onApplyFilter()
      this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].setValue(false);
    }
  }
  onApplyFilter() {
    this.filterParam = this.masterFormGroup.controls.masterFilterForm.value;
    const formValues = this.masterFormGroup.controls.masterFilterForm.value;
    this.newFilter = { ...formValues }
    if (!Array.isArray(this.newFilter))
      delete this.newFilter?.['applyMasterFilter'];
    this.newFilter = this.transformObject(this.newFilter as { [key: string]: string });
    this.currentPage = 1;
    this.getMasterDataConfig();
  }
  transformObject(inputObject: { [key: string]: string }): { FilterId: string; FilterValue: string }[] {
    let result: { FilterId: string; FilterValue: string }[] = []
    for (const key in inputObject) {
      if (inputObject[key]) {
        result.push({
          FilterId: key,
          FilterValue: inputObject[key]
        });
      }
    }
    return result;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.masterDataConfigService.refreshTrigger$.subscribe(() => {
      this.subscribeToFormChanges();
    });

    this.masterDataConfigService.refreshConfigData$.subscribe(() => {
      this.newFilter = [];
      this.getMasterDataConfig();
    });

    if (changes['receivedFileChangeValue']) {
      this.ngOnInit();
      this.newFilter = [];
      this.getMasterDataConfig();
      this.subscribeToFormChanges();
    }
    if (changes['receivedValue'] && !changes['receivedValue'].isFirstChange()){
      this.onApplyFilter();
    }


  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  private sortColumnName(gridColumnName: string): string {
    return gridColumnName
  }
 
  onPageLimitChange(event: Event) {
    let customEvent = event as CustomEvent;
    this.currentPage = 1;
    this.pageLimit = customEvent.detail.value;
    this.getMasterDataConfig();
  }

  onPageChange(eventgetMasterDataConfig: Event) {
    let customEvent = event as CustomEvent;
    let changedPage = customEvent.detail as number;
    if (changedPage != this.currentPage) {
      this.currentPage = changedPage;
      this.getMasterDataConfig();
    }
  }
  getMasterDataConfig(): void {
    this.getMasterConfigApiCallStatus = "IN_PROGRESS";
    if (this.tableID) {
      this.masterDataConfigService.getMasterDataList(this.currentPage, this.pageLimit, this.tableID, this.sort, this.newFilter).pipe(takeUntil(this.destroyed$)).subscribe({
        next: response => {
          const headers = response.headers;
          const body = response.body || [];
          this.data = body as MasterDataGrid;
          this.dataRecords = this.data.records;
          this.firstPage = headers.get('First-Page') ? +headers.get('First-Page')! : undefined;
          this.previousPage = headers.get('Previous-Page') ? +headers.get('Previous-Page')! : undefined;
          this.currentPage = +headers.get('Current-Page')!;
          this.nextPage = headers.get('Next-Page') ? +headers.get('Next-Page')! : undefined;
          this.lastPage = headers.get('Last-Page') ? +headers.get('Last-Page')! : undefined;
          this.totalItemCount = headers.get('Total-Item-Count') ? +headers.get('Total-Item-Count')! : undefined;
          this.apiVersion = headers.get('API-Version') ?? undefined;
          this.fetchColumnHeaders(this.data.headers)

          this.getMasterConfigApiCallStatus = "COMPLETED_SUCCESFULLY"
        },
        error: err => {
          this.getMasterConfigApiCallStatus = "FAILED"
          this.dataRecords = []
        }
      }
      );
      // this.getMasterConfigApiCallStatus = "NOT_STARTED";
    }
  }
  //fetch column headers
  fetchColumnHeaders(columnHeaders: { name: string, value: string }[]) {

    const displayedCols = columnHeaders.filter((item: { value: string, name: string }) =>
      item.value !== 'id' &&
      item.value != 'created_at' &&
      item.value != 'updated_at'

    );
    this.columns = displayedCols.map((col: { name: string, value: string }) => ({
      label: col.name,
      id: col.value,
      
    }));
    this.actionCols = [
      {
        label: 'Created Date Time',
        id: 'created_at',
        sortDisabled: true,

        dataType: {
          type: 'string',

          formatter: (value: string) => {
            const date = new Date(value as string);
            return `${utils.dateFormatter.format(date)}`;
          }
        }
      },
      {
        label: 'Updated Date Time',
        id: 'updated_at',
        sortDisabled: true,
        dataType: {
          type: 'string',
          formatter: (value: string) => {
            const date = new Date(value as string);
            return `${utils.dateFormatter.format(date)}`;
          }
        }
      },
      {
        label: '',
        id: 'EditAction',
        sortDisabled: true,

      },
    ];
    this.columns = [...this.columns, ...this.actionCols];

  }
  onSortDirectionChange(event: Event) {
    let customEvent = event as CustomEvent;
    let sortColumn: string = this.sortColumnName(customEvent.detail.column)
    let sortDirection: string = customEvent.detail.direction == "ascending" ? "asc" : "desc"
    if (sortColumn != "id") {
      this.sort = `${sortColumn};${sortDirection}`;
      this.currentPage = 1;
      this.getMasterDataConfig();
    }
  };
  showEditModalDialog(row: RowValue, data: { name: string }[]) {
    this.openEditDialog = true;
    this.showEdit=false;
    this.headers = [];
    this.entireRow = row;
    this.uploadErrList=[];
    this.displayErrMsg = false;
    data.forEach((item: { name: string }) => {
      if (item.name != 'ID' &&
        item.name != 'Created Date Time' &&
        item.name != 'Created By' &&
        item.name != 'Updated Date Time' &&
        item.name != 'Updated By'
      ) {
        this.headers.push(item.name)
      }
    })
    this.addControlsToTheFormGroup();

  }
  closeEditDialog() {
    this.fieldforms = [];
    this.masterDataEditForm.reset();
    this.openEditDialog = false;
    this.displayErrMsg = false;
    this.uploadErrList=[];
  }
  addControlsToTheFormGroup() {
    this.fieldforms = [];
    for (const keyVal in this.entireRow)
      if (keyVal != 'id' &&
        keyVal != 'created_at' &&
        keyVal != 'created_by_id' &&
        keyVal != 'updated_at' &&
        keyVal != 'updated_by_id'
      )
        this.fieldforms.push({
          name: keyVal,
          value: this.entireRow[keyVal]
        });
    this.fieldforms.forEach((field: { name: string; value: { toString: () => string; }; }) => {
      this.masterDataEditForm.setControl(field.name, new FormControl(field.value.toString() || ''));
    })
  }
  onInputChange(){
    if(this.masterDataEditForm.dirty){
      this.showEdit=true
    }else{
      this.showEdit=false;
    }
  }
  onEdit() {
    this.submitEdit=true;
    let requestBody: EditRequest;
    requestBody = {
      tableId: this.tableID,
      columns: [{
        columnName: "id",
        columnValue: this.entireRow.id.toString()
      }]
    }
    this.fieldforms.forEach((item: { name: string }) => {
      requestBody.columns.push({
        columnName: item.name,
        columnValue: this.masterDataEditForm.controls[item.name].value.toString()
      });

    })
    this.masterDataConfigService.editValidation(requestBody)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          if (err.error.length > 0) {
            this.submitEdit=false;
            this.uploadErrList = err.error;
            this.displayErrMsg = true;
            this.openEditDialog = true;
          }
         // this.toasterService.showToastr("Error while editing details", "error");
          throw err;
        })).subscribe(() => {
          this.openEditDialog = false;
          this.submitEdit=false;
          this.getMasterDataConfig();
          this.displayErrMsg = false;
          this.toasterService.showToastr("Master data updated successfully", "success");
        });
 
  }
  onCancel(){
    this.masterDataEditForm.reset();
    this.openEditDialog=false;
    this.uploadErrList=[];
    this.displayErrMsg=false;
  }
}
