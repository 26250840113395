import { inject, Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator } from "@angular/forms";
import { catchError, map, Observable, of } from "rxjs";
import { MasterDataService } from "../../../../services/master-data.service";
import { Equipment } from "../../../../models/equipment.model";
import { BulkWorkOrderSharedDataService } from "../../../../services/bulk-work-order-shared-data.service";
import { Mode } from "../../../../models/mode.model";
import { Shop } from "../../../../models/shop.model";
import { ToastrService } from "../../../../services/toastr-service";
import { ApplicationtTextService } from "../../../../services/application-text.service";
import { AemsValidationError } from "../../../../models/aems-validation-error.model";

@Injectable({ providedIn: 'root' })
export class EquipmentDetailsValidator implements AsyncValidator {
    private masterDataService: MasterDataService
    private sharedDataService: BulkWorkOrderSharedDataService
    private toastrService: ToastrService;
    private appTextSvc: ApplicationtTextService;
    private selectedMode: Mode | null = null;
    private selectedShop: Shop | null = null;
    constructor() {
        this.masterDataService = inject(MasterDataService);
        this.sharedDataService = inject(BulkWorkOrderSharedDataService);
        this.toastrService = inject(ToastrService);
        this.appTextSvc = inject(ApplicationtTextService);
        this.sharedDataService.Mode.subscribe(mode => this.selectedMode = mode);
        this.sharedDataService.Shop.subscribe(shop => this.selectedShop = shop);
    }
    validate(control: AbstractControl): Observable<AemsValidationError | null> {
        if (control.value) {
            return this.masterDataService.getEquipmentDetails(control.value, "")
                .pipe(map(res => {
                    this.shopAndEquipmentLocationMismatch(res);
                    return (
                        this.validateEquipmentExist(res) ||
                        this.validateModeAndEquipmentType(res) ||
                        this.validateExpiredLeaseAgreement(res) ||
                        this.validateLeaseAgreementForSpecificEquipment(res) ||
                        this.validateInvalidContainerEquipmentTypes(res));
                }),
                    catchError(err => of({ equipmentDetails: { error: this.appTextSvc.EquipmentNotInComet } })))
        } else {
            return of(null);
        }
    }

    private validateEquipmentExist(equipment: Equipment): AemsValidationError | null {
        if (!equipment) {
            return { equipmentDetails: { error: this.appTextSvc.EquipmentNotInComet } };
        }
        return null;
    }

    private validateModeAndEquipmentType(equipment: Equipment): AemsValidationError | null {
        console.log(this.selectedMode?.code);
        if (this.selectedMode?.code === "03" && equipment?.equipmentTypeCode === 'REEF') {
            return { equipmentDetails: { error: this.appTextSvc.OnlyDryContainerAllowed } };
        }
        if ((this.selectedMode?.code === "43"|| this.selectedMode?.code==="02") && equipment?.equipmentTypeCode === 'DRY') {
            return { equipmentDetails: { error: this.appTextSvc.OnlyReeferContainerAllowed } };
        }
        return null;
    }

    private shopAndEquipmentLocationMismatch(equipment: Equipment): null {
        console.log(this.selectedShop)
        if (equipment.presentLocation !== this.selectedShop?.locationCode) {
            this.toastrService.showToastr(this.appTextSvc.LocationMismatchWarning, 'warning', 60000)
        }
        return null;
    }

    /**
     * If ownership type for the equipment is Vessel Sharing Agreement (VSA) or Shipper Owned (SO)
     * then repair is not allowed, irrespective of agreement type.
     * @param equipment 
     * @param control 
     * @returns 
     */
    private validateExpiredLeaseAgreement(equipment: Equipment): AemsValidationError | null {
        const ownershipTypeCodes: string[] = ['VSA', 'SO'];
        if (ownershipTypeCodes.includes(equipment.ownershipTypeCode ?? '')) {
            return { equipmentDetails: { error: this.appTextSvc.EquipmentTypeNotAllowed } };
        }
        return null;
    }

    /**
     * For container equipment types other than 'GENS' and 'CHAS', if the ownership type is Vessel Sharing Agreement (VSA) or Shipper Owned (SO),
     * or if there is lease agreement comment, repair is not allowed.
     * @param equipment 
     * @param control 
     * @returns 
     */
    private validateLeaseAgreementForSpecificEquipment(equipment: Equipment): AemsValidationError | null {
        const ownershipTypeCodes: string[] = ['VSA', 'SO'];
        const specificEquipmentTypes: string[] = ["GENS", "CHAS"];

        const isSpecificEquipmentType = specificEquipmentTypes.includes(equipment?.equipmentTypeCode ?? '');
        const hasLeaseAgreementComment = !!equipment.positionLeaseAgreementComment;
        const hasLeaseAgreementOwnershipType = ownershipTypeCodes.includes(equipment?.ownershipTypeCode ?? '');

        if (!isSpecificEquipmentType && (hasLeaseAgreementComment || hasLeaseAgreementOwnershipType)) {
            return { equipmentDetails: { error: this.appTextSvc.EquipmentLeaseValidation } };
        }

        return null;
    }

    /**
     * If container is of type TWDK/TWFL, then repair is not allowed on this container
     * @param equipment 
     * @param control 
     * @returns 
     */
    private validateInvalidContainerEquipmentTypes(equipment: Equipment): AemsValidationError | null {
        const containerParentCode = "CONT";
        const invalidEquipmentTypes = ["TWDK", "TWFL"];

        const isContainerType = equipment.equipmentTypeParentCode === containerParentCode;
        const isInvalidEquipmentType = invalidEquipmentTypes.includes(equipment?.equipmentTypeCode ?? '');

        if (isContainerType && isInvalidEquipmentType) {
            return { equipmentDetails: { error: this.appTextSvc.ContainerTypeNotAllowed } };
        }

        return null;
    }


}