import "../../chunk-SDBWQ4EE.js";
import s from "imask";
class a {
  constructor(t, e, n) {
    this.host = null;
    this.mask = null;
    this.maskOptions = void 0;
    this.inputElement = null;
    this.host = t, this.inputElement = n, this.maskOptions = e, this.host.addController(this);
  }
  hostConnected() {
    this.inputElement && this.maskOptions && (typeof this.maskOptions == "string" || this.maskOptions instanceof RegExp ? this.mask = s(this.inputElement, {
      mask: this.maskOptions
    }) : this.mask = s(this.inputElement, {
      ...this.maskOptions
    }));
  }
  hostDisconnected() {
    this.host = null, this.inputElement = null, this.destroyMask();
  }
  get unmaskedValue() {
    return this.mask?.unmaskedValue || "";
  }
  get maskedValue() {
    return this.mask?.value || "";
  }
  updateOptions(t) {
    typeof t == "string" || t instanceof RegExp ? this.mask?.updateOptions({
      mask: t
    }) : this.mask?.updateOptions(t);
  }
  destroyMask() {
    this.mask?.destroy();
  }
  onAccept(t) {
    return this.mask?.on("accept", t);
  }
  onComplete(t) {
    return this.mask?.on("complete", t);
  }
}
export { a as MaskController };