import "../../chunk-SDBWQ4EE.js"; /**
                                  * @license
                                  * Copyright 2020 Google LLC
                                  * SPDX-License-Identifier: Apache-2.0
                                  */
const r = "SelectionController";
class l {
  constructor() {
    this.selected = null;
    this.ordered = null;
    this.set = new Set();
  }
}
class o {
  constructor(e) {
    this.sets = {};
    this.focusedSet = null;
    this.mouseIsDown = !1;
    this.updating = !1;
    e.addEventListener("keydown", t => {
      this.keyDownHandler(t);
    }), e.addEventListener("mousedown", () => {
      this.mousedownHandler();
    }), e.addEventListener("mouseup", () => {
      this.mouseupHandler();
    });
  }
  static getController(e) {
    if (!e.name) return null;
    const t = e.getRootNode(),
      n = `${e.name}${r}`;
    return t[n] || (t[n] = new o(t)), t[n];
  }
  keyDownHandler(e) {
    const t = e.target;
    "checked" in t && this.has(t) && (e.key == "ArrowRight" || e.key == "ArrowDown" ? this.selectNext(t) : (e.key == "ArrowLeft" || e.key == "ArrowUp") && this.selectPrevious(t));
  }
  mousedownHandler() {
    this.mouseIsDown = !0;
  }
  mouseupHandler() {
    this.mouseIsDown = !1;
  }
  has(e) {
    return this.getSet(e.name).set.has(e);
  }
  selectPrevious(e) {
    const t = this.getOrdered(e),
      n = t.indexOf(e),
      s = t[n - 1] || t[t.length - 1];
    return this.select(s), s;
  }
  selectNext(e) {
    const t = this.getOrdered(e),
      n = t.indexOf(e),
      s = t[n + 1] || t[0];
    return this.select(s), s;
  }
  select(e) {
    e.click();
  }
  focus(e) {
    if (this.mouseIsDown) return;
    const t = this.getSet(e.name),
      n = this.focusedSet;
    this.focusedSet = t, n != t && t.selected && t.selected != e && t.selected.focus();
  }
  isAnySelected(e) {
    const t = this.getSet(e.name);
    for (const n of t.set) if (n.checked) return !0;
    return !1;
  }
  getOrdered(e) {
    const t = this.getSet(e.name);
    return t.ordered || (t.ordered = Array.from(t.set), t.ordered.sort((n, s) => n.compareDocumentPosition(s) == Node.DOCUMENT_POSITION_PRECEDING ? 1 : 0)), t.ordered;
  }
  getSet(e) {
    return this.sets[e] || (this.sets[e] = new l()), this.sets[e];
  }
  register(e) {
    const t = e.name;
    if (!t) return;
    const n = this.getSet(t);
    n.set.add(e), n.ordered = null;
  }
  unregister(e) {
    if (!e.name) return;
    const t = this.getSet(e.name);
    t.set.delete(e), t.ordered = null, t.selected == e && (t.selected = null);
  }
  update(e) {
    if (this.updating || !e.name) return;
    this.updating = !0;
    const t = this.getSet(e.name);
    if (e.checked) {
      for (const n of t.set) n != e && (n.checked = !1);
      t.selected = e;
    }
    if (this.isAnySelected(e)) for (const n of t.set) {
      if (n.tabindex === void 0) break;
      n.tabindex = n.checked ? 0 : -1;
    }
    this.updating = !1;
  }
}
export { o as SingleSelectionController, l as SingleSelectionSet };