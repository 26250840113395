<form [formGroup]="repairLineItemForm">
    <div class="pure-g line-item">
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            @let dc=repairLineItemForm.controls.damageCode;
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="200px" ngDefaultControl label="Damage Code" placeholder="Type Code" fit="small"
                formControlName="damageCode" [data]="damageCodesTypeahead"
                [attr.invalid]="ctrlValidSvc.isControlInErrorState(dc)"
                oninput="this.value = this.value.toUpperCase()" [id]="controlId()+'-damage-code'">
                @if(dc.hasError('required')){
                    <span slot="errormessage">{{appTextSvc.Required}}</span>
                    }
                    @if(dc.hasError('typeaheadCodeExistValidator'))
                    {
                    <span slot="errormessage">{{appTextSvc.Invalid}}</span>
                    }
            </mc-typeahead>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            @let rc=repairLineItemForm.controls.repairCode;
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="300px" ngDefaultControl label="Repair Code" placeholder="Type Code" fit="small"
                [attr.invalid]="ctrlValidSvc.isControlInErrorState(rc)"
                formControlName="repairCode" [data]="repairCodesTypeahead" (blur)="onRepairCodeOptionSelected($event)">
                @if(rc.hasError('required')){
                    <span slot="errormessage">{{appTextSvc.Required}}</span>
                    }
                    @if(rc.hasError('typeaheadCodeExistValidator'))
                    {
                    <span slot="errormessage">{{appTextSvc.Invalid}}</span>
                    }
            </mc-typeahead>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            @let rlc=repairLineItemForm.controls.repairLocationCode;
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="300px" ngDefaultControl label="Repair Loc code" placeholder="Type Code" fit="small"
                formControlName="repairLocationCode" [data]="repairLocationCodesTypeahead"
                [attr.invalid]="ctrlValidSvc.isControlInErrorState(rlc)"
                oninput="this.value = this.value.toUpperCase()">
                @if(rlc.hasError('required')){
                    <span slot="errormessage">{{appTextSvc.Required}}</span>
                    }
                    @if(rlc.hasError('typeaheadCodeExistValidator'))
                    {
                    <span slot="errormessage">{{appTextSvc.Invalid}}</span>
                    }
            </mc-typeahead>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-2-24 p-r-8 m-b-12">
            @let tpi=repairLineItemForm.controls.tpiCode;
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="400px" ngDefaultControl label="TPI" placeholder="Type Code" fit="small"
                [attr.invalid]="ctrlValidSvc.isControlInErrorState(tpi)"
                formControlName="tpiCode" [data]="tpiCodesTypeahead" oninput="this.value = this.value.toUpperCase()">
                @if(tpi.hasError('required')){
                    <span slot="errormessage">{{appTextSvc.Required}}</span>
                    }
                    @if(tpi.hasError('typeaheadCodeExistValidator'))
                    {
                    <span slot="errormessage">{{appTextSvc.Invalid}}</span>
                    }
            </mc-typeahead>
        </div>
        <div class="pure-u-md-2-24 pure-u-lg-2-24 pure-u-xl-1-24 p-r-8 m-b-12">
            @let pcs=repairLineItemForm.controls.pieces;
            <mc-input ngDefaultControl label="Pcs" fit="small" [attr.invalid]="ctrlValidSvc.isControlInErrorState(pcs)" formControlName="pieces">
                @if(pcs.hasError('required')){
                    <span slot="errormessage">Required</span>
                    } @if(pcs.hasError('max')) {
                    <span slot="errormessage">Exceeds maximum allowed limit</span>
                    }@if(pcs.hasError('pattern')){
                    <span slot="errormessage">Incorrect Format</span>
                    }
            </mc-input>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-2-24 p-r-8 m-b-12">
            @let manh=repairLineItemForm.controls.manHoursPerPiece;
            <mc-input ngDefaultControl label="Man Hrs/pc" fit="small"
            [attr.invalid]="ctrlValidSvc.isControlInErrorState(manh)"
                formControlName="manHoursPerPiece">
                @if(manh.hasError('required')){
                    <span slot="errormessage">Required</span>
                    } @if(manh.hasError('max')) {
                    <span slot="errormessage">Exceeds maximum allowed limit</span>
                    }@if(manh.hasError('pattern')){
                    <span slot="errormessage">Incorrect Format</span>
                    }
            </mc-input>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            @let matc=repairLineItemForm.controls.materialCostPerPiece;
            <mc-input ngDefaultControl label="Material cost/pc" fit="small" formControlName="materialCostPerPiece"
            [attr.invalid]="ctrlValidSvc.isControlInErrorState(matc)"
                [suffix]="shop()?.maintenanceShopCurrency?.currencyCode">
                @if(matc.hasError('required')){
                    <span slot="errormessage">Required</span>
                    } @if(matc.hasError('max')) {
                    <span slot="errormessage">Exceeds maximum allowed limit</span>
                    }@if(matc.hasError('pattern')){
                    <span slot="errormessage">Incorrect Format</span>
                    }
            </mc-input>
        </div>
        <div class="pure-u-md-10-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-input ngDefaultControl label="Item Total" fit="small" formControlName="itemTotalCost"
                [suffix]="shop()?.maintenanceShopCurrency?.currencyCode" readonly>
            </mc-input>
        </div>
        <div style="text-align: right;" class="pure-u-md-4-24 pure-u-lg-1-24 pure-u-xl-1-24 p-r-8 m-b-12 m-t-20">
            <mc-button class="m-r-4 m-t-4 repair-line-item-delete-button" label="Button " variant="outlined"
                appearance="neutral" fit="small" icon="trash" hiddenlabel padding="default"
                (click)="onRemoveRepairLineItemClicked()">
            </mc-button>
        </div>
    </div>
</form>