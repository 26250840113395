@if(notification()){
<mc-notification actionsposition="right" [icon]="notification()?.icon" [heading]="notification()?.title"
    [appearance]="notification()?.appearance" actionsposition="bottom">
    <div class="notification-body" [class.show]="showBody()">
        <div class="m-b-8">{{notificationBodyTitle()}}</div>
        <hr/>
        @for (item of notificationBody(); track item) {
            <div class="m-b-8 m-t-8">
                <div>
                    @for (message of item.metadataMessage; track message) {
                       <div style="font-weight:500">
                        {{message}}
                       </div>
                    }
                </div>
                <div>{{item.message}}</div>
            </div>
            <hr/>
        }
    </div>
    @if(showBody()){
    <div class="mds-text--small-normal action-container action-container-refresh m-t-8">
        <div (click)="onHideDetailClicked()">
            Hide Detail
        </div>
        @if(notification()?.state===1 || notification()?.state===2){
            <div>
                <mc-button
                label="Button"
                hiddenlabel
                fit="small"
                icon="arrow-clockwise"
                appearance="neutral"
                variant="plain"
                padding="none"
                (click)="onViewDetailClicked($event)"
                [loading]="notificationDetailApiCallStatus()==='IN_PROGRESS'">
              </mc-button>
                
            </div>
        }
    </div>
    }@else {
    <div (click)="onViewDetailClicked($event)" class="mds-text--small-normal action-container">
        <div>
            View Detail
        </div>
        @if(notificationDetailApiCallStatus()==="IN_PROGRESS"){
        <div>
            <mc-loading-indicator hiddenlabel appearance="primary" orientation="horizontal" fit="small">
            </mc-loading-indicator>
        </div>
        }
    </div>
    }

</mc-notification>
<div class="mds-text--x-small-normal mds-neutral--weak__text-color" style="position: absolute; right: 10px;top:8px;">
    {{notification()?.associateTaskCreatedAtTime}}
</div>
}