import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';import { FlagOnOffAdvanceFilterComponent } from '../../components/flag-on-off-config/flag-on-off-advance-filter/flag-on-off-advance-filter.component';
import { FlagOnOffFilterComponent } from '../../components/flag-on-off-config/flag-on-off-filter/flag-on-off-filter.component';
import { FlagOnOffGridComponent } from '../../components/flag-on-off-config/flag-on-off-grid/flag-on-off-grid.component';
import { FlagOnOffFilterForm } from '../../form-model/flag-on-off-filter.form.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-flag-on-off-config',
    imports: [ReactiveFormsModule, FlagOnOffFilterComponent, FlagOnOffGridComponent, NgxSkeletonLoaderModule],
    templateUrl: './flag-on-off-config.component.html',
    styleUrl: './flag-on-off-config.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlagOnOffConfigComponent {
  
  filterFormGroup = this.formBuilder.group({
    flagFilterForm: this.formBuilder.group<FlagOnOffFilterForm>({} as FlagOnOffFilterForm),
  });
  constructor(private formBuilder: FormBuilder) {
  }
}
