import { a as i } from "../../chunk-SDBWQ4EE.js";
import { property as n } from "lit/decorators.js";
const d = s => {
  class e extends s {
    get isCheckable() {
      return this.controlType === "radio" || this.controlType === "checkbox" || this.controlType === "switch";
    }
    get inputType() {
      return this.type ? this.type : "text";
    }
    firstUpdated(t) {
      super.firstUpdated?.(t), this.addMirroredHiddenInput();
    }
    updated(t) {
      super.updated?.(t), this.handleValueChanged(t);
    }
    handleValueChanged(t) {
      this.name && t && (t.has("value") || t.has("checked") || t.has("files")) && this.setValue();
    }
    addMirroredHiddenInput() {
      this.name && (this.hiddenInput = document.createElement("input"), this.hiddenInput.setAttribute("type", this.inputType), this.hiddenInput.setAttribute("name", this.name), this.hiddenInput.setAttribute("style", "display:none"), this.hiddenInput.setAttribute("aria-hidden", "true"), this.hiddenInput.setAttribute("tabIndex", "-1"), this.required && this.hiddenInput.setAttribute("required", ""), this.setValue(), this.prepend(this.hiddenInput));
    }
    setValue() {
      this.hiddenInput?.setAttribute("value", typeof this.value == "string" ? this.value : JSON.stringify(this.value)), this.isCheckable && this.hiddenInput && (this.checked && this.name ? this.hiddenInput.setAttribute("name", this.name) : (this.hiddenInput.removeAttribute("name"), this.hiddenInput.removeAttribute("value"))), this.files && (this.hiddenInput.files = this.files), this.controlType !== "input" && this.controlType !== "textarea" && this.controlType !== "file" && this.dispatchEvent(new CustomEvent("input", {
        detail: this.isCheckable ? this.checked : this.value
      }));
    }
  }
  return i([n({
    type: String,
    reflect: !0
  })], e.prototype, "name", 2), i([n({
    type: String,
    reflect: !0
  })], e.prototype, "value", 2), i([n({
    type: Boolean,
    reflect: !0
  })], e.prototype, "checked", 2), i([n({
    type: Object
  })], e.prototype, "files", 2), i([n({
    type: String
  })], e.prototype, "type", 2), i([n({
    type: Boolean
  })], e.prototype, "required", 2), e;
};
export { d as FormField };