import {
	CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import {
	FormsModule, ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import '@maersk-global/mds-components-core/mc-tag';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-popover';

@Component({
    selector: 'app-validation-tag-input-grid',
    imports: [ReactiveFormsModule, CommonModule, FormsModule],
    templateUrl: './validation-tag-input-grid.component.html',
    styleUrl: './validation-tag-input-grid.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ValidationTagInputGridComponent {
	@Input() readOnly: boolean = true;
	@Input() inputTags: string[] = [];
	@Input() fit: "small" | "medium" | "large" = "small";
	@Input() label: string = "Label";
	@Input() labelPosition: "left" | "top" = "top";
	@Input() hiddenLabel: boolean = false;
	@Output() values = new EventEmitter<string[]>();
	hasDuplicateTag: boolean = false;
	inputControlValue = "";
	constructor() {
	}
	ngOnInit(): void {
		this.prefillValues();
	}
	addTag() {
		let value = this.inputControlValue.trim();
		if (value) {
			let itemExists = this.inputTags.includes(value);
			if (!itemExists) {
				this.inputTags.push(value);
				this.inputControlValue = "";
			} else {
				this.hasDuplicateTag = true;
			}
		}
	}
	prefillValues() {
		if (this.inputTags.length > 0 && !this.readOnly) {
			this.inputControlValue = this.inputTags[0];
			this.inputTags.splice(0, 1);
		}
	}
	removeTag(index: number) {
		this.inputTags.splice(index, 1);
		this.emitValues();
	}


	onEnterPressed(event: Event) {
		this.hasDuplicateTag = false;
		this.addTag();
	}
	onBlur() {
		this.emitValues();
	}

	private emitValues() {
		let value = this.inputControlValue.trim();
		if (value && !this.inputTags.includes(value)) {
			const controlValues = [...this.inputTags, value];
			this.values.emit(controlValues);
		} else {
			this.values.emit(this.inputTags)
		}
	}
}
