<div class="pure-g m-t-16">
    <div class="pure-g pure-u-md-18-24 pure-u-lg-19-24 pure-u-xl-20-24" style="overflow: auto;text-wrap:nowrap;">
        <div style="width:90px;display: inline-block;font-size: 0.9rem;">Quick Filters:</div>
        @for (option of untaggedStatusList; track option){
        @if($index<numberOfQuickFiltersToShow){ <mc-button [attr.data-cy]="option.description"
            class="m-r-8 tag-button" [label]="option.description"
            variant="filled" appearance="neutral" fit="small" padding="compact"
            (click)="selectStatusFromQuickFilter(option)"></mc-button>
            }@else {
            @if($index===numberOfQuickFiltersToShow){
            <mc-popover style="position: relative;" trigger="click" position="bottom-left">
                <div slot="trigger" class="overflow-count">
                    +{{untaggedStatusList.length-numberOfQuickFiltersToShow}}</div>
                    <div class="tag-button-container">
                @for (option of untaggedStatusList.slice(numberOfQuickFiltersToShow); track option){
                    <mc-button [attr.data-cy]="option.description" class="m-r-8 tag-button"
                        [label]="option.description"
                        variant="filled" appearance="neutral" fit="small" padding="compact"
                        (click)="selectStatusFromQuickFilter(option)"></mc-button>
                }
            </div>
            </mc-popover>
            }
            }
            }
    </div>
    <div class="pure-u-md-6-24 pure-u-lg-5-24 pure-u-xl-4-24 right">
        @if(bulkWorkOrderEnabled()){
        @if(canUserCreateWO){
            <app-aems-button-group label="Single Equipment" parentButtonRouterLink="/workorders/create" [menuItems]="[{label:'Multiple Equipments',routerLink:'/workorders/bulk-create'}]"></app-aems-button-group>
        }
    }@else {
        @if(canUserCreateWO){
            <mc-button label="Single Equipment" routerLink="/workorders/create" variant="filled" appearance="primary" fit="small"></mc-button>
        }
    }
        
    </div>
</div>
<div class="pure-g m-t-8 quick-filter-box">
    <div class="pure-g pure-u-md-21-24" style="display: flex;align-items: center;">
        <div class="pure-u-md-24-24 p-l-4 applied-filter-holder">
            @for (taggedFilter of taggedFilters; track taggedFilter.key){

            {{taggedFilter.key}}

            @if(isArrayType(taggedFilter.value)){

            @if(taggedFilter.key=="Mode" && getTaggedModes()){
            @for(taggedMode of getTaggedModes() ; track taggedMode.code){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedModes().indexOf(taggedMode))">{{getTaggedFilterValue(taggedFilter,
                getTaggedModes().indexOf(taggedMode))}}</mc-tag>
            }
            }
            @else if(taggedFilter.key=="Status" && getTaggedStatuses()){
            @for(taggedStatus of getTaggedStatuses() ; track taggedStatus.generatedCode){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedStatuses().indexOf(taggedStatus))">{{getTaggedFilterValue(taggedFilter,
                getTaggedStatuses().indexOf(taggedStatus))}}</mc-tag>
            }
            }
            @else if (taggedFilter.key=="Shop ID" && getTaggedShops()) {
            @for(taggedShop of getTaggedShops() ; track taggedShop.maintenanceShopCode){
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter,getTaggedShops().indexOf(taggedShop))">{{getTaggedFilterValue(taggedFilter,
                getTaggedShops().indexOf(taggedShop))}}</mc-tag>
            }
            }
            }
            @else{
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter)">{{getTaggedFilterValue(taggedFilter)}}</mc-tag>
            }
            }
            @if(taggedFilters.length>0){
            <a>
                <mc-button class="clear-btn" variant="plain" appearance="primary" fit="small" padding="default"
                    (click)="clearAllSelectedFilters()">
                    Clear All
                </mc-button>
            </a>
            }
        </div>
    </div>
    <div class="pure-u-md-3-24 utility-buttons">
        <!-- <div>
            <mc-button class="utility-button download" label="Button " variant="filled" appearance="neutral"
                fit="medium" icon="cog" hiddenlabel padding="default">
            </mc-button>
        </div> -->
        <div class="pure-g">
            <mc-tooltip appearance="neutral-default" fit="small" position="bottom-center">
                <mc-button class="utility-button" slot="trigger" label="Button" icon="sliders-horizontal"
                    variant="filled" appearance="neutral" hiddenlabel padding="default" fit="medium"
                    (click)="showAdvanceFiltersModal()">
                </mc-button>
                <span>Filters</span>
            </mc-tooltip>
            <mc-modal [open]="showAdvanceFilters" (closed)="closeAdvanceFiltersModal()" heading="Filters" fit="small"
                dimension="medium">
                <app-advance-filter
                    [workOrderFilterForm]="filterFormGroup.controls.workOrderFilterForm"></app-advance-filter>
            </mc-modal>
        </div>
    </div>
</div>