import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, effect, input, Input, signal } from '@angular/core';
import '@maersk-global/mds-components-core/mc-notification';
import '@maersk-global/mds-components-core/mc-progress-bar';
import { NotificationItem } from '../../models/notification-item.model';
import { NotificationService } from '../../services/notification.service';
import { APICallStatus } from '../../models/api-call-status.mode';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-notification-item',
  imports: [],
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {
  constructor(private notificationService: NotificationService) {

  }
  notification=input.required<NotificationItem | undefined>();
  showBody = signal(false);
  notificationDetailApiCallStatus = signal<APICallStatus>("NOT_STARTED");
  notificationBodyTitle = signal<string>("");
  notificationBody = signal<{metadataMessage:string[],message:string}[]>([]);
  onViewDetailClicked(event: Event) {
    this.notificationBody.set([]);
    this.notificationBodyTitle.set("");
    if (this.notification) {
      this.notificationDetailApiCallStatus.set("IN_PROGRESS");
      this.notificationService.getLongRunningJobDetails(this.notification()?.id??"").
        pipe(catchError(err => {
          this.notificationDetailApiCallStatus.set("FAILED");
          throw err
        }))
        .subscribe(response => {
          if (response.subJobs.length > 0) {
            const completedSubJobs = response.subJobs.filter(x => x.state === 3);
            const failedSubJobs = response.subJobs.filter(x => x.state === 2);
            const inProgressSubJobs = response.subJobs.filter(x => x.state === 1);
            const infoMessage = `Completed:${completedSubJobs.length}   Failed:${failedSubJobs.length}    In Progress:${inProgressSubJobs.length}`;
            this.notificationBodyTitle.set(infoMessage);
            response.subJobs.forEach(x => {
              if(x.state===2 && x.error){
               if(x.error.statusCode===422){
                const violations=x.error.violations;
                if(violations && violations.length>0){
                  violations.forEach(v=>{
                    const messageText=v.message;
                    let metadataMessage:string[]=[];
                    if(v.metadata){
                    Object.keys(v.metadata).forEach(key=>{
                      const message= key + ":" + (v.metadata[key]?v.metadata[key]:'EMPTY');
                      metadataMessage.push(message);
                    })
                  }
                  this.notificationBody.update(items=>items.concat({metadataMessage:metadataMessage,message:messageText}));
                  });
                }
               }
               
              }
            });
          }
          this.showBody.set(true);
          this.notificationDetailApiCallStatus.set("COMPLETED_SUCCESFULLY");
        })
    }
  }

  effectHideDetail = effect(() => {
    this.notification();
    this.showBody.set(false);
  });
  onHideDetailClicked() {
    this.showBody.set(false);
  }
}
