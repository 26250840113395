import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { utils } from '../../helper/utils';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { MasterCategory, MasterConfiguration } from '../../models/master-category.model';
import { McModal } from '@maersk-global/mds-components-core';
import { EditRequest, MasterData, MasterDataGrid } from '../../models/master-data.model';
import { MasterAdvanceFilterForm } from '../../form-model/master-advance-filter.form.model';

@Injectable({
  providedIn: 'root'
})
export class MasterDataConfigService {
  private refreshTrigger=new Subject<void>();
  private refreshConfigData=new Subject<void>();

  refreshTrigger$=this.refreshTrigger.asObservable();
  refreshConfigData$=this.refreshConfigData.asObservable();

  constructor(private httpClient: HttpClient) { }

  triggerRefresh(){
    this.refreshTrigger.next();
  }
  triggerConfigData(){
    this.refreshConfigData.next();
  }

  getAllCategoryList(): Observable<MasterCategory[]> {
    return this.httpClient.get<MasterCategory[]>(utils.GetUrlConfig("master-data/areas"));
  }
  getConfigFileByCategory(category: string): Observable<MasterConfiguration[]> {
    return this.httpClient.get<MasterConfiguration[]>(utils.GetUrlConfig("master-data/tables?AreaId=" + category));
  }
  downloadMasterData(tableId: number): Observable<MasterData> {
    return this.httpClient.get<MasterData>(utils.GetUrlConfig("master-data/download?TableId=" + tableId));
  }
  uploadMasterData(tableId: number, file: File): Observable<File> {
    const formData = new FormData();
    if(file){
      formData.append('file', file, file.name);
    }
   
    return this.httpClient.post<File>(utils.GetUrlConfig("master-data/upload?TableId=" + tableId), formData);
  }

getFilterDetails(tableId:number): Observable<MasterAdvanceFilterForm[]> {
  return this.httpClient.get<MasterAdvanceFilterForm[]>(utils.GetUrlConfig("master-data/advance-filters?TableId=" + tableId));
}
getMasterDataList(page: number, pageLimit: number,masterTableId:number, sort?: string, filter?:{}): Observable<HttpResponse<MasterDataGrid>> {
  const headers = new HttpHeaders();
  let url: string = `master-data/search`;
 if(filter==null){
  filter=[]
 }

  return this.httpClient.post<MasterDataGrid>(utils.GetUrlConfig(url), { Page:page,TableId: masterTableId,Limit:pageLimit,AdvancedFilters:filter,Sort: sort},{headers, observe: 'response'}).pipe(
    map((response: HttpResponse<MasterDataGrid>) => {
      const body = response.body;
      if (body) {
        return new HttpResponse({
          body: response.body,
          headers: response.headers
        });
      } else {
        throw new Error('Response body is null or undefined')
      }
    })
  );
}
editValidation(requestBody:EditRequest):Observable<string[]>{
  return this.httpClient.post<string[]>(utils.GetUrlConfig("master-data/save"), requestBody);
}

}
