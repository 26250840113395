var _ = Object.defineProperty;
var d = Object.getOwnPropertyDescriptor;
var s = (l, r, t, a) => {
  for (var i = a > 1 ? void 0 : a ? d(r, t) : r, o = l.length - 1, n; o >= 0; o--) (n = l[o]) && (i = (a ? n(r, t, i) : n(i)) || i);
  return a && i && _(r, t, i), i;
};
import { LitElement as g, html as b } from "lit";
import { classMap as v } from "lit/directives/class-map.js";
import { property as m, queryAssignedElements as S, state as x } from "lit/decorators.js";
import { unsafeCSS as f } from "lit";
import u from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as y } from "lit-element/lit-element.js";
var p = y`:host{line-height:0}.mc-hint{display:none;color:var(--mds_core_hint_text-color);font-family:var(--mds_brand_typography_text_font-family),var(--mds_brand_typography_text_font-family-fallback);font-size:var(--mds_brand_typography_text_small_mobile_font-size);line-height:var(--mds_brand_typography_text_small_mobile_line-height)}.mc-hint.visible{display:flex;margin-top:var(--mds_core_hint_margin-top);column-gap:var(--mds_core_hint_column-gap)}@media screen and (min-width:1000px){.mc-hint{font-size:var(--mds_brand_typography_text_small_desktop_font-size);line-height:var(--mds_brand_typography_text_small_desktop_line-height)}}.mc-hint{font-style:var(--mds_brand_typography_text_small_normal_font-style);font-weight:var(--mds_brand_typography_text_small_normal_font-weight)}`;
import c from "@maersk-global/mds-design-tokens/implementation/core/hint/js/design-tokens-px.css.js";
var h = [u, c, f(p)];
var e = class extends g {
  constructor() {
    super(...arguments);
    this.hasSlotHint = !1;
    this.visible = !1;
    this.fit = "medium";
  }
  static get styles() {
    return h;
  }
  async firstUpdated(t) {
    super.firstUpdated(t), await this.updateComplete, this.updateVisible();
  }
  willUpdate(t) {
    super.willUpdate(t), t.has("hint") && this.updateVisible();
  }
  render() {
    let t = {
      visible: this.visible,
      [`${this.fit}`]: !0
    };
    return b`<slot part="hint" class="mc-hint ${v(t)}" @slotchange="${this.onHintSlotChange}">${this.hint}</slot>`;
  }
  onHintSlotChange() {
    this.hasSlotHint = this.hintNodes.length > 0, this.updateVisible();
  }
  updateVisible() {
    this.visible = !!(this.hasSlotHint || this.hint && this.hint !== "");
  }
};
s([x()], e.prototype, "hasSlotHint", 2), s([S({
  slot: "",
  flatten: !0
})], e.prototype, "hintNodes", 2), s([m({
  type: Boolean,
  reflect: !0
})], e.prototype, "visible", 2), s([m({
  type: String
})], e.prototype, "hint", 2), s([m({
  type: String
})], e.prototype, "fit", 2);
customElements.get("mc-hint") || customElements.define("mc-hint", e);
export { e as McHint };