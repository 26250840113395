import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AemsHttpError } from '../../models/aems-http-error.model';

@Component({
    selector: 'app-error',
    imports: [],
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ErrorComponent {
  @Input() formContainer : FormGroup<any> | FormArray<any> |undefined;
  httpError =input<AemsHttpError | undefined>(undefined);

  getFormErrors(form : FormGroup<any> | FormArray<any> |undefined) : string[]{
    let errors : string[] = [];
    Object.keys(form?.errors??{}).forEach((key) => {
      errors.push(form?.getError(key).value);
    });
    return errors;
  }
}
