var h = Object.defineProperty;
var m = Object.getOwnPropertyDescriptor;
var o = (c, n, e, t) => {
  for (var l = t > 1 ? void 0 : t ? m(n, e) : n, s = c.length - 1, r; s >= 0; s--) (r = c[s]) && (l = (t ? r(n, e, l) : r(l)) || l);
  return t && l && h(n, e, l), l;
};
import { html as y } from "lit";
import { property as p, state as v } from "lit/decorators.js";
import { unsafeCSS as O } from "lit";
import b from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import S from "@maersk-global/mds-components-utils/lib/host/input-disabled.styles.js";
import { css as g } from "lit-element/lit-element.js";
var a = g`.mc-select ::part(text-and-icon){color:var(--mds_core_multi-select_select-all_color);text-decoration:underline}.mc-select [type=select-clear]::part(button){padding:var(--mds_core_multi-select_select-all_padding-top) var(--mds_core_multi-select_select-all_padding-right) var(--mds_core_multi-select_select-all_padding-bottom) var(--mds_core_multi-select_select-all_padding-left)}`;
import f from "@maersk-global/mds-design-tokens/implementation/core/multi-select/js/design-tokens-px.css.js";
var d = [b, S, f, O(a)];
import { selectStyles as _ } from "@maersk-global/mds-components-core-select";
import { McSelectBase as C } from "@maersk-global/mds-components-core-select";
import "@maersk-global/mds-components-core-option";
var i = class extends C {
  constructor() {
    super();
    this.listType = "multiple";
    this.labelSelector = "span[slot=label]";
    this.toggleOptions = !0;
    this.hiddenselectclearlabel = !1;
    this.selectalllabel = "Select all";
    this.clearalllabel = "Clear all";
    this.summarylabel = "# out of # selected";
    this.trailingicon = "chevron-down", this.keepclearbuttonvisible = !0, this.placeholderOption = !0;
  }
  static get styles() {
    return [super.styles, _, d];
  }
  renderPlaceholderOption() {
    return this.options && this.options.length > 1 && !this.hiddenselectclearlabel ? y`<mc-option type="select-clear" @click="${this.selectAllToggle}">${this.toggleOptions ? this.selectalllabel : this.clearalllabel}</mc-option>` : null;
  }
  async setOptionSelected(e) {
    this.clickedOption = e, this.clickedOption && (this.getSelectedOption(), this.value = this.getSelectedOptionValue(), this.selectedOptionLabel = this.getDisplayLabel(), this.dispatchEvent(new InputEvent("input")), this.dispatchEvent(new CustomEvent("optionselected", {
      detail: this.selectedOption?.map(t => ({
        value: t.value,
        label: t.innerHTML
      })),
      composed: !0,
      bubbles: !0
    })), this.updateToggleOptions());
  }
  getOptions() {
    this.slotElements && (this.options = Array.from(this.slotElements).filter(e => e.nodeName.toLowerCase() === "mc-option"), this.options.map(e => {
      e.label = this.getOptionLabel(e), e.name = this.name;
    }));
  }
  getDisplayLabel() {
    return this.selectedOption && this.selectedOption.length > 0 && this.options ? `${this.summarylabel.replace(/#/, this.selectedOption.length.toString()).replace(/#/, this.options.length.toString())}` : "";
  }
  getSelectedOption() {
    this.options && this.options.length > 0 && (this.selectedOption = this.options.filter(e => e.selected), this.updateToggleOptions());
  }
  getFocusedOption() {
    return this.clickedOption ? this.clickedOption : this.selectedOption && this.selectedOption.length > 0 ? this.selectedOption[0] : this.options ? this.options[0] : void 0;
  }
  handleValueLabelSlotChange() {
    this.selectedOptionLabel = this.getDisplayLabel();
  }
  updateToggleOptions() {
    this.toggleOptions = !!(this.selectedOption && this.selectedOption.length < 2), this.clearButtonEnabled = !!(this.clearbutton && this.selectedOption && this.selectedOption.length > 0);
  }
  selectAllToggle() {
    this.options && this.options.map(e => (e.disabled || (e.selected = this.toggleOptions), e));
  }
  getOptionLabel(e) {
    let t = e;
    if (t) {
      let l = t.childNodes,
        s = null;
      return l.length > 0 && (s = Array.from(l).filter(u => u.nodeName === "#text")[0].textContent), t && t.label || !s ? t.label : s;
    }
    return "";
  }
  getSelectedOptionValue() {
    let e = [];
    return this.selectedOption && this.selectedOption.map(t => {
      e.push(t.value);
    }), e;
  }
};
o([v()], i.prototype, "toggleOptions", 2), o([p({
  type: Boolean
})], i.prototype, "hiddenselectclearlabel", 2), o([p({
  type: String
})], i.prototype, "selectalllabel", 2), o([p({
  type: String
})], i.prototype, "clearalllabel", 2), o([p({
  type: String
})], i.prototype, "summarylabel", 2);
customElements.get("mc-multi-select") || customElements.define("mc-multi-select", i);
export { i as McMultiSelect };