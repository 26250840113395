import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { catchError, map, of, tap } from 'rxjs';
import { Equipment } from '../../../../models/equipment.model';
import { Repair } from '../../../../models/repair.model';
import { AlarmCriticatlity } from '../../../../models/alarm-criticality.model';
import { ToastrService } from '../../../../services/toastr-service';
import { ApplicationtTextService } from '../../../../services/application-text.service';
/** This validation does not block work order creation, its gives a warning only
 * A live reefer can be repaired only when there are medium or high alarm against it.
 * Repair on live reefer can also be done in case of No alarm, very low alarm or low alram
 * If and only if the any single repair code in the work order belongs to an exceptional list of
 * repair codes
 */
export function nonEmptyReeferRepairWarning(
  index: number,
  equipment: Equipment | null,
  repairs: Repair[] | null,
  toastrService: ToastrService,
  appTextSvc: ApplicationtTextService,
  //warningSharedService: WarningSharedService
): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    if (!repairCode)
      return null;
    if (!equipment)
      return null;
    if (equipment.isContainerEmpty || equipment.equipmentTypeCode!="REEF")
      return null;
    let validatorName = nonEmptyReeferRepairWarning.name + index;
    //warningSharedService.deleteWarning(validatorName)
    if (!equipment.isContainerEmpty && HasNoOrLowAlarm(equipment)) {
      let selectedRepair = repairs?.find(x => (x.code == repairCode))
      if (selectedRepair && !selectedRepair?.isExceptionalRepair) {
        toastrService.showToastr(
          appTextSvc.nonEmptyReeferRepairCode,
          'warning',
          60000 // Type of toast notification
        );
      }
    }
    return null;
  }
}
function HasNoOrLowAlarm(equipment: Equipment): boolean {
  return !equipment?.alarms || equipment?.alarms.length == 0 ||
    (equipment.alarms.some(alarm => alarm.alarmCriticality == AlarmCriticatlity.Low
      || alarm.alarmCriticality == AlarmCriticatlity.VeryLow));
}

