@if(receivedFileChangeValue!=0){
<div class="table-container m-t-12">
    @if((getMasterConfigApiCallStatus==="COMPLETED_SUCCESFULLY" && dataRecords.length>0) || getMasterConfigApiCallStatus
    ==="IN_PROGRESS"
    ){
    <mc-table data-cy="master-data-list"
        [data]="getMasterConfigApiCallStatus==='COMPLETED_SUCCESFULLY' && dataRecords.length>0 ? dataRecords: ''"
        fit="small" [columns]="columns" (sortchange)='onSortDirectionChange($event)' fit="small" nowrap="true"
        tabularFigures="true" headersticky height="100%">
        <ng-container *ngFor="let row of dataRecords; let i = index">
            <div slot="{{row.id}}_EditAction">
                <mc-icon class="edit-icon" icon="pencil" slot="trigger"
                    (click)="showEditModalDialog(row,data.headers)"></mc-icon>
            </div>
        </ng-container>
    </mc-table>
    }
    @else{
    <div class="pure-g">
        <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-204 flex-container flex-center no-data">
            <span i18n>No Data Found</span>
        </div>
        <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-36 flex-container flex-center">
            <span i18n>To widen your search, change or remove filters.</span>
        </div>
    </div>
    }
</div>

@if(data && data.records && data.records.length>0){
<div class="pure-g m-t-16 footer">
    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24">
        <mc-select style="width:140px;display: block;" [value]="pageLimit" optionswidth="100px" ngDefaultControl
            label="Per page" labelposition="left" fit="small" (optionselected)="onPageLimitChange($event)">
            @for (pageCount of [10,20,50,100,200]; track $index) {
            <mc-option [value]="pageCount">{{pageCount}}</mc-option>
            }
        </mc-select>
    </div>

    <div class="pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24 flex-container flex-center">
        @if(data.records.length!=totalItemCount){
        <mc-pagination hiddenlabels [currentpage]="currentPage" [totalpages]="lastPage" visiblepages="7"
            (pagechange)="onPageChange($event)" fit="small">
        </mc-pagination>
        }
    </div>

    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24 text-align-right">
        <span class="page-description" i18n>Showing {{data.records.length}} of
            {{totalItemCount}}</span>
    </div>
</div>
}


<mc-modal backdropcloseactiondisabled heading="Edit MasterData" [open]="openEditDialog"
    (closed)="closeEditDialog()">
    <form [formGroup]="masterDataEditForm">
        <div class="outer-class">
            <div class="pure-g">
                <div class="pure-u-md-24-24">
                    @for(field of fieldforms;track $index){
                    <div class="pure-u-md-12-24 p-b-8 p-r-8">


                        <mc-input label="{{this.headers[$index]}}" name="{{field.name}}" [formControlName]="field.name"
                            ngDefaultControl fit="medium" width="100" (input)="onInputChange()" placeholder="Select">
                        </mc-input>

                    </div>
                    }
                </div>
            </div>
            <div>
                @if(displayErrMsg){
                <div class="err-container">
                    <p><u>Error Message(s)</u></p>
                    <ng-container *ngFor="let row of uploadErrList;let i=index">
                        <div class="err-msg">{{row.error}}</div>
                    </ng-container>
                </div>
                }
            </div>

            <div class="utility-buttons">
                <mc-button label="Edit Validation" slot="primaryAction" variant="filled" appearance="primary"
                    fit="medium" class="p-r-16" (click)="onEdit()" [disabled]="!showEdit"
                    [loading]="submitEdit"></mc-button>
                <mc-button label="Cancel" slot="secondaryAction" appearance="neutral" fit="medium" class="p-r-16"
                    (click)="onCancel()"></mc-button>
            </div>
        </div>

    </form>

</mc-modal>
}
@else{
<div class="aems-card m-b-12">
    <div class="pure-g">
        <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 flex-container flex-center no-file-selected">
            <span i18n>Please select a configuration file to load the data</span>
        </div>
    </div>
</div>
}