<div class="aems-bread-crumb m-b-16">
    <a class="m-r-4" routerLink="/workorders">Work Orders</a><span>/ Create Bulk Workorders</span>
</div>
<app-error [httpError]="httpError()"></app-error>
<app-bulk-work-order-header></app-bulk-work-order-header>
<app-bulk-work-order-repair-line-items></app-bulk-work-order-repair-line-items>
<app-bulk-work-order-manhours [totalManHoursWithoutPrepTime]="totalManhoursWithoutPrepTime"
    [manHoursThatQualifyForPrepTime]="manHoursThatQualifyForPrepTime" [isPrepTimeApplicable]="isPrepTimeApplicable"
    [mode]="selectedMode"></app-bulk-work-order-manhours>
@if(showTotalCost){
<app-cwo-total-cost [cost]="costPerWorkorder"></app-cwo-total-cost>
}
<app-bulk-work-order-remark></app-bulk-work-order-remark>
<div style="text-align: right">
    <mc-button data-cy="submit" variant="filled" appearance="primary" fit="medium" padding="default"
        [disabled]="bulkWorkorderSubmitButtonDisabled()"[loading]="bulkWorkorderSubmitApiCallStatus=='IN_PROGRESS'" (click)="onSubmitClicked()">
        Create Workorders
    </mc-button>
</div>
<mc-toast position="bottom-right" duration="6000" appearance="success" [open]="successToastOpen()" (close)="onSuccessToastClosed()">
    <mc-notification [body]="appTextSvc.BulkWorkOrderSubmittedSuccessfully">
        <span slot="actions" class="mc-notification__actions">
            <mc-button padding="none" fit="small" class="undo" (click)="onToastrActionClicked()" variant="plain" appearance="neutral">Single Workorder</mc-button>
        </span>
    </mc-notification>
</mc-toast>