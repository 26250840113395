var c = Object.defineProperty;
var a = Object.getOwnPropertyDescriptor;
var e = (p, s, o, m) => {
  for (var r = m > 1 ? void 0 : m ? a(s, o) : s, l = p.length - 1, n; l >= 0; l--) (n = p[l]) && (r = (m ? n(s, o, r) : n(r)) || r);
  return m && r && c(s, o, r), r;
};
import { html as h, LitElement as d } from "lit";
import { property as i, queryAssignedElements as u, query as g } from "lit/decorators.js";
import "@maersk-global/mds-components-core-popover";
var t = class extends d {
  constructor() {
    super(...arguments);
    this.arrow = !1;
    this.fit = "medium";
    this.open = !1;
    this.position = "bottom-left";
    this.trigger = "click";
    this.contextmenuonside = !1;
  }
  firstUpdated(o) {
    super.firstUpdated(o), this.mcList && (this.mcList[0].role = "menu", this.mcList[0].scrolltofocused = !0, this.mcList[0].noborder = !0, this.mcList[0].addEventListener("listchange", () => {
      this.mcPopover && this.mcPopover.hide && this.mcPopover.hide();
    }));
  }
  updated(o) {
    super.updated(o), o.has("fit") && this.mcList && (this.mcList[0].fit = this.fit);
  }
  render() {
    return h`<mc-popover class="mc-menu" ?arrow="${this.arrow}" .fit="${this.fit}" .maxheight="${this.maxheight}" .maxwidth="${this.maxwidth}" ?open="${this.open}" ?contextmenuonside="${this.contextmenuonside}" .position="${this.position}" .trigger="${this.trigger}" .zindex="${this.zindex}"><slot slot="trigger" name="trigger"></slot><slot></slot></mc-popover>`;
  }
};
e([u({
  slot: "",
  flatten: !0
})], t.prototype, "mcList", 2), e([g("mc-popover")], t.prototype, "mcPopover", 2), e([i({
  type: Boolean
})], t.prototype, "arrow", 2), e([i({
  type: String
})], t.prototype, "fit", 2), e([i({
  type: String
})], t.prototype, "maxwidth", 2), e([i({
  type: String
})], t.prototype, "maxheight", 2), e([i({
  type: Boolean
})], t.prototype, "open", 2), e([i({
  type: String
})], t.prototype, "position", 2), e([i({
  type: String
})], t.prototype, "trigger", 2), e([i({
  type: Boolean
})], t.prototype, "contextmenuonside", 2), e([i({
  type: Number
})], t.prototype, "zindex", 2);
customElements.get("mc-menu") || customElements.define("mc-menu", t);
export { t as McMenu };