<div class="table-container m-t-12">
    @if((getRuleConfigApiCallStatus==="COMPLETED_SUCCESFULLY" && data.length>0) || getRuleConfigApiCallStatus ==="IN_PROGRESS"
    ){
    <mc-table data-cy="work-order-list"
        [data]="getRuleConfigApiCallStatus==='COMPLETED_SUCCESFULLY' && data.length>0 ? data: mockDataForLoadingAnimation"
        fit="small" [columns]="columns" nowrap="true" sortmanual sortdefaultdirection="descending"
        (sortchange)='onSortDirectionChange($event)' tabularFigures="true" headersticky height="100%">
        <ng-container *ngFor="let row of data; let i = index">
            <div slot="{{ row.id }}_actions">
                <mc-icon class="edit-icon" icon="pencil" slot="trigger" (click)="showEditModalDialog(row)"></mc-icon>
            </div>
        </ng-container>
        <ng-container *ngFor="let row of data; let i = index">
            <div slot="{{ row.id }}_shopCodes">
                <app-validation-tag-input-grid [readOnly]="true" [hiddenLabel]="true" [inputTags]="row?.shopCodes??[]">
                </app-validation-tag-input-grid>
            </div>
        </ng-container>
        @for(row of mockDataForLoadingAnimation;track $index){
                        @for (item of columns; track $index) {
                            <div slot="{{row.id}}_{{item.id}}">
                                <app-loader></app-loader>
                            </div>
                        }
            }
    </mc-table>
    }
    @else{
    <div class="pure-g">
        <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-204 flex-container flex-center no-data">
            <span i18n>No Data Found</span>
        </div>
        <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-36 flex-container flex-center">
            <span i18n>To widen your search, change or remove filters.</span>
        </div>
    </div>
    }
</div>
@if(data.length>0){
<div class="pure-g m-t-16 footer">
    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24">
        <mc-select style="width:140px;display: block;" [value]="pageLimit" optionswidth="100px" ngDefaultControl
            label="Per page" labelposition="left" fit="small" (optionselected)="onPageLimitChange($event)">
            @for (pageCount of [10,20,50,100,200]; track $index) {
            <mc-option [value]="pageCount">{{pageCount}}</mc-option>
            }
        </mc-select>
    </div>

    <div class="pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24 flex-container flex-center">
        @if(data.length!=totalItemCount){
        <mc-pagination hiddenlabels [currentpage]="currentPage" [totalpages]="lastPage" visiblepages="7"
            (pagechange)="onPageChange($event)" fit="small">
        </mc-pagination>
        }
    </div>

    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24 text-align-right">
        <span class="page-description" i18n>Showing {{data.length}} of
            {{totalItemCount}}</span>
    </div>
</div>
<mc-modal backdropcloseactiondisabled heading="Edit Validation" fit="medium" [open]="openEditDialog"
    (closed)="closeEditDialog()">
    @if(rowData){
    <form [formGroup]="flagEditForm">
        <div class="pure-g">
            <div class="pure-u-md-24-24">
            <div class="pure-u-md-12-12 p-b-16 p-r-16">
                <mc-select label="Validation" fit="medium" width="100" disabled placeholder="{{rowData.ruleName}}">
                </mc-select>
            </div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Region" fit="medium" width="100"
                    placeholder="{{rowData.loc}}" ngDefaultControl disabled>
                </mc-multi-select></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Cluster" fit="medium" width="100"
                    placeholder="{{rowData.clusterCode}}" ngDefaultControl disabled>

                </mc-multi-select></div>
            <div class="pure-u-md-24-24">
            @if(selectedShopCodes && selectedShopCodes.length>0){
                <div class="pure-u-md-12-12 p-b-16 p-r-16">
                    <mc-multi-select label="Shops" formControlName="shop" fit="medium" width="100"
                         placeholder="Select Shops" (optionselected)="onShopSelected($event)"  ngDefaultControl>
                        @for (shop of shop; track shop){
                        <mc-option [attr.data-cy]="shop.shopCode" [value]="shop.shopCode"> {{ shop.shopCode}}</mc-option>
                        }
                    </mc-multi-select>
                </div>
            }
                <br />
            </div>
            <div class="pure-u-md-24-24 utility-buttons">
                <mc-button label="Edit Validation" slot="primaryAction" variant="filled" appearance="primary"
                    fit="medium" class="p-r-16" (click)="onEditValidation(rowData)" [disabled]="!isShopSelected"></mc-button>
                <mc-button label="Cancel" slot="secondaryAction" appearance="neutral" fit="medium" (click)="closeEditDialog()"
                    class="p-r-16"></mc-button>
            </div>
        </div>
        </div>
    </form>
}
</mc-modal>
}