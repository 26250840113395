import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, catchError, shareReplay, of, delay, map, pipe, timer, switchMap, filter, throwError } from 'rxjs';
import { utils } from '../helper/utils';
import { TotalCost } from '../models/total-cost-model';
import { MaintenanceOrderHeader } from '../models/maintenance-order-header.model';
import { Equipment } from '../models/equipment.model';
import { EquipmentRemark } from '../models/equipment-remark.model';
import { MaintenanceOrderRemark } from '../models/maintenance-order-remark.model';
import { MaintenanceOrderFilter } from '../models/maintenance-order-filter.model';
import { MaintenanceOrderResponse } from '../models/maintenance-order-response.model';
import { MaintenanceOrderCreateRequest } from '../models/maintenance-order-create-request.model';
import { EMPTY_SUBSCRIPTION } from 'rxjs/internal/Subscription';
import { MaintenanceOrderChangeResult } from '../models/maintenance-order-change-result.model';
import { Currency } from '../models/currency.model';
import { ValidationSummary } from '../models/validation-summary.model';
import { ValidationReferenceResponse } from '../models/validation-reference-response-model';
import { MaintenanceOrderPartSerialNumberUpdateRequest } from '../models/maintenance-order-part-serial-number-update-request.model';
import { EquipmentNumberValidationModel } from '../models/equipment-number-validation.model';
import { RetriggerRequest } from '../models/retrigger.model';


@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {

  constructor(private httpClient: HttpClient) { }


  validateEquipmentNumber(equipmentNumber: string): Observable<EquipmentNumberValidationModel> {
    return this.httpClient.post<EquipmentNumberValidationModel>(utils.GetUrl("maintenance-orders/validate?property=equipmentNumber"), { equipmentNumber: equipmentNumber });
  }
  calculateTotalCost(workorderId: string | undefined ,maintenanceOrder: MaintenanceOrderCreateRequest): Observable<TotalCost> {
    if(workorderId){
      return this.httpClient.post<TotalCost>(`${utils.GetUrl("maintenance-orders")}/${workorderId}/calculate-cost`, maintenanceOrder);
    }  
    return this.httpClient.post<TotalCost>(utils.GetUrl("maintenance-orders/calculate-cost"), maintenanceOrder);
  }
  createOrUpdateWorkOrder(maintenanceOrder: MaintenanceOrderCreateRequest, workorderId: string | undefined = undefined): Observable<MaintenanceOrderResponse> {
    if (workorderId) {
      return this.httpClient.put<void>(
        `${utils.GetUrl("maintenance-orders")}/${workorderId}/?performValidation=true`,
        maintenanceOrder,
        { observe: 'response' }
      ).pipe(
        switchMap(response => {
          if (response.status === 200) {
            return this.getWorkOrderById(workorderId);
          } else {
            return throwError(() => response);
          }
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
    }
    return this.httpClient.post<MaintenanceOrderResponse>(utils.GetUrl("maintenance-orders/?performValidation=true"), maintenanceOrder);
  }

  deleteWorkOrder(workorderId:string|undefined=undefined): Observable<any> {
    if(workorderId){
      return this.httpClient.delete<any>(`${utils.GetUrl("maintenance-orders")}/${workorderId}`);
    }else{
   return timer(2000);
    }
  }
  approveWorkOrder(approveRequest:any) {;
    if(approveRequest){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/workflow-process/approve"),approveRequest);
    }else{
   return timer(2000);
    }
  }
  rejectWorkOrder(rejectRequest:any) {;
    if(rejectRequest){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/workflow-process/reject"),rejectRequest);
    }else{
   return timer(2000);
    }
  }
  forwardWorkOrder(forwardRequest:any) {;
    if(forwardRequest){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/workflow-process/forward"),forwardRequest);
    }else{
   return timer(2000);
    }
  }
  totalLossWorkOrder(totalLossRequest:any) {;
    if(totalLossRequest){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/workflow-process/totalloss"),totalLossRequest);
    }else{
   return timer(2000);
    }
  }
  holdWorkOrder(workOrderId:any, userId:any) {;
    if(workOrderId && userId){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/validation-referencedata/update-hold-status?workOrderId="+workOrderId+ "&&userId="+userId),null);
    }else{
   return timer(2000);
    }
  }
  updateWorkOrder(updateRequest:any) {;
    if(updateRequest){
      return this.httpClient.post<any>(utils.GetUrl("maintenance-orders/update-workorder-tpicode"),updateRequest);
    }else{
   return timer(2000);
    }
  }
 

  getAuditLogs(workorderId: string): Observable<MaintenanceOrderChangeResult> {
    return this.httpClient.get<MaintenanceOrderChangeResult>(utils.GetUrl(`maintenance-orders/${workorderId}/audit-logs`));
  }

  completeWorkOrder(workorderId:string,maintainceOrderUpdateAndComplete: MaintenanceOrderPartSerialNumberUpdateRequest | undefined):Observable<any>{
     
    return this.httpClient.patch<void>(`${utils.GetUrl("maintenance-orders")}/${workorderId}/complete-repair`,
    maintainceOrderUpdateAndComplete,
      { observe: 'response' }
    ).pipe(
      switchMap(response => {
        if (response.status === 200) {
          return this.getWorkOrderById(workorderId);
        } else {
          return throwError(() => response);
        }
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }
  markAsTTl(workorderId:string):Observable<any>{
    return this.httpClient.patch(`${utils.GetUrl("maintenance-orders")}/${workorderId}/mark-as-ttl`,null)
  }
  
  getWorkOrders(page: number, pageLimit: number, sort?: string, filters?: MaintenanceOrderFilter): Observable<HttpResponse<MaintenanceOrderHeader[]>> {
    let url: string = `maintenance-orders/?page=${page}&limit=${pageLimit}`;
    if (sort) {
      url += `&sort=${encodeURIComponent(sort)}`;
    }

    if (filters) {
      url = this.applyWorkOrdersFilter(filters, url);
    }

    return this.httpClient.get<MaintenanceOrderHeader[]>(utils.GetUrl(url), { observe: 'response' }).pipe(
      map((response: HttpResponse<MaintenanceOrderHeader[]>) => {
        const body = response.body;
        if (body) {
          const mappedBody = body.map(item => ({
            ...item,
            id: item.maintenanceOrderNumber,
            totalOrderCostUSD: item.exchangeRate * item.totalOrderCost,
            maintenanceOrderStatusDuration: Date.now() - new Date(item.maintenanceOrderStatusUpdatedTimestamp).getTime(),
            maintenanceOrderStatusName: utils.getMaintenanceOrderStatusText(item.maintenanceOrderStatusCode).description
          }));

          return new HttpResponse({
            body: mappedBody,
            headers: response.headers
          });
        } else {
          return response;
        }
      })
    );
  }

  private applyWorkOrdersFilter(filters: MaintenanceOrderFilter, url: string): string {
    const params = new URLSearchParams();

    if (filters.equipmentNumber) {
      params.append('equipmentNumber', filters.equipmentNumber);
    }
    if (filters.maintenanceRepairModes) {
      params.append('maintenanceRepairModes', filters.maintenanceRepairModes);
    }
    if (filters.maintenanceOrderNumber !== undefined) {
      params.append('maintenanceOrderNumber', filters.maintenanceOrderNumber.toString());
    }
    if (filters.maintenanceOrderStatusTypes !== undefined) {
      params.append('maintenanceOrderStatusTypes', filters.maintenanceOrderStatusTypes.toString());
    }
    if (filters.fromDate) {
      params.append('fromDate', utils.formatToISODateUTC(filters.fromDate, true));

    }
    if (filters.toDate) {
      params.append('toDate', utils.formatToISODateUTC(filters.toDate, false));

    }
    if (filters.maintenanceShopCodes) {
      params.append('maintenanceShopCodes', filters.maintenanceShopCodes);
    }
    if (filters.searchText) {
      params.append('searchText', filters.searchText);
    }
    if (filters.showFlaggedWO) {
      params.append('showFlaggedWO', filters.showFlaggedWO.toString());
    }

    const queryString = params.toString();
    if (url.includes('?')) {
      url += `&${queryString}`;
    } else {
      url += `?${queryString}`;
    }

    return url;
  }

  getWorkOrderById(id: string): Observable<MaintenanceOrderResponse> {
    return this.httpClient.get<MaintenanceOrderResponse>(utils.GetUrl(`maintenance-orders/${id}`));
  }

  getWorkOrderOrderExchangeRateInfoById(id: string): Observable<Currency> {
    return this.httpClient.get<Currency>(utils.GetUrl(`maintenance-orders/${id}/exchange-rate`));
  }


  getEquipmentDetails(workOrderId: string): Observable<Equipment | null> {
    return this.httpClient.get<Equipment>(utils.GetUrl(`maintenance-orders/${workOrderId}/equipment-details`));
  }
  
  getSummary(workOrderId: string): Observable<ValidationSummary[]> {
    return this.httpClient.get<ValidationSummary[]>(utils.GetUrl(`maintenance-orders/validation-summary/${workOrderId}`));
  }
  getTotalCost(workOrderId: string): Observable<TotalCost> {
    return this.httpClient.post<TotalCost>(utils.GetUrl(`maintenance-orders/${workOrderId}/saved-cost`), {});
  }
  validateRepairCode(maintainceOrder: MaintenanceOrderCreateRequest): Observable<any> {
    return this.httpClient.post(utils.GetUrl("maintenance-orders/validate?property=maintenanceTypeCode"), maintainceOrder);
  }
  getRemarks(workOrderId: string): Observable<MaintenanceOrderRemark[]> {
    return this.httpClient.get<MaintenanceOrderRemark[]>(utils.GetUrl(`maintenance-orders/${workOrderId}/remarks`));
  }
  postRemarks(workOrderId:string,remarks:MaintenanceOrderRemark[]):Observable<any>{
    return this.httpClient.post(utils.GetUrl(`maintenance-orders/${workOrderId}/remarks`),remarks)
  }
  postRemarks1(workOrderId:string,remarks:any[]):Observable<any>{
    return this.httpClient.post(utils.GetUrl(`maintenance-orders/workflow-process/remarks`),remarks)
  }
  getValidationRefData(workOrderId:string):Observable<ValidationReferenceResponse>{
  return this.httpClient.get<ValidationReferenceResponse>(utils.GetUrl(`maintenance-orders/validation-referencedata/${workOrderId}`));
  }
}
