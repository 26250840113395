const languageMap = {
  en: "en",
  es: "es-MX",
  pt: "pt-BR",
  fr: "fr-FR",
  tr: "tr-TR",
  de: "de-DE",
  zh_hans: "zh-CN",
  zh_hant: "zh-TW",
  jp: "ja-JP",
  ru: "ru-RU",
  ko: "ko-KR",
  it: "it-IT"
};
const langAttrMap = {
  en: "en",
  es: "es",
  pt: "pt",
  fr: "fr",
  tr: "tr",
  de: "de",
  zh_hans: "zh-hans",
  zh_hant: "zh-hant",
  jp: "ja",
  ru: "ru",
  ko: "ko",
  it: "it"
};

/**
 * Type of consent required by the user to use this cookie
 */
var ConsentType;
(function (ConsentType) {
  /**
   * Functional cookies are cookies that are strictly necessary for the website to function.
   * These can be denied by the user and so should be for functions that can degrade grawcefull
   */
  ConsentType["Functional"] = "functional";
  /**
   * Marketing cookies are used to track or identify visitors and can then be used later to market products or services to them.
   */
  ConsentType["Marketing"] = "marketing";
  /**
   * Statistical cookies are used to track and collect statistics about user behaviour.
   */
  ConsentType["Statistical"] = "statistical";
  /**
   * Essential cookies are cookies that are strictly necessary for the website to function properly.
   */
  ConsentType["Essential"] = "essential";
})(ConsentType || (ConsentType = {}));
/**
 * Retrieves whether the user has given consent for functional cookies
 * @param cookieString - The cookie string to search in, defaults to `document.cookie`
 * @since 5.14.0
 */
const getFunctionalConsent = (cookieString = document.cookie) => !!new RegExp(/consents_approved[^\]]*cookie_cat_functional/).exec(decodeURIComponent(cookieString));
/**
 * Retrieves whether the user has given consent for marketing cookies
 * @param cookieString - The cookie string to search in, defaults to `document.cookie`
 * @since 5.14.0
 */
const getMarketingConsent = (cookieString = document.cookie) => !!new RegExp(/consents_approved[^\]]*cookie_cat_marketing/).exec(decodeURIComponent(cookieString));
/**
 * Retrieves whether the user has given consent for statistical cookies
 * @param cookieString - The cookie string to search in, defaults to `document.cookie`
 * @since 5.14.0
 */
const getStatisticalConsent = (cookieString = document.cookie) => !!new RegExp(/consents_approved[^\]]*cookie_cat_statistic/).exec(decodeURIComponent(cookieString));
/**
 * Checks that the user has given consent for the specified type of cookie
 * @param {ConsentType} consentType the type of consent to check
 * @param {string} cookieString optional - the cookie string to search in, defaults to `document.cookie`
 * @returns whether the user has given consent for the specified type of cookie
 */
const checkConsent = (consentType, cookieString = document.cookie) => {
  switch (consentType) {
    case ConsentType.Functional:
      return getFunctionalConsent(cookieString);
    case ConsentType.Marketing:
      return getMarketingConsent(cookieString);
    case ConsentType.Statistical:
      return getStatisticalConsent(cookieString);
    case ConsentType.Essential:
      return true;
    default:
      return false;
  }
};

/**
 * Generates a key for local storage based on the key and category
 * @param key key name
 * @param category category for the key
 * @returns [category]key or just key
 */
function makeKey(key, category) {
  return (category ? `[${category}]` : "") + key;
}

/**
 * @private
 * @name checkCompatibility
 */
function checkCompatibility$1() {
  //  Thanks Modernizr!
  try {
    return "localStorage" in window && "setItem" in localStorage;
  } catch (_a) {
    return false;
  }
}
/**
 * @public
 * @name setItem
 * @param {*} key
 * @param {*} data
 * @description
 * Sets an item in HTML5 LocalStorage. Runs a compatibility check first which will fail silently if it is unsuccessful
 */
function setItem$1(key, data, category, consentType = ConsentType.Essential, cookieString = document.cookie) {
  if (checkCompatibility$1() && checkConsent(consentType, cookieString)) {
    localStorage.setItem(makeKey(key, category), JSON.stringify(data));
  }
}
/**
 * @public
 * @name getItem
 * @param {*} key
 */
function getItem$1(key, category) {
  if (checkCompatibility$1()) {
    try {
      return JSON.parse(localStorage.getItem(makeKey(key, category)));
    } catch (e) {
      return localStorage.getItem(makeKey(key, category));
    }
  } else {
    return "";
  }
}
/**
 * @public
 * @name removeItem
 * @param {*} key
 */
function removeItem$1(key, category) {
  if (checkCompatibility$1()) {
    localStorage.removeItem(makeKey(key, category));
  }
}
const localStore = {
  setItem: setItem$1,
  getItem: getItem$1,
  removeItem: removeItem$1
};

/**
 * @public
 * @name replacePlaceholders
 * @description
 * @param {string} str
 * @param {Object} data
 * @returns {string}
 */
function replacePlaceholders(str, data) {
  let string;
  if (typeof data === "object") {
    string = RegExp("\\{\\{(" + Object.keys(data).join("|") + ")\\}\\}", "g");
  }
  return str && string && str.replace(string, function (a, b) {
    return data[b];
  }) || "";
}
/**
 * @public
 * @deprecated Use CSS - `selector::first-letter { text-transform: uppercase; }`
 * @name firstLetterUppercase
 * @description Takes a string and makes its first letter uppercase.
 * @param {string} string
 * @returns {string}
 */
function firstLetterUppercase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * @public
 * @name isOverMax
 * @description Checks if value is greater that count limit.
 * @param {number} value
 * @param {number} countLimit
 * @returns {boolean}
 */
function isOverMax(value, countLimit) {
  const numberValue = parseInt(value);
  return numberValue > countLimit;
}
/**
 * @public
 * @name setMaxCount
 * @description
 * @param {number} value
 * @param {number} countLimit
 * @returns {number}
 */
function setMaxCount(value, countLimit) {
  if (!value && value !== 0) {
    return "-";
  }
  let numberValue = value;
  if (typeof value === "string") {
    numberValue = parseInt(value);
  }
  return numberValue <= countLimit ? numberValue : countLimit;
}
/**
 * @public
 * @name isEmpty
 * @description
 * @param {any} value
 * @returns {boolean}
 */
function isEmpty(value) {
  return !value;
}
/**
 * @public
 * @name getUrlParams
 * @description Extracts query params placed after a custom separator (e.g ? or #)
 * @param {string} str
 * @param {string} separator
 * @returns {Object} Query params as an object
 */
function getUrlParams(str, separator) {
  const separatorRegex = new RegExp(separator);
  const toReturn = {};
  str.replace(separatorRegex, "").split("&").map(pair => {
    const pairSplit = pair.split("=");
    toReturn[pairSplit[0]] = pairSplit[1];
  });
  return toReturn;
}
/**
 * @public
 * @name matchesStoredNonce
 * @description Evaluates either a provided string matches the stored nonce
 * @param {any} jwtNonce
 * @returns {boolean}
 */
function matchesStoredNonce(jwtNonce) {
  const storedNonce = getItem$1("nonce");
  // eslint-disable-next-line eqeqeq
  return jwtNonce == storedNonce;
}
/**
 * @public
 * @description Generates a random string, of given length
 * @name generateNonce
 * @param {number} length
 * @returns {string} Returns generated random string
 */
function generateNonce(length) {
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nonce = "";
  for (let i = 0; i < length; i++) {
    nonce += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return nonce;
}
/**
 * @public
 * @name bindEvent
 * @description Binds an event to an element; handles event names for IE
 * @param {HTMLElement} element
 * @param {string} eventName
 * @param {Function} eventHandler
 */
function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
    // @ts-ignore: IE-only property
  } else if (element.attachEvent) {
    // @ts-ignore: IE-only property
    element.attachEvent("on" + eventName, eventHandler, false);
  }
}
/**
 * @public
 * @name sendOriginWindowOpener
 * @description Sends a stringified object/string from the pop-up window to its opener
 */
function sendOriginWindowOpener(msg) {
  // Make sure you are sending a string, so stringify JSON
  try {
    window.opener.postMessage(JSON.stringify(msg), window.location.origin); //NOSONAR
  } catch (error) {
    console.error("error window opener", error);
  }
}
/**
 * @public
 * @name sendOriginWindowParent
 * @description Sends a stringified object/string from an iframe to its parent
 * @param {any} msg
 */
function sendOriginWindowParent(msg) {
  // Make sure you are sending a string, so stringify JSON
  try {
    window.parent.postMessage(JSON.stringify(msg), window.location.origin); //NOSONAR
  } catch (error) {
    console.error("error window origin", error);
  }
}
/**
 * @public
 * @name setOrigin
 * @description Manually builds window.location.origin
 */
function setOrigin() {
  if (!window.location.origin) {
    // @ts-ignore: Polyfill for IE
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
  }
}
/**
 * @public
 * @name decodeURIMessage
 * @description Decodes an URI component
 * @param {string} message
 */
function decodeURIMessage(message) {
  return decodeURIComponent((message + "").replace(/\+/g, "%20"));
}
/**
 * @public
 * @name getCookies
 * @description Returns cookies as object
 */
function getCookies() {
  if (!globalThis.document || globalThis.document && !globalThis.document.cookie) {
    return {};
  }
  const c = document.cookie.split("; ");
  const cookies = {};
  for (let i = c.length - 1; i >= 0; i--) {
    const C = c[i].split("=");
    cookies[C[0]] = C[1];
  }
  return cookies;
}
/**
 * @public
 * @name getSelectedLocale
 * @description Checks for a saved selected language code
 * @param {string} defaultLocale
 * @returns {string} Returns the selected or default locale
 */
function getSelectedLocale(defaultLocale) {
  const selectedLang = getCookies().selectedLang;
  return languageMap[selectedLang] || defaultLocale;
}
/**
 * @public
 * @name getSelectedLanguage
 * @description Checks for a saved selected language code
 * @param {string} defaultLang
 * @returns {string} Returns the selected or default lang
 */
function getSelectedLanguage(defaultLang) {
  return getCookies().selectedLang || defaultLang;
}
/**
 * @name getSelectedLangAttribute
 * @description Gets the correct language attribute tag for each language
 * @param defaultLang
 * @returns string to use in the html lang tag
 */
function getSelectedLangAttribute(defaultLang) {
  const selectedLang = getCookies().selectedLang;
  return langAttrMap[selectedLang] || defaultLang;
}
/**
 * @public
 * @name deepGet
 * @description Maps a path to an object and tries to navigate down on that path, to return the value it finds at the end
 * @param {Array} path - An array of properties, in order of depth
 * @param {Object} originalScope - A target object
 * @returns {any | null} - The value found at the end of the path, or `null`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepGet(path, originalScope) {
  if ({}.toString.call(originalScope) !== "[object Object]" || {}.toString.call(path) !== "[object Array]" || !path.length) {
    return null; // early exit (prevent unnecessary TypeErrors thrown to the user)
  }
  return path.reduce((acc, key, i) => acc && acc[key] ? acc[key] // match is found
  : acc[key] === 0 || acc[key] === false || acc[key] === "" ? acc[key] // 0, false and "" are valid values
  :
  // no match is found
  path[i + 1] ? {} // not the end of the path (we cannot early exit 'reduce')
  : null,
  // the end of the path
  originalScope);
}
/**
 * @public
 * @name checkAndSumProps
 * @description Tries to sum a number of values
 * @param props - Any number of values that can be properties of an object
 * @returns {number | null} - The sum of all properties if they are all numbers, or "null"
 */
function checkAndSumProps(...props) {
  let invalidSum = false;
  let sum = 0;
  for (let i = 0; i < props.length; i++) {
    if (typeof props[i] !== "number" || props[i] === null) {
      invalidSum = true;
      break;
    }
    sum += props[i];
  }
  return invalidSum ? null : sum;
}
/**
 * @public
 * @name deepCompareObjectProperties
 * @description Compare if some specific characteristics of two objects match. (if objects have exact same structure)
 * Those are:
 * - same number of properties
 * - properties have the same names
 *
 * DOES NOT validate property values. This validation should be done downstream
 * @param targetObject - The data object to be validated
 * @param referenceObject - A reference object
 * @returns {boolean}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepCompareObjectProperties(targetObject, referenceObject) {
  const targetObjectKeys = Object.keys(targetObject).sort();
  const referenceObjectKeys = Object.keys(referenceObject).sort();
  // Same number of props and same names
  if (JSON.stringify(targetObjectKeys) !== JSON.stringify(referenceObjectKeys)) {
    throw new Error("Not according to contract");
  } else {
    // If a property is an object, run the check on the property.
    // EXCEPTION: the 'tasks' collection is not parsed because it can have any number of keys, with any names.
    // TODO: parametrize exception keys
    targetObjectKeys.forEach(key => typeof targetObject[key] === "object" && typeof referenceObject[key] === "object" && key !== "tasks" && deepCompareObjectProperties(targetObject[key], referenceObject[key]));
  }
  return true;
}
/**
 * @public
 * @name flatten
 * @description flattens object or array to single tree object
 * @param data - The data to be flattened
 * @param prefix - prefix for the keys
 * @returns {Object}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function flatten(data, prefix = "") {
  if (typeof data === "undefined" || data === null) {
    throw new Error("flattenData: data is missing");
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nestElement = (prev, value, key) => {
    if (value && typeof value === "object") {
      return Object.assign(Object.assign({}, prev), flatten(value, `${prefix}${key}.`));
    } else {
      return Object.assign(Object.assign({}, prev), {
        [`${prefix}${key}`]: value
      });
    }
  };
  if (Array.isArray(data)) {
    return data.reduce(nestElement, {});
  } else {
    return Object.keys(data).reduce((prev, element) => nestElement(prev, data[element], element), {});
  }
}
/**
 * @name canAccess
 * @description Determines if a set of party codes meets the requirements of a specific feature in the decision tables.
 * Usage examples:
 * canAccess([1, 44, 5, 6], rules => rules.FEATURES.EXPORT, "duplicate-booking")
 * canAccess([], rules => rules.TABS, "export")
 * canAccess([[4, 14], [39, 14], 6], rules => rules.FEATURES.IMPORT, "missing-invoice-details")
 * @param {Number[]} customerRoles A set of unique party role codes that a customer has
 * @param {Function} nestedPropertyAccessor A fat arrow function that should only provide the path to a nested object property within the DECISION_TABLES constant (case-sensitive)
 * @param {String} element The name of the feature which should be validated
 * @param {Object} decisionTables Object containing list of roles for specific feature
 * @param {Object} [specialRoles] An optional object containing information on special roles (the special role and the customer who has it)
 * @return {Boolean} If an empty array is provided (i.e. there is no customer code or the customer code is not present in the party roles), the check will be skipped and the function will return "false".
 */
const canAccess = (customerRoles,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
nestedPropertyAccessor, element,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
decisionTables,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
specialRoles) => {
  const feature = nestedPropertyAccessor(decisionTables).get(element);
  if (!feature) {
    throw new Error("That feature doesn't exist!");
  } else {
    return customerRoles.length > 0 ? feature
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map(decisionRule => {
      // First, handle special cases.
      // If the current rule being validated is 4 ("Consignee") and the shipment has a "Switched Consignee" (39) party, then this customer cannot access the feature.
      // Likewise for 3 ("Shipper"), 38 ("Switched Shipper").
      if (decisionRule === 4 && specialRoles && specialRoles.switchedConsignee || decisionRule === 3 && specialRoles && specialRoles.switchedShipper || Array.isArray(decisionRule) && decisionRule[0] === 4 && specialRoles && specialRoles.switchedConsignee || Array.isArray(decisionRule) && decisionRule[0] === 3 && specialRoles && specialRoles.switchedShipper) {
        return false;
      } else if (Array.isArray(decisionRule)) {
        // If the rule is an array, verify if the user has all the codes from it. (i.e. if the decisionRule is [1, 14], then the user must have codes 1 and 14).
        return decisionRule.every(decisionRoleCode => customerRoles.includes(decisionRoleCode));
      } else {
        // If the rule is not an array, verify if the user has that role.
        // Note: technically, this implementation also allows for non-numeric party roles. But the intention is to have only Number or [Number].
        return customerRoles.includes(decisionRule);
      }
    }).includes(true) : false;
  }
};
/**
 * @name isMobilePlatform
 * @description determines if the web application is being requested to be rendered in mobile app based on the query param "platform=mobile"
 * @returns {boolean}
 */
function isMobilePlatform() {
  const params = new URLSearchParams(window.location.search);
  const platformIdentifier = params.get("platform");
  return platformIdentifier === "mobile";
}
/**
 * @name isTwillSmeJourney
 * @description determines whether twill sme-journey based localstorage value "experience = maersk-go"
 * @returns {boolean}
 */
function isTwillSmeJourney() {
  const custInfo = getItem$1("custinfo");
  return (custInfo === null || custInfo === void 0 ? void 0 : custInfo.experience) === "maersk-go";
}
const DEFAULT_SEALANDMAERSK_REGION_CODE = "seau";
const SEALAND_REGIONS = ["seau", "sejj", "mcpu"];
const isSealandRegion = x => SEALAND_REGIONS.includes(x);
function sealandMaerskRegionSelected() {
  // See https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
  const regionCookieValue = getCookies && getCookies() && getCookies().regionSelected;
  return isSealandRegion(regionCookieValue) ? regionCookieValue : DEFAULT_SEALANDMAERSK_REGION_CODE;
}
/**
 * @public
 * @constant
 * @description Global Configuration containing business information.
 * @type {Object}
 * @namespace
 */
const globalBaseConfig = {
  DEFAULT_SEALANDMAERSK_REGION_CODE,
  DEFAULT_BRAND_CODE: "maeu",
  BRANDS: [{
    brandName: "maersk",
    brandCode: "maeu",
    scacCode: "maeu",
    designCode: "maeu",
    langCode: "maeu",
    commercialName: "Maersk",
    domain: "maersk.com"
  }, {
    brandName: "maerskline",
    brandCode: "maeu",
    scacCode: "maeu",
    designCode: "maeu",
    langCode: "maeu",
    commercialName: "Maersk",
    domain: "maerskline.com"
  }, {
    brandName: "sealandmaersk",
    brandCode: sealandMaerskRegionSelected(),
    scacCode: sealandMaerskRegionSelected(),
    designCode: "seau",
    langCode: "seau",
    commercialName: "Sealand - A Maersk Company",
    domain: "sealandmaersk.com"
  }, {
    brandName: "sealand",
    brandCode: "seau",
    scacCode: "seau",
    designCode: "seau",
    langCode: "seau",
    commercialName: "Sealand - A Maersk Company",
    domain: "sealand.com"
  }, {
    brandName: "seagoline",
    brandCode: "sejj",
    scacCode: "sejj",
    designCode: "seau",
    langCode: "seau",
    commercialName: "Sealand - A Maersk Company",
    domain: "seagoline.com"
  }, {
    brandName: "mcc",
    brandCode: "mcpu",
    scacCode: "mcpu",
    designCode: "seau",
    langCode: "seau",
    commercialName: "Sealand - A Maersk Company",
    domain: "mcc.com.sg"
  }, {
    brandName: "maersklinelimited",
    brandCode: "maei",
    scacCode: "maei",
    designCode: "maeu",
    langCode: "maei",
    commercialName: "Maersk Line, Limited",
    domain: "maersklinelimited.com"
  }, {
    brandName: "hamburgsud",
    brandCode: "sudu",
    scacCode: "sudu",
    designCode: "hsud",
    langCode: "hsud",
    commercialName: "Hamburg Süd - A Maersk company",
    domain: "hamburgsud.com"
  }, {
    brandName: "hamburgsud",
    brandCode: "sudu",
    scacCode: "sudu",
    designCode: "hsud",
    langCode: "hsud",
    commercialName: "Hamburg Süd - A Maersk company",
    domain: "hamburgsud.io"
  }],
  AO: {
    brands: {
      maeu: "Maersk Line",
      seau: "SeaLand",
      sejj: "Seago Line",
      mcpu: "MCC Transport",
      sudu: "Hamburg Süd"
    },
    brandCodeUrlMapping: {
      sealandmaersk: sealandMaerskRegionSelected(),
      sealand: "seau",
      seagoline: "sejj",
      mcc: "mcpu",
      maerskline: "maeu",
      maersk: "maeu",
      maersklinelimited: "maei",
      hamburgsud: "sudu"
    },
    environmentsMap: {
      dev: "test",
      cdt: "test",
      apit: "test",
      preprod: "preprod",
      stage: "preprod",
      demo: "preprod",
      apid: "preprod",
      my: "prod",
      beta: "prod",
      www: "prod"
    },
    betaEnvironments: ["beta"],
    IGN: {
      assetsLocationMap: {
        localhost: "assetst",
        test: "assetst",
        preprod: "assetsd",
        prod: "assets"
      },
      apmt_assetsLocationMap: "api",
      HEADER_ID: "ign-header"
    },
    PSS: {
      PORTAL_HEADER_ID: "portal-menu",
      PORTAL_FOOTER_ID: "portal-footer",
      assetsLocation: "//assetst.maerskline.com/pss/"
    },
    I18NPATH: "/content/keys/query/",
    APMTHEADLESSKEYPATH: "/headless-keys/apps/"
  },
  DEFAULT_LANGUAGE: "en",
  DEFAULT_PROTOCOL: "https://",
  DEFAULT_REDIRECT: "/dashboard/",
  LOGIN_ORIGIN_QUERY_PARAM: "originalUrl",
  LOGIN_PATH: "portaluser/login",
  SSP_DASHBOARD: "l/dashboard",
  USER_ROLES: new Map([[1, "Booked By"], [2, "Contractual Customer"], [3, "Shipper"], [4, "Consignee"], [5, "First Notify Party"], [6, "Additional Notify Party"], [7, "Allocation Owner"], [11, "Outward Customs Broker"], [12, "Inward Customs Broker"], [13, "Contractual Carrier's Inward Agent"], [14, "Invoice Party"], [15, "Outward Forwarder"], [16, "Inward Forwarder"], [22, "Transport Document Receiver"], [25, "Contractual Carrier's Outward Agent"], [26, "Credit Party"], [27, "Product Owner"], [28, "Outward Document Owner"], [29, "Inward Document Owner"], [31, "Release to Party"], [32, "Lawful (B/L) Holder"], [33, "Demurrage Invoice Party"], [34, "Detention Invoice Party"], [35, "Supplier"], [36, "House Shipper"], [37, "House Consignee"], [38, "Switched Shipper"], [39, "Switched Consignee"], [40, "Doc Shipper"], [41, "Doc Consignee"], [44, "Price Owner"], [45, "Named Account Customer"], [46, "Export Deal Owner"], [47, "Import Deal Owner"], [48, "Import Demurrage Payer"], [49, "Import Detention Payer"]])
};

/**
 * @private
 * @param {string} url string or URL object (e.g. window.location)
 * @returns {Array} Array of URL parts
 */
function getUrlParts(url) {
  let urlObject = url;
  if (typeof url === "string") {
    try {
      urlObject = new URL(url);
    } catch (e) {
      console.warn(`[getUrlParts] expected type string; received ${typeof url} ! returning null`);
    }
  }
  return urlObject.hostname && urlObject.hostname.split(".") || null;
}
/**
 * @private
 * @name isIPAddress
 * @description Determines if an array of strings is an IP address. For example ['127','0','0','1'] (true) and ['beta','maersk','com'] (false)
 * @param {Array} urlParts
 * @returns {Boolean}
 */
function isIPAddress(urlParts) {
  let result = true;
  if (Array.isArray(urlParts) && urlParts.length >= 4) {
    for (let i = urlParts.length - 4; i < urlParts.length; i++) {
      if (!Number.isInteger(parseInt(urlParts[i], 10))) {
        result = false;
      }
    }
  } else {
    result = false;
  }
  return result;
}
/**
 * @public
 * @name isLocalhost
 * @description Determines if a given hostname is a local development machine.
 * This is indicated by the nodejs environment running in "development" mode, or the hostname being localhost, 0.0.0.0 or 127.0.0.1
 * @param {String} hostname
 * @returns {Boolean} Whether the hostname is considered localhosty
 */
function isLocalhost(hostname) {
  return process.env.NODE_ENV === "development" || ["localhost", "0.0.0.0", "127.0.0.1"].includes(hostname);
}
/**
 * @public
 * @name getBrandFromHostname
 * @description Uses the URL provided to determine the brand.
 * @param {string} url string or object (e.g. window.location)
 * @param {string} localOverride a brandName that should be used if the url domain is reported as localhost. Has no affect on other environments
 * @returns {Object} An brand object from the global configuration
 */
function getBrandFromHostname(url, localOverride = "maersk") {
  let urlParts = getUrlParts(url);
  let urlDomain = "";
  // Needs to determine if this is an IP address
  if (urlParts && isIPAddress(urlParts)) {
    urlDomain = urlParts.join(".");
  } else {
    if (urlParts) {
      // we need to reverse the array in order to work with multi level domains
      urlParts = urlParts.reverse();
      if (urlParts.length > 1 && urlParts[0] === "cn") {
        // We are assuming here that we don't have any domains that are www.maersk.cn - we'll always have www.maersk.com.cn
        urlDomain = urlParts[2];
      } else if (urlParts.length > 1 && urlParts[0] !== "cn") {
        urlDomain = urlParts[1];
      } else {
        urlDomain = urlParts[0];
      }
    } else {
      urlDomain = "";
    }
  }
  const domainToMatch = isLocalhost(urlDomain) ? localOverride : urlDomain;
  // This will always match as there's always a Maersk brand
  return globalBaseConfig.BRANDS.find(brand => brand.brandName === domainToMatch) || globalBaseConfig.BRANDS.find(brand => brand.brandName === "maersk");
}
/**
 * @public
 * @name getBetaFromHostname
 * @description Uses the URL provided to determine whether the app is running on a beta environment.
 * @param {string|Location} url
 * @returns {boolean} Is it a beta environment
 */
function getBetaFromHostname(url) {
  const urlParts = getUrlParts(url);
  if (urlParts && urlParts.length > 1) {
    const subdomain = urlParts[0];
    return globalBaseConfig.AO.betaEnvironments.includes(subdomain);
  }
  return false;
}
/**
 * @public
 * @name getEnvironmentIdentifier
 * @description Extracts the unique identifier for each environment
 * @returns {string} the unique identifier for each env, usually the subdomain
 * this function should cover the case for projects with subdomains like holding ENV flag ANYWHERE in the host
 * @param {URL} url url if you want to override the default url setup "new URL(window.location.href)"
 */
function getEnvironmentIdentifier(url) {
  if (!url) {
    url = new URL(window.location.href);
  }
  let label = url.hostname.split(".")[0];
  if ([/\.cdt\./, /\.cdt/, /cdt\./, /\.dev\./, /\.dev/, /dev\./].some(regex => url === null || url === void 0 ? void 0 : url.hostname.match(regex)) ||
  /**
   * Since 5.10.0 the default label for any unknown domain is prod.
   * Because of this, when apiDomain was introduced in 5.11.0, APIs called from a deployed storybook were
   * being sent to production APIs, which often failed.
   * Therefore, we are adding a check for the __STORYBOOK_ADDONS global variable, which is only present in storybook
   * so that the APIs in storybook will continue to call the test environment
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.__STORYBOOK_ADDONS) {
    label = "cdt";
  }
  // Demo labels
  if ([/\.demo\./, /\.demo/, /demo\./, /\.stage\./, /\.stage/, /stage\./].some(regex => url === null || url === void 0 ? void 0 : url.hostname.match(regex))) {
    label = "demo";
  }
  // Preprod labels
  if ([/\.preprod\./, /\.preprod/, /preprod\./, /uat\./].some(regex => url === null || url === void 0 ? void 0 : url.hostname.match(regex))) {
    label = "preprod";
  }
  return label;
}
/**
 * @public
 * @name getEnvironmentLabel
 * @description Returns the environment label based on the window location and the map found in the global config
 * @returns {string} the environment label [test | preprod | prod] , default: localhost
 * @param {URL} url url if you want to override the default url setup "new URL(window.location.href)"
 */
function getEnvironmentLabel(url) {
  if (!url) {
    url = new URL(window.location.href);
  }
  const environmentIdentifier = getEnvironmentIdentifier(url);
  const envLabel = globalBaseConfig.AO.environmentsMap[environmentIdentifier];
  if (envLabel) {
    return envLabel;
  }
  // check if we are on localhost
  if (isLocalhost(url.hostname)) {
    return "localhost";
  }
  return "prod";
}
/**
 * Determines if the domain is a chinese domain (by checking for .cn at the end of the hostname)
 * @param {string} hostname the domain of the host without ports. Should be window.location.hostname
 * @returns {boolean} Is the domain a Chinese one
 */
function isChinaDomain(hostname) {
  return hostname.endsWith(".cn");
}
/**
 * Retrieves the correct domain for the API based on the hostname
 * Specifically designed for China domains i.e. in china we use api.maersk.com.cn
 * @param {URL} url The current browser URL - i.e. new URL(window.location.href)
 * @returns the name of the domain i.e. api.maersk.com.cn
 */
function apiDomain(url) {
  const label = getEnvironmentLabel(url);
  const domain = isChinaDomain(url.hostname) ? "maersk.com.cn" : "maersk.com";
  if (label === "test" || label === "localhost") {
    return `api-cdt.${domain}`;
  } else if (label === "preprod") {
    return `api-stage.${domain}`;
  }
  // Prod by default
  return `api.${domain}`;
}
/**
 * @public
 * @name setFaviconByBrand
 * @description Updates the "favicon" according with the brand.
 * @param {Object} - brand configuration. Must contain a property called brandName with the name of the brand e.g. maersk, hamburgsud
 * Note, these icons are stored in Akamai NetStorage and are uploaded manually
 */
function setFaviconByBrand({
  brandName
}) {
  if (brandName) {
    const subdomain = globalBaseConfig.AO.IGN.assetsLocationMap[getEnvironmentLabel()];
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = `https://${subdomain}.maerskline.com/img/fav-icons/${brandName}.ico`;
    document.head.appendChild(link);
  } else {
    console.warn("[setFaviconByBrand] - Options ({ brandName }) not provided. Favicon not applied.");
  }
}
function attachElementToHead({
  elemType,
  rel,
  link,
  innerText,
  media,
  property,
  content,
  name
}) {
  const elem = document.createElement(elemType);
  if (rel && elemType) elem.rel = rel;
  if (property) elem.setAttribute("property", property);
  if (content) elem.content = content;
  if (name) elem.name = name;
  elemType === "script" ? elem.src = link : elem.href = link;
  if (innerText) elem.innerText = innerText;
  if (media) elem.media = media;
  document.head.appendChild(elem);
}
function getBrandCode(AO, DEFAULT_BRAND_CODE) {
  let brandCode = DEFAULT_BRAND_CODE;
  const currentLocationHostname = window.location.hostname;
  for (const brand in AO.brandCodeUrlMapping) {
    const hostFragments = currentLocationHostname.split(".");
    if (hostFragments.indexOf(brand) > -1) {
      brandCode = AO.brandCodeUrlMapping[brand];
      break;
    }
  }
  return brandCode;
}
function insertIGNContainer(headerId) {
  if (!document.getElementById(headerId)) {
    const ignHeaderContainer = document.createElement("header");
    ignHeaderContainer.id = headerId;
    ignHeaderContainer.classList.add("ign-header");
    ignHeaderContainer.setAttribute("role", "navigation");
    document.body.insertBefore(ignHeaderContainer, document.body.firstChild);
  }
}
function insertIGNHeaderAndFooter({
  brandCode,
  assetsLocationMap = {},
  settings = {}
}) {
  const envLabel = getEnvironmentLabel();
  const assetsEnv = envLabel !== "localhost" ? envLabel : "test";
  const ignSettings = {
    ASSETS_ENV: assetsEnv,
    brand: brandCode // Sets the branding
  };
  if (settings.version) {
    if (typeof settings.version === "string") {
      ignSettings.version = settings.version;
    } else {
      console.warn(`[IGN] Expected string; received ${typeof settings.version}!`);
    }
  }
  if (settings.disableOpacityChange) {
    if (typeof settings.disableOpacityChange === "boolean") {
      ignSettings.disableOpacityChange = settings.disableOpacityChange;
    } else {
      console.warn(`[IGN] Expected boolean; received ${typeof settings.disableOpacityChange}!`);
    }
  }
  window.projectServices = ignSettings;
  attachElementToHead({
    elemType: "script",
    link: `https://${assetsLocationMap[assetsEnv]}.maerskline.com/integrated-global-nav/2/loader.js`
  });
}
function setIGNHeaderAndFooter(config) {
  insertIGNContainer(config.headerId);
  insertIGNHeaderAndFooter(config);
}

/**
 * @public
 * @name getHeaderAndFooter
 * @description Adds the Header and Footer
 * Since 2.1, it only supports the IGN and will not load the PSS header
 * @since 1.0
 * @param {config} @default {globalBaseConfig}
 */
function getHeaderAndFooter(config = globalBaseConfig) {
  var _a;
  let brandCode = (_a = config.brand) === null || _a === void 0 ? void 0 : _a.brandCode;
  if (!config.fromMaerskPlugin) {
    /**
     * If this is called by the MaerskVueAppPlugin, then we already have this stuff:
     * - config.selectedLang
     * - config.isBeta
     * - config.brandCode
     * & sets origin
     */
    setOrigin();
    config.selectedLang = getSelectedLanguage(config.DEFAULT_LANGUAGE);
    config.isBeta = getBetaFromHostname(window.location);
    config.brandCode = getBrandCode(config.AO, config.DEFAULT_BRAND_CODE);
    brandCode = config.brandCode;
  }
  //If config.brandParam is present (during development mode only) then force switch brandcode to display corresponding header and footer
  brandCode = isLocalhost(window.location.hostname) && config.brandParam ? config.brandParam : brandCode;
  window.forceIGN = true;
  const ignConfig = {
    headerId: config.AO.IGN.HEADER_ID,
    brandCode: brandCode,
    assetsLocationMap: config.AO.IGN.assetsLocationMap,
    settings: {
      disableOpacityChange: config.AO.IGN.DISABLE_OPACITY_CHANGE,
      version: config.AO.IGN.VERSION,
      beta: config.isBeta
    }
  };
  setIGNHeaderAndFooter(ignConfig);
}

/**
 * @private
 * @category Authentication
 * @name _getJwt
 * @description Get JWT. Returns JWT as string.
 * If error, returns object containing error and failure date stamp.
 * If nothing in localstorage, returns null.
 * @param {string} type
 * @return {*}
 */
function _getJwt(type) {
  const jwt = window.localStorage.getItem(type);
  let toReturn;
  if (jwt && jwt.indexOf("Error") > -1) {
    const split = jwt.split(" | ");
    toReturn = {
      error: split[0],
      failureDateStamp: split[1]
    };
  } else {
    toReturn = jwt;
  }
  return toReturn;
}
/**
 * @public
 * @name getJwt
 * @category Authentication
 * @description Get FR JWT.
 * @return {string}. If nothing in localstorage, returns null. If error, returns object containing error and failure date stamp.
 */
function getJwt() {
  return _getJwt("frJwt");
}
/**
 * @public
 * @name getCustomerCode
 * @description Get customer code. Retrieves the customer code as a string(if one was set).
 * Note that this probably means that the customer has only one customer code and as such it is stored in the JWT.
 * @return {string}. If nothing in local storage, returns null.
 */
function getCustomerCode() {
  return window.localStorage.getItem("customerCode");
}
/**
 * @public
 * @name getUsiJwt
 * @category Authentication
 * @description Get USI JWT.
 * @deprecated Should not be needed by applications
 * @return {string}. If nothing in localstorage, returns null.  If error, returns object containing error and failure date stamp.
 */
function getUsiJwt() {
  return _getJwt("usiJwt");
}
/**
 * @public
 * @name decodeJwtBody
 * @category Authentication
 * @description Decode JWT Body. Takes in whole JWT and returns the decoded body.
 * @deprecated Should not be needed by applications
 * @param {string} jwt
 * @return {Object} Returns null if JWT is malformed.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeJwtBody(jwt) {
  let toReturn = null;
  if (jwt && (typeof jwt === "string" || jwt instanceof String) && jwt.split(".")[1]) {
    // split the jwt on the dots, take the second element (body) and sanitise
    const base64 = jwt.split(".")[1].replace("-", "+").replace("_", "/");
    toReturn = JSON.parse(atob(base64));
  }
  return toReturn;
}
/**
 * @public
 * @name getUserCredential
 * @category Authentication
 * @description
 * Gets the named parameter from the Jwt token
 * @param {string} param
 * @return {*} the token value, or null if none is found
 */
function getUserCredential(param) {
  const userCredentials = decodeJwtBody(getJwt());
  let toReturn = null;
  if (userCredentials) {
    toReturn = userCredentials[param] || null;
  }
  return toReturn;
}
/**
 * @public
 * @name redirectToLogin
 * @category Authentication
 * @description Change the URL to Login and provide a query param for going back to the origin after login is successful. If the page hostname is "localhost", falls back to 'cdt.maersk.com' as a domain.
 * @param {string} originPath - The stub to be attached to the origin domain (i.e. "/shipments").
 */
function redirectToLogin(originPath) {
  const host = isLocalhost(window.location.hostname) ? "cdt.maersk.com" : window.location.host;
  // Get the query/search params of the current location
  const params = new URLSearchParams(originPath);
  let utmParams = "";
  params.forEach((value, key) => {
    if (key.startsWith("utm_")) utmParams += `&${key}=${value}`;
  });
  let loginLocation = `${globalBaseConfig.DEFAULT_PROTOCOL}${host}/${globalBaseConfig.LOGIN_PATH}?${globalBaseConfig.LOGIN_ORIGIN_QUERY_PARAM}=${encodeURIComponent(`${globalBaseConfig.DEFAULT_PROTOCOL}${host}/${originPath}`)}`;
  if (utmParams) loginLocation += utmParams;
  window.location.assign(loginLocation);
}
/**
 * @public
 * @name redirectToSSP
 * @description Redirect to the SSP Version of dashboard
 */
function redirectToSSP() {
  const host = isLocalhost(window.location.hostname) ? "cdt.maersk.com" : window.location.host;
  window.location.assign(`${globalBaseConfig.DEFAULT_PROTOCOL}${host}/${globalBaseConfig.SSP_DASHBOARD}`);
}
/**
 * @public
 * @name goToOriginalUrl
 * @description Navigates to the local stored originalUrl or the default redirect url
 */
function goToOriginalUrl() {
  window.location.assign(localStorage.getItem("originalUrl") || `${window.location.origin}${globalBaseConfig.DEFAULT_REDIRECT}`);
}
/**
 * @public
 * @name getCustomerCodeFromJwt
 * @description get the customer code from JWT token
 * @return
 */
function getCustomerCodeFromJwt() {
  const jwt = getJwt();
  const decodedJwt = decodeJwtBody(jwt);
  return decodedJwt && decodedJwt.customer_code ? decodedJwt.customer_code : null;
}
/**
 * @public
 * @name getSalesForceAccessToken
 * @description get the Salesforce access token from local storage
 * access tokens are sent as part of headers of Salesforce apis to authenticate requests.
 * Note that jwt tokens can not be used authenticate as Salesforce is not on-boarded to authenticate requests through single sign-on
 * @return
 */
function getSalesForceAccessToken() {
  return window.localStorage.getItem("access_token");
}
/**
 * @public
 * @name isCustomerLoggedIn
 * @description determines if the customer is currently logged in by checking if the JWT token is present and valid
 * @returns boolean
 */
function isCustomerLoggedIn() {
  const jwt = getJwt();
  const decodedJwt = decodeJwtBody(jwt);
  return decodedJwt !== null && decodedJwt.exp >= Date.now() / 1000;
}

/**
 * @private
 * @name checkCompatibility
 */
function checkCompatibility() {
  //  Thanks Modernizr!
  try {
    return "sessionStorage" in window && "setItem" in sessionStorage;
  } catch (_a) {
    return false;
  }
}
/**
 * @public
 * @name setItem
 * @param {*} key
 * @param {*} data
 * @description
 * Sets an item in HTML5 sessionStorage. Runs a compatibility check first which will fail silently if it is unsuccessful
 */
function setItem(key, data, category, consentType = ConsentType.Essential, cookieString = document.cookie) {
  if (checkCompatibility() && checkConsent(consentType, cookieString)) {
    sessionStorage.setItem(makeKey(key, category), JSON.stringify(data));
  }
}
/**
 * @public
 * @name getItem
 * @param {*} key
 */
function getItem(key, category) {
  if (checkCompatibility()) {
    try {
      return JSON.parse(sessionStorage.getItem(makeKey(key, category)));
    } catch (e) {
      return sessionStorage.getItem(makeKey(key, category));
    }
  } else {
    return "";
  }
}
/**
 * @public
 * @name removeItem
 * @param {*} key
 */
function removeItem(key, category) {
  if (checkCompatibility()) {
    sessionStorage.removeItem(makeKey(key, category));
  }
}
const sessionStore = {
  setItem,
  getItem,
  removeItem
};

/**
 * @public
 * @deprecated Use feature detection instead
 * @name isIE
 * @description
 * It checks if the user agent is set to Internet Explorer
 * @return {boolean} Returns true if the browser is IE
 */
function isIE() {
  return window.navigator.userAgent.indexOf("Trident/") >= 0;
}

/* This file has helper functions for fetch-related tasks - for example: recursive retry function */
function asyncTimeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
async function sleep(timeout, fn, ...args) {
  await asyncTimeout(timeout);
  return await fn(...args);
}
function calculateTimeout(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
jsonResponse, defaultTimeout) {
  let timeout = defaultTimeout;
  if (jsonResponse.retryAfterIntervalSeconds) {
    timeout = jsonResponse.retryAfterIntervalSeconds * 1000;
  } else if ((jsonResponse.waitTime !== null || jsonResponse.waitTime !== undefined) && jsonResponse.waitTimeUnit) {
    // This calculates the timeout multiplier based on the waitTimeUnit
    // it's a set of IFs because jest had real problems with a switch!
    let multiplier = 1;
    if (jsonResponse.waitTimeUnit === "SECONDS") {
      multiplier = 1000;
    } else if (jsonResponse.waitTimeUnit === "MINUTES") {
      multiplier = 1000 * 60;
    } else if (jsonResponse.waitTimeUnit === "HOURS") {
      multiplier = 1000 * 60 * 60;
    }
    timeout = jsonResponse.waitTime * multiplier;
  }
  return timeout;
}
function convertPOSTtoGET({
  url,
  fetchOptions,
  getUrlPath,
  correlationId
}) {
  // this is POST to GET, not GET to anything else
  if (fetchOptions.method && !["PUT", "POST", "PATCH"].includes(fetchOptions.method)) {
    return {
      updatedUrl: url,
      updatedFetchOptions: fetchOptions
    };
  }
  if (getUrlPath) {
    const tempUrl = new URL(url);
    tempUrl.pathname = getUrlPath;
    url = tempUrl.href;
  }
  // Set the method to GET
  fetchOptions.method = "GET";
  // body cannot be sent with GET, so it should be deleted
  delete fetchOptions.body;
  // Construct the new URL
  const urlObject = new URL(url);
  // Add whatever existing parameters are there
  if (fetchOptions.params) {
    for (const [key, value] of Object.entries(fetchOptions.params)) {
      urlObject.searchParams.set(key, value);
    }
  }
  // Add the correlation ID
  if (correlationId) {
    urlObject.searchParams.set("correlation_id", correlationId);
  }
  return {
    updatedUrl: urlObject.href,
    updatedFetchOptions: fetchOptions
  };
}
/**
 * @public
 * @name getRetryableResponse
 * @param {string} url The URL of the endpoint
 * @param {object} fetchOptions A Request object for the fetch (https://developer.mozilla.org/en-US/docs/Web/API/Request/Request)
 * @param {number} retries Number of times to retry the function before exiting
 * @param {number} timeout in milliseconds. default 3000
 * @param {string} getUrlPath the url to be used in the subsequent GET request
 * @returns {Promise<any>} A promise that will resolve to the data, or throw an error if none is returned after the retry period
 * @description
 * This function will throw errors back to the consuming application to handle them
 * The flow for this pattern is as follows:
 * POST to a URL with the required parameters
 * You will be given a 202 with the CorrelationID and a retry time
 * After that, you need to make a GET request to the same endpoint with the correlationID
 * Possible results of that are:
 * 200 - with the data - success!
 * 204 - No Content, still processing, retry - try again
 * 400-599 - Error - exit from loop
 */
async function getRetryableResponse({
  url,
  fetchOptions,
  retries = 5,
  timeout = 3000,
  getUrlPath
}) {
  if (retries === 0) {
    return;
  }
  const response = await fetch(url, fetchOptions);
  let json;
  // Avoid throwing an Error when there is no content
  if (response.status !== 204) {
    json = await response.json();
  }
  // Success!
  if (response.status === 200) {
    return json;
  }
  // On response errors (400 - 599) - exit
  if (response.status >= 400) {
    throw json;
  }
  if (Array.isArray(json)) {
    if (json.length === 1) json = json[0];else throw new Error("Response is a collection with zero or more then one element. It cannot be handled by multiple retryable requests.");
  }
  let updatedUrl = url;
  let updatedFetchOptions = fetchOptions;
  let retriesLeft = retries;
  if (response.status === 202) {
    // Once you get a 202, the API should have given you a correlationId to use to get the result
    let correlationId;
    if (json && json.correlationId) {
      correlationId = json.correlationId;
    } else {
      throw new Error("Response does not contain 'correlationId' which is required for making another request.");
    }
    // This is the time to update the fetch options - the next request is a GET
    const res = convertPOSTtoGET({
      url,
      fetchOptions: updatedFetchOptions,
      getUrlPath,
      correlationId
    });
    updatedUrl = res.updatedUrl;
    updatedFetchOptions = res.updatedFetchOptions;
    timeout = calculateTimeout(json, timeout);
  } else {
    // Still just a correlation id in the response - try again
    // Doing it this way means that if someone sets the retryLimit as -1, it's possible to loop infinitely.
    retriesLeft = retries - 1;
  }
  // Make another call after a timeout
  return await sleep(timeout, getRetryableResponse, {
    url: updatedUrl,
    fetchOptions: updatedFetchOptions,
    retries: retriesLeft,
    timeout
  });
}

/**
 * Formats a number to the correct locale representation
 * based on the language that has been selected on the site
 * You can pass a number or a string as the NumberFormat function performs conversion for you
 * https://tc39.es/ecma402/#sec-number-format-functions
 * @param {number | string} numberOrString
 * @returns {string} the number provided, formatted based on the current language cookie
 * @public
 */
function formatNumber(numberOrString) {
  if (Intl && Intl.NumberFormat) {
    const lang = getSelectedLocale(globalBaseConfig.DEFAULT_LANGUAGE);
    return new Intl.NumberFormat(lang).format(numberOrString);
  } else {
    return numberOrString.toString();
  }
}
//Helper functions for formatting. Not exposed, they don't need to be.
// Testing is provided via the removeRounding function
// Replaces a character at a point in the string
function replaceAt(str, index, replacement) {
  return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}
/**
 * Removes the rounding of formatted numbers caused by Intl.NumberFormat
 * Normally, that's fine, but this is a financial product and we can't give away money
 * @param {number} originalNo The original number before formatting
 * @param {string} newNo the formatted number from Intl.NumberFormat
 * @param {Intl.NumberFormatPart[]} formattedParts the parts of the formatted number as provided by Intl.NumberFormat
 * @protected
 */
function removeRounding(originalNo, newNo, formattedParts) {
  var _a, _b, _c;
  // Define the basic things that we need.
  let newNoIntsOnly = newNo.replace(/[^\d\\.\\,\s]+/, "").trim();
  let originalNoString = originalNo.toString();
  let negativePresent = "";
  // To handle the negative numbers
  if (originalNoString.charAt(0) === "-") {
    originalNoString = originalNoString.slice(1);
    negativePresent = "-";
  }
  const hasDecimals = originalNoString.includes(".");
  const decimalChar = (_a = formattedParts.find(part => part.type === "decimal")) === null || _a === void 0 ? void 0 : _a.value;
  const orgNumber = originalNoString.replace(/[\\.]/g, "");
  const numIntergers = newNoIntsOnly.replace(/[^0-9]/g, "").length;
  // If the original number has some decimals
  if (hasDecimals) {
    // If those first two decimals are 99 and it's rounded up - decimals can be any number of digits long
    const decimals = originalNoString.split(".")[1];
    if (decimals.startsWith("99") && parseInt(decimals[2]) >= 5) {
      // It's rounded up so the last number before the decimals needs to go down 1, and decimals need to be set to the right length
      // Does it have any decimal places in the formatted number?
      let decimalPlaces = 0;
      if (decimalChar) {
        // Yes, it has decimal places, get the right number of them
        decimalPlaces = ((_c = (_b = formattedParts.find(part => part.type === "fraction")) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.length) || 0;
        // Replace the new decimals with the original ones to the same length
        const orgDecimals = originalNoString.match(new RegExp("\\.([0-9]{" + decimalPlaces + "})"));
        newNoIntsOnly = newNoIntsOnly.replace(new RegExp("[^0-9](?=[^,|.]*$)[0-9]{" + decimalPlaces + "}"), decimalChar + orgDecimals[1]);
      }
      // Finally, correct the integers. This will need to be recursive as it could be 99999999999.995
      let lastOrigNumber = originalNoString.split(".")[0].length - 1;
      let lastNewNumber = newNoIntsOnly.length - decimalPlaces - 1;
      while (lastOrigNumber >= 0) {
        // If it's a group separator, skip it
        // Note, space is a possible group separator for French
        if ([".", ",", " "].includes(newNoIntsOnly[lastNewNumber])) {
          lastNewNumber--;
        }
        // If the numbers don't match, replace it and move on.
        if (newNoIntsOnly[lastNewNumber] !== originalNoString[lastOrigNumber]) {
          newNoIntsOnly = replaceAt(newNoIntsOnly, lastNewNumber, originalNoString[lastOrigNumber]);
          lastNewNumber--;
          lastOrigNumber--;
        } else {
          // Otherwise, we're done
          break;
        }
      }
      // Check the length of the integers, it may have gone from 9 to 10
      if (originalNoString[0] === "9" && newNoIntsOnly[0] === "1") {
        // Then cut the first number off
        newNoIntsOnly = newNoIntsOnly.substring(1);
      }
    } else {
      const fraction = formattedParts.find(part => part.type === "fraction");
      if (fraction && fraction.value[0] !== decimals[0]) {
        newNoIntsOnly = newNoIntsOnly.replace(/..$/, `${decimals[0]}${decimals[1]}`);
      } else if (!fraction && orgNumber[numIntergers - 1] === "9") {
        // If the last digit of number was 9, then the above code stepped over to the next ten. We should move it back
        const lastPart = newNoIntsOnly.split(",").slice(-1)[0];
        if (lastPart.length === 3) {
          const lastPartCeil = Number(newNoIntsOnly.split(",").slice(-1)[0]) - 1;
          newNoIntsOnly = newNoIntsOnly.replace(/[0-9]{3}$/, lastPartCeil.toString());
        }
      } else if (orgNumber[numIntergers - 1]) {
        newNoIntsOnly = newNoIntsOnly.replace(/.$/, orgNumber[numIntergers - 1]);
      }
    }
  }
  newNoIntsOnly = negativePresent + newNoIntsOnly;
  return newNoIntsOnly;
}
/**
 * Formats a currency amount to the right string based on Intl formatting
 * It does not round any value or decimal places unless specified by the currency
 * It will always return latin numbers
 * @param {number} amount A number to be formatted
 * @param {string} currency ISO 4217 currency code e.g. USD
 * @returns {string} the number provided, formatted based on the current language cookie
 * @public
 */
function formatCurrency(amount, currency) {
  const lang = getSelectedLocale(globalBaseConfig.DEFAULT_LANGUAGE);
  const func = new Intl.NumberFormat(`${lang}-u-nu-latn`,
  // Use the language set in the cookie but in latin character set
  {
    style: "currency",
    currency: currency,
    currencyDisplay: "code"
  });
  const formatted = func.format(amount);
  const formattedParts = func.formatToParts(amount);
  // RemoveRounding also strips currency symbols, which we also want
  // We will always attempt to remove rounding because of the above
  return removeRounding(amount, formatted, formattedParts);
}
/**
 * Masks a number or string e.g 1234567 will be masked as ****567
 * You can pass a number or a string as the maskData function performs conversion for you
 * @example e.g { fromStart: 2 } starts masking from index 2 and { fromEnd: 2 } starts from numberOrString.length-2 index
 * @param {number | string} numberOrString
 * @param {MaskDataConfig} config
 * @returns {string} the number or string provided
 * @public
 */
function maskData(numberOrString, config) {
  if (!numberOrString || !["string", "number"].includes(typeof numberOrString)) {
    throw new Error("Not a valid input type for masking. Only string and number types allowed and can not be empty");
  }
  if (!config || !Object.keys(config).length || !(config.fromStart || config.fromEnd) || !(typeof config.fromStart === "number" || typeof config.fromEnd === "number")) {
    throw new Error("Not a valid config. Must include either fromStart or fromEnd");
  }
  if (config.fromStart && config.fromEnd) {
    throw new Error("Not a valid config. Must include either fromStart or fromEnd, can not be both");
  }
  if (config.fromStart && config.fromStart < 0 || config.fromEnd && config.fromEnd < 0) {
    throw new Error("Not a valid config. fromStart or fromEnd can not be negative");
  }
  const convertedInput = `${numberOrString}`;
  if (config.fromStart && convertedInput.length < config.fromStart || config.fromEnd && convertedInput.length < config.fromEnd) {
    throw new Error("The number of chars need not to be masked can not be greater than input");
  }
  let noOfcharsNotToBeMasked = 0,
    charsNotToBeMasked = "";
  if (config.fromStart) {
    noOfcharsNotToBeMasked = convertedInput.length - config.fromStart;
    charsNotToBeMasked = convertedInput.substring(0, config.fromStart);
  } else if (config.fromEnd) {
    noOfcharsNotToBeMasked = convertedInput.length - config.fromEnd;
    charsNotToBeMasked = convertedInput.substring(convertedInput.length - config.fromEnd);
  }
  const maskedDigits = "*".repeat(noOfcharsNotToBeMasked);
  return config.fromStart ? charsNotToBeMasked.concat(maskedDigits) : maskedDigits.concat(charsNotToBeMasked);
}
/**
 * Masks the customer code skipping the last three chars e.g "123456789" as "******789"
 * @param {number | string} code
 * @returns {string} The masked code
 * @public
 */
function maskCustomerCode(code) {
  return maskData(code, {
    fromEnd: 3
  });
}
const lbsKgsConversion = 2.20462;
/**
 * Converts a number of lbs to kgs
 * @param {Number} amount amount in lbs
 */
const lbsToKgs = amount => {
  return amount / lbsKgsConversion;
};
/**
 * Converts a number of kgs to lbs
 * @param {Number} amount amount in kgs
 */
const kgsToLbs = amount => {
  return amount * lbsKgsConversion;
};

/**
 * @public
 * @class LanguageHandlerCommon
 * @name LanguageHandlerCommon
 * @description
 * LanguageHandlerCommon is a class that fetches the correct language translation file with the correct fallback
 * from the given source.
 * For most of the projects, you won't need to use this library directly,
 * instead, you should be using the framework-specific plugin for languages
 * @param {string} langFilePathWeb URL of the remote language file folder. Not required if keyRootId is present
 * @param {string} langJson URL of the language file folder within the application to handle fallback
 * @param {string} keyRootId Key of the SiteCore asset. If both keyRootId and langFilePathWeb are provided, the keyRootId takes precedence
 * @param {Array} tags An array of tags to be appended to the request. Only valid for requests with a keyRootId
 * @param {number} keyRootVersion A number (or a string) for the version of the SiteCore asset. A version number is required e.g. "1" - if no number is supplied, the default is "1"
 * @param {LangCode} langCode The language code for the request. If set to null it will resolve this from the hostname
 * @param {boolean} isAPMT A boolean to determine if the request is for APMT
 * @param {string} apmtAppName The name of the APMT application
 * @param {string} consumerKey Stargate consumer key to be used for the request for sitecore headless keys (only for APMT)
 */
class LanguageHandler {
  constructor(langFilePathWeb, langFilePathLocal, keyRootId, tags, keyRootVersion, langCode, langJson, isAPMT, apmtAppName, consumerKey) {
    this.langFilePathWeb = langFilePathWeb;
    this.langFilePathLocal = langFilePathLocal;
    this.keyRootId = keyRootId;
    this.tags = tags;
    this.keyRootVersion = keyRootVersion;
    this.langCode = langCode;
    this.langJson = langJson;
    this.isAPMT = isAPMT;
    this.apmtAppName = apmtAppName;
    this.consumerKey = consumerKey;
    // verify params
    if (!this.langFilePathWeb && !this.keyRootId) {
      throw new Error("langFilePathWeb or keyRootId are required!");
    }
    if (!this.langFilePathLocal && !this.langJson) {
      throw new Error("langFilePathLocal or langJson required!");
    }
    // ensure default value
    if (!this.keyRootVersion) {
      this.keyRootVersion = "1";
    }
    if (this.keyRootId) {
      // If there's no langFilePathWeb, and we've passed param verification, then we can create the langFilePathWeb for SiteCore
      const env = getEnvironmentLabel();
      let domain = `${globalBaseConfig.DEFAULT_PROTOCOL}${globalBaseConfig.AO.IGN.assetsLocationMap[env]}.maerskline.com`;
      this.langFilePathWeb = `${domain}${globalBaseConfig.AO.I18NPATH}${keyRootId}`;
      // if APMT, then create a different domain and langfilepath
      if (this.isAPMT) {
        domain = `${globalBaseConfig.DEFAULT_PROTOCOL}${globalBaseConfig.AO.IGN.apmt_assetsLocationMap}.apmterminals.com`;
        if (!this.apmtAppName) {
          throw new Error("APMT App name is required!");
        }
        this.langFilePathWeb = `${domain}${globalBaseConfig.AO.APMTHEADLESSKEYPATH}${this.apmtAppName}`;
      }
    }
    if (this.keyRootId) {
      if (!langCode) {
        this.langCode = getBrandFromHostname(window.location.href).langCode;
      }
      // Check that the tags are an array, if not, don't set them
      if (!this.tags) {
        this.tags = [];
      }
    }
  }
  assembleAssetUrl(language) {
    // load language from outside with provided language
    let fullUrl = `${this.langFilePathWeb}/${language}`;
    if (this.keyRootId) {
      const version = `&version=v${this.keyRootVersion}`; // A version number is required
      if (!this.isAPMT) {
        fullUrl = `${fullUrl}.json?carrierCode=${this.langCode}${version}`;
      }
      // Calculate the tags
      // There are two default ones - beta and china. Beta is for the beta hostname
      // china is for .com.cn domains
      // The order does not matter
      let tagsLocal = [];
      if (isChinaDomain(window.location.hostname)) {
        tagsLocal.push("china");
      }
      if (getBetaFromHostname(window.location)) {
        tagsLocal.push("beta");
      }
      // Append any user-provided tags
      tagsLocal = tagsLocal.concat(this.tags);
      if (tagsLocal.length > 0) {
        fullUrl = `${fullUrl}&tags=${tagsLocal.join(",")}`;
      }
      // if APMT, then add the sc_apikey and create a full URL
      if (this.isAPMT) {
        fullUrl = `${fullUrl}?sc_apikey=${this.keyRootId}`;
      }
    } else {
      fullUrl = `${fullUrl}.json`;
    }
    return fullUrl;
  }
  /**
   * @public
   * @name getLanguageData
   * @param {string} language The name of the language - note: this is language, not locales
   * @description sets the html attribute 'lang' and triggers the loading of the current language
   * @returns {Promise<any>} Promise resolved with the language data
   */
  getLanguageData(language) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // verify params
      if (!language) {
        reject(new Error("language is required!"));
      }
      try {
        // load language from outside with provided language
        const fullUrl = this.assembleAssetUrl(language);
        const languageData = await this.loadLanguageFromURL(fullUrl);
        resolve(languageData);
      } catch (e) {
        try {
          // load language from outside with DEFAULT language
          const languageData = await this.loadLanguageFromURL(this.assembleAssetUrl(globalBaseConfig.DEFAULT_LANGUAGE));
          resolve(languageData);
        } catch (e) {
          // load language from local file
          try {
            let languageData = {};
            if (this.langJson) {
              languageData = this.langJson;
            } else {
              languageData = await this.loadLanguageFromURL(`${this.langFilePathLocal}/${globalBaseConfig.DEFAULT_LANGUAGE}.json`);
            }
            resolve(languageData);
          } catch (e) {
            reject(new Error(JSON.stringify(e)));
          }
        }
      }
    });
  }
  async loadLanguageFromURL(path) {
    // verify params
    if (!path) {
      throw new Error("path is required!");
    }
    // load language from outside
    let response;
    if (this.isAPMT) {
      response = await fetch(path, {
        headers: {
          "Consumer-Key": this.consumerKey
        }
      });
    } else {
      response = await fetch(path);
    }
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const languageData = await response.json();
    return languageData;
  }
}
const DEFAULT_CONTENT_TYPE = "application/json";
async function executeRequest({
  url,
  method = "GET",
  body = null,
  headers = new Headers(),
  credentials = "same-origin",
  authorizedRequest = true,
  consumerKey = undefined,
  rawResponse = false,
  signal = undefined
}) {
  if (!headers.has("Accept")) {
    headers.set("Accept", DEFAULT_CONTENT_TYPE);
  }
  if (body && !headers.has("Content-Type") && ["PUT", "POST", "PATCH", "DELETE"].includes(method.toUpperCase()) && !(body instanceof FormData)) {
    headers.set("Content-Type", DEFAULT_CONTENT_TYPE);
    // if the content type is set to json by default
    // stringify the body
    body = JSON.stringify(body);
  }
  if (!headers.has("Authorization") && authorizedRequest) {
    const jwt = getJwt();
    headers.set("Authorization", `Bearer ${jwt}`);
  }
  if (consumerKey) {
    headers.set("Consumer-Key", consumerKey);
  }
  const response = await fetch(url, {
    method,
    body,
    headers,
    credentials,
    signal
  });
  if (!response.ok) {
    throw response;
  }
  try {
    if (rawResponse) {
      return response;
    } else {
      // we should ignore the response payload when the api returns no data a.k.a 204
      if (response.status === 204) {
        return;
      }
      // Only return the json if there is a body in the response and it is of type application/json
      if (response.headers.get("Content-Type") && response.headers.get("Content-Type").indexOf(DEFAULT_CONTENT_TYPE) === 0) {
        return response.json();
      }
      return response.text();
    }
  } catch (_a) {
    return response;
  }
}
export { ConsentType, LanguageHandler, apiDomain, bindEvent, canAccess, checkAndSumProps, checkConsent, decodeJwtBody, decodeURIMessage, deepCompareObjectProperties, deepGet, executeRequest, firstLetterUppercase, flatten, formatCurrency, formatNumber, generateNonce, getBetaFromHostname, getBrandFromHostname, getCookies, getCustomerCode, getCustomerCodeFromJwt, getEnvironmentIdentifier, getEnvironmentLabel, getFunctionalConsent, getHeaderAndFooter, getItem$1 as getItem, getJwt, getMarketingConsent, getRetryableResponse, getSalesForceAccessToken, getSelectedLangAttribute, getSelectedLanguage, getSelectedLocale, getStatisticalConsent, getUrlParams, getUserCredential, getUsiJwt, globalBaseConfig, goToOriginalUrl, isChinaDomain, isCustomerLoggedIn, isEmpty, isIE, isLocalhost, isMobilePlatform, isOverMax, isTwillSmeJourney, kgsToLbs, langAttrMap, languageMap, lbsToKgs, localStore, makeKey, maskCustomerCode, maskData, matchesStoredNonce, redirectToLogin, redirectToSSP, removeItem$1 as removeItem, removeRounding, replacePlaceholders, sendOriginWindowOpener, sendOriginWindowParent, sessionStore, setFaviconByBrand, setItem$1 as setItem, setMaxCount, setOrigin };
