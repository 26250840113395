var p = Object.defineProperty;
var u = Object.getOwnPropertyDescriptor;
var n = (a, t, s, r) => {
  for (var e = r > 1 ? void 0 : r ? u(t, s) : t, o = a.length - 1, m; o >= 0; o--) (m = a[o]) && (e = (r ? m(t, s, e) : m(e)) || e);
  return r && e && p(t, s, e), e;
};
import { LitElement as x, html as l } from "lit";
import { unsafeCSS as b } from "lit";
import f from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as h } from "lit-element/lit-element.js";
var d = h`:host{display:flex}.mc-side-bar{display:flex;flex-direction:column;overflow:auto}@media only screen and (max-width:599px){.mc-side-bar{height:64px;justify-content:center;width:52px}.mc-side-bar mc-button{margin:0 8px}}@media only screen and (min-width:600px){.mc-side-bar{height:64px;justify-content:center;width:56px}.mc-side-bar mc-button{margin:0 8px 0 12px}}@media only screen and (min-width:900px){.mc-side-bar{height:100%;justify-content:flex-start;width:242px}}@media only screen and (min-width:1200px){.mc-side-bar{width:296px}}`;
var c = [b(d), f];
import { query as w } from "lit/decorators.js";
import { Responsive as v } from "@maersk-global/mds-components-utils";
import "@maersk-global/mds-components-core-button";
import "@maersk-global/mds-components-core-drawer";
var i = class extends v(x) {
  static get styles() {
    return c;
  }
  render() {
    return l`<aside class="mc-side-bar">${this.renderSideBar()}</aside>`;
  }
  renderSideBar() {
    return this.viewport === "x-small" || this.viewport === "small" || this.viewport === "medium" ? l`<div class="mobile-side-bar"><mc-button icon="bars-horizontal" fit="small" appearance="neutral" variant="plain" label="Show Navigation" hiddenlabel @click="${this.toggleMobileNav}"></mc-button><mc-drawer position="left"><slot></slot></mc-drawer></div>` : l`<div class="desktop-side-bar"><slot></slot></div>`;
  }
  toggleMobileNav() {
    this.mcDrawer.open = !this.mcDrawer.open;
  }
};
n([w("mc-drawer")], i.prototype, "mcDrawer", 2);
customElements.get("mc-side-bar") || customElements.define("mc-side-bar", i);
export { i as McSideBar };