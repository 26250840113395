@if(readOnly){
    @if(inputTags.length>3){
    <div class="tagged-input-parent">
      <div>{{ inputTags[0]}},{{inputTags[1]}},{{inputTags[2]}},{{inputTags[3]}}</div>
      @if(inputTags.length>4){
      <mc-popover trigger="click" position="bottom-left">
        <div slot="trigger" class="overflow-count overflow-count-readonly">+{{inputTags.length-4}}</div>
        @if(inputTags){
        <div class="tags-container">
          @for(tag of inputTags;track tag){
          @if($index>3){
          <div>{{ tag }}</div>
          }
          }
        </div>
        }
      </mc-popover>
      }
    </div>
    }
    @else{ 
    @if(inputTags.length == 1){
    <div class="tagged-input-parent">
      <span>{{ inputTags[0]}}</span>
    </div>
    }
    @if(inputTags.length == 2){
    <div class="tagged-input-parent">
      <span>{{ inputTags[0]}},{{ inputTags[1]}}</span>
    </div>
    }
    @if(inputTags.length == 3){
    <div class="tagged-input-parent">
      <span>{{ inputTags[0]}},{{ inputTags[1]}},{{ inputTags[2]}}</span>
    </div>
    }
    @if(inputTags.length == 4){
      <div class="tagged-input-parent">
        <span>{{ inputTags[0]}},{{ inputTags[1]}},{{ inputTags[2]}},{{ inputTags[3]}}</span>
      </div>
      }
    }
    }