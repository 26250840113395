
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { utils } from '../../helper/utils';
import { flagOnOffHeader } from '../../models/flag-on-off.model';
import { map, Observable } from 'rxjs';
import { Cluster, Loc, LocCluster, Locs, ShopCode } from '../../models/loc.model';
import { RuleValidation } from '../../models/rule-validation.model';
import { CreateValidation } from '../../models/create-validation.model';
import { FlagConfigFIlter } from '../../models/flag-config-filter.model';

@Injectable({
  providedIn: 'root'
})

export class FlagOnOffConfigService {
  constructor(private httpClient: HttpClient) { }

  getValidationsList(page: number, pageLimit: number, sort?: string,filters?:FlagConfigFIlter): Observable<HttpResponse<flagOnOffHeader[]>> {
    let url: string = `ruleconfiguration/getall/?page=${page}&limit=${pageLimit}`;
    if (sort) {
      url += `&sort=${encodeURIComponent(sort)}`;
    }
     if (filters) {
       url = this.applyFlagFilter(filters, url);
   }
    return this.httpClient.get<flagOnOffHeader[]>(utils.GetUrlConfig(url), { observe: 'response' }).pipe(
      map((response: HttpResponse<flagOnOffHeader[]>) => {
        const body = response.body;
        if (body) {
          const mappedBody = body.map(item => ({
            ...item,
            id: item.rowId,

          }));

          return new HttpResponse({
            body: mappedBody,
            headers: response.headers
          });
        } else {
          return response;
        }
      })
    );
  }
  private applyFlagFilter(filters: FlagConfigFIlter, url: string): string {
    const params = new URLSearchParams();

    if (filters.locs) {
      params.append('locs', filters.locs);
    }
    if (filters.clusterCodes) {
      params.append('clusterCodes', filters.clusterCodes);
    }
    if (filters.ruleName !== undefined) {
      params.append('ruleName', filters.ruleName);
    }
    
    if (filters.shopCodes) {
      params.append('shopCodes', filters.shopCodes);
    }
    

    const queryString = params.toString();
    if (url.includes('?')) {
      url += `&${queryString}`;
    } else {
      url += `?${queryString}`;
    }

    return url;
  }



  getAllLocList(): Observable<Loc[]> {
    return this.httpClient.get<Loc[]>( utils.GetUrlConfig("ruleconfiguration-master/loc"));
  }
  getAllClusterList(): Observable<Cluster[]> {
    return this.httpClient.get<Cluster[]>( utils.GetUrlConfig("ruleconfiguration-master/clusters"));
  }
  getAllShopList(): Observable<ShopCode[]> {
    return this.httpClient.get<ShopCode[]>(utils.GetUrlConfig("ruleconfiguration-master/shopcodes"))
  }
  getAllValidationList(): Observable<RuleValidation[]> {
    return this.httpClient.get<RuleValidation[]>(utils.GetUrlConfig("ruleconfiguration-master/rules"));
  }
  getClusterByRegion(selectedRegion: Locs): Observable<Cluster[]> {
    return this.httpClient.post<Cluster[]>(utils.GetUrlConfig("ruleconfiguration-master/clusterbyloc"), selectedRegion);
  }
  getShopsByCluster(selectedLocCluster: LocCluster): Observable<ShopCode[]> {
    return this.httpClient.post<ShopCode[]>(utils.GetUrlConfig("ruleconfiguration-master/shopcodesbyclusterloc"), selectedLocCluster);
  }
  createValidation(requestBody: CreateValidation): Observable<string[]> {
    return this.httpClient.post<string[]>(utils.GetUrlConfig("ruleconfiguration/create"), requestBody);
  }
  editValidation(requestBody:CreateValidation):Observable<string[]>{
    return this.httpClient.post<string[]>(utils.GetUrlConfig("ruleconfiguration/update"), requestBody);
  }
  onDownloadValidation(){
    return this.httpClient.get<flagOnOffHeader[]>(utils.GetUrlConfig("ruleconfiguration/download"));
  }
}
