import { Injectable } from '@angular/core';
import { Mode } from '../models/mode.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Shop } from '../models/shop.model';
import { BulkWorkOrderHeaderValue } from '../models/bulk-work-order/bulk-work-order-header.model';
import { BulkWorkOrderRepairLineItem } from '../models/bulk-work-order/bulk-work-order-repair-line-item.model';
import { MaintenanceOrderManHours } from '../models/maintenance-order-man-hours';
import { FormControlStatus } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BulkWorkOrderSharedDataService {

  private reset$ = new BehaviorSubject<boolean>(false);
  get Reset(): Observable<boolean> {
    return this.reset$.asObservable();
  }
  set Reset(value:boolean) {
    this.reset$.next(value);
  }

  private mode$ = new BehaviorSubject<Mode|null>(null);
  get Mode(): Observable<Mode|null> {
    return this.mode$.asObservable();
  }
  set Mode(mode: Mode|null) {
    this.mode$.next(mode);
  }

  private shop$ = new BehaviorSubject<Shop|null>(null);
  get Shop(): Observable<Shop|null> {
    return this.shop$.asObservable();
  }
  set Shop(shop: Shop|null) {
    this.shop$.next(shop);
  }

  private bulkWorkOrderHeader$ = new BehaviorSubject<BulkWorkOrderHeaderValue>({} as BulkWorkOrderHeaderValue);
  get BulkWorkOrderHeader(): Observable<BulkWorkOrderHeaderValue> {
    return this.bulkWorkOrderHeader$.asObservable();
  }
  set BulkWorkOrderHeader(bulkWorkOrderHeader: BulkWorkOrderHeaderValue) {
    this.bulkWorkOrderHeader$.next(bulkWorkOrderHeader);
  }

  private bulkWorkOrderRepairLineItems$ = new BehaviorSubject<BulkWorkOrderRepairLineItem[]>([]);
  get BulkWorkOrderRepairLineItems(): Observable<BulkWorkOrderRepairLineItem[]> {
    return this.bulkWorkOrderRepairLineItems$.asObservable();
  }
  set BulkWorkOrderRepairLineItems(bulkWorkOrderRepairLineItems: BulkWorkOrderRepairLineItem[]) {
    this.bulkWorkOrderRepairLineItems$.next(bulkWorkOrderRepairLineItems);
  }

  private bulkWorkOrderManHours$ = new BehaviorSubject<MaintenanceOrderManHours[]>([]);
  get BulkWorkOrderManHours(): Observable<MaintenanceOrderManHours[]> {
    return this.bulkWorkOrderManHours$.asObservable();
  }
  set BulkWorkOrderManHours(bulkWorkOrderManHours: MaintenanceOrderManHours[]) {
    this.bulkWorkOrderManHours$.next(bulkWorkOrderManHours);
  }

  private bulkWorkOrderRemark$ = new BehaviorSubject<string>("");
  get BulkWorkOrderRemark(): Observable<string> {
    return this.bulkWorkOrderRemark$.asObservable();
  }
  set BulkWorkOrderRemark(bulkWorkOrderRemark: string) {
    this.bulkWorkOrderRemark$.next(bulkWorkOrderRemark);
  }

  private headerFormStatus$=new BehaviorSubject<FormControlStatus>("INVALID");
  get HeaderFormStatus():Observable<FormControlStatus>{
    return this.headerFormStatus$.asObservable();
  }
  set HeaderFormStatus(status:FormControlStatus){
    this.headerFormStatus$.next(status);
  }

  private repairLineItemFormStatus$=new BehaviorSubject<FormControlStatus>("INVALID");
  get RepairLineItemFormStatus():Observable<FormControlStatus>{
    return this.repairLineItemFormStatus$.asObservable();
  }
  set RepairLineItemFormStatus(status:FormControlStatus){
    this.repairLineItemFormStatus$.next(status);
  }

  private manHoursFormStatus$=new BehaviorSubject<FormControlStatus>("INVALID");
  get ManHoursFormStatus():Observable<FormControlStatus>{
    return this.manHoursFormStatus$.asObservable();
  }
  set ManHoursFormStatus(status:FormControlStatus){
    this.manHoursFormStatus$.next(status);
  }
}
