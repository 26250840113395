import { environment } from '../../environments/environment';
import { AuthPolicies } from '../auth/auth-policies';
import { MaintenanceOrderStatus, MaintenanceOrderStatusCode } from '../models/maintenance-order-status.model';
import { User } from '../models/user.model';
import { UserAccessType } from '../models/user-access-type.model';
export class utils {
  static locale = (navigator && navigator.language) || "en-US";
  static numberFormatter = new Intl.NumberFormat(this.locale, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })


  static dateFormatter = new Intl.DateTimeFormat(this.locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  static customDateFormatter = new Intl.DateTimeFormat(this.locale, {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  static formatDate(date: Date): string {
    // Get formatted date string from Intl.DateTimeFormat
    const formattedDateParts = utils.customDateFormatter.formatToParts(date);

    // Extract relevant parts
    const day = formattedDateParts.find(part => part.type === 'day')?.value || '00';
    const month = formattedDateParts.find(part => part.type === 'month')?.value || '00';
    const year = formattedDateParts.find(part => part.type === 'year')?.value || '0000';
    const hour = formattedDateParts.find(part => part.type === 'hour')?.value || '00';
    const minute = formattedDateParts.find(part => part.type === 'minute')?.value || '00';
    const second = formattedDateParts.find(part => part.type === 'second')?.value || '00';

    // Format the date as "dd-MM-yyyy HH:mm:ss"
    return `${day}-${month}-${year} ${hour}:${minute}:${second}`;
  }
  static formatDateYYYYMMDD(date: Date): string {
    // Get formatted date string from Intl.DateTimeFormat
    const formattedDateParts = utils.customDateFormatter.formatToParts(date);

    // Extract relevant parts
    const day = formattedDateParts.find(part => part.type === 'day')?.value || '00';
    const month = formattedDateParts.find(part => part.type === 'month')?.value || '00';
    const year = formattedDateParts.find(part => part.type === 'year')?.value || '0000';
    // Format the date as "dd-MM-yyyy HH:mm:ss"
    return `${year}-${month}-${day} 00:00:00.000`;
  }

  /**
   * Rounds a number to two decimal places
   * 7.123 => 7.12
   * 7.126 => 7.13
   * 7.125 => 7.13
   * 7.12 => 7.12
   * 7.00 => 7
   * 7.1 => 7.1
   * 7.10=>7.1
   * @param value number to round
   * @returns number rounded to two decimal places
   */
  static formatNumberToTwoDigitDecimal(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }
  


  static formatDuration(durationInMilliseconds: number): string {
    const millisecondsInMinute = 60000;
    const millisecondsInHour = 3600000;
    const millisecondsInDay = 86400000;

    const days = Math.floor(durationInMilliseconds / millisecondsInDay);
    durationInMilliseconds %= millisecondsInDay;

    const hours = Math.floor(durationInMilliseconds / millisecondsInHour);
    durationInMilliseconds %= millisecondsInHour;

    const minutes = Math.floor(durationInMilliseconds / millisecondsInMinute);

    if (days > 0) {
      return hours > 0 ? `${days}d${hours}h` : `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return `${minutes}m`;
    }
  }

  static GetUrl(path: string): string {
    return environment.API_BASE_URL + path;
  }
  static GetUrlConfig(path: string): string {
    return environment.API_APPROVAL_URL + path;
  }
  static GetMasterDataUrl(path: string): string {
    return environment.API_BASE_URL + 'master-data/' + path;
  }
  static GetApplicationSupportUrl(path: string): string {
    return environment.APP_SUPPORT_BASE_URL + path;
  }
  static getLoggedInUserName() {
    var userName = localStorage.getItem('user');
    if (userName) {
      const user = JSON.parse(userName);
      return user.firstName + ' ' + user.lastName;
    }
    return 'User name not found';
  }
  static checkForDuplicates(array: any[], keyName: string): boolean {
    return new Set(array.map((item) => item[keyName])).size !== array.length;
  }

  static get allowedFileExtensions(): string[] {
    return [
      'tiff',
      'jfif',
      'bmp',
      'gif',
      'svg',
      'png',
      'jpeg',
      'jpg',
      'svgz',
      'webp',
      'ico',
      'xbm',
      'dib',
      'pjp',
      'apng',
      'tif',
      'pjpeg',
      'avif',
      'pdf',
      'doc',
      'docx',
    ];
  }

  static getMaintenanceOrderStatusText(statusType: MaintenanceOrderStatusCode): MaintenanceOrderStatus {

    const userAccessType: UserAccessType = AuthPolicies.userAccessType;
    const statusTextMap: { [key in UserAccessType]: { [key in MaintenanceOrderStatusCode]: MaintenanceOrderStatus } } = {
      [UserAccessType.Vendor]: {
        [MaintenanceOrderStatusCode.Rejected]: { description: "Rejected", generatedCode: `${MaintenanceOrderStatusCode.Rejected}` },
        [MaintenanceOrderStatusCode.DeclaredAsTTL]: { description: "Declared as TTL", generatedCode: `${MaintenanceOrderStatusCode.DeclaredAsTTL}` },
        [MaintenanceOrderStatusCode.PendingApproval]: { description: "Pending Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingApproval}-${MaintenanceOrderStatusCode.PendingSpecialistApproval}-${MaintenanceOrderStatusCode.SuspendForTTLCheck}-${MaintenanceOrderStatusCode.SuspendToCENELM}` },
        [MaintenanceOrderStatusCode.PendingSpecialistApproval]: { description: "Pending Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingApproval}-${MaintenanceOrderStatusCode.PendingSpecialistApproval}-${MaintenanceOrderStatusCode.SuspendForTTLCheck}-${MaintenanceOrderStatusCode.SuspendToCENELM}` },
        [MaintenanceOrderStatusCode.SuspendForTTLCheck]: { description: "Pending Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingApproval}-${MaintenanceOrderStatusCode.PendingSpecialistApproval}-${MaintenanceOrderStatusCode.SuspendForTTLCheck}-${MaintenanceOrderStatusCode.SuspendToCENELM}` },
        [MaintenanceOrderStatusCode.SuspendToCENELM]: { description: "Pending Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingApproval}-${MaintenanceOrderStatusCode.PendingSpecialistApproval}-${MaintenanceOrderStatusCode.SuspendForTTLCheck}-${MaintenanceOrderStatusCode.SuspendToCENELM}` },
        [MaintenanceOrderStatusCode.Approved]: { description: "Approved", generatedCode: `${MaintenanceOrderStatusCode.Approved}` },
        [MaintenanceOrderStatusCode.RepairCompleted]: { description: "Repair Completed", generatedCode: `${MaintenanceOrderStatusCode.RepairCompleted}` },
        [MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested]: { description: "Purchase Order Initiated", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested}-${MaintenanceOrderStatusCode.PurchaseOrderInitiated}-${MaintenanceOrderStatusCode.PurchaseOrderFailed}` },
        [MaintenanceOrderStatusCode.PurchaseOrderInitiated]: { description: "Purchase Order Initiated", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested}-${MaintenanceOrderStatusCode.PurchaseOrderInitiated}-${MaintenanceOrderStatusCode.PurchaseOrderFailed}` },
        [MaintenanceOrderStatusCode.PurchaseOrderFailed]: { description: "Purchase Order Initiated", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested}-${MaintenanceOrderStatusCode.PurchaseOrderInitiated}-${MaintenanceOrderStatusCode.PurchaseOrderFailed}` },
        [MaintenanceOrderStatusCode.PurchaseOrderCreated]: { description: "Purchase Order Created", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderCreated}` },
        //[MaintenanceOrderStatusCode.PaymentFailed]: { description: "Payment Initiated", generatedCode: `${MaintenanceOrderStatusCode.PaymentFailed}-${MaintenanceOrderStatusCode.PaymentCompleted}` },
        //[MaintenanceOrderStatusCode.PaymentCompleted]: { description: "Payment Initiated", generatedCode: `${MaintenanceOrderStatusCode.PaymentFailed}-${MaintenanceOrderStatusCode.PaymentCompleted}` },
        [MaintenanceOrderStatusCode.Processed]: { description: "Processed", generatedCode: `${MaintenanceOrderStatusCode.Processed}` },
        [MaintenanceOrderStatusCode.Deleted]: { description: "Deleted", generatedCode: `${MaintenanceOrderStatusCode.Deleted}` }
      },
      [UserAccessType.GSC]: {
        [MaintenanceOrderStatusCode.Rejected]: { description: "Rejected", generatedCode: `${MaintenanceOrderStatusCode.Rejected}` },
        [MaintenanceOrderStatusCode.DeclaredAsTTL]: { description: "Declared as TTL", generatedCode: `${MaintenanceOrderStatusCode.DeclaredAsTTL}` },
        [MaintenanceOrderStatusCode.PendingApproval]: { description: "Pending Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingApproval}` },
        [MaintenanceOrderStatusCode.PendingSpecialistApproval]: { description: "Pending Specialist Approval", generatedCode: `${MaintenanceOrderStatusCode.PendingSpecialistApproval}` },
        [MaintenanceOrderStatusCode.SuspendForTTLCheck]: { description: "Suspend for TTL check", generatedCode: `${MaintenanceOrderStatusCode.SuspendForTTLCheck}` },
        [MaintenanceOrderStatusCode.SuspendToCENELM]: { description: "Pending Manager Approval", generatedCode: `${MaintenanceOrderStatusCode.SuspendToCENELM}` },
        [MaintenanceOrderStatusCode.Approved]: { description: "Approved", generatedCode: `${MaintenanceOrderStatusCode.Approved}` },
        [MaintenanceOrderStatusCode.RepairCompleted]: { description: "Repair Completed", generatedCode: `${MaintenanceOrderStatusCode.RepairCompleted}` },
        [MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested]: { description: "Purchase Order Initiated", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested}-${MaintenanceOrderStatusCode.PurchaseOrderInitiated}` },
        [MaintenanceOrderStatusCode.PurchaseOrderInitiated]: { description: "Purchase Order Initiated", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderRetriggerRequested}-${MaintenanceOrderStatusCode.PurchaseOrderInitiated}` },
        [MaintenanceOrderStatusCode.PurchaseOrderFailed]: { description: "Purchase Order Failed", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderFailed}` },
        [MaintenanceOrderStatusCode.PurchaseOrderCreated]: { description: "Purchase Order Created", generatedCode: `${MaintenanceOrderStatusCode.PurchaseOrderCreated}` },
        //[MaintenanceOrderStatusCode.PaymentFailed]: { description: "Payment Failed", generatedCode: `${MaintenanceOrderStatusCode.PaymentFailed}` },
        //[MaintenanceOrderStatusCode.PaymentCompleted]: { description: "Payment Completed", generatedCode: `${MaintenanceOrderStatusCode.PaymentCompleted}` },
        [MaintenanceOrderStatusCode.Processed]: { description: "Processed", generatedCode: `${MaintenanceOrderStatusCode.Processed}` },
        [MaintenanceOrderStatusCode.Deleted]: { description: "Deleted", generatedCode: `${MaintenanceOrderStatusCode.Deleted}` }
      }
    };

    return statusTextMap[userAccessType][statusType] ?? { description: "Unknown", generatedCode: `${statusType}` };;
  }

  /**
   * Return date in relative format string
   * E.g for today's date, it will return "Today"
   * E.g for yesterday's date, it will return "Yesterday"
   * E.g for date 2 days ago, it will return "Monday, 12th July"
   * @param date 
   */
static getDateInRelativeFormat(date: Date): string {
 const todaysDate=new Date();
 const todaysDateString = new Date().toLocaleDateString();
 const reqDateString=date.toLocaleDateString();
 if(todaysDateString===reqDateString){
    return "Today";
  }
  const yesterday = new Date(todaysDate.setDate(todaysDate.getDate() - 1));
  const yesterdayDateString = yesterday.toLocaleDateString();
  if(yesterdayDateString===reqDateString){
    return "Yesterday";
  }
  const options:Intl.DateTimeFormatOptions = { weekday: "long", year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(utils.locale,options);
}

  static formatToISODateUTC(dateString: string, startOfDay: boolean): string {
    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));
    if (!startOfDay) {
      date.setUTCHours(23, 59, 59, 999);
    }
    return date.toISOString();
  }
  static toDate(dateString: string): Date {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }
}
