<header>
    <div id="nav-title">
        <div>
            @if(isSideNavOpen){
            <mc-icon (click)="onHamburgerClicked()" class="collapsed-menu" icon="times" size="24"></mc-icon>
            }@else{
            <mc-icon (click)="onHamburgerClicked()" class="collapsed-menu" icon="bars-horizontal" size="24"></mc-icon>
            }
        </div>
        <img class="maersk-logo" alt="maersk logo" title="maersk logo" src="assets/images/star.svg">

        <span class="product-title">Advanced Equipment Maintenance System</span>
    </div>
    <div class="icon-container">
        <ul>
            <li>
                @let notificationCount=notificationService.NewNotificationsCount|async;
                @if(notificationCount && notificationCount>0){
                    <div class="notif-count-badge">{{notificationCount}}</div>
                }
                <mc-button label="Notifications" hiddenlabel variant="plain" icon="bell"
                    (click)="onBellIconClicked()"></mc-button>
                @if((notificationService.ShowWorkInProgress | async) ===true){
                <div class="in-progress-container">
                    <mc-loading-indicator hiddenlabel variant="bar" appearance="primary" fit="small">
                    </mc-loading-indicator>
                </div>
                }
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Profile" hiddenlabel variant="plain" icon="user-circle"
                        slot="trigger"></mc-button>
                    <div>
                        <span>{{'Welcome, ' + loggedInUser?.firstName}}</span><span
                            class="m-l-4">{{loggedInUser?.lastName}}</span>
                        <div class="mds-text--small-normal mds-neutral--weak__text-color p-t-4">
                            <div>{{'Username: '+loggedInUser?.username}}</div>
                            <div>{{'Email Address: '+loggedInUser?.emailAddress}}</div>
                            <div>{{'Company: '+loggedInUser?.companyName}}</div>
                            <div>{{'Roles: '+loggedInUser?.roles?.join(',')}}</div>
                        </div>
                    </div>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Change theme" hiddenlabel variant="plain" icon="moon"
                        (click)="onToggleThemeClicked()" slot="trigger"></mc-button>
                    <span>Toggle theme</span>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Logout" hiddenlabel variant="plain" icon="door-arrow-right"
                        (click)="onLogoutClicked()" slot="trigger"></mc-button>
                    <span>Logout</span>
                </mc-tooltip>
            </li>
        </ul>
    </div>
    <div class="menu-overlay" (click)="onHamburgerClicked()" [class.menu-overlay-visible]="isSideNavOpen">

    </div>
</header>
<mc-drawer (closed)="onNotificationClosed()" [open]="openNotification()">
    <span slot="heading">Notifications</span>
    <div>
        @for(notificationHeader of notificationItems|keyvalue:numericCompare; track notificationHeader.key){
        <div class="mds-text--small-bold m-b-8">
            {{notificationHeader.value[0].associatedTaskCreatedAtDateRelative}}
        </div>
        @for(notification of notificationHeader.value;track notification.id){
        <div style="position: relative;"> 
            <app-notification-item [notification]="notification"></app-notification-item>
           
        </div>
        <br>
        }
        }
        @empty {
        <div>
            <span class="mds-text--large-normal mds-neutral--weakest__text-color">You have no notification</span>
        </div>
        }
    </div>
</mc-drawer>