import { Component,computed,CUSTOM_ELEMENTS_SCHEMA, Input, input } from '@angular/core';
import '@maersk-global/mds-components-core/mc-file-upload';
import { FileUploadComponent } from '../../../shared-components/aems-file-upload/file-upload.component';
import { FormGroup } from '@angular/forms';
import { AttachmentForm } from '../../../form-model/attachment.form.model';
import { AemsFile } from '../../../models/aems-file.model';

@Component({
    selector: 'app-cwo-attachments',
    imports: [FileUploadComponent],
    templateUrl: './cwo-attachments.component.html',
    styleUrl: './cwo-attachments.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateWorkOrderAttachmentsComponent {
@Input({ required: true }) attachmentForm!: FormGroup<AttachmentForm>;
@Input() files!:AemsFile[]
shopCode = input.required<string>();

}
