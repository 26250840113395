var d = Object.defineProperty;
var c = Object.getOwnPropertyDescriptor;
var m = (a, o, t, e) => {
  for (var s = e > 1 ? void 0 : e ? c(o, t) : o, r = a.length - 1, i; r >= 0; r--) (i = a[r]) && (s = (e ? i(o, t, s) : i(s)) || s);
  return e && s && d(o, t, s), s;
};
import { property as S } from "lit/decorators.js";
import { unsafeCSS as h } from "lit";
import f from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as u } from "lit-element/lit-element.js";
var p = u`:host([orientation=vertical]) .mc-list{height:100%;max-height:100%}:host([orientation=horizontal]) .mc-list{max-width:100%;min-width:unset}.mc-list{box-shadow:inset 0 0 0 var(--mds_core_button-group_border-width) var(--mds_core_button-group_border-color);overflow:auto;scroll-behavior:smooth;scroll-snap-type:both mandatory;-ms-overflow-style:none}.mc-list.no-border{border-radius:var(--mds_core_button-group_border-radius)}.mc-list::-webkit-scrollbar{display:none}.mc-list.large,.mc-list.medium,.mc-list.small{padding:0}`;
import y from "@maersk-global/mds-design-tokens/implementation/core/button-group/js/design-tokens-px.css.js";
var n = [f, y, h(p)];
import "@maersk-global/mds-components-core-button-group-item";
import { McList as b } from "@maersk-global/mds-components-core-list";
var l = class extends b {
  constructor() {
    super();
    this.selectiontype = "none";
    this.width = "auto", this.disablecircularkeynav = !0, this.keynavigationtype = "tab", this.disablehandlemousemove = !0, this.noborder = !0, this.scrollToSelectedValue = !0, this.orientation = "horizontal";
  }
  static get styles() {
    return [super.styles, n];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.adjustSeparatorsOnSelectedItems();
  }
  willUpdate(t) {
    super.willUpdate(t), t.has("selectiontype") && (this.type = this.selectiontype);
  }
  handleListChange(t) {
    super.handleListChange(t), t && this.adjustSeparatorsOnSelectedItems(t);
  }
  adjustSeparatorsOnSelectedItems(t) {
    if (t) {
      this.adjustSeparatorsByItem(t);
      return;
    }
    this.allItems?.forEach(e => {
      e.selected && this.adjustSeparatorsByItem(e);
    });
  }
  adjustSeparatorsByItem(t) {
    if (this.allItems) {
      let e = "after-selected",
        s = this.allItems.indexOf(t);
      if (this.type === "single") {
        let r = this.allItems.find(i => i.classList.contains(e));
        r && r.classList.remove(e);
      }
      s != null && s < this.allItems.length - 1 && (t.selected ? this.allItems[s + 1].classList.add(e) : this.allItems[s + 1].classList.remove(e));
    }
  }
};
m([S({
  type: String,
  reflect: !0
})], l.prototype, "selectiontype", 2);
customElements.get("mc-button-group") || customElements.define("mc-button-group", l);
export { l as McButtonGroup };