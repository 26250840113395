import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { clone } from 'lodash-es';
import { Part } from '../../../models/part.model';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-typeahead';
import { Shop } from '../../../models/shop.model';
import { RepairLineItemForm } from '../../../form-model/cwo-repair-line-item.form.model';
import { PartLineItemForm, PartLineItemFormValue } from '../../../form-model/part-line-item.form.model';
import { ToastrService } from '../../../services/toastr-service';
import { animate, style, transition, trigger } from '@angular/animations';
import { partNumberUniqueValidator } from './validators/part-number-unique.validator';
import { ErrorComponent } from '../../../shared-components/error/error.component';
import { ReplaySubject, takeUntil } from 'rxjs';
import { utils } from '../../../helper/utils';
import { MaintenanceOrderPart } from '../../../models/maintenance-order-part';
import { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types';
import { CodeDescription } from '../../../models/code-description.model';
import { typeaheadCodeExistValidator } from '../../../shared-validators/typeahead-code-exist.validator';
import { TagInputComponent } from '../../../shared-components/tag-input/tag-input.component';
import { ApplicationtTextService } from '../../../services/application-text.service';
@Component({
    selector: 'app-repair-parts',
    imports: [ReactiveFormsModule, CommonModule, ErrorComponent, TagInputComponent],
    templateUrl: './cwo-repair-parts.component.html',
    styleUrl: './cwo-repair-parts.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    animations: [trigger('removeRepairLineItem', [
            transition(':leave', [
                animate('0.2s ease-in', style({ transform: 'translateX(-100%)' }))
            ])
        ])]
})
export class CreateWorkOrderRepairPartsComponent implements OnInit {
  @Input({ required: true }) parentForm!: FormGroup<RepairLineItemForm>;
  @Input({ required: true }) availablePartsForSelectedRepairCodeAndMode: Part[] = [];
  @Input() existingParts: MaintenanceOrderPart[] | undefined;
  @Input() partsSnapshotData: MaintenanceOrderPart[] | undefined;
  @Input({ required: true }) shop: Shop | null = null;
  @Input() isPartsMandatory:boolean=false;
  @Output() allPartsRemoved = new EventEmitter<boolean>()
  @Output() repairPartsTotal = new EventEmitter<number>();
  parts!: FormArray<FormGroup<PartLineItemForm>>;
  openDeletePartConfirmationDialog: boolean = false;
  selectedPartIndexForDelete: number = -1;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  partNumbersTypeAhead: IMcTypeaheadData[] = [];
  existingOldPartSerialNumbers: string[][] = [];
  constructor(private formBuilder: FormBuilder, private toastrService: ToastrService, private appTextService: ApplicationtTextService) {
  }
  ngOnInit(): void {
    this.partNumbersTypeAhead = this.mapCodeDescriptionToTypeaheadData(this.availablePartsForSelectedRepairCodeAndMode);
    if (this.existingParts && this.existingParts.length>0) {

      // Build the part form 
      this.existingParts.forEach((item) => {
        let newPartLineItemForm = this.initParts();
        let selectedPart = this.availablePartsForSelectedRepairCodeAndMode.filter(part => part.partCode === item.partNumber)[0]
        this.addValidatorsToPartPieces(newPartLineItemForm, selectedPart);
        if (this.parts) {
          this.parts.push(newPartLineItemForm);
        } else {
          this.parts = this.formBuilder.array([newPartLineItemForm], partNumberUniqueValidator());
        }
      })
      // Assign values to the built part form
      this.parts.patchValue(
        this.existingParts.map(part => {
          this.existingOldPartSerialNumbers.push(clone(part.currentSerialNumbers) ?? []);
          return {
            partNumber: part.partNumber,
            currentSerialNumbers: part.currentSerialNumbers,
            pieces: part.unitQuantity,
            partCost: utils.formatNumberToTwoDigitDecimal(part.unitPrice),
            importTaxApplicable: "No"
          }
        })
      )
    } else {
      this.parts = this.formBuilder.array([this.initParts()], partNumberUniqueValidator());
      this.existingOldPartSerialNumbers.push([]);
    }

    this.parentForm.controls.parts = this.parts;
    this.parts.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: PartLineItemFormValue[]) => {
        this.calculateRepairPartItemTotalCost(value)
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onRemovePartClicked(index: number) {
    if (this.parts.length === 1 && this.isPartsMandatory) {
      this.toastrService.showToastr(this.appTextService.AtLeastOnePartRequired, 'warning');
      return;
    }
    this.selectedPartIndexForDelete = index;
    this.openDeletePartConfirmationDialog = true;
  }
  public onDeletePartConfirmationCancel() {
    this.selectedPartIndexForDelete = -1;
    this.openDeletePartConfirmationDialog = false;
  }
  public onDeletePartConfirmationClosed() {
    this.selectedPartIndexForDelete = -1;
    this.openDeletePartConfirmationDialog = false;
  }
  public onDeletePartConfirmation() {
    if (this.selectedPartIndexForDelete > -1) {
      this.parts.removeAt(this.selectedPartIndexForDelete);
      this.openDeletePartConfirmationDialog = false;
    }
    if(this.parts.length===0){
       this.allPartsRemoved.emit(true);
    }
  }
  onAddPartClicked() {
    this.existingOldPartSerialNumbers.push([]);
    this.parts.push(this.initParts());

  }
  onTagChanged(part: FormGroup<PartLineItemForm>, value: string[]) {
    part.controls.currentSerialNumbers.setValue(value);
  }
  onPartOptionSelected(event: Event, partLineItem: FormGroup<PartLineItemForm>) {
    let customEvent = event as CustomEvent;

    //For edit mode, first check if the selected part number is already a part of the work order
    let snapshotPriceForPart: number | undefined = undefined;
    if (this.partsSnapshotData) {
      let selectedPartFromWorkorderSnapshot = this.partsSnapshotData.find(part => part.partNumber === customEvent.detail.value);
      if (selectedPartFromWorkorderSnapshot) {
        snapshotPriceForPart = selectedPartFromWorkorderSnapshot.unitPrice;
      }
    }

    let selectedPart = this.availablePartsForSelectedRepairCodeAndMode.find(
      (part) => part.code === customEvent.detail.value
    );
    if (selectedPart) {
      this.addValidatorsToPartPieces(partLineItem, selectedPart);
      partLineItem.controls.pieces.setValue(1);
      if (snapshotPriceForPart) {
        partLineItem.controls.partCost.setValue(utils.formatNumberToTwoDigitDecimal(snapshotPriceForPart));
      } else {
        partLineItem.controls.partCost.setValue(utils.formatNumberToTwoDigitDecimal(selectedPart.finalPriceAfterDiscountAndMarkupPerUnit));
      }
    }
  }
  isControlInErrorState(control: AbstractControl | null): boolean | null {
    if (control?.invalid && control.touched) {
      return true;
    }
    return null;
  }
  private addValidatorsToPartPieces(partLineItem: FormGroup<PartLineItemForm>, part: Part) {
    partLineItem.controls.pieces.setValidators([Validators.required, Validators.max(part.maxQuantity)]);
  }

  private initParts(): FormGroup<PartLineItemForm> {
    var newPart = this.formBuilder.nonNullable.group({
      partNumber: this.formBuilder.nonNullable.control<string>('', [Validators.required, typeaheadCodeExistValidator(this.partNumbersTypeAhead)]),
      currentSerialNumbers: this.formBuilder.nonNullable.control<string[]>([]),
      pieces: this.formBuilder.nonNullable.control<number>(0, Validators.required),
      partCost: this.formBuilder.nonNullable.control<number>(0),
      importTaxApplicable: this.formBuilder.nonNullable.control<string>('Yes', Validators.required),
    }) as FormGroup<PartLineItemForm>;
    return newPart;
  }

  private calculateRepairPartItemTotalCost(value: PartLineItemFormValue[]) {
    var allPartsTotalCost = 0;
    value.forEach((partLineItem) => {
      let partCost = partLineItem.partCost ?? 0;
      let pieces = partLineItem.pieces ?? 0;
      let totalCost = partCost * pieces;
      allPartsTotalCost += totalCost;
    });
    this.repairPartsTotal.emit(allPartsTotalCost);
  }
  private mapCodeDescriptionToTypeaheadData(data: CodeDescription[]): IMcTypeaheadData[] {
    return data.map((codeDescription) => {
      return {
        label: codeDescription.code,
        sublabel: codeDescription.description,
        value: codeDescription.code,
      };
    });
  }
}
