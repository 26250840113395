var g = Object.defineProperty;
var y = Object.getOwnPropertyDescriptor;
var s = (d, u, e, i) => {
  for (var t = i > 1 ? void 0 : i ? y(u, e) : u, o = d.length - 1, n; o >= 0; o--) (n = d[o]) && (t = (i ? n(u, e, t) : n(t)) || t);
  return i && t && g(u, e, t), t;
};
import { LitElement as O, html as a } from "lit";
import { classMap as f } from "lit/directives/class-map.js";
import { property as r } from "lit/decorators.js";
import { ifDefined as x } from "lit/directives/if-defined.js";
import { FormField as C, setHostCssClass as I, DisabledState as A } from "@maersk-global/mds-components-utils";
import { unsafeCSS as $ } from "lit";
import _ from "@maersk-global/mds-design-tokens/implementation/core/select-native/js/design-tokens-px.css.js";
import { css as S } from "lit-element/lit-element.js";
var m = S`.mc-input.suffix mc-icon{position:absolute;right:0;cursor:pointer;pointer-events:none}.mc-input.multiple .mc-input__input{height:unset}.mc-input .input{appearance:none;-moz-appearance:none;-webkit-appearance:none;width:100%;position:relative}.mc-input .input.active-placeholder{color:var(--mds_core_input_placeholder_text-color)}.mc-input.small:not(.vanity) .input{padding-right:var(--mds_core_select-native_small_padding-right)}.mc-input.small.vanity .input{padding-right:var(--mds_core_select-native_small_vanity_padding-right)}.mc-input.small.suffix mc-icon{top:var(--mds_core_select-native_small_chevron_top)}.mc-input.medium:not(.vanity) .input{padding-right:var(--mds_core_select-native_medium_padding-right)}.mc-input.medium.vanity .input{padding-right:var(--mds_core_select-native_medium_vanity_padding-right)}.mc-input.medium.suffix mc-icon{top:var(--mds_core_select-native_medium_chevron_top)}.mc-input.large:not(.vanity) .input{padding-right:var(--mds_core_select-native_large_padding-right)}.mc-input.large.vanity .input{padding-right:var(--mds_core_select-native_large_vanity_padding-right)}.mc-input.large.suffix mc-icon{top:var(--mds_core_select-native_large_chevron_top)}`;
var v = [_, $(m)];
import { inputStyles as E } from "@maersk-global/mds-components-core-input";
import "@maersk-global/mds-components-core-error";
import "@maersk-global/mds-components-core-hint";
import "@maersk-global/mds-components-core-icon";
import "@maersk-global/mds-components-core-label";
var l = class l extends A(C(O)) {
  constructor() {
    super(...arguments);
    this.isFirstUpdate = !0;
    this.focused = !1;
    this.fit = "medium";
    this.hiddenlabel = !1;
    this.id = "";
    this.invalid = !1;
    this.label = "Label";
    this.labelposition = "top";
    this.placeholder = "Select an item";
    this.required = !1;
    this.variant = "default";
    this.width = "auto";
  }
  get isPlaceholderActive() {
    let e = !this.selectedindex || this.selectedindex.length === 0;
    return !this._value && e || Array.isArray(this._value) && this._value.length === 0 && e;
  }
  get inputId() {
    return `${this.id ?? "field"}-input`;
  }
  set value(e) {
    if (l.isValueValidInVue2(e)) {
      let i = this._value,
        t = !e || Array.isArray(e) && e.length === 0;
      t && i && t && (this.selectedindex = void 0), this._value = e, this.requestUpdate("value", i);
    }
  }
  get value() {
    return this._value;
  }
  static get styles() {
    return [E, v];
  }
  render() {
    let e = {
      multiple: this.variant === "multiple",
      suffix: this.variant !== "multiple",
      vanity: this.variant === "vanity",
      [`${this.fit}`]: !0,
      [`${this.labelposition}`]: !0
    };
    return a`<div class="mc-input ${f(e)}">${this.renderLabel()}<div class="container">${this.renderField()} ${this.renderError()} ${this.renderHint()}</div></div>`;
  }
  renderField() {
    let e = I(this.classList, ["hover", "focus", "active"]),
      i = {
        "active-placeholder": this.isPlaceholderActive && this.variant !== "multiple",
        [e]: !0
      };
    return a`<div class="inner"><div class="field" style="${this.width === "auto" ? "" : `width:${this.width}%`}"><select id="${this.inputId}" aria-labelledby="label" aria-describedby="hint" @change="${this.handleOnChange}" class="input ${f(i)}" ?multiple="${this.variant === "multiple"}" ?disabled="${this.disabled}" ?invalid="${this.invalid}" aria-invalid="${x(this.invalid ? this.invalid : void 0)}" ?required="${this.required}" @focus="${this.onInputFocus}" @blur="${this.onInputBlur}">${this.renderPlaceholder()} ${this.renderSelectOptions()}</select> ${this.renderIcon()}</div></div>`;
  }
  renderLabel() {
    return a`<label class="${this.hiddenlabel ? "hiddenlabel" : ""}" part="label-container" for="${this.inputId}"><mc-label exportparts="label" id="label" .label="${this.label}" .fit="${this.fit}" ?hiddenlabel="${this.hiddenlabel}" .labelposition="${this.labelposition}"><slot name="label">${this.label}</slot></mc-label></label>`;
  }
  renderError() {
    return a`<mc-error id="invalid" .errormessage="${this.errormessage}" .fit="${this.fit}" ?invalid="${this.invalid}"><slot name="errormessage">${this.errormessage}</slot></mc-error>`;
  }
  renderHint() {
    return a`<mc-hint id="hint" .hint="${this.hint}" .fit="${this.fit}"><slot name="hint">${this.hint}</slot></mc-hint>`;
  }
  renderSelectOptions() {
    return this.options && this.options.map(e => a`<option value="${e !== null && typeof e == "object" ? e.value : e}" ?selected="${!this.isPlaceholderActive && this.isOptionSelected(e)}">${e !== null && typeof e == "object" ? e.label : e}</option>`);
  }
  isOptionSelected(e) {
    if (this._value) {
      let i = this._value.find(t => (typeof t == "string" || typeof t == "number") && (typeof e == "string" || typeof e == "number") ? t == e : typeof t == "object" && typeof e == "object" ? t.value == e.value : !1);
      return this.select && i && (typeof i == "string" || typeof i == "number" ? this.select.value = i.toString() : this.select.value = i.value.toString()), !!i;
    }
    return !1;
  }
  renderPlaceholder() {
    return this.placeholder && this.variant !== "multiple" ? a`<option value="" ?selected="${this.isPlaceholderActive}">${this.placeholder}</option>` : null;
  }
  renderIcon() {
    return this.variant === "multiple" ? null : a`<mc-icon class="affix type-suffix" icon="chevron-down" size="${this.fit === "large" ? "24" : "20"}"></mc-icon>`;
  }
  handleOnChange(e) {
    let [i, t] = this.getSelected();
    this.value = i, this.selectedindex = t, this.dispatchEvent(new CustomEvent("change", {
      detail: {
        selectedOptions: i,
        selectedindex: t,
        selectedIndex: t
      }
    })), e.stopPropagation();
  }
  getSelected() {
    let e = [],
      i = [],
      t = this.select?.options;
    if (t) {
      let o = t.length;
      for (let n = 0; n < o; n++) if (t[n].selected) {
        let h = t[n];
        this.options?.find((p, b) => (typeof p == "object" && p.value !== void 0 ? p.value.toString() === h.value : p.toString() === h.value) ? (e.push(p), i.push(b), !0) : !1);
      }
    }
    return [e, i];
  }
  focus() {
    let e = new CustomEvent("focus");
    this.select?.dispatchEvent(e), this.select?.focus();
  }
  onInputFocus() {
    this.focused = !0;
  }
  blur() {
    let e = new CustomEvent("blur");
    this.select?.dispatchEvent(e), this.select?.blur();
  }
  onInputBlur() {
    this.focused = !1;
  }
  firstUpdated(e) {
    super.firstUpdated(e), this.initializeElements();
  }
  willUpdate(e) {
    super.willUpdate(e), e.has("selectedindex") && this.selectedindex && (!e.has("value") || !this.isFirstUpdate) && (this.value = this.options?.filter((i, t) => this.selectedindex?.includes(t))), this.isFirstUpdate = !1;
  }
  initializeElements() {
    this.select = this.shadowRoot?.querySelector("select");
  }
  static isValueValidInVue2(e) {
    if (!e) return !0;
    let i = e === "[object Object]",
      t = !Array.isArray(e);
    return !(i || t);
  }
};
s([r({
  type: String
})], l.prototype, "errormessage", 2), s([r({
  type: String
})], l.prototype, "fit", 2), s([r({
  type: Boolean
})], l.prototype, "hiddenlabel", 2), s([r({
  type: String
})], l.prototype, "hint", 2), s([r({
  type: String,
  reflect: !0
})], l.prototype, "id", 2), s([r({
  type: Boolean,
  reflect: !0
})], l.prototype, "invalid", 2), s([r({
  type: String
})], l.prototype, "label", 2), s([r({
  type: String
})], l.prototype, "labelposition", 2), s([r({
  type: Array
})], l.prototype, "options", 2), s([r({
  type: String
})], l.prototype, "placeholder", 2), s([r({
  type: Boolean,
  reflect: !0
})], l.prototype, "required", 2), s([r({
  type: Array
})], l.prototype, "selectedindex", 2), s([r({
  type: Array,
  reflect: !0,
  hasChanged: l.isValueValidInVue2
})], l.prototype, "value", 1), s([r({
  type: String
})], l.prototype, "variant", 2), s([r({
  type: String
})], l.prototype, "width", 2);
var c = l;
customElements.get("mc-select-native") || customElements.define("mc-select-native", c);
export { c as McSelectNative };