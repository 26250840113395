<form [formGroup]="masterHeaderForm">
  
    <div class="aems-card m-b-12" style="margin-top:10px; padding-bottom:5px;">
        <div class="pure-g">

            <div class="pure-u-md-8-24 p-b-16 p-r-16">
                <mc-select label="Category" fit="medium" width="100" placeholder="Select Category"   ngDefaultControl
                    formControlName="category" (optionselected)="onCategorySelected($event)">
                    @for (area of categoryList; track area){
                    <mc-option [attr.data-cy]="area.areaID" [value]="area.areaID">{{area.areaName}}</mc-option>
                    }
                </mc-select>
            </div>

            <div class="pure-u-md-8-24 p-b-16 p-r-16">
                <mc-select label="Configuration file name" fit="medium" width="100" (input)="emitValue()" 
                    placeholder="Select configuration file" ngDefaultControl formControlName="configurationFile">
                    @for (file of configList; track file){
                    <mc-option [attr.data-cy]="file.tableID" [value]="file" >{{file.tableName}}</mc-option>
                    }
                </mc-select>
            </div>
        </div>
    </div>
</form>