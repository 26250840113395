import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, effect, input } from '@angular/core';
import { Mode } from '../../../models/mode.model';
import { utils } from '../../../helper/utils';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { totalHoursSumValidator } from '../../create-work-order/man-hours/cwo-man-hours/validators/total-hours-sum.validator';
import { ManHoursForm } from '../../../form-model/cwo-man-hours.form.model';
import { MaintenanceOrderManHours } from '../../../models/maintenance-order-man-hours';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';
import { ErrorComponent } from '../../../shared-components/error/error.component';
import { ControlValidationService } from '../../../services/control-validation.service';

@Component({
	selector: 'app-bulk-work-order-manhours',
	imports: [ReactiveFormsModule, ErrorComponent],
	templateUrl: './bulk-work-order-manhours.component.html',
	styleUrl: './bulk-work-order-manhours.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderManhoursComponent {
	manHoursForm = new FormGroup<ManHoursForm>({} as ManHoursForm);
	totalManHoursWithoutPrepTime = input.required<number>();
	manHoursThatQualifyForPrepTime = input.required<number>()
	isPrepTimeApplicable = input.required<boolean>()
	mode = input.required<Mode | null>();
	prepTime = computed(() => {
		if (!this.isPrepTimeApplicable()) {
			return 0;
		}
		return (
			this.mode()
				?.preparationTimes.sort((a, b) => a.maxTime - b.maxTime)
				.find((pt) => pt.maxTime >= this.manHoursThatQualifyForPrepTime())?.preparationHours ?? 0
		);
	});
	totalManHoursWithPrepTime = computed(() => {
		return utils.formatNumberToTwoDigitDecimal(this.prepTime() + this.totalManHoursWithoutPrepTime());
	});
	constructor(private formBuilder: FormBuilder, private bulkWorkOrderSharedDataService: BulkWorkOrderSharedDataService, public ctrlValidSvc: ControlValidationService) {
		this.manHoursForm.valueChanges.subscribe((val) => {
			const ordinaryHours: MaintenanceOrderManHours = {
				manHours: val?.ordinaryHours,
				manHoursType: "REGULAR"
			};
			const overtimeHours: MaintenanceOrderManHours = {
				manHours: val?.totalManHoursOvertime,
				manHoursType: "OVERTIME"
			}
			const doubletimeHours: MaintenanceOrderManHours = {
				manHours: val?.totalManHoursDoubletime,
				manHoursType: "DOUBLETIME"
			}
			const miscHours: MaintenanceOrderManHours = {
				manHours: val?.totalManHoursMisc,
				manHoursType: "MISCELLANEOUS"
			}
			this.bulkWorkOrderSharedDataService.BulkWorkOrderManHours = [ordinaryHours, overtimeHours, doubletimeHours, miscHours];
		});

		this.manHoursForm.statusChanges.subscribe((status) => {
			this.bulkWorkOrderSharedDataService.ManHoursFormStatus = status;
		});
	}
	ngOnInit(): void {
		this.addControlsToFormGroup();
	}
	private updates = effect(() => {
		const totalManHoursDoubleTime = this.manHoursForm.controls.totalManHoursDoubletime.value;
		const totalManHoursMisc = this.manHoursForm.controls.totalManHoursMisc.value;
		const totalManHoursOvertime = this.manHoursForm.controls.totalManHoursOvertime.value;
		const totalNonOrdinraryHours = totalManHoursDoubleTime + totalManHoursMisc + totalManHoursOvertime;
		this.manHoursForm.controls.preparationTime.setValue(this.prepTime());
		this.manHoursForm.controls.totalManHours.setValue(this.totalManHoursWithPrepTime());
		this.manHoursForm.controls.ordinaryHours.setValue(utils.formatNumberToTwoDigitDecimal(this.totalManHoursWithPrepTime() - totalNonOrdinraryHours));
	});

	private addControlsToFormGroup() {
		this.manHoursForm.addControl(
			'preparationTime',
			this.formBuilder.nonNullable.control<number>(0)
		);
		this.manHoursForm.addControl('totalManHours', this.formBuilder.nonNullable.control<number>(0));
		this.manHoursForm.addControl(
			'ordinaryHours',
			this.formBuilder.nonNullable.control<number>(0, [
				Validators.pattern(/^\d*\.?\d{1,2}$/),
				Validators.required,
			])
		);
		this.manHoursForm.addControl(
			'totalManHoursOvertime',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d*\.?\d{1,2}$/))
		);
		this.manHoursForm.addControl(
			'totalManHoursDoubletime',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d*\.?\d{1,2}$/))
		);
		this.manHoursForm.addControl(
			'totalManHoursMisc',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d*\.?\d{1,2}$/))
		);
		this.manHoursForm.addValidators([totalHoursSumValidator()]);
	}
}
