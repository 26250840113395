import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MasterDataService } from '../../../services/master-data.service';
import { FlagOnOffAddForm } from '../../../form-model/flag-on-off-add-form.model';
import { CommonModule } from '@angular/common';
import { RuleValidation } from '../../../models/rule-validation.model';
import { Cluster, Loc, LocCluster, ShopCode } from '../../../models/loc.model';
import { FlagOnOffConfigService } from '../flag-on-off-config.service';
import { catchError, firstValueFrom, ReplaySubject, takeUntil, timer } from 'rxjs';
import { ToastrService } from '../../../services/toastr-service';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { User } from '../../../models/user.model';
import { AuthHelper } from '../../../auth/auth-helper';
import { CreateValidation } from '../../../models/create-validation.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-flag-on-off-add-validation',
    imports: [CommonModule, FormsModule, ReactiveFormsModule,],
    templateUrl: './flag-on-off-add-validation.component.html',
    styleUrl: './flag-on-off-add-validation.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlagOnOffAddValidationComponent {
  @Input({ required: true }) flagAddForm!: FormGroup<FlagOnOffAddForm>;
  @Output() private onClickEvent = new EventEmitter<boolean>();
  validation: RuleValidation[] = [];
  countrycluster: Cluster[] = [];
  shop: ShopCode[] = [];
  location: Loc[] = []
  locList: Loc[] = []
  cluster: Cluster[] = [];
  shopCode: ShopCode[] = [];
  loggedinUser: User | null = AuthHelper.user;
  disableAdd: boolean = false;
  message: string = '';
  addClick: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  getLocApiCall: APICallStatus = "NOT_STARTED";
  getRuleApiCall: APICallStatus = "NOT_STARTED";
  getClusterApiCall: APICallStatus = "NOT_STARTED";
  saveValidationApiCall: APICallStatus = "NOT_STARTED";

  constructor(private masterDataService: MasterDataService,
    private formBuilder: FormBuilder,
    private flagConfigService: FlagOnOffConfigService, private toastrService: ToastrService, private router: Router) {
  }

  ngOnInit() {
    this.addControlsToTheFormGroup();
    this.subscribeToMasterDataApiCalls();
    this.flagAddForm?.controls.shop.disable();
    this.flagAddForm?.controls.countrycluster.disable();
  }

  private addControlsToTheFormGroup() {
    this.flagAddForm.addControl('location', this.formBuilder.nonNullable.control<Loc[]>([], Validators.required));
    this.flagAddForm.addControl('validation', this.formBuilder.nonNullable.control<RuleValidation[]>([], Validators.required));
    this.flagAddForm.addControl('countrycluster', this.formBuilder.nonNullable.control<Cluster[]>([], Validators.required));
    this.flagAddForm.addControl('shop', this.formBuilder.nonNullable.control<ShopCode[]>([], Validators.required));
  }
  isControlInErrorState(control: FormControl): boolean | null {
    if (control.invalid && control.touched) {
      return true;
    }
    return null;
  }


  private subscribeToMasterDataApiCalls() {
    this.getAllLoc();
    this.getAllRuleValidation();
    this.location = this.locList
  }
  async getAllLoc() {
    this.locList = await firstValueFrom(this.flagConfigService.getAllLocList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getLocApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get LOC details", "error");
          throw err;
        })));
    this.location = this.locList

  }
  async getAllRuleValidation() {
    this.validation = await firstValueFrom(this.flagConfigService.getAllValidationList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getRuleApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Validation details", "error");
          throw err;
        })));
  }
  onRegionSelected(event: Event) {
    let customEvent = event.target as HTMLInputElement;
    if (customEvent.value.length!==0) {
      this.getClusterByLoc(customEvent.value);
      this.flagAddForm.controls.countrycluster.setValue(null);
      this.flagAddForm.controls.shop.setValue(null);
      this.flagAddForm.controls.countrycluster.enable();
      this.countrycluster = this.cluster;
      this.flagAddForm.controls.shop.disable();
    } else {
      this.flagAddForm.controls.countrycluster.setValue(null);
      this.flagAddForm.controls.shop.setValue(null);
      this.flagAddForm.controls.shop.disable();
      this.flagAddForm.controls.countrycluster.disable();

    }
  }

  onClusterSelected(event: Event) {
    let customEvent = event.target as HTMLInputElement;
    if (customEvent.value && this.flagAddForm.controls.location.value != null) {
      this.getShopsByCluster(customEvent.value);
      this.flagAddForm.controls.shop.setValue(null);
      this.flagAddForm.controls.shop.enable();
    }
    else {
      this.flagAddForm.controls.shop.setValue(null);
      this.flagAddForm.controls.shop.disable();
    }
  }

  async getClusterByLoc(region: string) {
    let selectedRegion = {};
    selectedRegion = {
      locs: [region]
    }
    this.cluster = await firstValueFrom(this.flagConfigService.getClusterByRegion(selectedRegion)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getClusterApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Cluster details", "error");
          throw err;
        })));
    this.countrycluster = this.cluster;
  }
  async getShopsByCluster(cluster: string) {
    let requestBody: LocCluster;
    requestBody = {
      clusterCodes: [this.flagAddForm.controls.countrycluster.value],
      locs: [this.flagAddForm.controls.location.value],
    }
    this.shop = await firstValueFrom(this.flagConfigService.getShopsByCluster(requestBody)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getClusterApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Cluster details", "error");
          throw err;
        })));
  }

  onAddValidation() {
    this.saveValidationApiCall = "IN_PROGRESS"
    let requestBody: CreateValidation;

    if (this.flagAddForm) {
      requestBody = {
        loc: this.flagAddForm.controls.location.value,
        shopCodes: this.flagAddForm.controls.shop.value,
        validationRuleId: this.flagAddForm.controls.validation.value.validationRuleId,
        ruleName: this.flagAddForm.controls.validation.value.ruleName,
        clusterCode: this.flagAddForm.controls.countrycluster.value,
        userId: this.loggedinUser?.userId
      }
      this.flagConfigService.createValidation(requestBody)
        .pipe(takeUntil(this.destroyed$),
          catchError(err => {
            this.saveValidationApiCall = "FAILED";
            this.toastrService.showToastr("Error while saving details", "error");
            throw err;
          })).subscribe(() => {
            this.saveValidationApiCall = "COMPLETED_SUCCESFULLY";
            this.toastrService.showToastr("New validation added successfully", "success");
            this.addClick = true;
            this.onClickEvent.emit(this.addClick);
            this.onCancelClick();
            timer(1000).subscribe(x => {
            this.router.navigateByUrl('/', {skipLocationChange: true})
            .then(()=>this.router.navigate(['approvalSettings/disableValidation']));
            })

            
          });
    } else {
      this.message = "Please enter all fields."
      this.disableAdd = true;
    }
  }

  onCancelClick() {
    this.flagAddForm.reset();
  }
}

