import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Equipment } from "../../../../models/equipment.model";
import { ContainerGrade } from "../../../../models/container-grade.model";
import { Mode } from "../../../../models/mode.model";
import { ToastrService } from "../../../../services/toastr-service";
import { ApplicationtTextService } from "../../../../services/application-text.service";

export function ptiStatusValidator(
  index: number,
  equipment: Equipment | null,
  toastrService: ToastrService,
  appTextSvc: ApplicationtTextService,
  //warningSharedService: WarningSharedService
): ValidatorFn {
  return (control: AbstractControl) => {
    let enteredRepairCode = control.value as string;
    if (!enteredRepairCode) {
      return null;
    }
    if (!equipment) {
      return null;
    }
    if (!equipment.ptiStatus) {
      return null;
    }
    let raiseWarning = false;
    if (equipment.ptiStatus.color == "Blue") {
      raiseWarning = true;
    }
    if (equipment.ptiStatus.color == "Green") {
      if (enteredRepairCode == PtiRepairCodeMapping.ShortPTI
        || enteredRepairCode == PtiRepairCodeMapping.LongPTI) {
        raiseWarning = true;
      }
    }
    if (equipment.ptiStatus.color == "Red") {
      if (equipment.ptiStatus.description == "Short PTI") {
        if (enteredRepairCode == PtiRepairCodeMapping.VisualInspection || enteredRepairCode == PtiRepairCodeMapping.LongPTI) {
          raiseWarning = true;
        }
      }
      if (equipment.ptiStatus.description == "*PTI/SPTI") {
        if (enteredRepairCode == PtiRepairCodeMapping.VisualInspection || enteredRepairCode == PtiRepairCodeMapping.ShortPTI) {
          raiseWarning = true;
        }
      }
    }
    let validatorName = ptiStatusValidator.name + index;
    if (raiseWarning) {
      toastrService.showToastr(
        appTextSvc.ptiStatusValidator,
        'warning',
        60000
      );
    }
    return null;
  }
}
interface PtiRepairCodeMapping {
  [name: string]: string | undefined;
}
export const ptiRepairCodeMapping: PtiRepairCodeMapping = {
};
export const PtiRepairCodeMapping = {
  VisualInspection: "0948",
  ShortPTI: "0942",
  LongPTI: "0940"
}