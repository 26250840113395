
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { utils } from '../helper/utils';
import { Observable} from 'rxjs';
import { BulkWOrkOrderRequest } from '../models/bulk-work-order/bulk-work-order-request.model';
@Injectable({
  providedIn: 'root',
})
export class BulkWorkOrderService {
  constructor(private client: HttpClient) { }

public postBulkWorkOrder(bulkWorkOrderRequestBody: BulkWOrkOrderRequest): Observable<{id:string,createdAt:Date}> {
    return this.client.post<any>(utils.GetUrl('maintenance-orders/bulk-upload'), bulkWorkOrderRequestBody);
  }
}
