import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MasterDataHeaderComponent } from '../../components/master-data-config/master-data-header/master-data-header.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MasterHeaderForm } from '../../form-model/master-header.form.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MasterDataFilterComponent } from '../../components/master-data-config/master-data-filter/master-data-filter.component';
import { MasterDataGridComponent } from '../../components/master-data-config/master-data-grid/master-data-grid.component';
import { BlobLeaseClient } from '@azure/storage-blob';


@Component({
    selector: 'app-master-data-config',
    imports: [MasterDataHeaderComponent, ReactiveFormsModule, NgxSkeletonLoaderModule, MasterDataFilterComponent, MasterDataGridComponent],
    templateUrl: './master-data-config.component.html',
    styleUrl: './master-data-config.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterDataConfigComponent {
  masterFormGroup = this.formBuilder.group({
   
       masterHeaderForm: this.formBuilder.group<MasterHeaderForm>({} as MasterHeaderForm),
       masterFilterForm: new FormGroup({})
     });
  constructor(private formBuilder: FormBuilder) {
  }
  fileChangeValue:number=0;
  valueClick:boolean=false

  onValueChange(newValue:number){
    this.fileChangeValue=newValue;
  }
  onAddClick(value:boolean){
    this.valueClick=value;
  }
}
