import { Component, Input, OnInit } from '@angular/core';
import { MaintenanceOrderPart } from '../../../../models/maintenance-order-part';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PartLineItemForm } from '../../../../form-model/part-line-item.form.model';
import { TagInputComponent } from '../../../../shared-components/tag-input/tag-input.component';
import { AuthPolicies } from '../../../../auth/auth-policies';
import { MaintenanceOrderStatusCode } from '../../../../models/maintenance-order-status.model';

@Component({
    selector: 'app-list-repair-part-items',
    imports: [ReactiveFormsModule, TagInputComponent],
    templateUrl: './list-repair-part-items.component.html',
    styleUrls: ['./list-repair-part-items.component.scss']
})

export class ListRepairPartItemsComponent implements OnInit {

  @Input({ required: true }) repairPart?: MaintenanceOrderPart;
  @Input({ required: true }) maintainceOrderStatus?: MaintenanceOrderStatusCode;
  repairPartForm!: FormGroup;

  constructor(private fb: FormBuilder,public authPolicy: AuthPolicies) {

  }

  ngOnInit() {
    this.repairPartForm = this.fb.group({
      currentSerialNumbers: [this.repairPart?.currentSerialNumbers ? this.repairPart?.currentSerialNumbers : []],
      newSerialNumbers: [this.repairPart?.newSerialNumbers ? this.repairPart?.newSerialNumbers : []]
    });

    this.repairPartForm.valueChanges.subscribe((value) => {
      if (this.repairPart != undefined) {
        this.repairPart.currentSerialNumbers = value.orderedSerialNumber;
        this.repairPart.newSerialNumbers = value.newSerialNumbers;
      }
    });
  }
  isSerialNumberEditable(){
    return this.maintainceOrderStatus===390 && this.authPolicy.canEditWorkOrder;
  }
  onTagChanged(field: string, value: string[]) {
    console.log(value);
    if (field == 'currentSerialNumbers' && this.repairPart != undefined) {
      this.repairPart.currentSerialNumbers = value;
    }
    if (field == 'newSerialNumbers' && this.repairPart != undefined) {
      this.repairPart.newSerialNumbers = value;
    }
  }

}
