import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthPolicies } from '../../../auth/auth-policies';
import { AdvanceFilterComponent } from '../advance-filter/advance-filter.component';
import { CommonModule } from '@angular/common';
import "@maersk-global/mds-components-core/mc-tag";
import "@maersk-global/mds-components-core/mc-modal";
import "@maersk-global/mds-components-core/mc-tooltip";
import { WorkOrderFilterForm } from '../../../form-model/work-order-filter.form.model';
import { MaintenanceOrderStatus } from '../../../models/maintenance-order-status.model';
import { Mode } from '../../../models/mode.model';
import { Shop } from '../../../models/shop.model';
import { debounceTime, distinctUntilChanged, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderFilter } from '../../../models/maintenance-order-filter.model';
import { WorkOrderSearchTextForm } from '../../../form-model/work-order-search-text.form.model';
import { AemsButtonGroupComponent } from '../../../shared-components/aems-button-group/aems-button-group.component';
import { environment } from '../../../../environments/environment';
import { MasterDataService } from '../../../services/master-data.service';

type FilterValue = string | boolean | Mode[] | MaintenanceOrderStatus[] | Shop[];

@Component({
    selector: 'app-filters',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdvanceFilterComponent, AemsButtonGroupComponent],
    templateUrl: './filters.component.html',
    styleUrl: './filters.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FiltersComponent {
  bulkWorkOrderEnabled = signal(environment.Features.EnableBulkWorkOrder);
  showAdvanceFilters: boolean = false;
  canUserCreateWO: boolean = false;
  taggedStatusList: MaintenanceOrderStatus[] = [];
  untaggedStatusList: MaintenanceOrderStatus[] = [];
  readonly screenWidth=window.innerWidth;
  @Input({ required: true }) filterFormGroup!: FormGroup<{
    workOrderFilterForm: FormGroup<WorkOrderFilterForm>;
    workOrderSearchTextForm: FormGroup<WorkOrderSearchTextForm>;
  }>;

  savedFilter!: FormGroup<WorkOrderFilterForm>;
  taggedFilters: { key: string; value: FilterValue }[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private authPolicies: AuthPolicies, private masterDataService: MasterDataService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.masterDataService.getMaintenanceOrderStatus().pipe(takeUntil(this.destroyed$)).subscribe((statuscount) => {
      this.untaggedStatusList = statuscount;
    })
    if (this.authPolicies.canCreateWorkOrder) {
      this.canUserCreateWO = true;
    }
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.filterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.value) {
          this.showAdvanceFilters = false;
          this.updateSelectedFiltersSection();
        }
        if(this.filterFormGroup.controls.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.value){
          this.updateSelectedFiltersSection();
        }
      });
  }

  selectStatusFromQuickFilter(statusCount: MaintenanceOrderStatus): void {
    const newStatus: MaintenanceOrderStatus = {
      generatedCode: statusCount.generatedCode,
      description: statusCount.description
    };

    const currentStatuses = this.filterFormGroup.controls.workOrderFilterForm.controls.status.value || [];
    if (!currentStatuses.some(status => status.generatedCode === newStatus.generatedCode)) {
      const updatedStatuses = [...currentStatuses, newStatus];
      this.filterFormGroup.controls.workOrderFilterForm.controls.status.setValue(updatedStatuses);
      this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
      this.updateSelectedFiltersSection();
    }
  }


  unselectSpecificFilter(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): void {
    switch (taggedFilter.key) {
      case "Equipment Id":
        this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.setValue(null);
        break;

      case "Mode":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let taggedModes = taggedFilter.value as Mode[]
          const updatedModes: Mode[] = taggedModes.filter((_: Mode, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.mode.setValue(updatedModes.length ? updatedModes : null);
        }
        break;

      case "WO Number":
        this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.setValue(null);
        break;

      case "Status":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let statuses = taggedFilter.value as MaintenanceOrderStatus[]
          const updatedStatuses: MaintenanceOrderStatus[] = statuses.filter((_: MaintenanceOrderStatus, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.status.setValue(updatedStatuses.length ? updatedStatuses : null);
        }
        break;

      case "From":
        this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.setValue(null);
        break;

      case "To":
        this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.setValue(null);
        break;

      case "Shop ID":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let shops = taggedFilter.value as Shop[]
          const updatedShops: Shop[] = shops.filter((_: Shop, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.shop.setValue(updatedShops.length ? updatedShops : null);
        }
        break;

      case "Show only flagged work orders":
        this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.setValue(null);
        break;

      default:
        break;
    }
    this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }

  showAdvanceFiltersModal() {
    this.showAdvanceFilters = true;
    this.saveCurrentFilters();
  }

  closeAdvanceFiltersModal() {
    if (this.showAdvanceFilters) {
      this.showAdvanceFilters = false;
      this.filterFormGroup.controls.workOrderFilterForm.reset(this.savedFilter.value);
    }
    this.updateSelectedFiltersSection();
  }

  clearAllSelectedFilters() {
    this.filterFormGroup.reset();
    this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }

  private saveCurrentFilters(): void {
    this.savedFilter = this.formBuilder.group({
      equipmentId: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value),
      mode: this.formBuilder.control<Mode[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value),
      woNumber: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value),
      status: this.formBuilder.control<MaintenanceOrderStatus[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.status.value),
      fromDate: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value),
      toDate: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value),
      shop: this.formBuilder.control<Shop[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value),
      showFlaggedWO: this.formBuilder.control<boolean | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value),
      applyWOFilter: this.formBuilder.control<boolean | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.value),
    });
  }

  updateSelectedFiltersSection() {
    this.markAsTagged("Equipment Id", this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value);
    this.markAsTagged("Mode", this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value);
    this.markAsTagged("WO Number", this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value);
    this.markAsTagged("From", this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value);
    this.markAsTagged("To", this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value);
    this.markAsTagged("Shop ID", this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value);
    this.markAsTagged("Show only flagged work orders", this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value);
    this.updateStatusTag();
  }

  private updateStatusTag() {
    this.masterDataService.getMaintenanceOrderStatus().pipe(takeUntil(this.destroyed$)).subscribe({
      next: response => {
        const selectedStatuses = this.filterFormGroup.controls.workOrderFilterForm.controls.status.value || [];

        this.taggedStatusList = response.filter(status => selectedStatuses.some(selected => selected.generatedCode === status.generatedCode)
        );
        this.untaggedStatusList = response.filter(status => !selectedStatuses.some(selected => selected.generatedCode === status.generatedCode)
        );
        this.markAsTagged("Status", this.filterFormGroup.controls.workOrderFilterForm.controls.status.value);
      },
      error: err => {
      }
    }
    );
  }

  markAsTagged(key: string, newValue: FilterValue | null) {
    const currentValue = this.getFilterValue(key);
    if (currentValue !== newValue) {
      this.removeFilter(key);
      if (newValue !==false && newValue !== null && newValue !== '' && (!Array.isArray(newValue) || newValue.length > 0)) {
        this.taggedFilters.unshift({ key, value: newValue });
      }
    }
  }

  removeFilter(key: string) {
    this.taggedFilters = this.taggedFilters.filter(filter => filter.key !== key);
  }

  getFilterValue(key: string) {
    const filter = this.taggedFilters.find(filter => filter.key === key);
    return filter ? filter.value : null;
  }

  getTaggedFilterValue(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): FilterValue {
    switch (taggedFilter.key) {
      case "Equipment Id":
      case "WO Number":
      case "From":
      case "To":
        return taggedFilter.value;

      case "Mode":
        let modes = taggedFilter.value as Mode[]
        return modes[itemNumber].code;

      case "Status":
        let statuses = taggedFilter.value as MaintenanceOrderStatus[];
        return `${statuses[itemNumber].description}`;

      case "Shop ID":
        let shops = taggedFilter.value as Shop[]
        return shops[itemNumber].maintenanceShopCode;

      case "Show only flagged work orders":
        let showFlaggedWO = taggedFilter.value as boolean;
        return showFlaggedWO ? "Yes" : "No";

      default:
        return '';
    }
  }

  getTaggedModes(): Mode[] {
    const modeTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Mode");
    return modeTag && Array.isArray(modeTag.value) ? modeTag.value as Mode[] : [];
  }

  getTaggedStatuses(): MaintenanceOrderStatus[] {
    const statusTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Status");
    return statusTag && Array.isArray(statusTag.value) ? statusTag.value as MaintenanceOrderStatus[] : [];
  }

  getTaggedShops(): Shop[] {
    const shopTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Shop ID");
    return shopTag && Array.isArray(shopTag.value) ? shopTag.value as Shop[] : [];
  }


  isArrayType(value: FilterValue): boolean {
    return Array.isArray(value);
  }

  get numberOfQuickFiltersToShow():number{
   
    if(this.screenWidth>=320 && this.screenWidth<768){
      return 2;
    }
    if(this.screenWidth>=768 && this.screenWidth<1024){
      return 3;
    }
    if(this.screenWidth>=1024 && this.screenWidth<1280){
      return 4
    }
    if(this.screenWidth>=1280 && this.screenWidth<1920){
      return 6
    }
    if(this.screenWidth>=1920 && this.screenWidth<2560){
      return 8
    }
    if(this.screenWidth>=2560 && this.screenWidth<3840){
      return 12
    }
    return 100;
  }
}