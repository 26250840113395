import "../../chunk-SDBWQ4EE.js";
const ht = () => {
  "use strict";

  const u = new WeakMap(),
    y = new WeakMap(),
    h = new WeakMap(),
    d = new WeakMap(),
    V = new WeakMap(),
    X = new WeakMap(),
    A = new WeakMap(),
    f = new WeakMap(),
    x = new WeakMap(),
    g = new WeakMap(),
    L = new WeakMap(),
    N = new WeakMap(),
    O = new WeakMap(),
    R = new WeakMap(),
    b = new WeakMap(),
    w = {
      ariaAtomic: "aria-atomic",
      ariaAutoComplete: "aria-autocomplete",
      ariaBusy: "aria-busy",
      ariaChecked: "aria-checked",
      ariaColCount: "aria-colcount",
      ariaColIndex: "aria-colindex",
      ariaColIndexText: "aria-colindextext",
      ariaColSpan: "aria-colspan",
      ariaCurrent: "aria-current",
      ariaDisabled: "aria-disabled",
      ariaExpanded: "aria-expanded",
      ariaHasPopup: "aria-haspopup",
      ariaHidden: "aria-hidden",
      ariaInvalid: "aria-invalid",
      ariaKeyShortcuts: "aria-keyshortcuts",
      ariaLabel: "aria-label",
      ariaLevel: "aria-level",
      ariaLive: "aria-live",
      ariaModal: "aria-modal",
      ariaMultiLine: "aria-multiline",
      ariaMultiSelectable: "aria-multiselectable",
      ariaOrientation: "aria-orientation",
      ariaPlaceholder: "aria-placeholder",
      ariaPosInSet: "aria-posinset",
      ariaPressed: "aria-pressed",
      ariaReadOnly: "aria-readonly",
      ariaRelevant: "aria-relevant",
      ariaRequired: "aria-required",
      ariaRoleDescription: "aria-roledescription",
      ariaRowCount: "aria-rowcount",
      ariaRowIndex: "aria-rowindex",
      ariaRowIndexText: "aria-rowindextext",
      ariaRowSpan: "aria-rowspan",
      ariaSelected: "aria-selected",
      ariaSetSize: "aria-setsize",
      ariaSort: "aria-sort",
      ariaValueMax: "aria-valuemax",
      ariaValueMin: "aria-valuemin",
      ariaValueNow: "aria-valuenow",
      ariaValueText: "aria-valuetext",
      role: "role"
    },
    Y = (e, t) => {
      for (const a in w) {
        t[a] = null;
        let i = null;
        const n = w[a];
        Object.defineProperty(t, a, {
          get() {
            return i;
          },
          set(r) {
            i = r, e.isConnected ? e.setAttribute(n, r) : g.set(e, t);
          }
        });
      }
    },
    Z = e => {
      const t = f.get(e.target);
      t && t.size && t.forEach(a => {
        a.constructor.formAssociated && a.formResetCallback && a.formResetCallback.apply(a);
      });
    },
    v = e => {
      const t = Array.from(e.elements).filter(r => !r.tagName.includes("-") && r.validity).map(r => r.validity.valid),
        a = f.get(e) || [],
        i = Array.from(a).filter(r => r.isConnected).map(r => d.get(r).validity.valid),
        n = [...t, ...i].includes(!1);
      e.toggleAttribute("internals-invalid", n), e.toggleAttribute("internals-valid", !n);
    },
    E = e => {
      let t = e.parentNode;
      return t && t.tagName !== "FORM" && (t = E(t)), t;
    },
    j = e => {
      v(E(e.target));
    },
    tt = e => {
      v(E(e.target));
    },
    et = e => {
      const t = ":is(button[type=submit], input[type=submit], button:not([type])):not([disabled])";
      let a = `${t}:not([form])`;
      e.id && (a += `,${t}[form='${e.id}']`), e.addEventListener("click", i => {
        if (i.target.closest(a)) {
          const r = f.get(e);
          if (e.noValidate) return;
          r.size && Array.from(r).reverse().map(c => d.get(c).reportValidity()).includes(!1) && i.preventDefault();
        }
      });
    },
    H = (e, t, a) => {
      if (t) {
        const i = f.get(t);
        if (i) i.add(e);else {
          const n = new Set();
          n.add(e), f.set(t, n), et(t), t.addEventListener("reset", Z), t.addEventListener("input", tt), t.addEventListener("change", j);
        }
        X.set(t, {
          ref: e,
          internals: a
        }), e.constructor.formAssociated && e.formAssociatedCallback && setTimeout(() => {
          e.formAssociatedCallback.apply(e, [t]);
        }, 0), v(t);
      }
    },
    F = (e, t) => {
      e.toggleAttribute("internals-disabled", t), t ? e.setAttribute("aria-disabled", "true") : e.removeAttribute("aria-disabled"), e.formDisabledCallback && e.formDisabledCallback.apply(e, [t]);
    },
    P = (e, t) => {
      if (t.length) {
        Array.from(t).forEach(i => i.addEventListener("click", e.click.bind(e)));
        let a = t[0].id;
        t[0].id || (a = `${t[0].htmlFor}_Label`, t[0].id = a), e.setAttribute("aria-labelledby", a);
      }
    };
  function D(e) {
    const t = d.get(e),
      {
        form: a
      } = t;
    H(e, a, t), P(e, t.labels);
  }
  const W = (e, t = !1) => {
    const a = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
      acceptNode(r) {
        return d.has(r) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
      }
    });
    let i = a.nextNode();
    const n = !t || e.disabled;
    for (; i;) i.formDisabledCallback && n && F(i, e.disabled), i = a.nextNode();
  };
  function M() {
    return typeof MutationObserver < "u";
  }
  const _ = e => {
      h.get(e).forEach(a => {
        a.remove();
      }), h.set(e, []);
    },
    $ = {
      attributes: !0,
      attributeFilter: ["disabled", "name"]
    },
    z = M() ? new MutationObserver(e => {
      for (const t of e) {
        const a = t.target;
        if (t.attributeName === "disabled" && (a.constructor.formAssociated ? F(a, a.hasAttribute("disabled")) : a.localName === "fieldset" && W(a)), t.attributeName === "name" && a.constructor.formAssociated) {
          const i = d.get(a),
            n = x.get(a);
          i.setFormValue(n);
        }
      }
    }) : {};
  function k(e) {
    e.forEach(t => {
      const {
          addedNodes: a,
          removedNodes: i
        } = t,
        n = Array.from(a),
        r = Array.from(i);
      n.forEach(o => {
        if (d.has(o) && o.constructor.formAssociated && D(o), g.has(o)) {
          const s = g.get(o);
          Object.keys(w).filter(l => s[l] !== null).forEach(l => {
            o.setAttribute(w[l], s[l]);
          }), g.delete(o);
        }
        if (b.has(o)) {
          const s = b.get(o);
          o.setAttribute("internals-valid", s.validity.valid.toString()), o.setAttribute("internals-invalid", (!s.validity.valid).toString()), o.setAttribute("aria-invalid", (!s.validity.valid).toString()), b.delete(o);
        }
        if (o.localName === "form") {
          const s = f.get(o),
            c = document.createTreeWalker(o, NodeFilter.SHOW_ELEMENT, {
              acceptNode(T) {
                return d.has(T) && !(s && s.has(T)) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
              }
            });
          let l = c.nextNode();
          for (; l;) D(l), l = c.nextNode();
        }
        o.localName === "fieldset" && (z.observe?.(o, $), W(o, !0));
      }), r.forEach(o => {
        const s = d.get(o);
        s && h.get(s) && _(s), A.has(o) && A.get(o).disconnect();
      });
    });
  }
  const U = e => {
    if (e.constructor.formAssociated) {
      const t = d.get(e),
        {
          labels: a,
          form: i
        } = t;
      P(e, a), H(e, i, t);
    }
  };
  function at(e) {
    e.forEach(t => {
      const {
        removedNodes: a
      } = t;
      a.forEach(i => {
        const n = O.get(t.target);
        d.has(i) && U(i), n.disconnect();
      });
    });
  }
  const it = e => {
    const t = new MutationObserver(at);
    t.observe?.(e, {
      childList: !0
    }), O.set(e, t);
  };
  M() && new MutationObserver(k);
  const C = {
      childList: !0,
      subtree: !0
    },
    K = (e, t) => {
      const a = document.createElement("input");
      return a.type = "hidden", a.name = e.getAttribute("name"), e.after(a), h.get(t).push(a), a;
    },
    nt = (e, t) => {
      h.set(t, []), z.observe?.(e, $);
    },
    m = (e, t, a = DOMException) => {
      if (!e.constructor.formAssociated) throw new a(t);
    },
    q = (e, t, a) => {
      const i = f.get(e);
      return i && i.size && i.forEach(n => {
        d.get(n)[a]() || (t = !1);
      }), t;
    };
  class ot {
    constructor() {
      this.badInput = !1, this.customError = !1, this.patternMismatch = !1, this.rangeOverflow = !1, this.rangeUnderflow = !1, this.stepMismatch = !1, this.tooLong = !1, this.tooShort = !1, this.typeMismatch = !1, this.valid = !0, this.valueMissing = !1, Object.seal(this);
    }
  }
  const rt = e => (e.badInput = !1, e.customError = !1, e.patternMismatch = !1, e.rangeOverflow = !1, e.rangeUnderflow = !1, e.stepMismatch = !1, e.tooLong = !1, e.tooShort = !1, e.typeMismatch = !1, e.valid = !0, e.valueMissing = !1, e),
    st = e => {
      let t = !0;
      for (const a in e) a !== "valid" && e[a] !== !1 && (t = !1);
      return t;
    },
    lt = (e, t, a) => (e.valid = st(t), Object.keys(t).forEach(i => e[i] = t[i]), a && v(a), e),
    S = new WeakMap();
  function B(e, t) {
    e.toggleAttribute(t, !0), e.part && e.part.add(t);
  }
  class I extends Set {
    static get isPolyfilled() {
      return !0;
    }
    constructor(t) {
      if (super(), !t || !t.tagName || t.tagName.indexOf("-") === -1) throw new TypeError("Illegal constructor");
      S.set(this, t);
    }
    add(t) {
      if (!/^--/.test(t) || typeof t != "string") throw new DOMException(`Failed to execute 'add' on 'CustomStateSet': The specified value ${t} must start with '--'.`);
      const a = super.add(t),
        i = S.get(this),
        n = `state${t}`;
      return i.isConnected ? B(i, n) : setTimeout(() => {
        B(i, n);
      }), a;
    }
    clear() {
      for (const [t] of this.entries()) this.delete(t);
      super.clear();
    }
    delete(t) {
      const a = super.delete(t),
        i = S.get(this);
      return i.isConnected ? (i.toggleAttribute(`state${t}`, !1), i.part && i.part.remove(`state${t}`)) : setTimeout(() => {
        i.toggleAttribute(`state${t}`, !1), i.part && i.part.remove(`state${t}`);
      }), a;
    }
  }
  function G(e, t, a, i) {
    if (a === "a" && !i) throw new TypeError("Private accessor was defined without a getter");
    if (typeof t == "function" ? e !== t || !i : !t.has(e)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return a === "m" ? i : a === "a" ? i.call(e) : i ? i.value : t.get(e);
  }
  function ct(e, t, a, i, n) {
    if (i === "m") throw new TypeError("Private method is not writable");
    if (i === "a" && !n) throw new TypeError("Private accessor was defined without a setter");
    if (typeof t == "function" ? e !== t || !n : !t.has(e)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return i === "a" ? n.call(e, a) : n ? n.value = a : t.set(e, a), a;
  }
  let p;
  class dt {
    constructor(t) {
      p.set(this, void 0), ct(this, p, t, "f");
      for (let a = 0; a < t.length; a++) {
        const i = t[a];
        this[a] = i, i.hasAttribute("name") && (this[i.getAttribute("name")] = i);
      }
      Object.freeze(this);
    }
    get length() {
      return G(this, p, "f").length;
    }
    [(p = new WeakMap(), Symbol.iterator)]() {
      return G(this, p, "f")[Symbol.iterator]();
    }
    item(t) {
      return this[t] == null ? null : this[t];
    }
    namedItem(t) {
      return this[t] == null ? null : this[t];
    }
  }
  function ut() {
    const e = HTMLFormElement.prototype.checkValidity,
      t = HTMLFormElement.prototype.reportValidity;
    function a(...r) {
      const o = e.apply(this, r);
      return q(this, o, "checkValidity");
    }
    function i(...r) {
      const o = t.apply(this, r);
      return q(this, o, "reportValidity");
    }
    HTMLFormElement.prototype.checkValidity = a, HTMLFormElement.prototype.reportValidity = i;
    const {
      get: n
    } = Object.getOwnPropertyDescriptor(HTMLFormElement.prototype, "elements");
    Object.defineProperty(HTMLFormElement.prototype, "elements", {
      get(...r) {
        const o = n.call(this, ...r),
          s = Array.from(f.get(this) || []);
        if (s.length === 0) return o;
        const c = Array.from(o).concat(s).sort((l, T) => l.compareDocumentPosition ? l.compareDocumentPosition(T) & 2 ? 1 : -1 : 0);
        return new dt(c);
      }
    });
  }
  class J {
    static get isPolyfilled() {
      return !0;
    }
    constructor(t) {
      if (!t || !t.tagName || t.tagName.indexOf("-") === -1) throw new TypeError("Illegal constructor");
      const a = t.getRootNode(),
        i = new ot();
      this.states = new I(t), u.set(this, t), y.set(this, i), d.set(t, this), Y(t, this), nt(t, this), Object.seal(this), a instanceof DocumentFragment && it(a);
    }
    checkValidity() {
      const t = u.get(this);
      if (m(t, "Failed to execute 'checkValidity' on 'ElementInternals': The target element is not a form-associated custom element."), !this.willValidate) return !0;
      const a = y.get(this);
      if (!a.valid) {
        const i = new Event("invalid", {
          bubbles: !1,
          cancelable: !0,
          composed: !1
        });
        t.dispatchEvent(i);
      }
      return a.valid;
    }
    get form() {
      const t = u.get(this);
      m(t, "Failed to read the 'form' property from 'ElementInternals': The target element is not a form-associated custom element.");
      let a;
      return t.constructor.formAssociated === !0 && (a = E(t)), a;
    }
    get labels() {
      const t = u.get(this);
      m(t, "Failed to read the 'labels' property from 'ElementInternals': The target element is not a form-associated custom element.");
      const a = t.getAttribute("id"),
        i = t.getRootNode();
      return i && a ? i.querySelectorAll(`[for="${a}"]`) : [];
    }
    reportValidity() {
      const t = u.get(this);
      if (m(t, "Failed to execute 'reportValidity' on 'ElementInternals': The target element is not a form-associated custom element."), !this.willValidate) return !0;
      const a = this.checkValidity(),
        i = N.get(this);
      if (i && !t.constructor.formAssociated) throw new DOMException("Failed to execute 'reportValidity' on 'ElementInternals': The target element is not a form-associated custom element.");
      return !a && i && (t.focus(), i.focus()), a;
    }
    setFormValue(t) {
      const a = u.get(this);
      if (m(a, "Failed to execute 'setFormValue' on 'ElementInternals': The target element is not a form-associated custom element."), _(this), t != null && !(t instanceof FormData)) {
        if (a.getAttribute("name")) {
          const i = K(a, this);
          i.value = t;
        }
      } else t != null && t instanceof FormData && Array.from(t).reverse().forEach(([i, n]) => {
        if (typeof n == "string") {
          const r = K(a, this);
          r.name = i, r.value = n;
        }
      });
      x.set(a, t);
    }
    setValidity(t, a, i) {
      const n = u.get(this);
      if (m(n, "Failed to execute 'setValidity' on 'ElementInternals': The target element is not a form-associated custom element."), !t) throw new TypeError("Failed to execute 'setValidity' on 'ElementInternals': 1 argument required, but only 0 present.");
      N.set(this, i);
      const r = y.get(this),
        o = {};
      for (const l in t) o[l] = t[l];
      Object.keys(o).length === 0 && rt(r);
      const s = {
        ...r,
        ...o
      };
      delete s.valid;
      const {
        valid: c
      } = lt(r, s, this.form);
      if (!c && !a) throw new DOMException("Failed to execute 'setValidity' on 'ElementInternals': The second argument should not be empty if one or more flags in the first argument are true.");
      V.set(this, c ? "" : a), n.isConnected ? (n.toggleAttribute("internals-invalid", !c), n.toggleAttribute("internals-valid", c), n.setAttribute("aria-invalid", `${!c}`)) : b.set(n, this);
    }
    get shadowRoot() {
      const t = u.get(this),
        a = L.get(t);
      return a || null;
    }
    get validationMessage() {
      const t = u.get(this);
      return m(t, "Failed to read the 'validationMessage' property from 'ElementInternals': The target element is not a form-associated custom element."), V.get(this);
    }
    get validity() {
      const t = u.get(this);
      return m(t, "Failed to read the 'validity' property from 'ElementInternals': The target element is not a form-associated custom element."), y.get(this);
    }
    get willValidate() {
      const t = u.get(this);
      return m(t, "Failed to read the 'willValidate' property from 'ElementInternals': The target element is not a form-associated custom element."), !(t.disabled || t.hasAttribute("disabled") || t.hasAttribute("readonly"));
    }
  }
  let Q;
  function mt(...e) {
    const t = Q.apply(this, e);
    if (L.set(this, t), M()) {
      const a = new MutationObserver(k);
      window.ShadyDOM ? a.observe(this, C) : a.observe(t, C), A.set(this, a);
    }
    return t;
  }
  function ft() {
    if (typeof window > "u" || !window.ElementInternals || !HTMLElement.prototype.attachInternals) return !1;
    class e extends HTMLElement {
      constructor() {
        super(), this.internals = this.attachInternals();
      }
    }
    const t = `element-internals-feature-detection-${Math.random().toString(36).replace(/[^a-z]+/g, "")}`;
    customElements.define(t, e);
    const a = new e();
    return ["shadowRoot", "form", "willValidate", "validity", "validationMessage", "labels", "setFormValue", "setValidity", "checkValidity", "reportValidity"].every(i => i in a.internals);
  }
  if (ft()) {
    if (typeof window < "u" && !window.CustomStateSet) {
      window.CustomStateSet = I;
      const e = HTMLElement.prototype.attachInternals;
      HTMLElement.prototype.attachInternals = function (...t) {
        const a = e.call(this, t);
        return a.states = new I(this), a;
      };
    }
  } else {
    if (typeof window < "u" && (window.ElementInternals = J), typeof CustomElementRegistry < "u") {
      const e = CustomElementRegistry.prototype.define;
      CustomElementRegistry.prototype.define = function (t, a, i) {
        if (a.formAssociated) {
          const n = a.prototype.connectedCallback;
          a.prototype.connectedCallback = function () {
            R.has(this) || (R.set(this, !0), this.hasAttribute("disabled") && F(this, !0)), n?.apply(this), U(this);
          };
        }
        e.call(this, t, a, i);
      };
    }
    typeof HTMLElement < "u" && (HTMLElement.prototype.attachInternals = function () {
      if (this.tagName) {
        if (this.tagName.indexOf("-") === -1) throw new Error("Failed to execute 'attachInternals' on 'HTMLElement': Unable to attach ElementInternals to non-custom elements.");
      } else return {};
      if (d.has(this)) throw new DOMException("DOMException: Failed to execute 'attachInternals' on 'HTMLElement': ElementInternals for the specified element was already attached.");
      return new J(this);
    }), typeof Element < "u" && (Q = Element.prototype.attachShadow, Element.prototype.attachShadow = mt), M() && new MutationObserver(k).observe(document.documentElement, C), typeof HTMLFormElement < "u" && ut(), typeof window < "u" && !window.CustomStateSet && (window.CustomStateSet = I);
  }
};
export { ht as attachElementInternalsPolyfill };