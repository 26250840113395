import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BulkWorkOrderHeader, SingleEquipmentForm } from '../../../form-model/bulk-workorder-header.form.model';
import { ButtonWithConfirmationComponent } from '../../../shared-components/button-with-confirmation/button-with-confirmation.component';
import { FileUploadComponent } from '../../../shared-components/aems-file-upload/file-upload.component';
import { ApplicationtTextService } from '../../../services/application-text.service';
import { ToastrService } from '../../../services/toastr-service';
import '@maersk-global/mds-components-core/mc-input';
import { Shop } from '../../../models/shop.model';
import { Mode } from '../../../models/mode.model';
import { MasterDataService } from '../../../services/master-data.service';
import { User } from '../../../models/user.model';
import { AttachmentForm } from '../../../form-model/attachment.form.model';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';
import { forkJoin, ReplaySubject, takeUntil } from 'rxjs';
import { BulkWorkOrderHeaderValue } from '../../../models/bulk-work-order/bulk-work-order-header.model';
import { ControlValidationService } from '../../../services/control-validation.service';
import { EquipmentDetailsValidator } from './validators/equipment-details.asyncValidator';
import { EquipmentWorkorderExistValidator } from '../../../shared-validators/equipment-workorder-exist.validator';


@Component({
  selector: 'app-bulk-work-order-header',
  imports: [ButtonWithConfirmationComponent, ReactiveFormsModule, FileUploadComponent],
  templateUrl: './bulk-work-order-header.component.html',
  styleUrl: './bulk-work-order-header.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderHeaderEquipmentsComponent implements OnInit, OnDestroy {
  bulkWorkOrderHeaderForm: FormGroup<BulkWorkOrderHeader>;
  shops: Shop[] = [];
  modes: Mode[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  constructor(private fb: FormBuilder,
    public appTextSvc: ApplicationtTextService,
    private toastr: ToastrService,
    private masterDataSvc: MasterDataService,
    private bulkWorkOrderSharedDataService: BulkWorkOrderSharedDataService,
    public ctrlValidSvc: ControlValidationService,
    private equipmentValidator: EquipmentDetailsValidator,
    private workorderExistValidator: EquipmentWorkorderExistValidator) {
    this.bulkWorkOrderHeaderForm = this.fb.group<BulkWorkOrderHeader>({
      maintenanceShop: this.fb.nonNullable.control({} as Shop),
      maintenanceRepairMode: this.fb.nonNullable.control({} as Mode),
      equipments: this.fb.array<FormGroup<SingleEquipmentForm>>([])
    });
    this.bulkWorkOrderHeaderForm.valueChanges.subscribe(() => {
     this.postFromValueToSharedService();
    });
    this.bulkWorkOrderHeaderForm.statusChanges.subscribe((status) => {
      this.postFromValueToSharedService();
      this.bulkWorkOrderSharedDataService.HeaderFormStatus = status;
    })
    this.onAddEquipment();
    this.bulkWorkOrderSharedDataService.Reset.
      pipe(takeUntil(this.destroyed$)).
      subscribe(() => {
        this.resetEquipment();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.bulkWorkOrderSharedDataService.Mode = null;
    this.bulkWorkOrderSharedDataService.Shop = null;
  }

  private postFromValueToSharedService(): void {
    if(this.bulkWorkOrderHeaderForm.invalid || this.bulkWorkOrderHeaderForm.pending)
      return;
    const val = this.bulkWorkOrderHeaderForm.getRawValue();
    const fieldsWithEquipmentNumbers=val.equipments.filter((equipment)=>equipment.equipmentNumber);
    const headerValue: BulkWorkOrderHeaderValue = {
      maintenanceShopCode: val.maintenanceShop.maintenanceShopCode,
      maintenanceRepairModeCode: val.maintenanceRepairMode.code,
      equipments: fieldsWithEquipmentNumbers.map((equipment) => {
        return {
          equipmentNumber: equipment.equipmentNumber,
          vendorsReference: equipment.vendorsReference,
          attachedImages: equipment.attachedImages.files
        }
      })

    }
    this.bulkWorkOrderSharedDataService.BulkWorkOrderHeader = headerValue;
  }

  private resetEquipment(): void {
    this.bulkWorkOrderHeaderForm.controls.equipments.clear();
    this.onAddEquipment();
  }
  ngOnInit(): void {
    forkJoin([this.masterDataSvc.getRepairMode("43"), this.masterDataSvc.getRepairMode("03"), this.masterDataSvc.getRepairMode("02")]).subscribe(([mode1, mode2, mode3]) => {
      this.modes.push(mode3);
      this.modes.push(mode2);
      this.modes.push(mode1);
      this.bulkWorkOrderHeaderForm.controls.maintenanceRepairMode.setValue(this.modes[0]);
    });
    this.bulkWorkOrderHeaderForm.controls.maintenanceShop.valueChanges.subscribe((shop: Shop) => {
      this.bulkWorkOrderSharedDataService.Shop = shop;
      this.resetEquipment();
    });
    this.bulkWorkOrderHeaderForm.controls.maintenanceRepairMode.valueChanges.subscribe((mode: Mode) => {
      this.bulkWorkOrderSharedDataService.Mode = mode;
      this.resetEquipment();
    });
    this.getUserAssignedShop();
  }
  get equipments(): FormArray<FormGroup<SingleEquipmentForm>> {
    return this.bulkWorkOrderHeaderForm.get('equipments') as FormArray<FormGroup<SingleEquipmentForm>>;
  }
  get singleEquipmentForm(): FormGroup<SingleEquipmentForm> {
    const equipmentForm = this.fb.nonNullable.group<SingleEquipmentForm>({
      equipmentNumber: this.fb.nonNullable.control('', {
        updateOn: 'blur', validators: [Validators.pattern(/^[A-Z]{4}[0-9]{6,9}$/)],
        asyncValidators: [this.equipmentValidator.validate.bind(this.equipmentValidator), this.workorderExistValidator.validate.bind(this.workorderExistValidator)]
      }),
      vendorsReference: this.fb.nonNullable.control(''),
      attachedImages: this.fb.nonNullable.group<AttachmentForm>({} as AttachmentForm)
    });
    return equipmentForm;
  }
  onAddEquipment(): void {
    this.equipments.push(this.singleEquipmentForm);
  }
  deleteEquipment($index: number): () => void {
    return () => {
      if (this.equipments.length === 1) {
        this.toastr.showToastr(this.appTextSvc.AtleastOneEquipmentRequired, "warning");
        return;
      }
      this.equipments.removeAt($index);
    }
  }

  onKeyPressLastControlInRow(index: number): void {
    if (index === this.equipments.length - 1) {
      this.onAddEquipment()
    }
  }

  private getUserAssignedShop() {
    const userData = localStorage.getItem('user');
    if (userData === null) {
      return;
    }
    const user: User = JSON.parse(userData);
    this.shops = user.maintenanceShops;
    this.bulkWorkOrderHeaderForm.controls.maintenanceShop.setValue(this.shops[0]);
  }
}