import { Component, ElementRef, ViewChild, CUSTOM_ELEMENTS_SCHEMA, Input, EventEmitter, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MasterDataService } from '../../../services/master-data.service';
import { Mode } from '../../../models/mode.model';
import { MaintenanceOrderStatus } from '../../../models/maintenance-order-status.model';
import { Shop } from '../../../models/shop.model';
import { ReplaySubject, forkJoin, take, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import "@maersk-global/mds-components-core/mc-multi-select";
import "@maersk-global/mds-components-core/mc-input-date";
import { WorkOrderFilterForm } from '../../../form-model/work-order-filter.form.model';
import { User } from '../../../models/user.model';

@Component({
    selector: 'app-advance-filter',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    templateUrl: './advance-filter.component.html',
    styleUrl: './advance-filter.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AdvanceFilterComponent implements OnInit {
  @Input({ required: true }) workOrderFilterForm!: FormGroup<WorkOrderFilterForm>;


  modes: Mode[] = [];
  statuses: MaintenanceOrderStatus[] = [];
  shops: Shop[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private masterDataService: MasterDataService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.addControlsToTheFormGroup();
    this.getUserAssignedShop();
    this.subscribeToMasterDataApiCalls();
  }

  private addControlsToTheFormGroup() {
    this.workOrderFilterForm.addControl('equipmentId', this.formBuilder.control<string | null>(null, {
      validators: [Validators.pattern(/^[A-Z]{4}[0-9]{6,9}$/)]
    }));
    this.workOrderFilterForm.addControl('mode', this.formBuilder.control<Mode[] | null>(null));
    this.workOrderFilterForm.addControl('fromDate', this.formBuilder.control<string | null>(null));
    this.workOrderFilterForm.addControl('toDate', this.formBuilder.control<string | null>(null));
    this.workOrderFilterForm.addControl('woNumber', this.formBuilder.control<string | null>(null, {
      validators: [Validators.pattern(/^\d+$/)]
    }));
    this.workOrderFilterForm.addControl('status', this.formBuilder.control<MaintenanceOrderStatus[] | null>(null));
    this.workOrderFilterForm.addControl('shop', this.formBuilder.control<Shop[] | null>(null));
    this.workOrderFilterForm.addControl('showFlaggedWO', this.formBuilder.control<boolean | null>(null));
    this.workOrderFilterForm.addControl('applyWOFilter', this.formBuilder.control<boolean | null>(null));
  }

  isControlInErrorState(control: FormControl): boolean | null {
    if (control.invalid && control.touched) {
      return true;
    }
    return null;
  }

  private subscribeToMasterDataApiCalls() {
    forkJoin([
      this.masterDataService.getRepairModes().pipe(take(1)),
      this.masterDataService.getMaintenanceOrderStatus()
    ]).pipe(takeUntil(this.destroyed$)).subscribe(([modes, status]) => {
      this.modes = modes;
      this.statuses = status;
    });
  }

  private getUserAssignedShop() {
		const userData = localStorage.getItem('user');
		if (userData === null) {
			return;
		}

		const user: User = JSON.parse(userData);
		this.shops = user.maintenanceShops;
	}

  applyFilters() {
    this.workOrderFilterForm.controls.applyWOFilter.setValue(true);
  }

  clearFilters() {
    this.workOrderFilterForm.reset();
  }
}
