var f = Object.defineProperty;
var g = Object.getOwnPropertyDescriptor;
var n = (c, m, e, t) => {
  for (var r = t > 1 ? void 0 : t ? g(m, e) : m, s = c.length - 1, i; s >= 0; s--) (i = c[s]) && (r = (t ? i(m, e, r) : i(r)) || r);
  return t && r && f(m, e, r), r;
};
import { LitElement as w, html as p } from "lit";
import { classMap as x } from "lit/directives/class-map.js";
import { property as o, query as y, state as l } from "lit/decorators.js";
import { repeat as v } from "lit/directives/repeat.js";
import { unsafeCSS as _ } from "lit";
import k from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as b } from "lit-element/lit-element.js";
var u = b`:host([fullwidth]),:host([fullwidth]) .mc-month-year-picker,:host([fullwidth]) .mc-month-year-picker mc-picker{width:100%}:host([nopadding]) .mc-month-year-picker{padding:0}.mc-month-year-picker{display:flex}.mc-month-year-picker.small{padding:var(--mds_core_month-year-picker_small_padding-top) var(--mds_core_month-year-picker_small_padding-right) var(--mds_core_month-year-picker_small_padding-bottom) var(--mds_core_month-year-picker_small_padding-left);gap:var(--mds_core_month-year-picker_small_gap)}.mc-month-year-picker.medium{padding:var(--mds_core_month-year-picker_medium_padding-top) var(--mds_core_month-year-picker_medium_padding-right) var(--mds_core_month-year-picker_medium_padding-bottom) var(--mds_core_month-year-picker_medium_padding-left);gap:var(--mds_core_month-year-picker_medium_gap)}.mc-month-year-picker.large{padding:var(--mds_core_month-year-picker_large_padding-top) var(--mds_core_month-year-picker_large_padding-right) var(--mds_core_month-year-picker_large_padding-bottom) var(--mds_core_month-year-picker_large_padding-left);gap:var(--mds_core_month-year-picker_large_gap)}`;
import M from "@maersk-global/mds-design-tokens/implementation/core/month-year-picker/js/design-tokens-px.css.js";
var d = [k, M, _(u)];
import "@maersk-global/mds-components-core-picker";
import "@maersk-global/mds-components-core-picker-item";
var a = class extends w {
  constructor() {
    super();
    this.months = new Map();
    this.years = [];
    this.value = {
      month: null,
      year: null
    };
    this.fit = "medium";
    this.preventinitialeventdispatch = !1;
    this.min = {
      month: null,
      year: null
    };
    this.max = {
      month: null,
      year: null
    };
    this.locale = "en-GB";
    this.yearcap = 10;
    this.locale = this.locale ? this.locale : new Intl.DateTimeFormat().resolvedOptions().locale || "en-GB", this.months = this.getMonths(), this.years = this.getYears(+this.yearcap);
    let e = new Date();
    this.month = this.getInitialMonth(e), this.year = e.getFullYear();
  }
  static get styles() {
    return d;
  }
  render() {
    let e = {
      [`${this.fit}`]: !0
    };
    return p`<div class="mc-month-year-picker ${x(e)}" @keydown="${this.handleKeyDown}"><mc-picker data-cy="month" class="month" aria-label="Choose a month" .fit="${this.fit}" .value="${this.month}" @pickerselected="${this.handleMonthChange}" ?preventinitialeventdispatch="${this.preventinitialeventdispatch}">${v(Array.from(this.months.values()), t => t.number, t => p`<mc-picker-item .disabled="${t.disabled}" .value="${t.name}" .label="${t.name}"></mc-picker-item>`)}</mc-picker><mc-picker data-cy="year" class="year" aria-label="Choose a year" .fit="${this.fit}" .value="${this.year}" @pickerselected="${this.handleYearChange}" ?preventinitialeventdispatch="${this.preventinitialeventdispatch}">${v(this.years, t => t.number, t => p`<mc-picker-item .disabled="${t.disabled}" .value="${t.number}" .label="${t.number.toString()}"></mc-picker-item>`)}</mc-picker></div>`;
  }
  willUpdate(e) {
    if (super.willUpdate(e), e.has("yearcap") && (this.years = this.getYears(+this.yearcap)), (e.has("min") || e.has("max")) && (this.months = this.getMonths(), this.years = this.getYears(+this.yearcap)), e.has("value")) {
      if (this.value?.month === null || !this.value?.year) return;
      let {
        month: t,
        year: r
      } = this.value;
      setTimeout(() => {
        this.setMonthYear({
          month: t,
          year: r
        });
      }, 0);
    }
  }
  focus() {
    this.monthPicker?.focusWith(!1);
  }
  show() {
    this.monthPicker && this.yearPicker && (this.monthPicker.value = this.month, this.yearPicker.value = this.year, this.monthPicker.show(), this.yearPicker.show());
  }
  hide() {
    this.monthPicker?.hide(), this.yearPicker?.hide();
  }
  handleMonthChange(e) {
    let t = e.detail.item.value;
    this.handleMonthYearChange(t, this.year);
  }
  handleYearChange(e) {
    let t = e.detail.item.value;
    this.handleMonthYearChange(this.month, t);
  }
  handleMonthYearChange(e, t) {
    let r = {
      month: e,
      year: t
    };
    if (this.value?.month === r.month && this.value?.year === r.year) return;
    this.value = r;
    let s = r;
    typeof s.month == "string" && this.months.forEach(i => {
      i.name === s.month && (s = {
        ...s,
        month: i.number
      });
    }), this.dispatchEvent(new CustomEvent("monthyearselected", {
      detail: {
        value: s
      }
    }));
  }
  setMonthYear({
    month: e,
    year: t
  }) {
    if (!e && e !== 0 || !t) return;
    this.months = this.getMonths();
    let r = "";
    typeof e == "number" ? r = this.getMonthFromNumber(e) : r = e, Array.from(this.months.values()).find(i => i.name === r)?.disabled && (r = Array.from(this.months.values()).find(i => !i.disabled)?.name), this.month = r, this.year = +t, this.show();
  }
  getMonths() {
    let e = new Intl.DateTimeFormat(this.locale, {
        month: "long"
      }),
      t = new Date().getFullYear(),
      r = new Map();
    return Array.from({
      length: 12
    }, (s, i) => {
      let h = {
        name: e.format(new Date(t, i, 1)),
        number: i,
        disabled: this.isMonthDisabled(i)
      };
      r.set(i, h);
    }), r;
  }
  getYears(e = +this.yearcap) {
    let t = new Date().getFullYear(),
      r = t - e,
      s = t + e;
    return Array.from({
      length: s - r + 1
    }, (i, h) => ({
      number: r + h,
      disabled: this.isYearDisabled(r + h)
    }));
  }
  getInitialMonth(e) {
    return this.months.get(e.getMonth())?.name;
  }
  getMonthFromNumber(e) {
    return e > 11 || e < 0 ? this.months.get(new Date().getMonth())?.name : this.months.get(e)?.name;
  }
  isMonthDisabled(e) {
    if (!this.min?.month && !this.max?.month) return !1;
    let t;
    this.min?.month && this.min?.year && this.value?.year ? t = this.value.year > this.min?.year ? -1 : this.getMinMaxMonthNumber(this.min.month) : this.min?.month && !this.min?.year ? t = this.getMinMaxMonthNumber(this.min.month) : t = -1;
    let r;
    return this.max?.month && this.max?.year && this.value?.year ? r = this.value.year < this.max.year ? 9999 : this.getMinMaxMonthNumber(this.max.month) : this.max?.month && !this.max?.year ? r = this.getMinMaxMonthNumber(this.max.month) : r = 9999, e < t || e > r;
  }
  getMinMaxMonthNumber(e) {
    if (typeof e == "string") {
      let t = Array.from(this.months.values()).find(r => r.name === e);
      return t ? t.number : 0;
    }
    return e;
  }
  isYearDisabled(e) {
    if (!this.min?.year && !this.max?.year) return !1;
    let t = this.min?.year || -1,
      r = this.max?.year || 9999;
    return e < t || e > r;
  }
  handleKeyDown(e) {
    e.preventDefault(), e.key === "ArrowLeft" && this.monthPicker?.focusWith(), e.key === "ArrowRight" && this.yearPicker?.focusWith();
  }
};
n([l()], a.prototype, "months", 2), n([l()], a.prototype, "years", 2), n([l()], a.prototype, "month", 2), n([l()], a.prototype, "year", 2), n([o({
  type: Object
})], a.prototype, "value", 2), n([o({
  type: String
})], a.prototype, "fit", 2), n([o({
  type: Boolean
})], a.prototype, "preventinitialeventdispatch", 2), n([o({
  type: Object
})], a.prototype, "min", 2), n([o({
  type: Object
})], a.prototype, "max", 2), n([o({
  type: String
})], a.prototype, "locale", 2), n([o({
  type: Number
})], a.prototype, "yearcap", 2), n([y(".month")], a.prototype, "monthPicker", 2), n([y(".year")], a.prototype, "yearPicker", 2);
customElements.get("mc-month-year-picker") || customElements.define("mc-month-year-picker", a);
export { a as McMonthYearPicker };