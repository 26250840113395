<form [formGroup]="flagFilterForm">
    <div class="pure-g">
        <div class="pure-u-md-24-24">
           
            <div class="pure-u-md-12-12 p-b-16 p-r-16"><mc-select label="Validation" fit="medium" width="100"
                placeholder="Select a validation" ngDefaultControl formControlName="validation">
                @for (rule of validation; track rule){
                <mc-option [attr.data-cy]="rule.ruleName" [value]="rule">{{rule.ruleName}}</mc-option>
                }
            </mc-select></div>

            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Region" fit="medium" width="100"
                    placeholder="Select region" ngDefaultControl formControlName="location" (optionselected)="onRegionSelected($event) ">
                    @for (loc of location; track loc){
                    <mc-option [attr.data-cy]="loc.loc" [value]="loc.loc"> {{ loc.loc }}</mc-option>
                    }
                </mc-multi-select></div>
                <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Cluster" fit="medium" width="100"
                    placeholder="Select cluster" ngDefaultControl formControlName="countrycluster"  (optionselected)="onClusterSelected($event)">
                    @for (country of countrycluster; track country){
                        <mc-option [attr.data-cy]="country.clusterCode" [value]="country.clusterCode"> {{ country.clusterCode }}</mc-option>
                        }
                </mc-multi-select></div>

                <div class="pure-u-md-12-12 p-b-16 p-r-16"><mc-multi-select label="Shop" fit="medium" width="100"
                    placeholder="Select Shops" ngDefaultControl formControlName="shop" >
                    @for (shop of shop; track shop){
                        <mc-option [attr.data-cy]="shop.shopCode" [value]="shop.shopCode"> {{ shop.shopCode }}</mc-option>
                        }
                </mc-multi-select></div>
       
            <br />
           
        </div>
        <div class="pure-u-md-24-24 utility-buttons">
            <mc-button label="Apply Filters" slot="primaryAction" variant="filled" appearance="primary" fit="medium"
                (click)="applyFilters()"  class="p-r-16"></mc-button>
            <mc-button label="Cancel"  slot="secondaryAction" appearance="neutral" fit="medium"
                (click)="clearFilters()"  dialogaction="cancel" class="p-r-16"></mc-button>
        </div>
    </div>
</form>