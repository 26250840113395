import { Injectable } from "@angular/core";
import { UserMessages } from "../helper/user-messages";
@Injectable({
    providedIn: 'root'
})
export class ApplicationtTextService{
    get BulkEquipmentDeleteConfirmation():string{
        return 'All details including uploaded images will be deleted permanently. Are you sure you want to proceed?';
    }

    get AtleastOneEquipmentRequired():string{
        return 'At least one equipment is required';
    }
    get WorkOrderSubmittedSuccessfully():string{
        return 'Work Order created successfully!';
    }
    get WorkOrderSubmittedFailed():string{
        return 'Failed to create Work Order';
    }
    get WorkOrderResubmittedSuccessfully():string{
        return 'Work Order re-submitted successfully!';
    }
    get LocationMismatchWarning():string{
        return 'Shop location does not match the equipment location';
    }
    get UnableToSaveRemarks():string{
        return 'Unable to save remark';
    }
    get ptiStatusValidator():string{
        return 'Work order created against the suggested container status, it might be rejected. Please enter comments why this repair is done.';
    }
    get FileDeletedSuccessfully():string{
        return 'File deleted successfully';
    }
    get FailedToDeleteFile():string{
        return 'The file could not be deleted, please try again';
    }
    get FileSizeExceeded():string{
        return 'The uploaded file exceeds 5MB';
    }
    get MaxFilesUpload():string{
        return 'You can upload up to 5 files';
    }
    get DuplicateFile():string{
        return 'There are one or more files with the same file name';
    }
    get InvalidFileExtension():string{
        return 'Only .jpg, .png and .pdf files can be uploaded' ;
    }
    get nonEmptyReeferRepairCode():string{
        return 'You are creating this work order against a live reefer which does not have any high or medium alarm. It will be subject to enhanced scrutiny.';
    }
    getDuplicateRepairCodeValidators(duplicateRepair:string):string{
        return `Repair ${duplicateRepair} has already been done on the equipment, are you sure it is required?`;
    }
    get AtLeastOnePartRequired():string{
        return 'Atleast one part is required';
    }
    get AttachmentServiceDown():string{
        return 'The file upload service is down, please try again in some time';
    }
    get AtLeastOneRepairLineItemRequired():string{
        return 'At least one repair line item is required';
    }
    get BulkWorkOrderSubmittedSuccessfully():string{
        return 'Success! Check notification for status';
    }

    get OnlyDryContainerAllowed():string{
        return 'Only dry container is allowed for selected Mode';
    }

    get OnlyReeferContainerAllowed():string{
        return 'Only reefer container is allowed for selected Mode';
    }

    get ContainerTypeNotAllowed():string{
        return "Equipment type codes 'TWDK' and 'TWFL' are not allowed for containers"
    }

    get EquipmentTypeNotAllowed():string{
        return "Equipment type VSA/SO, repair not allowed"
    }

    get EquipmentLeaseValidation():string{
        return "Equipment is not owned, leased or operated by Maersk, repair not allowed"
    }

    get EquipmentNotInComet():string{
        return "Equipment does not exist";
    }

    get Required():string{
        return "Required";
    }
    
    get Invalid():string{
        return "Invalid Code";
    }

}