import "../../chunk-SDBWQ4EE.js";
const a = {
  en: "en",
  es: "es-MX",
  pt: "pt-BR",
  fr: "fr-FR",
  tr: "tr-TR",
  de: "de-DE",
  zh_hans: "zh-CN",
  zh_hant: "zh-TW",
  jp: "ja-JP",
  ru: "ru-RU",
  ko: "ko-KR",
  it: "it-IT"
};
function s(e) {
  const t = r().selectedLang;
  return a[t] || e;
}
function r() {
  if (!globalThis.document || globalThis.document && !globalThis.document.cookie) return {};
  const e = document.cookie.split("; "),
    t = {};
  for (let n = e.length - 1; n >= 0; n--) {
    const o = e[n].split("=");
    t[o[0]] = o[1];
  }
  return t;
}
export { s as getSelectedLocale };