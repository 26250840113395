var _ = Object.defineProperty;
var g = Object.getOwnPropertyDescriptor;
var r = (u, o, e, t) => {
  for (var s = t > 1 ? void 0 : t ? g(o, e) : o, a = u.length - 1, l; a >= 0; a--) (l = u[a]) && (s = (t ? l(o, e, s) : l(s)) || s);
  return t && s && _(o, e, s), s;
};
import { LitElement as S, html as h } from "lit";
import { repeat as v } from "lit/directives/repeat.js";
import { classMap as $ } from "lit/directives/class-map.js";
import { property as m, query as f, state as p } from "lit/decorators.js";
import { timeFormatter as w, padStart as c } from "@maersk-global/mds-components-utils";
import { unsafeCSS as y } from "lit";
import b from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as k } from "lit-element/lit-element.js";
var n = k`.mc-time-picker{display:flex}.mc-time-picker.small{padding:var(--mds_core_time-picker_small_padding-top) var(--mds_core_time-picker_small_padding-right) var(--mds_core_time-picker_small_padding-bottom) var(--mds_core_time-picker_small_padding-left);gap:var(--mds_core_time-picker_small_gap)}.mc-time-picker.medium{padding:var(--mds_core_time-picker_medium_padding-top) var(--mds_core_time-picker_medium_padding-right) var(--mds_core_time-picker_medium_padding-bottom) var(--mds_core_time-picker_medium_padding-left);gap:var(--mds_core_time-picker_medium_gap)}.mc-time-picker.large{padding:var(--mds_core_time-picker_large_padding-top) var(--mds_core_time-picker_large_padding-right) var(--mds_core_time-picker_large_padding-bottom) var(--mds_core_time-picker_large_padding-left);gap:var(--mds_core_time-picker_large_gap)}`;
import C from "@maersk-global/mds-design-tokens/implementation/core/time-picker/js/design-tokens-px.css.js";
var d = [b, C, y(n)];
import "@maersk-global/mds-components-core-picker";
import "@maersk-global/mds-components-core-picker-item";
var i = class extends S {
  constructor() {
    super(...arguments);
    this.firstUpdate = !0;
    this.hours = [];
    this.minutes = [];
    this.hour = "00";
    this.minute = "00";
    this.fit = "medium";
    this.hourstep = 1;
    this.minutestep = 1;
    this.preselectcurrenttime = !1;
    this.preventinitialeventdispatch = !1;
    this.trapfocus = !1;
    this.value = "";
  }
  static get styles() {
    return d;
  }
  render() {
    let e = {
      [`${this.fit}`]: !0
    };
    return h`<div class="mc-time-picker ${$(e)}" @keydown="${this.handleKeyDown}"><mc-picker exportparts="container: hours-container" data-cy="hour" class="hour" aria-label="Choose an hour" .fit="${this.fit}" .value="${this.hour}" @pickerselected="${this.handleHourChange}" ?preventinitialeventdispatch="${this.preventinitialeventdispatch}">${v(this.hours, t => t, t => h`<mc-picker-item width="fixed" .value="${t}" .label="${t}"></mc-picker-item>`)}</mc-picker><mc-picker exportparts="container: minutes-container" data-cy="minute" class="minute" aria-label="Choose a minute" .fit="${this.fit}" .value="${this.minute}" @pickerselected="${this.handleMinuteChange}" ?preventinitialeventdispatch="${this.preventinitialeventdispatch}">${v(this.minutes, t => t, t => h`<mc-picker-item width="fixed" .value="${t}" .label="${t}"></mc-picker-item>`)}</mc-picker></div>`;
  }
  focus() {
    this.hourPicker?.focusWith(!0);
  }
  show() {
    this.hourPicker?.show(), this.minutePicker?.show();
  }
  hide() {
    this.hourPicker?.hide(), this.minutePicker?.hide();
  }
  connectedCallback() {
    super.connectedCallback(), this.setHours(), this.setMinutes();
  }
  willUpdate(e) {
    super.willUpdate(e), e.has("preselectcurrenttime") && this.setCurrentTime(), e.has("hourstep") && !this.firstUpdate && this.setHours(), e.has("minutestep") && !this.firstUpdate && this.setMinutes(), e.has("value") && this.setTime(), this.firstUpdate = !1;
  }
  setMinutes() {
    this.minutes = this.getTimeArray(0, 60, this.minutestep);
  }
  setHours() {
    this.hours = this.getTimeArray(0, 24, this.hourstep);
  }
  setCurrentTime() {
    this.preselectcurrenttime && (this.value = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit"
    }));
  }
  handleKeyDown(e) {
    e.key === "Tab" && this.trapfocus && (e.preventDefault(), this.hourPicker?.focused ? this.minutePicker?.focusWith() : this.hourPicker?.focusWith()), e.key === "Enter" && (e.preventDefault(), this.handleTimeChange(!0)), e.key === "ArrowLeft" && (e.preventDefault(), this.hourPicker?.focusWith()), e.key === "ArrowRight" && (e.preventDefault(), this.minutePicker?.focusWith());
  }
  handleHourChange(e) {
    this.hour = e.detail.item.value, this.handleTimeChange(e.detail.force);
  }
  handleMinuteChange(e) {
    this.minute = e.detail.item.value, this.handleTimeChange(e.detail.force);
  }
  handleTimeChange(e = !1) {
    let t = `${c(this.hour)}:${c(this.minute)}`;
    (t !== this.value || e) && (this.value = t, this.dispatchEvent(new CustomEvent("timeselected", {
      detail: {
        time: this.value
      }
    })));
  }
  setTime() {
    if (this.value) {
      let [, e, t] = w(this.value);
      t !== this.minute && (this.minute = t, this.minutePicker?.show()), e !== this.hour && (this.hour = e, this.hourPicker?.show());
    }
  }
  getTimeArray(e, t, s) {
    return Array.from(Array.from(Array(Math.ceil((t - e) / s)).keys()), a => e + a * s).map(a => c(a));
  }
};
r([p()], i.prototype, "hours", 2), r([p()], i.prototype, "minutes", 2), r([f(".hour")], i.prototype, "hourPicker", 2), r([f(".minute")], i.prototype, "minutePicker", 2), r([p()], i.prototype, "hour", 2), r([p()], i.prototype, "minute", 2), r([m({
  type: String
})], i.prototype, "fit", 2), r([m({
  type: Number
})], i.prototype, "hourstep", 2), r([m({
  type: Number
})], i.prototype, "minutestep", 2), r([m({
  type: Boolean
})], i.prototype, "preselectcurrenttime", 2), r([m({
  type: Boolean
})], i.prototype, "preventinitialeventdispatch", 2), r([m({
  type: Boolean
})], i.prototype, "trapfocus", 2), r([m({
  type: String
})], i.prototype, "value", 2);
customElements.get("mc-time-picker") || customElements.define("mc-time-picker", i);
export { i as McTimePicker };