<form [formGroup]="manHoursForm">
    <app-error [formContainer]="manHoursForm"></app-error>
    <div class="aems-card m-b-12" [class.error-on-form-group]="manHoursForm.hasError('totalHoursSumValidator')">
        <div class="pure-g">
            <div class="pure-u-md-6-24 pure-u-lg-6-24 m-b-12 p-r-16">
                <mc-input ngDefaultControl formControlName="ordinaryHours" label="Ordinary Hours"
                    placeholder="Enter ordinary hours" fit="small"
                    [attr.invalid]="ctrlValidSvc.isControlInErrorState(manHoursForm.controls.ordinaryHours)">
                @if(manHoursForm.controls.ordinaryHours.hasError('pattern')){
                <span slot="errormessage">Incorrect Format</span>
                }
                @if(manHoursForm.controls.ordinaryHours.hasError('required')){
                    <span slot="errormessage">Required</span>
                    }
            </mc-input>
            </div>
            <div class="pure-u-md-6-24 pure-u-lg-6-24 m-b-12 p-r-16">
                <mc-input ngDefaultControl formControlName="totalManHoursOvertime" label="OT1"
                    placeholder="Enter overtime hours" fit="small"
                    [attr.invalid]="ctrlValidSvc.isControlInErrorState(manHoursForm.controls.totalManHoursOvertime)">
                @if(manHoursForm.controls.totalManHoursOvertime.hasError('pattern')){
                <span slot="errormessage">Incorrect Format</span>
                }
            </mc-input>
            </div>
            <div class="pure-u-md-6-24 pure-u-lg-6-24 m-b-12 p-r-16">
                <mc-input ngDefaultControl formControlName="totalManHoursDoubletime" label="OT2"
                    placeholder="Enter overtime hours" fit="small"
                    [attr.invalid]="ctrlValidSvc.isControlInErrorState(manHoursForm.controls.totalManHoursDoubletime)">
                @if(manHoursForm.controls.totalManHoursDoubletime.hasError('pattern')){
                <span slot="errormessage">Incorrect Format</span>
           
                }
            </mc-input>
            </div>
            <div class="pure-u-md-6-24 pure-u-lg-6-24 m-b-12 p-r-16">
                <mc-input ngDefaultControl formControlName="totalManHoursMisc" label="OT3"
                    placeholder="Enter overtime hours" fit="small"
                    [attr.invalid]="ctrlValidSvc.isControlInErrorState(manHoursForm.controls.totalManHoursMisc)">
                @if(manHoursForm.controls.totalManHoursMisc.hasError('pattern')){
                <span slot="errormessage">Incorrect Format</span>
                }
            </mc-input>
            </div>
            <div class="pure-u-md-24-24 pure-u-lg-24-24">

                <div class="pure-u-md-6-24 pure-u-lg-6-24 mds-text--small-bold">
                    <span>Preparation Time:  {{prepTime()}}</span>
                </div>

                <div class="pure-u-md-6-24 pure-u-lg-6-24 mds-text--small-bold">
                    <span>Total Man Hours:  {{totalManHoursWithPrepTime()}}</span>
                </div>
            </div>
        </div>
    </div>
</form>
