import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import "@maersk-global/mds-components-core/mc-pagination";
import { WorkOrderService } from '../../../services/work-order.service';
import { MaintenanceOrderHeader } from '../../../models/maintenance-order-header.model';
import { utils } from '../../../helper/utils';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import "@maersk-global/mds-components-core/mc-table";
import { WorkOrderFilterForm } from '../../../form-model/work-order-filter.form.model';
import { FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderFilter } from '../../../models/maintenance-order-filter.model';
import { WorkOrderSearchTextForm } from '../../../form-model/work-order-search-text.form.model';
import { LoaderComponent } from "../../../shared-components/loader/loader.component";
import { APICallStatus } from '../../../models/api-call-status.mode';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-icon';

@Component({
    selector: 'app-work-order-grid',
    imports: [CommonModule, NgxSkeletonLoaderModule, RouterModule, LoaderComponent],
    templateUrl: './work-order-grid.component.html',
    styleUrl: './work-order-grid.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WorkOrderGridComponent {
  data: MaintenanceOrderHeader[] = [];
  mockDataForLoadingAnimation:{id:number}[]=[...Array(30).keys()].map(i=>({id:i}))
  firstPage: number | undefined;
  previousPage: number | undefined;
  currentPage: number = 1;
  nextPage: number | undefined;
  lastPage: number | undefined;
  totalItemCount: number | undefined;
  apiVersion: string | undefined;
  pageLimit: number = 20;
  sort: string | undefined;
  filterParam: MaintenanceOrderFilter | undefined;
  getWorkOrdersApiCallStatus: APICallStatus = "NOT_STARTED";
  hasData: boolean = false;
  urlNavigate:string|undefined;
  @Input({ required: true }) filterFormGroup!: FormGroup<{
    workOrderFilterForm: FormGroup<WorkOrderFilterForm>;
    workOrderSearchTextForm: FormGroup<WorkOrderSearchTextForm>;
  }>;

  columns: Array<TableColumn> = [
    {
      id: 'maintenanceOrderNumber',
      label: 'Work Order Number',
      sortDisabled: true
    },
    {
      id: 'equipmentNumber',
      label: 'Equipment Number',
      sortDisabled: true
    },
    {
      id: 'totalOrderCostUSD',
      label: 'Total Value(USD)',
      dataType: {
        type: 'number',
        formatter: (value, row) => {
          return utils.numberFormatter.format(Number(value));
        }
      }
    },
    {
      id: 'totalOrderCost',
      label: 'Total Value(Shop Currency)',
      sortDisabled: true,
      dataType: {
        type: 'number',
        formatter: (value, row) => {
          return `${(row.original as MaintenanceOrderHeader).isoCurrencyCode} ${utils.numberFormatter.format(Number(value))}`;
        }
      }
    },
    {
      id: 'maintenanceRepairModeCode',
      label: 'Mode',
      sortDisabled: true
    },
    {
      id: 'maintenanceOrderStatusName',
      label: 'Status',
      sortDisabled: true
    },
    {
      id: 'maintenanceOrderStatusDuration',
      label: 'Status Duration',
      dataType: {
        type: 'string',
        formatter: (cellValue: unknown) => utils.formatDuration(cellValue as number)
      }
    },
    {
      id: 'createTimestamp',
      label: 'Created On',
      dataType: {
        type: 'string',
        formatter: (value) => {
          const date = new Date(value as string);
          return `${utils.dateFormatter.format(date)}`;
        }
      }
    },
    {
      id: 'createUserID',
      label: 'Created By',
      sortDisabled: true
    },
    {
      id: 'maintenanceShopCode',
      label: 'Shop ID',
      sortDisabled: true
    },
    {
      id: 'locationCode',
      label: 'Location',
      sortDisabled: true
    },
    {
      id: 'updateTimestamp',
      label: 'Last Updated On',
      dataType: {
        type: 'string',
        formatter: (value) => {
          const stringValue = value as string;
          const date = new Date(stringValue);
          return stringValue && date ? utils.dateFormatter.format(date) : '';
        }
      }
    }
  ];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private workOrderService: WorkOrderService) {

  }

  ngOnInit() {
    this.sort = `createTimestamp:desc,updateTimestamp:desc`;
    if(window.location.pathname === '/workorders'){
      this.urlNavigate='/workorders';
    }
    if(window.location.pathname === '/approvals'){
      this.urlNavigate='/approvals';
    }
    this.getWorkOrder();
    this.subscribeToFormChanges();
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private subscribeToFormChanges() {
    this.filterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.value ||
          this.filterFormGroup.controls.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.value) {
          this.onApplyFilter();

          this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(false);
          this.filterFormGroup.controls.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.setValue(false);
        }
      });
  }



  onSortDirectionChange(event: Event) {
    let customEvent = event as CustomEvent;
    let sortColumn: string = this.sortColumnName(customEvent.detail.column)
    let sortDirection: string = customEvent.detail.direction == "ascending" ? "asc" : "desc"
    if (sortColumn != "id") {
      this.sort = `${sortColumn}:${sortDirection}`;
      this.currentPage = 1;
      this.getWorkOrder();
    }
  };

  private sortColumnName(gridColumnName: string): string {
    switch (gridColumnName) {
      case "maintenanceOrderStatusDuration": return "maintenanceOrderStatusUpdatedTimestamp";
      case "totalOrderCostUSD": return "totalOrderCost";
      default: return gridColumnName
    }
  }

  onPageLimitChange(event: Event) {
    let customEvent = event as CustomEvent;
    this.currentPage = 1;
    this.pageLimit = customEvent.detail.value;

    this.getWorkOrder();
  }

  onPageChange(event: Event) {
    let customEvent = event as CustomEvent;
    let changedPage = customEvent.detail as number;
    if (changedPage != this.currentPage) {
      this.currentPage = changedPage;
      this.getWorkOrder();
    }
  }

  onApplyFilter() {
    if (this.filterFormGroup.controls.workOrderFilterForm.valid) {
      const filters: MaintenanceOrderFilter = {};

      if (this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value) {
        filters.equipmentNumber = this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value;
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value && this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value.length > 0) {
        filters.maintenanceRepairModes = this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value.map(x => x.code).join(',');
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value) {
        filters.maintenanceOrderNumber = +this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value;
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.status.value && this.filterFormGroup.controls.workOrderFilterForm.controls.status.value.length > 0) {
        filters.maintenanceOrderStatusTypes = this.filterFormGroup.controls.workOrderFilterForm.controls.status.value.map(x => x.generatedCode?.replace(/\-/g, ',')).join(',');
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value) {
        filters.fromDate = this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value;
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value) {
        filters.toDate = this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value;
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value && this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value.length > 0) {
        filters.maintenanceShopCodes = this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value.map(x => x.maintenanceShopCode).join(',');
      }
      if (this.filterFormGroup.controls.workOrderSearchTextForm.controls.searchText.value) {
        filters.searchText = this.filterFormGroup.controls.workOrderSearchTextForm.controls.searchText.value;
      }
      if (this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value) {
        filters.showFlaggedWO = this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value;
      }
      this.filterParam = filters;
      this.currentPage = 1;
      this.getWorkOrder();
    }
  }

  getWorkOrder(): void {
    this.getWorkOrdersApiCallStatus = "IN_PROGRESS";
    this.workOrderService.getWorkOrders(this.currentPage, this.pageLimit, this.sort, this.filterParam).pipe(takeUntil(this.destroyed$)).subscribe({
      next: response => {
        const headers = response.headers;
        const body = response.body || [];
        this.data = body;
        this.firstPage = headers.get('First-Page') ? +headers.get('First-Page')! : undefined;
        this.previousPage = headers.get('Previous-Page') ? +headers.get('Previous-Page')! : undefined;
        this.currentPage = +headers.get('Current-Page')!;
        this.nextPage = headers.get('Next-Page') ? +headers.get('Next-Page')! : undefined;
        this.lastPage = headers.get('Last-Page') ? +headers.get('Last-Page')! : undefined;
        this.totalItemCount = headers.get('Total-Item-Count') ? +headers.get('Total-Item-Count')! : undefined;
        this.apiVersion = headers.get('API-Version') ?? undefined;
        this.getWorkOrdersApiCallStatus = "COMPLETED_SUCCESFULLY"
      },
      error: err => {
        this.getWorkOrdersApiCallStatus = "FAILED"
        this.data=[]
      }
    }
    );
  }

  clearFilter() {
    this.filterFormGroup.reset();
    this.filterFormGroup.controls.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.setValue(true);
    this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
  }
}

