import { a } from "../../chunk-SDBWQ4EE.js";
import { property as r } from "lit/decorators.js";
const o = s => {
  class t extends s {
    constructor() {
      super(...arguments);
      this.checked = !1;
    }
    get value() {
      return this._value;
    }
    set value(e) {
      this.cachedValue = e === void 0 || e === "" || e === !1 ? !0 : e, this._value = e;
    }
    async firstUpdated(e) {
      super.firstUpdated(e), this.updateValue();
    }
    updated(e) {
      super.updated(e), e.has("checked") && this.updateValue();
    }
    updateValue() {
      this._value = this.checked ? this.cachedValue : !1;
    }
  }
  return a([r({
    type: Boolean,
    reflect: !0
  })], t.prototype, "checked", 2), a([r({
    type: String,
    reflect: !0
  })], t.prototype, "value", 1), t;
};
export { o as Checkable };