var g = Object.defineProperty;
var v = Object.getOwnPropertyDescriptor;
var s = (a, o, t, r) => {
  for (var e = r > 1 ? void 0 : r ? v(o, t) : o, c = a.length - 1, m; c >= 0; c--) (m = a[c]) && (e = (r ? m(o, t, e) : m(e)) || e);
  return r && e && g(o, t, e), e;
};
import { LitElement as $, html as D } from "lit";
import { property as n, state as u } from "lit/decorators.js";
import { classMap as x } from "lit/directives/class-map.js";
import { unsafeSVG as d } from "lit/directives/unsafe-svg.js";
import { styleMap as I } from "lit/directives/style-map.js";
import { until as R } from "lit/directives/until.js";
import { isCdn as b, supportsDynamicImport as T } from "@maersk-global/mds-components-utils";
import { MdsConfig as f } from "@maersk-global/mds-config";
import { unsafeCSS as S } from "lit";
import w from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as y } from "lit-element/lit-element.js";
var p = y`.mc-icon{display:flex;fill:inherit}.mc-icon.size-16,.mc-icon.size-16 svg{height:var(--mds_core_icon_small_height);width:var(--mds_core_icon_small_width)}.mc-icon.size-20{height:var(--mds_core_icon_medium_height);width:var(--mds_core_icon_medium_width)}.mc-icon.size-24{height:var(--mds_core_icon_large_height);width:var(--mds_core_icon_large_width)}`;
import _ from "@maersk-global/mds-design-tokens/implementation/core/icon/js/design-tokens-px.css.js";
var h = [w, _, S(p)];
var z = "https://assets.maerskline.com/mds/icons/latest/svg",
  i = class i extends $ {
    constructor() {
      super(...arguments);
      this.convertedSize = "20";
      this.icon = "empty";
      this.size = "20";
    }
    static get styles() {
      return h;
    }
    get iconTitle() {
      return this.title || (this.icon !== "empty" ? this.icon : void 0);
    }
    willUpdate(t) {
      super.willUpdate(t), (t.has("size") || t.has("icon") || t.has("title")) && (this.convertedSize = this.size === "16" ? "20" : this.size, this.icon && this.icon !== "empty" && (this.svg = this.renderIcon(this.convertedSize)));
    }
    render() {
      let t = {
          [`size-${this.size}`]: !0
        },
        r = {
          fill: this.color ? this.color : "var(--mds_core_icon_color)"
        };
      return D`<span part="icon-wrapper" class="mc-icon ${x(t)}" style="${I(r)}">${this.icon && this.icon !== "empty" && this.svg ? R(this.svg) : this.getEmptyIcon(this.convertedSize)}</span>`;
    }
    async renderIcon(t) {
      try {
        i.supportsDynamicImport === void 0 && (i.supportsDynamicImport = T());
        let r;
        if (!i.supportsDynamicImport || b()) r = await fetch(`${z}/${t}px/mi-${this.icon}.svg`).then(e => {
          if (e.ok) return e.text();
        });else {
          let e = null;
          f.iconsDynamicImportPath ? e = await import(/* @vite-ignore */`${f.iconsDynamicImportPath}@maersk-global/icons/js/${t}px/mi-${this.icon}.js`) : e = await import(/* @vite-ignore */`@maersk-global/icons/js/${t}px/mi-${this.icon}.js`), r = e.default;
        }
        return d(this.addTitleToSvg(r));
      } catch {
        return this.getEmptyIcon(t);
      }
    }
    addTitleToSvg(t) {
      return this.iconTitle ? t.replace(/<svg/, `<svg aria-label="${this.iconTitle}"`) : t;
    }
    getEmptyIcon(t) {
      return d(`<svg
    width="${t}"
    height="${t}"
    viewBox="0 0 ${t} ${t}"
    xmlns="http://www.w3.org/2000/svg"
  ></svg>`);
    }
  };
i.supportsDynamicImport = void 0, s([u()], i.prototype, "convertedSize", 2), s([u()], i.prototype, "svg", 2), s([n({
  type: String
})], i.prototype, "color", 2), s([n({
  type: String,
  reflect: !0
})], i.prototype, "icon", 2), s([n({
  type: String
})], i.prototype, "size", 2), s([n({
  type: String,
  reflect: !0
})], i.prototype, "title", 2);
var l = i;
customElements.get("mc-icon") || customElements.define("mc-icon", l);
export { l as McIcon };