import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, firstValueFrom, ReplaySubject, takeUntil, timer } from 'rxjs';
import { MasterDataConfigService } from '../master-data-config.service';
import * as XLSX from 'xlsx';
import { ToastrService } from '../../../services/toastr-service';
import { HttpErrorResponse } from '@angular/common/http';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { MasterDataAdvancedFilterComponent } from '../master-data-advanced-filter/master-data-advanced-filter.component';
type FilterValue = string | string[] | number | number[] | boolean;
import "@maersk-global/mds-components-core/mc-file-upload";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MasterHeaderForm } from '../../../form-model/master-header.form.model';
import { MasterAdvanceFilterForm } from '../../../form-model/master-advance-filter.form.model';

@Component({
    selector: 'app-master-data-filter',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MasterDataAdvancedFilterComponent, NgxSkeletonLoaderModule],
    templateUrl: './master-data-filter.component.html',
    styleUrl: './master-data-filter.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterDataFilterComponent {
  @Input({ required: true }) masterFormGroup!: FormGroup<{
    masterHeaderForm: FormGroup<MasterHeaderForm>;
    masterFilterForm: FormGroup;
  }>
  getfilterApiCall: APICallStatus = "NOT_STARTED";
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  formDetails: { label: string; type: string }[] = [];
  tableData: any;
  showDownload: boolean = true;
  openUploadDialog: boolean = false;
  file: File | undefined;
  displayErrMsg: boolean = false;
  uploadErrList: Array<HttpErrorResponse> = [];
  submitLoader: boolean = false;
  downloadLoader: boolean = false;
  showDoneBtn: boolean = false;
  showAdvanceFilters: boolean = false;
  formList: MasterAdvanceFilterForm[] = [];
  list: MasterAdvanceFilterForm[] = [];
  taggedFilters: { key: string; value: FilterValue }[] = [];
  filterLoader: boolean = false;
  savedFilter!: FormGroup;
  @Input() receivedFileChangeValue: number = 0;
  constructor(private masterDataConfigService: MasterDataConfigService,
    private toasterService: ToastrService, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.subscribeToFormChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedFileChangeValue']) {
      this.taggedFilters = [];
      this.masterFormGroup.controls.masterFilterForm.reset();
    }
  }
  private subscribeToFormChanges() {
    this.masterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter']) {
          if (this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].value) {
            this.showAdvanceFilters = false;
          }
        }
      });
  }
  private saveCurrentFilters(): void {
    const flagFilterForm = this.masterFormGroup.get('masterFilterForm') as FormGroup;
    const savedControls: { [key: string]: AbstractControl } = {};
    Object.keys(flagFilterForm.controls).forEach(controlName => {
      const controlValue = flagFilterForm.controls[controlName].value;
      savedControls[controlName] = this.fb.control(controlValue);
    });
    this.savedFilter = this.fb.group(savedControls);
  }
  async onDownloadClick() {
    this.downloadLoader = true;
    let tableId = this.masterFormGroup.controls.masterHeaderForm.controls['configurationFile'].value.tableID;
    let tableName = this.masterFormGroup.controls.masterHeaderForm.controls['configurationFile'].value.tableName;
    if (tableId)
      this.masterDataConfigService.downloadMasterData(tableId)
        .pipe(takeUntil(this.destroyed$),
          catchError(err => {
            this.toasterService.showToastr('Error while downloading', 'error');
            this.downloadLoader = false;
            throw err;
          })).subscribe((data) => {
            this.tableData = data;
            const header = this.tableData.header;
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tableData.data)
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' })
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, tableName + '.xlsx');
            this.downloadLoader = false;
            this.toasterService.showToastr('Data downloaded successfully', 'success');
          });
  }
  onFileChange(event: Event) {
    this.displayErrMsg = false;
    this.uploadErrList = [];
    let customEvent = event as CustomEvent;
    const file = customEvent.detail[0];
    if (file) {
      this.file = file;
      this.showDoneBtn = true;
    } else {
      this.showDoneBtn = false;
    }

  }
  async onUploadClick() {
    this.submitLoader = true;
    this.uploadErrList = [];

    let tableId = this.masterFormGroup.controls.masterHeaderForm.controls['configurationFile'].value.tableID;
    if (this.file) {
      if (tableId)
        this.masterDataConfigService.uploadMasterData(tableId, this.file)
          .pipe(takeUntil(this.destroyed$),
            catchError(err => {
              if (err.error.length > 0) {

                this.uploadErrList = err.error;
                this.displayErrMsg = true;
                this.submitLoader = false;
              }
              throw err;
            })).subscribe((data) => {
              this.toasterService.showToastr('File uploaded successfully', 'success');
              this.openUploadDialog = false;
              this.submitLoader = false;
            });
    }
  }
  closeUploadDialog() {
    this.openUploadDialog = false;
    this.displayErrMsg = false;
  }

  uploadDialog() {
    this.openUploadDialog = true;
    this.displayErrMsg = false;
    this.uploadErrList = [];
  }

  showAdvanceFiltersModal() {
    this.filterLoader = true;
    this.masterDataConfigService.getFilterDetails(this.receivedFileChangeValue)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getfilterApiCall = "FAILED";
          this.filterLoader = false;
          this.toasterService.showToastr("Unable to get filter details", "error");
          throw err;
        })).subscribe((data) => {
          this.formList = data;
          this.addControlsToTheFormGroup();
          this.showAdvanceFilters = true;
          this.filterLoader = false;
          this.saveCurrentFilters();
        });


  }
  addControlsToTheFormGroup() {
    let form = this.masterFormGroup.controls.masterFilterForm;
    const controlName = Object.keys(this.masterFormGroup.controls.masterFilterForm.controls)

    if (controlName.length == 0) {
      this.masterFormGroup.controls.masterFilterForm = this.fb.group({});
      this.list = [];
      this.list = this.formList;
      if (this.list !== null || this.list !== undefined) {
        this.list.forEach((field: MasterAdvanceFilterForm) => {
          this.masterFormGroup.controls.masterFilterForm.addControl(field.columnIdentity, (this.fb.control('')))
        })
        this.masterFormGroup.controls.masterFilterForm.addControl('applyMasterFilter', this.fb.control<boolean | null>(null));
      }
    }
    else {
      this.list = [];
      this.list = this.formList;
      if (this.list !== null || this.list !== undefined) {
        this.list.forEach((field: MasterAdvanceFilterForm) => {
          const controlNames = field.columnIdentity;
          if (!controlName.includes(controlNames)) {
            this.masterFormGroup.controls.masterFilterForm = this.fb.group({});
            this.list = [];
            this.list = this.formList;
            if (this.list !== null || this.list !== undefined) {
              this.list.forEach((field: MasterAdvanceFilterForm) => {
                this.masterFormGroup.controls.masterFilterForm.addControl(field.columnIdentity, (this.fb.control('')))
              })
              this.masterFormGroup.controls.masterFilterForm.addControl('applyMasterFilter', this.fb.control<boolean | null>(null));
            }
          }
        })
      }

    }

  }

  closeAdvanceFiltersModal() {
    if (this.showAdvanceFilters) {
      this.showAdvanceFilters = false;
      this.masterFormGroup.controls.masterFilterForm.reset(this.savedFilter.value);
    }
    else {
      this.updateSelectedFiltersSection();
    }

  }
  clearAllSelectedFilters() {
    this.closeAdvanceFiltersModal();
  }
  updateSelectedFiltersSection() {
    let form = this.masterFormGroup.controls.masterFilterForm;
    Object.keys(form.controls).forEach((controlName) => {
      const controlVal = form.get(controlName)?.value;
      this.masterFormGroup.controls.masterFilterForm.get(controlName)?.setValue(controlVal)
      this.markAsTagged(`${controlName}`, controlVal);
    })
  }
  markAsTagged(key: string, newValue: FilterValue | null) {
    const currentValue = this.getFilterValue(key);
    if (currentValue !== newValue) {
      this.removeFilter(key);
      if (newValue !== false && newValue !== null && newValue !== '' && (!Array.isArray(newValue) || newValue.length > 0)) {
        this.taggedFilters.unshift({ key, value: newValue });
      }
    }
  }

  removeFilter(key: string) {
    if (key !== "applyMasterFilter") {
      this.taggedFilters = this.taggedFilters.filter(filter => filter.key !== key);
    }
    else {
      this.masterDataConfigService.triggerRefresh();
    }
  }

  getFilterValue(key: string) {
    if (key !== "applyMasterFilter") {

      const filter = this.taggedFilters.find(filter => filter.key === key);
      return filter ? filter.value : null;
    }
    return null;

  }
  getTaggedFilterValue(
    taggedFilter: { key: string; value: FilterValue },
    itemNumber: number = -1
  ): FilterValue {
    let form = this.masterFormGroup.controls.masterFilterForm;
    for (let controlName of Object.keys(form.controls)) {
      if (taggedFilter.key === controlName) {
        const region = taggedFilter.value as (string[] | number[]);
        if (Array.isArray(region) && itemNumber >= 0 && itemNumber < region.length) {
          return region[itemNumber];
        }
        return '';
      }
    }
    return '';
  }

  isArrayType(value: FilterValue): boolean {
    return Array.isArray(value);
  }
  public onClickEvents(addClick: boolean) {
    if (addClick == true) {
      timer(1000).subscribe(x => {
        this.closeAdvance();
      })
    }
  }

  closeAdvance() {
    this.showAdvanceFilters = false;
    this.updateSelectedFiltersSection();  
  }

  unselectSpecificFilter(taggedFilter: { key: string; value: FilterValue }): void {
    let form = this.masterFormGroup.controls.masterFilterForm;
    let formValue = form.value;
    Object.keys(form.controls).forEach((controlName) => {
      const controlVal = form.get(controlName)?.value;
      const name = `${controlName}`

      switch (taggedFilter.key) {
        case `${controlName}`:

          let formValue = taggedFilter.value
          form.get(controlName)?.setValue(null);
          break;

        default:
          break;
      }
    });
    this.masterFormGroup.controls.masterFilterForm.controls['applyMasterFilter'].setValue(true);
    this.updateSelectedFiltersSection();
  }
}
