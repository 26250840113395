var _ = Object.defineProperty;
var h = Object.getOwnPropertyDescriptor;
var s = (l, o, e, i) => {
  for (var r = i > 1 ? void 0 : i ? h(o, e) : o, m = l.length - 1, p; m >= 0; m--) (p = l[m]) && (r = (i ? p(o, e, r) : p(r)) || r);
  return i && r && _(o, e, r), r;
};
import { LitElement as g, html as v } from "lit";
import { classMap as b } from "lit/directives/class-map.js";
import { property as a, queryAssignedElements as S, state as x } from "lit/decorators.js";
import { unsafeCSS as f } from "lit";
import u from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as y } from "lit-element/lit-element.js";
var n = y`:host{line-height:0}.mc-error{display:none;color:var(--mds_core_error_text-color);font-family:var(--mds_brand_typography_text_font-family),var(--mds_brand_typography_text_font-family-fallback);font-size:var(--mds_brand_typography_text_small_mobile_font-size);line-height:var(--mds_brand_typography_text_small_mobile_line-height)}.mc-error.visible.invalid{display:flex;margin-top:var(--mds_core_error_margin-top);column-gap:var(--mds_core_error_column-gap)}@media screen and (min-width:1000px){.mc-error{font-size:var(--mds_brand_typography_text_small_desktop_font-size);line-height:var(--mds_brand_typography_text_small_desktop_line-height)}}.mc-error{font-style:var(--mds_brand_typography_text_small_normal_font-style);font-weight:var(--mds_brand_typography_text_small_normal_font-weight)}`;
import c from "@maersk-global/mds-design-tokens/implementation/core/error/js/design-tokens-px.css.js";
var d = [u, c, f(n)];
var t = class extends g {
  constructor() {
    super(...arguments);
    this.hasSlotError = !1;
    this.visible = !1;
    this.invalid = !1;
    this.fit = "medium";
  }
  static get styles() {
    return d;
  }
  async firstUpdated(e) {
    super.firstUpdated(e), await this.updateComplete, this.updateVisible();
  }
  willUpdate(e) {
    super.willUpdate(e), (e.has("errormessage") || e.has("invalid")) && this.updateVisible();
  }
  render() {
    let e = {
      visible: this.visible,
      invalid: !!this.invalid,
      [`${this.fit}`]: !0
    };
    return v`<slot part="error" class="mc-error ${b(e)}" @slotchange="${this.onErrorSlotChange}">${this.errormessage}</slot>`;
  }
  onErrorSlotChange() {
    this.hasSlotError = this.errorNodes.length > 0, this.updateVisible();
  }
  updateVisible() {
    this.visible = !!(this.hasSlotError || this.errormessage && this.errormessage !== "");
  }
};
s([x()], t.prototype, "hasSlotError", 2), s([S({
  slot: "",
  flatten: !0
})], t.prototype, "errorNodes", 2), s([a({
  type: Boolean,
  reflect: !0
})], t.prototype, "visible", 2), s([a({
  type: String
})], t.prototype, "errormessage", 2), s([a({
  type: Boolean
})], t.prototype, "invalid", 2), s([a({
  type: String
})], t.prototype, "fit", 2);
customElements.get("mc-error") || customElements.define("mc-error", t);
export { t as McError };