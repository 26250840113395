import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, signal } from '@angular/core';
import { BulkWorkOrderHeaderEquipmentsComponent } from '../../components/bulk-work-order/bulk-work-order-header/bulk-work-order-header.component';
import { BulkWorkOrderRepairLineItemsComponent } from '../../components/bulk-work-order/bulk-work-order-repair-line-items/bulk-work-order-repair-line-items.component';
import { BulkWorkOrderManhoursComponent } from '../../components/bulk-work-order/bulk-work-order-manhours/bulk-work-order-manhours.component';
import { BulkWorkOrderRemarkComponent } from '../../components/bulk-work-order/bulk-work-order-remark/bulk-work-order-remark.component';
import { utils } from '../../helper/utils';
import { BulkWorkOrderSharedDataService } from '../../services/bulk-work-order-shared-data.service';
import { Shop } from '../../models/shop.model';
import { Mode } from '../../models/mode.model';
import { APICallStatus } from '../../models/api-call-status.mode';
import { Router, RouterModule } from '@angular/router';
import { BulkWorkOrderRepairLineItem } from '../../models/bulk-work-order/bulk-work-order-repair-line-item.model';
import { catchError, combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { BulkWOrkOrderRequest } from '../../models/bulk-work-order/bulk-work-order-request.model';
import { BulkWorkOrderService } from '../../services/bulk-work-order.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AemsHttpError } from '../../models/aems-http-error.model';
import { ErrorComponent } from '../../shared-components/error/error.component';
import '@maersk-global/mds-components-core/mc-toast';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-notification';
import { ApplicationtTextService } from '../../services/application-text.service';
import { NotificationService } from '../../services/notification.service';
import { MaintenanceOrderCreateRequest } from '../../models/maintenance-order-create-request.model';
import { WorkOrderService } from '../../services/work-order.service';
import { CreateWorkOrderTotalCostComponent } from '../../components/create-work-order/total-cost/cwo-total-cost/cwo-total-cost.component';
import { TotalCost } from '../../models/total-cost-model';


@Component({
  selector: 'app-bulk-work-order',
  imports: [BulkWorkOrderHeaderEquipmentsComponent,
    BulkWorkOrderRepairLineItemsComponent,
    BulkWorkOrderManhoursComponent,
    BulkWorkOrderRemarkComponent,
    RouterModule,
    ErrorComponent,
  CreateWorkOrderTotalCostComponent],
  templateUrl: './bulk-work-order.component.html',
  styleUrl: './bulk-work-order.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BulkWorkOrderComponent implements OnDestroy {
  totalManhoursWithoutPrepTime: number = 0;
  manHoursThatQualifyForPrepTime: number = 0;
  isPrepTimeApplicable: boolean = false;
  selectedShop: Shop | null = null;
  selectedMode: Mode | null = null;
  bulkWorkorderSubmitApiCallStatus: APICallStatus = "NOT_STARTED";
  bulkWorkorderSubmitButtonDisabled = signal(true);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private bulkWorkOrderRequest: BulkWOrkOrderRequest = {} as BulkWOrkOrderRequest;
  httpError = signal<AemsHttpError | undefined>(undefined);
  successToastOpen = signal(false);
  costPerWorkorder:TotalCost={} as TotalCost;
  showTotalCost:boolean=false;
  constructor(private bulkWorkorderSharedDataService: BulkWorkOrderSharedDataService,
    private bulkWorkOrderService: BulkWorkOrderService,
    public appTextSvc: ApplicationtTextService,
    private notificationService: NotificationService,
    private router: Router,
    private workOrderService: WorkOrderService) {
    bulkWorkorderSharedDataService.Shop.subscribe(shop => {
      this.selectedShop = shop;
    });
    bulkWorkorderSharedDataService.Mode.subscribe(mode => {
      this.selectedMode = mode;
    });
    bulkWorkorderSharedDataService.BulkWorkOrderRepairLineItems.subscribe(repairLineItems => {
      this.calculateManHoursForRepairLineItems(repairLineItems);
    });

    combineLatest([
      bulkWorkorderSharedDataService.BulkWorkOrderHeader,
      bulkWorkorderSharedDataService.BulkWorkOrderRepairLineItems,
      bulkWorkorderSharedDataService.BulkWorkOrderRemark,
      bulkWorkorderSharedDataService.BulkWorkOrderManHours,
      bulkWorkorderSharedDataService.HeaderFormStatus,
      bulkWorkorderSharedDataService.RepairLineItemFormStatus,
      bulkWorkorderSharedDataService.ManHoursFormStatus])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([header, repairLineItems, remark, manHours, headerStatus, repairLineItemStatus, manHoursStatus]) => {
        if (header.equipments && header.equipments.length > 0 && repairLineItems.length > 0
          && headerStatus === "VALID" && repairLineItemStatus === "VALID" && manHoursStatus === "VALID") {
          this.bulkWorkorderSubmitButtonDisabled.set(false);
          return;
        } else {
          this.bulkWorkorderSubmitButtonDisabled.set(true);
        }
        this.bulkWorkOrderRequest.maintenanceShopCode = header.maintenanceShopCode;
        this.bulkWorkOrderRequest.maintenanceRepairModeCode = header.maintenanceRepairModeCode;
        this.bulkWorkOrderRequest.equipments = header.equipments;
        this.bulkWorkOrderRequest.maintenanceOrderLines = repairLineItems;
        this.bulkWorkOrderRequest.maintenanceOrderRemarkText = remark;
        this.bulkWorkOrderRequest.maintenanceOrderManHours = manHours;
        if(repairLineItems.length>0){
        this.mapCostModel();
        }
      });
  }

  private mapCostModel() {
    const costModel: MaintenanceOrderCreateRequest = {
      maintenanceShopCode: this.bulkWorkOrderRequest.maintenanceShopCode,
      equipmentNumber: this.bulkWorkOrderRequest.equipments[0].equipmentNumber,
      maintenanceRepairModeCode: this.bulkWorkOrderRequest.maintenanceRepairModeCode,
      vendorsReference: "",
      maintenanceOrderRemarks: [],
      reeferContainerCondition: "",
      maintenanceOrderCause: "",
      attachedImages: [],
      maintenanceOrderManHours: this.bulkWorkOrderRequest.maintenanceOrderManHours,
      damagedAt: "",
      maintenanceOrderLines: this.bulkWorkOrderRequest.maintenanceOrderLines.map((lineItem) => ({
        ...lineItem,
        attachedImages: [],
        maintenanceOrderParts: [],
      })),
    }
    this.workOrderService.calculateTotalCost(undefined, costModel).subscribe((response) => {
      this.costPerWorkorder = response;
      this.showTotalCost = true;
    })
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  private calculateManHoursForRepairLineItems(repairLineItems: BulkWorkOrderRepairLineItem[]) {
    this.totalManhoursWithoutPrepTime = 0;
    this.manHoursThatQualifyForPrepTime = 0;
    this.isPrepTimeApplicable = false;
    repairLineItems.forEach((repairLineItem) => {
      let manhours = +(repairLineItem?.manHoursPerPiece ?? 0);
      let pieces = +(repairLineItem?.repairQuantity ?? 0);
      this.totalManhoursWithoutPrepTime = this.totalManhoursWithoutPrepTime + (manhours * pieces);
      if (this.selectedShop?.isPrepTimeEnabled) {
        if (!repairLineItem.excludePreparationHours) {
          this.manHoursThatQualifyForPrepTime = this.manHoursThatQualifyForPrepTime + (manhours * pieces);
          this.isPrepTimeApplicable = true;
        }
      }
    });
    this.totalManhoursWithoutPrepTime = utils.formatNumberToTwoDigitDecimal(this.totalManhoursWithoutPrepTime);
  }

  onSubmitClicked() {
    this.bulkWorkorderSubmitApiCallStatus = "IN_PROGRESS";
    this.bulkWorkOrderService.postBulkWorkOrder(this.bulkWorkOrderRequest)
      .pipe(catchError((error: HttpErrorResponse) => {
        const errorBody = error.error as AemsHttpError;
        this.httpError.set(errorBody);
        this.bulkWorkorderSubmitApiCallStatus = "FAILED";
        throw error;
      }))
      .subscribe((response) => {
        this.httpError.set(undefined);
        this.notificationService.addNotification({
          id: response.id,
          state: 1,
          title: "Create bulk work order",
          associatedTaskCreatedAtDateRelative: utils.getDateInRelativeFormat(new Date(response.createdAt)),
          associateTaskCreatedAtTime: new Date(response.createdAt).toLocaleTimeString(),
          appearance: "info",
          icon: "info-circle",
          daysBefore: 0,
          associatedTaskCreatedAt: new Date(response.createdAt),
          notificationType: "CREATE_WO"
        });
        this.bulkWorkorderSharedDataService.Reset = true;
        this.successToastOpen.set(true);
        this.bulkWorkorderSubmitApiCallStatus = "COMPLETED_SUCCESFULLY";
      })
  }

  onToastrActionClicked() {
    this.router.navigate(['/workorders/create']);
  }
  onSuccessToastClosed() {
    this.successToastOpen.set(false);
  }
}
