import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RemarkForm } from '../../../form-model/cwo-remark.form.model';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';

@Component({
    selector: 'app-bulk-work-order-remark',
    imports: [ReactiveFormsModule],
    templateUrl: './bulk-work-order-remark.component.html',
    styleUrl: './bulk-work-order-remark.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderRemarkComponent {
  remarkForm: FormGroup<RemarkForm>;
  constructor(private formBuilder: FormBuilder, private bulkworkOrderSharedDataService: BulkWorkOrderSharedDataService) {
    this.remarkForm = this.formBuilder.group({
      'remark': this.formBuilder.nonNullable.control<string>('', Validators.required)
    });
    this.remarkForm.valueChanges.subscribe((val) => {
      this.bulkworkOrderSharedDataService.BulkWorkOrderRemark = val.remark??"";
    });
    this.bulkworkOrderSharedDataService.Reset.subscribe(() => {
      this.remarkForm.reset();
    });
  }
}
