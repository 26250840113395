import { Component } from '@angular/core';

@Component({
    selector: 'app-forbidden',
    imports: [],
    templateUrl: './forbidden.component.html',
    styleUrl: './forbidden.component.scss'
})
export class ForbiddenComponent {

}
