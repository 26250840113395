import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, Renderer2, signal } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import "@maersk-global/mds-components-core/mc-icon";
import "@maersk-global/mds-components-core/mc-tooltip";
import '@maersk-global/mds-components-core/mc-drawer';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import { Theme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { AuthHelper } from '../../auth/auth-helper';
import { NotificationService } from '../../services/notification.service';
import { groupBy } from 'lodash-es';
import { NotificationItem } from '../../models/notification-item.model';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
@Component({
    selector: 'app-header',
    imports: [CommonModule,NotificationItemComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Output() menuClicked=new EventEmitter();
  openNotification=signal(false)
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  isSideNavOpen=false;
  loggedInUser=AuthHelper.user;
  notificationItems:Record<string,NotificationItem[]>|undefined;
  constructor(private themeService:ThemeService,private renderer:Renderer2,
     public notificationService:NotificationService) {
    this.themeService.setTheme(this.currentTheme, this.renderer);
    notificationService.checkLongrunningJobsForLoggedinUser();
    notificationService.Notifications.subscribe(x=>{
      x.sort((a,b)=>b.associatedTaskCreatedAt.getTime()-a.associatedTaskCreatedAt.getTime());
      this.notificationItems=groupBy(x,x=>x.daysBefore);
    });
  }
  
  onToggleThemeClicked() {
    this.currentTheme = this.currentTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    this.themeService.setTheme(this.currentTheme, this.renderer);
  }
  onLogoutClicked(){
    AuthHelper.logout();
  }
  onHamburgerClicked(){
    this.isSideNavOpen=!this.isSideNavOpen;
    this.menuClicked.emit();
  }
  onNotificationClosed() {
    this.openNotification.set(false);
  }
  onBellIconClicked(){
    this.openNotification.set(true);
    this.notificationService.NewNotificationsCount=0;
    localStorage.setItem("inProgressOrQueuedCreateJobs", this.notificationService.InProgressJobsCount.toString());
  }
  numericCompare(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return parseInt(a.key, 10) - parseInt(b.key, 10);
  }

}
