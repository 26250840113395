<form [formGroup]="masterFilterForm">

    <div class="pure-g">
        <div class="pure-u-md-24-24">
            @for (formvalue of formList; track formvalue; ;let i= $index){

            @if(formvalue.filterControlType==="TextBox"){
            <div class="pure-u-md-12-24 p-b-16 p-r-16">
                <mc-input name="{{formvalue.filterLable}}" formControlName="{{formvalue.columnIdentity}}"  placeholder="Enter {{formvalue.filterLable}}" ngDefaultControl 
                    fit="medium" label="{{formvalue.filterLable}}">
                </mc-input>
            </div>
            }

            @if (formvalue.filterControlType==="DropDown"){
            <div class="pure-u-md-12-24 p-b-16 p-r-16">
                <mc-select label="{{formvalue.filterLable}}" fit="medium" width="100"
                    placeholder="Select {{formvalue.filterLable}}"  formControlName="{{formvalue.columnIdentity}}" ngDefaultControl>
                    @if(formList[i].dropdownData.values){
                    @for (val of formList[i].dropdownData.values; track val){
                    <mc-option [value]="val">{{val}}</mc-option>
                    }
                }
                </mc-select>
            </div>
                 }
            }
        </div>


        <div class="pure-u-md-24-24 utility-buttons">
            <mc-button label="Apply Filters" slot="primaryAction" variant="filled" appearance="primary" fit="medium"
                (click)="applyFilters()"  class="p-r-16"></mc-button>
            <mc-button label="Cancel"  slot="secondaryAction" appearance="neutral" fit="medium"
                (click)="clearFilters()"  dialogaction="cancel" class="p-r-16"></mc-button>
        </div>





    </div>

</form>