import e from "@babel/runtime/helpers/defineProperty";
var t = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};
function n(e) {
  var t = {
    exports: {}
  };
  return e(t, t.exports), t.exports;
}
var r,
  o,
  i = function (e) {
    return e && e.Math == Math && e;
  },
  a = i("object" == typeof globalThis && globalThis) || i("object" == typeof window && window) || i("object" == typeof self && self) || i("object" == typeof t && t) || function () {
    return this;
  }() || Function("return this")(),
  c = function (e) {
    try {
      return !!e();
    } catch (e) {
      return !0;
    }
  },
  s = !c(function () {
    return 7 != Object.defineProperty({}, 1, {
      get: function () {
        return 7;
      }
    })[1];
  }),
  u = !c(function () {
    var e = function () {}.bind();
    return "function" != typeof e || e.hasOwnProperty("prototype");
  }),
  l = Function.prototype.call,
  f = u ? l.bind(l) : function () {
    return l.apply(l, arguments);
  },
  d = {}.propertyIsEnumerable,
  h = Object.getOwnPropertyDescriptor,
  p = {
    f: h && !d.call({
      1: 2
    }, 1) ? function (e) {
      var t = h(this, e);
      return !!t && t.enumerable;
    } : d
  },
  g = function (e, t) {
    return {
      enumerable: !(1 & e),
      configurable: !(2 & e),
      writable: !(4 & e),
      value: t
    };
  },
  y = Function.prototype,
  v = y.call,
  m = u && y.bind.bind(v, v),
  _ = u ? m : function (e) {
    return function () {
      return v.apply(e, arguments);
    };
  },
  w = _({}.toString),
  b = _("".slice),
  k = function (e) {
    return b(w(e), 8, -1);
  },
  O = Object,
  x = _("".split),
  I = c(function () {
    return !O("z").propertyIsEnumerable(0);
  }) ? function (e) {
    return "String" == k(e) ? x(e, "") : O(e);
  } : O,
  S = function (e) {
    return null == e;
  },
  j = TypeError,
  E = function (e) {
    if (S(e)) throw j("Can't call method on " + e);
    return e;
  },
  T = function (e) {
    return I(E(e));
  },
  P = "object" == typeof document && document.all,
  R = {
    all: P,
    IS_HTMLDDA: void 0 === P && void 0 !== P
  },
  A = R.all,
  C = R.IS_HTMLDDA ? function (e) {
    return "function" == typeof e || e === A;
  } : function (e) {
    return "function" == typeof e;
  },
  M = R.all,
  F = R.IS_HTMLDDA ? function (e) {
    return "object" == typeof e ? null !== e : C(e) || e === M;
  } : function (e) {
    return "object" == typeof e ? null !== e : C(e);
  },
  L = function (e) {
    return C(e) ? e : void 0;
  },
  N = function (e, t) {
    return arguments.length < 2 ? L(a[e]) : a[e] && a[e][t];
  },
  z = _({}.isPrototypeOf),
  D = "undefined" != typeof navigator && String(navigator.userAgent) || "",
  H = a.process,
  U = a.Deno,
  B = H && H.versions || U && U.version,
  W = B && B.v8;
W && (o = (r = W.split("."))[0] > 0 && r[0] < 4 ? 1 : +(r[0] + r[1])), !o && D && (!(r = D.match(/Edge\/(\d+)/)) || r[1] >= 74) && (r = D.match(/Chrome\/(\d+)/)) && (o = +r[1]);
var $ = o,
  G = !!Object.getOwnPropertySymbols && !c(function () {
    var e = Symbol();
    return !String(e) || !(Object(e) instanceof Symbol) || !Symbol.sham && $ && $ < 41;
  }),
  V = G && !Symbol.sham && "symbol" == typeof Symbol.iterator,
  q = Object,
  J = V ? function (e) {
    return "symbol" == typeof e;
  } : function (e) {
    var t = N("Symbol");
    return C(t) && z(t.prototype, q(e));
  },
  Y = String,
  K = function (e) {
    try {
      return Y(e);
    } catch (e) {
      return "Object";
    }
  },
  X = TypeError,
  Q = function (e) {
    if (C(e)) return e;
    throw X(K(e) + " is not a function");
  },
  Z = function (e, t) {
    var n = e[t];
    return S(n) ? void 0 : Q(n);
  },
  ee = TypeError,
  te = Object.defineProperty,
  ne = function (e, t) {
    try {
      te(a, e, {
        value: t,
        configurable: !0,
        writable: !0
      });
    } catch (n) {
      a[e] = t;
    }
    return t;
  },
  re = "__core-js_shared__",
  oe = a[re] || ne(re, {}),
  ie = n(function (e) {
    (e.exports = function (e, t) {
      return oe[e] || (oe[e] = void 0 !== t ? t : {});
    })("versions", []).push({
      version: "3.27.2",
      mode: "global",
      copyright: "© 2014-2023 Denis Pushkarev (zloirock.ru)",
      license: "https://github.com/zloirock/core-js/blob/v3.27.2/LICENSE",
      source: "https://github.com/zloirock/core-js"
    });
  }),
  ae = Object,
  ce = function (e) {
    return ae(E(e));
  },
  se = _({}.hasOwnProperty),
  ue = Object.hasOwn || function (e, t) {
    return se(ce(e), t);
  },
  le = 0,
  fe = Math.random(),
  de = _(1..toString),
  he = function (e) {
    return "Symbol(" + (void 0 === e ? "" : e) + ")_" + de(++le + fe, 36);
  },
  pe = a.Symbol,
  ge = ie("wks"),
  ye = V ? pe.for || pe : pe && pe.withoutSetter || he,
  ve = function (e) {
    return ue(ge, e) || (ge[e] = G && ue(pe, e) ? pe[e] : ye("Symbol." + e)), ge[e];
  },
  me = TypeError,
  _e = ve("toPrimitive"),
  we = function (e, t) {
    if (!F(e) || J(e)) return e;
    var n,
      r = Z(e, _e);
    if (r) {
      if (void 0 === t && (t = "default"), n = f(r, e, t), !F(n) || J(n)) return n;
      throw me("Can't convert object to primitive value");
    }
    return void 0 === t && (t = "number"), function (e, t) {
      var n, r;
      if ("string" === t && C(n = e.toString) && !F(r = f(n, e))) return r;
      if (C(n = e.valueOf) && !F(r = f(n, e))) return r;
      if ("string" !== t && C(n = e.toString) && !F(r = f(n, e))) return r;
      throw ee("Can't convert object to primitive value");
    }(e, t);
  },
  be = function (e) {
    var t = we(e, "string");
    return J(t) ? t : t + "";
  },
  ke = a.document,
  Oe = F(ke) && F(ke.createElement),
  xe = function (e) {
    return Oe ? ke.createElement(e) : {};
  },
  Ie = !s && !c(function () {
    return 7 != Object.defineProperty(xe("div"), "a", {
      get: function () {
        return 7;
      }
    }).a;
  }),
  Se = Object.getOwnPropertyDescriptor,
  je = {
    f: s ? Se : function (e, t) {
      if (e = T(e), t = be(t), Ie) try {
        return Se(e, t);
      } catch (e) {}
      if (ue(e, t)) return g(!f(p.f, e, t), e[t]);
    }
  },
  Ee = s && c(function () {
    return 42 != Object.defineProperty(function () {}, "prototype", {
      value: 42,
      writable: !1
    }).prototype;
  }),
  Te = String,
  Pe = TypeError,
  Re = function (e) {
    if (F(e)) return e;
    throw Pe(Te(e) + " is not an object");
  },
  Ae = TypeError,
  Ce = Object.defineProperty,
  Me = Object.getOwnPropertyDescriptor,
  Fe = "enumerable",
  Le = "configurable",
  Ne = "writable",
  ze = {
    f: s ? Ee ? function (e, t, n) {
      if (Re(e), t = be(t), Re(n), "function" == typeof e && "prototype" === t && "value" in n && Ne in n && !n[Ne]) {
        var r = Me(e, t);
        r && r[Ne] && (e[t] = n.value, n = {
          configurable: Le in n ? n[Le] : r[Le],
          enumerable: Fe in n ? n[Fe] : r[Fe],
          writable: !1
        });
      }
      return Ce(e, t, n);
    } : Ce : function (e, t, n) {
      if (Re(e), t = be(t), Re(n), Ie) try {
        return Ce(e, t, n);
      } catch (e) {}
      if ("get" in n || "set" in n) throw Ae("Accessors not supported");
      return "value" in n && (e[t] = n.value), e;
    }
  },
  De = s ? function (e, t, n) {
    return ze.f(e, t, g(1, n));
  } : function (e, t, n) {
    return e[t] = n, e;
  },
  He = Function.prototype,
  Ue = s && Object.getOwnPropertyDescriptor,
  Be = ue(He, "name"),
  We = {
    EXISTS: Be,
    PROPER: Be && "something" === function () {}.name,
    CONFIGURABLE: Be && (!s || s && Ue(He, "name").configurable)
  },
  $e = _(Function.toString);
C(oe.inspectSource) || (oe.inspectSource = function (e) {
  return $e(e);
});
var Ge,
  Ve,
  qe,
  Je = oe.inspectSource,
  Ye = a.WeakMap,
  Ke = C(Ye) && /native code/.test(String(Ye)),
  Xe = ie("keys"),
  Qe = function (e) {
    return Xe[e] || (Xe[e] = he(e));
  },
  Ze = {},
  et = "Object already initialized",
  tt = a.TypeError,
  nt = a.WeakMap;
if (Ke || oe.state) {
  var rt = oe.state || (oe.state = new nt());
  rt.get = rt.get, rt.has = rt.has, rt.set = rt.set, Ge = function (e, t) {
    if (rt.has(e)) throw tt(et);
    return t.facade = e, rt.set(e, t), t;
  }, Ve = function (e) {
    return rt.get(e) || {};
  }, qe = function (e) {
    return rt.has(e);
  };
} else {
  var ot = Qe("state");
  Ze[ot] = !0, Ge = function (e, t) {
    if (ue(e, ot)) throw tt(et);
    return t.facade = e, De(e, ot, t), t;
  }, Ve = function (e) {
    return ue(e, ot) ? e[ot] : {};
  }, qe = function (e) {
    return ue(e, ot);
  };
}
var it = {
    set: Ge,
    get: Ve,
    has: qe,
    enforce: function (e) {
      return qe(e) ? Ve(e) : Ge(e, {});
    },
    getterFor: function (e) {
      return function (t) {
        var n;
        if (!F(t) || (n = Ve(t)).type !== e) throw tt("Incompatible receiver, " + e + " required");
        return n;
      };
    }
  },
  at = n(function (e) {
    var t = We.CONFIGURABLE,
      n = it.enforce,
      r = it.get,
      o = String,
      i = Object.defineProperty,
      a = _("".slice),
      u = _("".replace),
      l = _([].join),
      f = s && !c(function () {
        return 8 !== i(function () {}, "length", {
          value: 8
        }).length;
      }),
      d = String(String).split("String"),
      h = e.exports = function (e, r, c) {
        "Symbol(" === a(o(r), 0, 7) && (r = "[" + u(o(r), /^Symbol\(([^)]*)\)/, "$1") + "]"), c && c.getter && (r = "get " + r), c && c.setter && (r = "set " + r), (!ue(e, "name") || t && e.name !== r) && (s ? i(e, "name", {
          value: r,
          configurable: !0
        }) : e.name = r), f && c && ue(c, "arity") && e.length !== c.arity && i(e, "length", {
          value: c.arity
        });
        try {
          c && ue(c, "constructor") && c.constructor ? s && i(e, "prototype", {
            writable: !1
          }) : e.prototype && (e.prototype = void 0);
        } catch (e) {}
        var h = n(e);
        return ue(h, "source") || (h.source = l(d, "string" == typeof r ? r : "")), e;
      };
    Function.prototype.toString = h(function () {
      return C(this) && r(this).source || Je(this);
    }, "toString");
  }),
  ct = function (e, t, n, r) {
    r || (r = {});
    var o = r.enumerable,
      i = void 0 !== r.name ? r.name : t;
    if (C(n) && at(n, i, r), r.global) o ? e[t] = n : ne(t, n);else {
      try {
        r.unsafe ? e[t] && (o = !0) : delete e[t];
      } catch (e) {}
      o ? e[t] = n : ze.f(e, t, {
        value: n,
        enumerable: !1,
        configurable: !r.nonConfigurable,
        writable: !r.nonWritable
      });
    }
    return e;
  },
  st = Math.ceil,
  ut = Math.floor,
  lt = Math.trunc || function (e) {
    var t = +e;
    return (t > 0 ? ut : st)(t);
  },
  ft = function (e) {
    var t = +e;
    return t != t || 0 === t ? 0 : lt(t);
  },
  dt = Math.max,
  ht = Math.min,
  pt = Math.min,
  gt = function (e) {
    return e > 0 ? pt(ft(e), 9007199254740991) : 0;
  },
  yt = function (e) {
    return gt(e.length);
  },
  vt = function (e) {
    return function (t, n, r) {
      var o,
        i = T(t),
        a = yt(i),
        c = function (e, t) {
          var n = ft(e);
          return n < 0 ? dt(n + t, 0) : ht(n, t);
        }(r, a);
      if (e && n != n) {
        for (; a > c;) if ((o = i[c++]) != o) return !0;
      } else for (; a > c; c++) if ((e || c in i) && i[c] === n) return e || c || 0;
      return !e && -1;
    };
  },
  mt = {
    includes: vt(!0),
    indexOf: vt(!1)
  }.indexOf,
  _t = _([].push),
  wt = function (e, t) {
    var n,
      r = T(e),
      o = 0,
      i = [];
    for (n in r) !ue(Ze, n) && ue(r, n) && _t(i, n);
    for (; t.length > o;) ue(r, n = t[o++]) && (~mt(i, n) || _t(i, n));
    return i;
  },
  bt = ["constructor", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "toLocaleString", "toString", "valueOf"],
  kt = bt.concat("length", "prototype"),
  Ot = {
    f: Object.getOwnPropertyNames || function (e) {
      return wt(e, kt);
    }
  },
  xt = {
    f: Object.getOwnPropertySymbols
  },
  It = _([].concat),
  St = N("Reflect", "ownKeys") || function (e) {
    var t = Ot.f(Re(e)),
      n = xt.f;
    return n ? It(t, n(e)) : t;
  },
  jt = function (e, t, n) {
    for (var r = St(t), o = ze.f, i = je.f, a = 0; a < r.length; a++) {
      var c = r[a];
      ue(e, c) || n && ue(n, c) || o(e, c, i(t, c));
    }
  },
  Et = /#|\.prototype\./,
  Tt = function (e, t) {
    var n = Rt[Pt(e)];
    return n == Ct || n != At && (C(t) ? c(t) : !!t);
  },
  Pt = Tt.normalize = function (e) {
    return String(e).replace(Et, ".").toLowerCase();
  },
  Rt = Tt.data = {},
  At = Tt.NATIVE = "N",
  Ct = Tt.POLYFILL = "P",
  Mt = Tt,
  Ft = je.f,
  Lt = function (e, t) {
    var n,
      r,
      o,
      i,
      c,
      s = e.target,
      u = e.global,
      l = e.stat;
    if (n = u ? a : l ? a[s] || ne(s, {}) : (a[s] || {}).prototype) for (r in t) {
      if (i = t[r], o = e.dontCallGetSet ? (c = Ft(n, r)) && c.value : n[r], !Mt(u ? r : s + (l ? "." : "#") + r, e.forced) && void 0 !== o) {
        if (typeof i == typeof o) continue;
        jt(i, o);
      }
      (e.sham || o && o.sham) && De(i, "sham", !0), ct(n, r, i, e);
    }
  },
  Nt = "undefined" != typeof process && "process" == k(process),
  zt = String,
  Dt = TypeError,
  Ht = Object.setPrototypeOf || ("__proto__" in {} ? function () {
    var e,
      t = !1,
      n = {};
    try {
      (e = _(Object.getOwnPropertyDescriptor(Object.prototype, "__proto__").set))(n, []), t = n instanceof Array;
    } catch (e) {}
    return function (n, r) {
      return Re(n), function (e) {
        if ("object" == typeof e || C(e)) return e;
        throw Dt("Can't set " + zt(e) + " as a prototype");
      }(r), t ? e(n, r) : n.__proto__ = r, n;
    };
  }() : void 0),
  Ut = ze.f,
  Bt = ve("toStringTag"),
  Wt = function (e, t, n) {
    e && !n && (e = e.prototype), e && !ue(e, Bt) && Ut(e, Bt, {
      configurable: !0,
      value: t
    });
  },
  $t = ve("species"),
  Gt = TypeError,
  Vt = function (e, t) {
    if (z(t, e)) return e;
    throw Gt("Incorrect invocation");
  },
  qt = {};
qt[ve("toStringTag")] = "z";
var Jt = "[object z]" === String(qt),
  Yt = ve("toStringTag"),
  Kt = Object,
  Xt = "Arguments" == k(function () {
    return arguments;
  }()),
  Qt = Jt ? k : function (e) {
    var t, n, r;
    return void 0 === e ? "Undefined" : null === e ? "Null" : "string" == typeof (n = function (e, t) {
      try {
        return e[t];
      } catch (e) {}
    }(t = Kt(e), Yt)) ? n : Xt ? k(t) : "Object" == (r = k(t)) && C(t.callee) ? "Arguments" : r;
  },
  Zt = function () {},
  en = [],
  tn = N("Reflect", "construct"),
  nn = /^\s*(?:class|function)\b/,
  rn = _(nn.exec),
  on = !nn.exec(Zt),
  an = function (e) {
    if (!C(e)) return !1;
    try {
      return tn(Zt, en, e), !0;
    } catch (e) {
      return !1;
    }
  },
  cn = function (e) {
    if (!C(e)) return !1;
    switch (Qt(e)) {
      case "AsyncFunction":
      case "GeneratorFunction":
      case "AsyncGeneratorFunction":
        return !1;
    }
    try {
      return on || !!rn(nn, Je(e));
    } catch (e) {
      return !0;
    }
  };
cn.sham = !0;
var sn,
  un,
  ln,
  fn,
  dn = !tn || c(function () {
    var e;
    return an(an.call) || !an(Object) || !an(function () {
      e = !0;
    }) || e;
  }) ? cn : an,
  hn = TypeError,
  pn = ve("species"),
  gn = function (e, t) {
    var n,
      r = Re(e).constructor;
    return void 0 === r || S(n = Re(r)[pn]) ? t : function (e) {
      if (dn(e)) return e;
      throw hn(K(e) + " is not a constructor");
    }(n);
  },
  yn = Function.prototype,
  vn = yn.apply,
  mn = yn.call,
  _n = "object" == typeof Reflect && Reflect.apply || (u ? mn.bind(vn) : function () {
    return mn.apply(vn, arguments);
  }),
  wn = function (e) {
    if ("Function" === k(e)) return _(e);
  },
  bn = wn(wn.bind),
  kn = function (e, t) {
    return Q(e), void 0 === t ? e : u ? bn(e, t) : function () {
      return e.apply(t, arguments);
    };
  },
  On = N("document", "documentElement"),
  xn = _([].slice),
  In = TypeError,
  Sn = function (e, t) {
    if (e < t) throw In("Not enough arguments");
    return e;
  },
  jn = /(?:ipad|iphone|ipod).*applewebkit/i.test(D),
  En = a.setImmediate,
  Tn = a.clearImmediate,
  Pn = a.process,
  Rn = a.Dispatch,
  An = a.Function,
  Cn = a.MessageChannel,
  Mn = a.String,
  Fn = 0,
  Ln = {},
  Nn = "onreadystatechange";
c(function () {
  sn = a.location;
});
var zn = function (e) {
    if (ue(Ln, e)) {
      var t = Ln[e];
      delete Ln[e], t();
    }
  },
  Dn = function (e) {
    return function () {
      zn(e);
    };
  },
  Hn = function (e) {
    zn(e.data);
  },
  Un = function (e) {
    a.postMessage(Mn(e), sn.protocol + "//" + sn.host);
  };
En && Tn || (En = function (e) {
  Sn(arguments.length, 1);
  var t = C(e) ? e : An(e),
    n = xn(arguments, 1);
  return Ln[++Fn] = function () {
    _n(t, void 0, n);
  }, un(Fn), Fn;
}, Tn = function (e) {
  delete Ln[e];
}, Nt ? un = function (e) {
  Pn.nextTick(Dn(e));
} : Rn && Rn.now ? un = function (e) {
  Rn.now(Dn(e));
} : Cn && !jn ? (fn = (ln = new Cn()).port2, ln.port1.onmessage = Hn, un = kn(fn.postMessage, fn)) : a.addEventListener && C(a.postMessage) && !a.importScripts && sn && "file:" !== sn.protocol && !c(Un) ? (un = Un, a.addEventListener("message", Hn, !1)) : un = Nn in xe("script") ? function (e) {
  On.appendChild(xe("script"))[Nn] = function () {
    On.removeChild(this), zn(e);
  };
} : function (e) {
  setTimeout(Dn(e), 0);
});
var Bn = {
    set: En,
    clear: Tn
  },
  Wn = function () {
    this.head = null, this.tail = null;
  };
Wn.prototype = {
  add: function (e) {
    var t = {
        item: e,
        next: null
      },
      n = this.tail;
    n ? n.next = t : this.head = t, this.tail = t;
  },
  get: function () {
    var e = this.head;
    if (e) return null === (this.head = e.next) && (this.tail = null), e.item;
  }
};
var $n,
  Gn,
  Vn,
  qn,
  Jn,
  Yn = Wn,
  Kn = /ipad|iphone|ipod/i.test(D) && "undefined" != typeof Pebble,
  Xn = /web0s(?!.*chrome)/i.test(D),
  Qn = je.f,
  Zn = Bn.set,
  er = a.MutationObserver || a.WebKitMutationObserver,
  tr = a.document,
  nr = a.process,
  rr = a.Promise,
  or = Qn(a, "queueMicrotask"),
  ir = or && or.value;
if (!ir) {
  var ar = new Yn(),
    cr = function () {
      var e, t;
      for (Nt && (e = nr.domain) && e.exit(); t = ar.get();) try {
        t();
      } catch (e) {
        throw ar.head && $n(), e;
      }
      e && e.enter();
    };
  jn || Nt || Xn || !er || !tr ? !Kn && rr && rr.resolve ? ((qn = rr.resolve(void 0)).constructor = rr, Jn = kn(qn.then, qn), $n = function () {
    Jn(cr);
  }) : Nt ? $n = function () {
    nr.nextTick(cr);
  } : (Zn = kn(Zn, a), $n = function () {
    Zn(cr);
  }) : (Gn = !0, Vn = tr.createTextNode(""), new er(cr).observe(Vn, {
    characterData: !0
  }), $n = function () {
    Vn.data = Gn = !Gn;
  }), ir = function (e) {
    ar.head || $n(), ar.add(e);
  };
}
var sr = ir,
  ur = function (e) {
    try {
      return {
        error: !1,
        value: e()
      };
    } catch (e) {
      return {
        error: !0,
        value: e
      };
    }
  },
  lr = a.Promise,
  fr = "object" == typeof Deno && Deno && "object" == typeof Deno.version,
  dr = !fr && !Nt && "object" == typeof window && "object" == typeof document;
lr && lr.prototype;
var hr,
  pr,
  gr,
  yr = ve("species"),
  vr = !1,
  mr = C(a.PromiseRejectionEvent),
  _r = Mt("Promise", function () {
    var e = Je(lr),
      t = e !== String(lr);
    if (!t && 66 === $) return !0;
    if (!$ || $ < 51 || !/native code/.test(e)) {
      var n = new lr(function (e) {
          e(1);
        }),
        r = function (e) {
          e(function () {}, function () {});
        };
      if ((n.constructor = {})[yr] = r, !(vr = n.then(function () {}) instanceof r)) return !0;
    }
    return !t && (dr || fr) && !mr;
  }),
  wr = {
    CONSTRUCTOR: _r,
    REJECTION_EVENT: mr,
    SUBCLASSING: vr
  },
  br = TypeError,
  kr = function (e) {
    var t, n;
    this.promise = new e(function (e, r) {
      if (void 0 !== t || void 0 !== n) throw br("Bad Promise constructor");
      t = e, n = r;
    }), this.resolve = Q(t), this.reject = Q(n);
  },
  Or = {
    f: function (e) {
      return new kr(e);
    }
  },
  xr = Bn.set,
  Ir = "Promise",
  Sr = wr.CONSTRUCTOR,
  jr = wr.REJECTION_EVENT,
  Er = wr.SUBCLASSING,
  Tr = it.getterFor(Ir),
  Pr = it.set,
  Rr = lr && lr.prototype,
  Ar = lr,
  Cr = Rr,
  Mr = a.TypeError,
  Fr = a.document,
  Lr = a.process,
  Nr = Or.f,
  zr = Nr,
  Dr = !!(Fr && Fr.createEvent && a.dispatchEvent),
  Hr = "unhandledrejection",
  Ur = function (e) {
    var t;
    return !(!F(e) || !C(t = e.then)) && t;
  },
  Br = function (e, t) {
    var n,
      r,
      o,
      i = t.value,
      a = 1 == t.state,
      c = a ? e.ok : e.fail,
      s = e.resolve,
      u = e.reject,
      l = e.domain;
    try {
      c ? (a || (2 === t.rejection && qr(t), t.rejection = 1), !0 === c ? n = i : (l && l.enter(), n = c(i), l && (l.exit(), o = !0)), n === e.promise ? u(Mr("Promise-chain cycle")) : (r = Ur(n)) ? f(r, n, s, u) : s(n)) : u(i);
    } catch (e) {
      l && !o && l.exit(), u(e);
    }
  },
  Wr = function (e, t) {
    e.notified || (e.notified = !0, sr(function () {
      for (var n, r = e.reactions; n = r.get();) Br(n, e);
      e.notified = !1, t && !e.rejection && Gr(e);
    }));
  },
  $r = function (e, t, n) {
    var r, o;
    Dr ? ((r = Fr.createEvent("Event")).promise = t, r.reason = n, r.initEvent(e, !1, !0), a.dispatchEvent(r)) : r = {
      promise: t,
      reason: n
    }, !jr && (o = a["on" + e]) ? o(r) : e === Hr && function (e, t) {
      try {
        1 == arguments.length ? console.error(e) : console.error(e, t);
      } catch (e) {}
    }("Unhandled promise rejection", n);
  },
  Gr = function (e) {
    f(xr, a, function () {
      var t,
        n = e.facade,
        r = e.value;
      if (Vr(e) && (t = ur(function () {
        Nt ? Lr.emit("unhandledRejection", r, n) : $r(Hr, n, r);
      }), e.rejection = Nt || Vr(e) ? 2 : 1, t.error)) throw t.value;
    });
  },
  Vr = function (e) {
    return 1 !== e.rejection && !e.parent;
  },
  qr = function (e) {
    f(xr, a, function () {
      var t = e.facade;
      Nt ? Lr.emit("rejectionHandled", t) : $r("rejectionhandled", t, e.value);
    });
  },
  Jr = function (e, t, n) {
    return function (r) {
      e(t, r, n);
    };
  },
  Yr = function (e, t, n) {
    e.done || (e.done = !0, n && (e = n), e.value = t, e.state = 2, Wr(e, !0));
  },
  Kr = function (e, t, n) {
    if (!e.done) {
      e.done = !0, n && (e = n);
      try {
        if (e.facade === t) throw Mr("Promise can't be resolved itself");
        var r = Ur(t);
        r ? sr(function () {
          var n = {
            done: !1
          };
          try {
            f(r, t, Jr(Kr, n, e), Jr(Yr, n, e));
          } catch (t) {
            Yr(n, t, e);
          }
        }) : (e.value = t, e.state = 1, Wr(e, !1));
      } catch (t) {
        Yr({
          done: !1
        }, t, e);
      }
    }
  };
if (Sr && (Cr = (Ar = function (e) {
  Vt(this, Cr), Q(e), f(hr, this);
  var t = Tr(this);
  try {
    e(Jr(Kr, t), Jr(Yr, t));
  } catch (e) {
    Yr(t, e);
  }
}).prototype, (hr = function (e) {
  Pr(this, {
    type: Ir,
    done: !1,
    notified: !1,
    parent: !1,
    reactions: new Yn(),
    rejection: !1,
    state: 0,
    value: void 0
  });
}).prototype = ct(Cr, "then", function (e, t) {
  var n = Tr(this),
    r = Nr(gn(this, Ar));
  return n.parent = !0, r.ok = !C(e) || e, r.fail = C(t) && t, r.domain = Nt ? Lr.domain : void 0, 0 == n.state ? n.reactions.add(r) : sr(function () {
    Br(r, n);
  }), r.promise;
}), pr = function () {
  var e = new hr(),
    t = Tr(e);
  this.promise = e, this.resolve = Jr(Kr, t), this.reject = Jr(Yr, t);
}, Or.f = Nr = function (e) {
  return e === Ar || undefined === e ? new pr(e) : zr(e);
}, C(lr) && Rr !== Object.prototype)) {
  gr = Rr.then, Er || ct(Rr, "then", function (e, t) {
    var n = this;
    return new Ar(function (e, t) {
      f(gr, n, e, t);
    }).then(e, t);
  }, {
    unsafe: !0
  });
  try {
    delete Rr.constructor;
  } catch (e) {}
  Ht && Ht(Rr, Cr);
}
Lt({
  global: !0,
  constructor: !0,
  wrap: !0,
  forced: Sr
}, {
  Promise: Ar
}), Wt(Ar, Ir, !1), function (e) {
  var t = N(e),
    n = ze.f;
  s && t && !t[$t] && n(t, $t, {
    configurable: !0,
    get: function () {
      return this;
    }
  });
}(Ir);
var Xr = {},
  Qr = ve("iterator"),
  Zr = Array.prototype,
  eo = ve("iterator"),
  to = function (e) {
    if (!S(e)) return Z(e, eo) || Z(e, "@@iterator") || Xr[Qt(e)];
  },
  no = TypeError,
  ro = function (e, t, n) {
    var r, o;
    Re(e);
    try {
      if (!(r = Z(e, "return"))) {
        if ("throw" === t) throw n;
        return n;
      }
      r = f(r, e);
    } catch (e) {
      o = !0, r = e;
    }
    if ("throw" === t) throw n;
    if (o) throw r;
    return Re(r), n;
  },
  oo = TypeError,
  io = function (e, t) {
    this.stopped = e, this.result = t;
  },
  ao = io.prototype,
  co = function (e, t, n) {
    var r,
      o,
      i,
      a,
      c,
      s,
      u,
      l,
      d = n && n.that,
      h = !(!n || !n.AS_ENTRIES),
      p = !(!n || !n.IS_RECORD),
      g = !(!n || !n.IS_ITERATOR),
      y = !(!n || !n.INTERRUPTED),
      v = kn(t, d),
      m = function (e) {
        return r && ro(r, "normal", e), new io(!0, e);
      },
      _ = function (e) {
        return h ? (Re(e), y ? v(e[0], e[1], m) : v(e[0], e[1])) : y ? v(e, m) : v(e);
      };
    if (p) r = e.iterator;else if (g) r = e;else {
      if (!(o = to(e))) throw oo(K(e) + " is not iterable");
      if (void 0 !== (l = o) && (Xr.Array === l || Zr[Qr] === l)) {
        for (i = 0, a = yt(e); a > i; i++) if ((c = _(e[i])) && z(ao, c)) return c;
        return new io(!1);
      }
      r = function (e, t) {
        var n = arguments.length < 2 ? to(e) : t;
        if (Q(n)) return Re(f(n, e));
        throw no(K(e) + " is not iterable");
      }(e, o);
    }
    for (s = p ? e.next : r.next; !(u = f(s, r)).done;) {
      try {
        c = _(u.value);
      } catch (e) {
        ro(r, "throw", e);
      }
      if ("object" == typeof c && c && z(ao, c)) return c;
    }
    return new io(!1);
  },
  so = ve("iterator"),
  uo = !1;
try {
  var lo = 0,
    fo = {
      next: function () {
        return {
          done: !!lo++
        };
      },
      return: function () {
        uo = !0;
      }
    };
  fo[so] = function () {
    return this;
  }, Array.from(fo, function () {
    throw 2;
  });
} catch (e) {}
var ho = wr.CONSTRUCTOR || !function (e, t) {
  if (!t && !uo) return !1;
  var n = !1;
  try {
    var r = {};
    r[so] = function () {
      return {
        next: function () {
          return {
            done: n = !0
          };
        }
      };
    }, e(r);
  } catch (e) {}
  return n;
}(function (e) {
  lr.all(e).then(void 0, function () {});
});
Lt({
  target: "Promise",
  stat: !0,
  forced: ho
}, {
  all: function (e) {
    var t = this,
      n = Or.f(t),
      r = n.resolve,
      o = n.reject,
      i = ur(function () {
        var n = Q(t.resolve),
          i = [],
          a = 0,
          c = 1;
        co(e, function (e) {
          var s = a++,
            u = !1;
          c++, f(n, t, e).then(function (e) {
            u || (u = !0, i[s] = e, --c || r(i));
          }, o);
        }), --c || r(i);
      });
    return i.error && o(i.value), n.promise;
  }
});
var po = wr.CONSTRUCTOR,
  go = lr && lr.prototype;
if (Lt({
  target: "Promise",
  proto: !0,
  forced: po,
  real: !0
}, {
  catch: function (e) {
    return this.then(void 0, e);
  }
}), C(lr)) {
  var yo = N("Promise").prototype.catch;
  go.catch !== yo && ct(go, "catch", yo, {
    unsafe: !0
  });
}
Lt({
  target: "Promise",
  stat: !0,
  forced: ho
}, {
  race: function (e) {
    var t = this,
      n = Or.f(t),
      r = n.reject,
      o = ur(function () {
        var o = Q(t.resolve);
        co(e, function (e) {
          f(o, t, e).then(n.resolve, r);
        });
      });
    return o.error && r(o.value), n.promise;
  }
}), Lt({
  target: "Promise",
  stat: !0,
  forced: wr.CONSTRUCTOR
}, {
  reject: function (e) {
    var t = Or.f(this);
    return f(t.reject, void 0, e), t.promise;
  }
});
var vo = function (e, t) {
    if (Re(e), F(t) && t.constructor === e) return t;
    var n = Or.f(e);
    return (0, n.resolve)(t), n.promise;
  },
  mo = wr.CONSTRUCTOR;
N("Promise"), Lt({
  target: "Promise",
  stat: !0,
  forced: mo
}, {
  resolve: function (e) {
    return vo(this, e);
  }
});
var _o = lr && lr.prototype,
  wo = !!lr && c(function () {
    _o.finally.call({
      then: function () {}
    }, function () {});
  });
if (Lt({
  target: "Promise",
  proto: !0,
  real: !0,
  forced: wo
}, {
  finally: function (e) {
    var t = gn(this, N("Promise")),
      n = C(e);
    return this.then(n ? function (n) {
      return vo(t, e()).then(function () {
        return n;
      });
    } : e, n ? function (n) {
      return vo(t, e()).then(function () {
        throw n;
      });
    } : e);
  }
}), C(lr)) {
  var bo = N("Promise").prototype.finally;
  _o.finally !== bo && ct(_o, "finally", bo, {
    unsafe: !0
  });
}
var ko,
  Oo = function (e) {
    return {
      iterator: e,
      next: Q(Re(e).next)
    };
  },
  xo = Object.keys || function (e) {
    return wt(e, bt);
  },
  Io = s && !Ee ? Object.defineProperties : function (e, t) {
    Re(e);
    for (var n, r = T(t), o = xo(t), i = o.length, a = 0; i > a;) ze.f(e, n = o[a++], r[n]);
    return e;
  },
  So = {
    f: Io
  },
  jo = "prototype",
  Eo = "script",
  To = Qe("IE_PROTO"),
  Po = function () {},
  Ro = function (e) {
    return "<" + Eo + ">" + e + "</" + Eo + ">";
  },
  Ao = function (e) {
    e.write(Ro("")), e.close();
    var t = e.parentWindow.Object;
    return e = null, t;
  },
  Co = function () {
    try {
      ko = new ActiveXObject("htmlfile");
    } catch (e) {}
    var e, t, n;
    Co = "undefined" != typeof document ? document.domain && ko ? Ao(ko) : (t = xe("iframe"), n = "java" + Eo + ":", t.style.display = "none", On.appendChild(t), t.src = String(n), (e = t.contentWindow.document).open(), e.write(Ro("document.F=Object")), e.close(), e.F) : Ao(ko);
    for (var r = bt.length; r--;) delete Co[jo][bt[r]];
    return Co();
  };
Ze[To] = !0;
var Mo,
  Fo,
  Lo = Object.create || function (e, t) {
    var n;
    return null !== e ? (Po[jo] = Re(e), n = new Po(), Po[jo] = null, n[To] = e) : n = Co(), void 0 === t ? n : So.f(n, t);
  },
  No = function (e, t, n) {
    for (var r in t) ct(e, r, t[r], n);
    return e;
  },
  zo = !c(function () {
    function e() {}
    return e.prototype.constructor = null, Object.getPrototypeOf(new e()) !== e.prototype;
  }),
  Do = Qe("IE_PROTO"),
  Ho = Object,
  Uo = Ho.prototype,
  Bo = zo ? Ho.getPrototypeOf : function (e) {
    var t = ce(e);
    if (ue(t, Do)) return t[Do];
    var n = t.constructor;
    return C(n) && t instanceof n ? n.prototype : t instanceof Ho ? Uo : null;
  },
  Wo = "USE_FUNCTION_CONSTRUCTOR",
  $o = ve("asyncIterator"),
  Go = a.AsyncIterator,
  Vo = oe.AsyncIteratorPrototype;
if (Vo) Mo = Vo;else if (C(Go)) Mo = Go.prototype;else if (oe[Wo] || a[Wo]) try {
  Fo = Bo(Bo(Bo(Function("return async function*(){}()")()))), Bo(Fo) === Object.prototype && (Mo = Fo);
} catch (e) {}
Mo || (Mo = {}), C(Mo[$o]) || ct(Mo, $o, function () {
  return this;
});
var qo = Mo,
  Jo = function (e, t) {
    return {
      value: e,
      done: t
    };
  },
  Yo = N("Promise"),
  Ko = ve("toStringTag"),
  Xo = "AsyncIteratorHelper",
  Qo = "WrapForValidAsyncIterator",
  Zo = it.set,
  ei = function (e) {
    var t = !e,
      n = it.getterFor(e ? Qo : Xo),
      r = function (e) {
        var r = ur(function () {
            return n(e);
          }),
          o = r.error,
          i = r.value;
        return o || t && i.done ? {
          exit: !0,
          value: o ? Yo.reject(i) : Yo.resolve(Jo(void 0, !0))
        } : {
          exit: !1,
          value: i
        };
      },
      o = function (e, n) {
        var r = function () {
          var r = n();
          if (t) {
            e.awaiting = r;
            var o = function () {
              e.awaiting === r && (e.awaiting = null);
            };
            r.then(o, o);
          }
          return r;
        };
        return e.awaiting ? e.awaiting = e.awaiting.then(r, r) : r();
      };
    return No(Lo(qo), {
      next: function () {
        var e = r(this),
          t = e.exit,
          n = e.value;
        return t ? n : o(n, function () {
          var e = ur(function () {
              return Re(n.nextHandler(Yo));
            }),
            t = e.error,
            r = e.value;
          return t && (n.done = !0), t ? Yo.reject(r) : Yo.resolve(r);
        });
      },
      return: function () {
        var t = r(this),
          n = t.exit,
          i = t.value;
        return n ? i : o(i, function () {
          i.done = !0;
          var t,
            n,
            r = i.iterator,
            o = ur(function () {
              if (i.inner) try {
                ro(i.inner.iterator, "normal");
              } catch (e) {
                return ro(r, "throw", e);
              }
              return Z(r, "return");
            });
          return t = n = o.value, o.error ? Yo.reject(n) : void 0 === t ? Yo.resolve(Jo(void 0, !0)) : (n = (o = ur(function () {
            return f(t, r);
          })).value, o.error ? Yo.reject(n) : e ? Yo.resolve(n) : Yo.resolve(n).then(function (e) {
            return Re(e), Jo(void 0, !0);
          }));
        });
      }
    });
  },
  ti = ei(!0),
  ni = ei(!1);
De(ni, Ko, "Async Iterator Helper");
var ri = function (e, t) {
    var n = function (n, r) {
      r ? (r.iterator = n.iterator, r.next = n.next) : r = n, r.type = t ? Qo : Xo, r.nextHandler = e, r.counter = 0, r.done = !1, r.awaiting = null, Zo(this, r);
    };
    return n.prototype = t ? ti : ni, n;
  },
  oi = function (e, t, n, r) {
    try {
      var o = Z(e, "return");
      if (o) return N("Promise").resolve(f(o, e)).then(function () {
        t(n);
      }, function (e) {
        r(e);
      });
    } catch (e) {
      return r(e);
    }
    t(n);
  },
  ii = ri(function (e) {
    var t = this,
      n = t.iterator,
      r = t.predicate;
    return new e(function (o, i) {
      var a = function (e) {
          t.done = !0, i(e);
        },
        c = function (e) {
          oi(n, a, e, a);
        },
        s = function () {
          try {
            e.resolve(Re(f(t.next, n))).then(function (n) {
              try {
                if (Re(n).done) t.done = !0, o(Jo(void 0, !0));else {
                  var i = n.value;
                  try {
                    var u = r(i, t.counter++),
                      l = function (e) {
                        e ? o(Jo(i, !1)) : s();
                      };
                    F(u) ? e.resolve(u).then(l, c) : l(u);
                  } catch (e) {
                    c(e);
                  }
                }
              } catch (e) {
                a(e);
              }
            }, a);
          } catch (e) {
            a(e);
          }
        };
      s();
    });
  });
Lt({
  target: "AsyncIterator",
  proto: !0,
  real: !0
}, {
  filter: function (e) {
    return new ii(Oo(this), {
      predicate: Q(e)
    });
  }
});
var ai,
  ci,
  si,
  ui = ve("iterator"),
  li = !1;
[].keys && ("next" in (si = [].keys()) ? (ci = Bo(Bo(si))) !== Object.prototype && (ai = ci) : li = !0);
var fi = !F(ai) || c(function () {
  var e = {};
  return ai[ui].call(e) !== e;
});
fi && (ai = {}), C(ai[ui]) || ct(ai, ui, function () {
  return this;
});
var di = {
    IteratorPrototype: ai,
    BUGGY_SAFARI_ITERATORS: li
  },
  hi = di.IteratorPrototype,
  pi = ve("toStringTag"),
  gi = a.Iterator,
  yi = !C(gi) || gi.prototype !== hi || !c(function () {
    gi({});
  }),
  vi = function () {
    Vt(this, hi);
  };
ue(hi, pi) || De(hi, pi, "Iterator"), !yi && ue(hi, "constructor") && hi.constructor !== Object || De(hi, "constructor", vi), vi.prototype = hi, Lt({
  global: !0,
  constructor: !0,
  forced: yi
}, {
  Iterator: vi
});
var mi = di.IteratorPrototype,
  _i = ve("toStringTag"),
  wi = "IteratorHelper",
  bi = "WrapForValidIterator",
  ki = it.set,
  Oi = function (e) {
    var t = it.getterFor(e ? bi : wi);
    return No(Lo(mi), {
      next: function () {
        var n = t(this);
        if (e) return n.nextHandler();
        try {
          var r = n.done ? void 0 : n.nextHandler();
          return Jo(r, n.done);
        } catch (e) {
          throw n.done = !0, e;
        }
      },
      return: function () {
        var n = t(this),
          r = n.iterator;
        if (n.done = !0, e) {
          var o = Z(r, "return");
          return o ? f(o, r) : Jo(void 0, !0);
        }
        if (n.inner) try {
          ro(n.inner.iterator, "normal");
        } catch (e) {
          return ro(r, "throw", e);
        }
        return ro(r, "normal"), Jo(void 0, !0);
      }
    });
  },
  xi = Oi(!0),
  Ii = Oi(!1);
De(Ii, _i, "Iterator Helper");
var Si = function (e, t) {
    var n = function (n, r) {
      r ? (r.iterator = n.iterator, r.next = n.next) : r = n, r.type = t ? bi : wi, r.nextHandler = e, r.counter = 0, r.done = !1, ki(this, r);
    };
    return n.prototype = t ? xi : Ii, n;
  },
  ji = function (e, t, n, r) {
    try {
      return r ? t(Re(n)[0], n[1]) : t(n);
    } catch (t) {
      ro(e, "throw", t);
    }
  },
  Ei = Si(function () {
    for (var e, t, n = this.iterator, r = this.predicate, o = this.next;;) {
      if (e = Re(f(o, n)), this.done = !!e.done) return;
      if (t = e.value, ji(n, r, [t, this.counter++], !0)) return t;
    }
  });
Lt({
  target: "Iterator",
  proto: !0,
  real: !0
}, {
  filter: function (e) {
    return new Ei(Oo(this), {
      predicate: Q(e)
    });
  }
});
var Ti = TypeError,
  Pi = function (e) {
    var t = 0 == e,
      n = 1 == e,
      r = 2 == e,
      o = 3 == e;
    return function (e, i, a) {
      var c = Oo(e),
        s = N("Promise"),
        u = c.iterator,
        l = c.next,
        d = 0,
        h = void 0 !== i;
      return !h && t || Q(i), new s(function (e, c) {
        var p = function (e) {
            oi(u, c, e, c);
          },
          g = function () {
            try {
              if (h) try {
                !function (e) {
                  if (e > 9007199254740991) throw Ti("Maximum allowed index exceeded");
                }(d);
              } catch (e) {
                p(e);
              }
              s.resolve(Re(f(l, u))).then(function (l) {
                try {
                  if (Re(l).done) t ? (a.length = d, e(a)) : e(!o && (r || void 0));else {
                    var f = l.value;
                    try {
                      if (h) {
                        var y = i(f, d),
                          v = function (i) {
                            if (n) g();else if (r) i ? g() : oi(u, e, !1, c);else if (t) try {
                              a[d++] = i, g();
                            } catch (e) {
                              p(e);
                            } else i ? oi(u, e, o || f, c) : g();
                          };
                        F(y) ? s.resolve(y).then(v, p) : v(y);
                      } else a[d++] = f, g();
                    } catch (e) {
                      p(e);
                    }
                  }
                } catch (e) {
                  c(e);
                }
              }, c);
            } catch (e) {
              c(e);
            }
          };
        g();
      });
    };
  },
  Ri = {
    toArray: Pi(0),
    forEach: Pi(1),
    every: Pi(2),
    some: Pi(3),
    find: Pi(4)
  }.forEach;
Lt({
  target: "AsyncIterator",
  proto: !0,
  real: !0
}, {
  forEach: function (e) {
    return Ri(this, e);
  }
}), Lt({
  target: "Iterator",
  proto: !0,
  real: !0
}, {
  forEach: function (e) {
    var t = Oo(this),
      n = 0;
    Q(e), co(t, function (t) {
      e(t, n++);
    }, {
      IS_RECORD: !0
    });
  }
});
var Ai = ri(function (e) {
  var t = this,
    n = t.iterator,
    r = t.mapper;
  return new e(function (o, i) {
    var a = function (e) {
        t.done = !0, i(e);
      },
      c = function (e) {
        oi(n, a, e, a);
      };
    e.resolve(Re(f(t.next, n))).then(function (n) {
      try {
        if (Re(n).done) t.done = !0, o(Jo(void 0, !0));else {
          var i = n.value;
          try {
            var s = r(i, t.counter++),
              u = function (e) {
                o(Jo(e, !1));
              };
            F(s) ? e.resolve(s).then(u, c) : u(s);
          } catch (e) {
            c(e);
          }
        }
      } catch (e) {
        a(e);
      }
    }, a);
  });
});
Lt({
  target: "AsyncIterator",
  proto: !0,
  real: !0
}, {
  map: function (e) {
    return new Ai(Oo(this), {
      mapper: Q(e)
    });
  }
});
var Ci = Si(function () {
  var e = this.iterator,
    t = Re(f(this.next, e));
  if (!(this.done = !!t.done)) return ji(e, this.mapper, [t.value, this.counter++], !0);
});
Lt({
  target: "Iterator",
  proto: !0,
  real: !0
}, {
  map: function (e) {
    return new Ci(Oo(this), {
      mapper: Q(e)
    });
  }
});
var Mi,
  Fi,
  Li = String,
  Ni = function (e) {
    if ("Symbol" === Qt(e)) throw TypeError("Cannot convert a Symbol value to a string");
    return Li(e);
  },
  zi = function () {
    var e = Re(this),
      t = "";
    return e.hasIndices && (t += "d"), e.global && (t += "g"), e.ignoreCase && (t += "i"), e.multiline && (t += "m"), e.dotAll && (t += "s"), e.unicode && (t += "u"), e.unicodeSets && (t += "v"), e.sticky && (t += "y"), t;
  },
  Di = a.RegExp,
  Hi = c(function () {
    var e = Di("a", "y");
    return e.lastIndex = 2, null != e.exec("abcd");
  }),
  Ui = Hi || c(function () {
    return !Di("a", "y").sticky;
  }),
  Bi = {
    BROKEN_CARET: Hi || c(function () {
      var e = Di("^r", "gy");
      return e.lastIndex = 2, null != e.exec("str");
    }),
    MISSED_STICKY: Ui,
    UNSUPPORTED_Y: Hi
  },
  Wi = a.RegExp,
  $i = c(function () {
    var e = Wi(".", "s");
    return !(e.dotAll && e.exec("\n") && "s" === e.flags);
  }),
  Gi = a.RegExp,
  Vi = c(function () {
    var e = Gi("(?<a>b)", "g");
    return "b" !== e.exec("b").groups.a || "bc" !== "b".replace(e, "$<a>c");
  }),
  qi = it.get,
  Ji = ie("native-string-replace", String.prototype.replace),
  Yi = RegExp.prototype.exec,
  Ki = Yi,
  Xi = _("".charAt),
  Qi = _("".indexOf),
  Zi = _("".replace),
  ea = _("".slice),
  ta = (Fi = /b*/g, f(Yi, Mi = /a/, "a"), f(Yi, Fi, "a"), 0 !== Mi.lastIndex || 0 !== Fi.lastIndex),
  na = Bi.BROKEN_CARET,
  ra = void 0 !== /()??/.exec("")[1];
(ta || ra || na || $i || Vi) && (Ki = function (e) {
  var t,
    n,
    r,
    o,
    i,
    a,
    c,
    s = this,
    u = qi(s),
    l = Ni(e),
    d = u.raw;
  if (d) return d.lastIndex = s.lastIndex, t = f(Ki, d, l), s.lastIndex = d.lastIndex, t;
  var h = u.groups,
    p = na && s.sticky,
    g = f(zi, s),
    y = s.source,
    v = 0,
    m = l;
  if (p && (g = Zi(g, "y", ""), -1 === Qi(g, "g") && (g += "g"), m = ea(l, s.lastIndex), s.lastIndex > 0 && (!s.multiline || s.multiline && "\n" !== Xi(l, s.lastIndex - 1)) && (y = "(?: " + y + ")", m = " " + m, v++), n = new RegExp("^(?:" + y + ")", g)), ra && (n = new RegExp("^" + y + "$(?!\\s)", g)), ta && (r = s.lastIndex), o = f(Yi, p ? n : s, m), p ? o ? (o.input = ea(o.input, v), o[0] = ea(o[0], v), o.index = s.lastIndex, s.lastIndex += o[0].length) : s.lastIndex = 0 : ta && o && (s.lastIndex = s.global ? o.index + o[0].length : r), ra && o && o.length > 1 && f(Ji, o[0], n, function () {
    for (i = 1; i < arguments.length - 2; i++) void 0 === arguments[i] && (o[i] = void 0);
  }), o && h) for (o.groups = a = Lo(null), i = 0; i < h.length; i++) a[(c = h[i])[0]] = o[c[1]];
  return o;
});
var oa = Ki;
Lt({
  target: "RegExp",
  proto: !0,
  forced: /./.exec !== oa
}, {
  exec: oa
});
var ia = ve("species"),
  aa = RegExp.prototype,
  ca = function (e, t, n, r) {
    var o = ve(e),
      i = !c(function () {
        var t = {};
        return t[o] = function () {
          return 7;
        }, 7 != ""[e](t);
      }),
      a = i && !c(function () {
        var t = !1,
          n = /a/;
        return "split" === e && ((n = {}).constructor = {}, n.constructor[ia] = function () {
          return n;
        }, n.flags = "", n[o] = /./[o]), n.exec = function () {
          return t = !0, null;
        }, n[o](""), !t;
      });
    if (!i || !a || n) {
      var s = wn(/./[o]),
        u = t(o, ""[e], function (e, t, n, r, o) {
          var a = wn(e),
            c = t.exec;
          return c === oa || c === aa.exec ? i && !o ? {
            done: !0,
            value: s(t, n, r)
          } : {
            done: !0,
            value: a(n, t, r)
          } : {
            done: !1
          };
        });
      ct(String.prototype, e, u[0]), ct(aa, o, u[1]);
    }
    r && De(aa[o], "sham", !0);
  },
  sa = _("".charAt),
  ua = _("".charCodeAt),
  la = _("".slice),
  fa = function (e) {
    return function (t, n) {
      var r,
        o,
        i = Ni(E(t)),
        a = ft(n),
        c = i.length;
      return a < 0 || a >= c ? e ? "" : void 0 : (r = ua(i, a)) < 55296 || r > 56319 || a + 1 === c || (o = ua(i, a + 1)) < 56320 || o > 57343 ? e ? sa(i, a) : r : e ? la(i, a, a + 2) : o - 56320 + (r - 55296 << 10) + 65536;
    };
  },
  da = {
    codeAt: fa(!1),
    charAt: fa(!0)
  }.charAt,
  ha = function (e, t, n) {
    return t + (n ? da(e, t).length : 1);
  },
  pa = Math.floor,
  ga = _("".charAt),
  ya = _("".replace),
  va = _("".slice),
  ma = /\$([$&'`]|\d{1,2}|<[^>]*>)/g,
  _a = /\$([$&'`]|\d{1,2})/g,
  wa = function (e, t, n, r, o, i) {
    var a = n + e.length,
      c = r.length,
      s = _a;
    return void 0 !== o && (o = ce(o), s = ma), ya(i, s, function (i, s) {
      var u;
      switch (ga(s, 0)) {
        case "$":
          return "$";
        case "&":
          return e;
        case "`":
          return va(t, 0, n);
        case "'":
          return va(t, a);
        case "<":
          u = o[va(s, 1, -1)];
          break;
        default:
          var l = +s;
          if (0 === l) return i;
          if (l > c) {
            var f = pa(l / 10);
            return 0 === f ? i : f <= c ? void 0 === r[f - 1] ? ga(s, 1) : r[f - 1] + ga(s, 1) : i;
          }
          u = r[l - 1];
      }
      return void 0 === u ? "" : u;
    });
  },
  ba = TypeError,
  ka = function (e, t) {
    var n = e.exec;
    if (C(n)) {
      var r = f(n, e, t);
      return null !== r && Re(r), r;
    }
    if ("RegExp" === k(e)) return f(oa, e, t);
    throw ba("RegExp#exec called on incompatible receiver");
  },
  Oa = ve("replace"),
  xa = Math.max,
  Ia = Math.min,
  Sa = _([].concat),
  ja = _([].push),
  Ea = _("".indexOf),
  Ta = _("".slice),
  Pa = "$0" === "a".replace(/./, "$0"),
  Ra = !!/./[Oa] && "" === /./[Oa]("a", "$0");
ca("replace", function (e, t, n) {
  var r = Ra ? "$" : "$0";
  return [function (e, n) {
    var r = E(this),
      o = S(e) ? void 0 : Z(e, Oa);
    return o ? f(o, e, r, n) : f(t, Ni(r), e, n);
  }, function (e, o) {
    var i = Re(this),
      a = Ni(e);
    if ("string" == typeof o && -1 === Ea(o, r) && -1 === Ea(o, "$<")) {
      var c = n(t, i, a, o);
      if (c.done) return c.value;
    }
    var s = C(o);
    s || (o = Ni(o));
    var u = i.global;
    if (u) {
      var l = i.unicode;
      i.lastIndex = 0;
    }
    for (var f = [];;) {
      var d = ka(i, a);
      if (null === d) break;
      if (ja(f, d), !u) break;
      "" === Ni(d[0]) && (i.lastIndex = ha(a, gt(i.lastIndex), l));
    }
    for (var h, p = "", g = 0, y = 0; y < f.length; y++) {
      for (var v = Ni((d = f[y])[0]), m = xa(Ia(ft(d.index), a.length), 0), _ = [], w = 1; w < d.length; w++) ja(_, void 0 === (h = d[w]) ? h : String(h));
      var b = d.groups;
      if (s) {
        var k = Sa([v], _, m, a);
        void 0 !== b && ja(k, b);
        var O = Ni(_n(o, void 0, k));
      } else O = wa(v, a, m, _, b, o);
      m >= g && (p += Ta(a, g, m) + O, g = m + v.length);
    }
    return p + Ta(a, g);
  }];
}, !!c(function () {
  var e = /./;
  return e.exec = function () {
    var e = [];
    return e.groups = {
      a: "7"
    }, e;
  }, "7" !== "".replace(e, "$<a>");
}) || !Pa || Ra);
var Aa = RegExp.prototype,
  Ca = We.PROPER,
  Ma = "toString",
  Fa = RegExp.prototype[Ma],
  La = c(function () {
    return "/a/b" != Fa.call({
      source: "a",
      flags: "b"
    });
  }),
  Na = Ca && Fa.name != Ma;
(La || Na) && ct(RegExp.prototype, Ma, function () {
  var e = Re(this),
    t = Ni(e.source),
    n = Ni(function (e) {
      var t = e.flags;
      return void 0 !== t || "flags" in Aa || ue(e, "flags") || !z(Aa, e) ? t : f(zi, e);
    }(e));
  return "/" + t + "/" + n;
}, {
  unsafe: !0
});
var za = n(function (e, t) {
    var n;
    e.exports = (n = n || function (e, t) {
      var n = Object.create || function () {
          function e() {}
          return function (t) {
            var n;
            return e.prototype = t, n = new e(), e.prototype = null, n;
          };
        }(),
        r = {},
        o = r.lib = {},
        i = o.Base = {
          extend: function (e) {
            var t = n(this);
            return e && t.mixIn(e), t.hasOwnProperty("init") && this.init !== t.init || (t.init = function () {
              t.$super.init.apply(this, arguments);
            }), t.init.prototype = t, t.$super = this, t;
          },
          create: function () {
            var e = this.extend();
            return e.init.apply(e, arguments), e;
          },
          init: function () {},
          mixIn: function (e) {
            for (var t in e) e.hasOwnProperty(t) && (this[t] = e[t]);
            e.hasOwnProperty("toString") && (this.toString = e.toString);
          },
          clone: function () {
            return this.init.prototype.extend(this);
          }
        },
        a = o.WordArray = i.extend({
          init: function (e, n) {
            e = this.words = e || [], this.sigBytes = n != t ? n : 4 * e.length;
          },
          toString: function (e) {
            return (e || s).stringify(this);
          },
          concat: function (e) {
            var t = this.words,
              n = e.words,
              r = this.sigBytes,
              o = e.sigBytes;
            if (this.clamp(), r % 4) for (var i = 0; i < o; i++) {
              var a = n[i >>> 2] >>> 24 - i % 4 * 8 & 255;
              t[r + i >>> 2] |= a << 24 - (r + i) % 4 * 8;
            } else for (i = 0; i < o; i += 4) t[r + i >>> 2] = n[i >>> 2];
            return this.sigBytes += o, this;
          },
          clamp: function () {
            var t = this.words,
              n = this.sigBytes;
            t[n >>> 2] &= 4294967295 << 32 - n % 4 * 8, t.length = e.ceil(n / 4);
          },
          clone: function () {
            var e = i.clone.call(this);
            return e.words = this.words.slice(0), e;
          },
          random: function (t) {
            for (var n, r = [], o = function (t) {
                var n = 987654321,
                  r = 4294967295;
                return function () {
                  var o = ((n = 36969 * (65535 & n) + (n >> 16) & r) << 16) + (t = 18e3 * (65535 & t) + (t >> 16) & r) & r;
                  return o /= 4294967296, (o += .5) * (e.random() > .5 ? 1 : -1);
                };
              }, i = 0; i < t; i += 4) {
              var c = o(4294967296 * (n || e.random()));
              n = 987654071 * c(), r.push(4294967296 * c() | 0);
            }
            return new a.init(r, t);
          }
        }),
        c = r.enc = {},
        s = c.Hex = {
          stringify: function (e) {
            for (var t = e.words, n = e.sigBytes, r = [], o = 0; o < n; o++) {
              var i = t[o >>> 2] >>> 24 - o % 4 * 8 & 255;
              r.push((i >>> 4).toString(16)), r.push((15 & i).toString(16));
            }
            return r.join("");
          },
          parse: function (e) {
            for (var t = e.length, n = [], r = 0; r < t; r += 2) n[r >>> 3] |= parseInt(e.substr(r, 2), 16) << 24 - r % 8 * 4;
            return new a.init(n, t / 2);
          }
        },
        u = c.Latin1 = {
          stringify: function (e) {
            for (var t = e.words, n = e.sigBytes, r = [], o = 0; o < n; o++) {
              var i = t[o >>> 2] >>> 24 - o % 4 * 8 & 255;
              r.push(String.fromCharCode(i));
            }
            return r.join("");
          },
          parse: function (e) {
            for (var t = e.length, n = [], r = 0; r < t; r++) n[r >>> 2] |= (255 & e.charCodeAt(r)) << 24 - r % 4 * 8;
            return new a.init(n, t);
          }
        },
        l = c.Utf8 = {
          stringify: function (e) {
            try {
              return decodeURIComponent(escape(u.stringify(e)));
            } catch (e) {
              throw new Error("Malformed UTF-8 data");
            }
          },
          parse: function (e) {
            return u.parse(unescape(encodeURIComponent(e)));
          }
        },
        f = o.BufferedBlockAlgorithm = i.extend({
          reset: function () {
            this._data = new a.init(), this._nDataBytes = 0;
          },
          _append: function (e) {
            "string" == typeof e && (e = l.parse(e)), this._data.concat(e), this._nDataBytes += e.sigBytes;
          },
          _process: function (t) {
            var n = this._data,
              r = n.words,
              o = n.sigBytes,
              i = this.blockSize,
              c = o / (4 * i),
              s = (c = t ? e.ceil(c) : e.max((0 | c) - this._minBufferSize, 0)) * i,
              u = e.min(4 * s, o);
            if (s) {
              for (var l = 0; l < s; l += i) this._doProcessBlock(r, l);
              var f = r.splice(0, s);
              n.sigBytes -= u;
            }
            return new a.init(f, u);
          },
          clone: function () {
            var e = i.clone.call(this);
            return e._data = this._data.clone(), e;
          },
          _minBufferSize: 0
        });
      o.Hasher = f.extend({
        cfg: i.extend(),
        init: function (e) {
          this.cfg = this.cfg.extend(e), this.reset();
        },
        reset: function () {
          f.reset.call(this), this._doReset();
        },
        update: function (e) {
          return this._append(e), this._process(), this;
        },
        finalize: function (e) {
          return e && this._append(e), this._doFinalize();
        },
        blockSize: 16,
        _createHelper: function (e) {
          return function (t, n) {
            return new e.init(n).finalize(t);
          };
        },
        _createHmacHelper: function (e) {
          return function (t, n) {
            return new d.HMAC.init(e, n).finalize(t);
          };
        }
      });
      var d = r.algo = {};
      return r;
    }(Math), n);
  }),
  Da = n(function (e, t) {
    var n;
    e.exports = (n = za, function () {
      var e = n,
        t = e.lib.WordArray;
      function r(e, n, r) {
        for (var o = [], i = 0, a = 0; a < n; a++) if (a % 4) {
          var c = r[e.charCodeAt(a - 1)] << a % 4 * 2,
            s = r[e.charCodeAt(a)] >>> 6 - a % 4 * 2;
          o[i >>> 2] |= (c | s) << 24 - i % 4 * 8, i++;
        }
        return t.create(o, i);
      }
      e.enc.Base64 = {
        stringify: function (e) {
          var t = e.words,
            n = e.sigBytes,
            r = this._map;
          e.clamp();
          for (var o = [], i = 0; i < n; i += 3) for (var a = (t[i >>> 2] >>> 24 - i % 4 * 8 & 255) << 16 | (t[i + 1 >>> 2] >>> 24 - (i + 1) % 4 * 8 & 255) << 8 | t[i + 2 >>> 2] >>> 24 - (i + 2) % 4 * 8 & 255, c = 0; c < 4 && i + .75 * c < n; c++) o.push(r.charAt(a >>> 6 * (3 - c) & 63));
          var s = r.charAt(64);
          if (s) for (; o.length % 4;) o.push(s);
          return o.join("");
        },
        parse: function (e) {
          var t = e.length,
            n = this._map,
            o = this._reverseMap;
          if (!o) {
            o = this._reverseMap = [];
            for (var i = 0; i < n.length; i++) o[n.charCodeAt(i)] = i;
          }
          var a = n.charAt(64);
          if (a) {
            var c = e.indexOf(a);
            -1 !== c && (t = c);
          }
          return r(e, t, o);
        },
        _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
      };
    }(), n.enc.Base64);
  }),
  Ha = n(function (e, t) {
    var n;
    e.exports = (n = za, function (e) {
      var t = n,
        r = t.lib,
        o = r.WordArray,
        i = r.Hasher,
        a = t.algo,
        c = [],
        s = [];
      !function () {
        function t(t) {
          for (var n = e.sqrt(t), r = 2; r <= n; r++) if (!(t % r)) return !1;
          return !0;
        }
        function n(e) {
          return 4294967296 * (e - (0 | e)) | 0;
        }
        for (var r = 2, o = 0; o < 64;) t(r) && (o < 8 && (c[o] = n(e.pow(r, .5))), s[o] = n(e.pow(r, 1 / 3)), o++), r++;
      }();
      var u = [],
        l = a.SHA256 = i.extend({
          _doReset: function () {
            this._hash = new o.init(c.slice(0));
          },
          _doProcessBlock: function (e, t) {
            for (var n = this._hash.words, r = n[0], o = n[1], i = n[2], a = n[3], c = n[4], l = n[5], f = n[6], d = n[7], h = 0; h < 64; h++) {
              if (h < 16) u[h] = 0 | e[t + h];else {
                var p = u[h - 15],
                  g = (p << 25 | p >>> 7) ^ (p << 14 | p >>> 18) ^ p >>> 3,
                  y = u[h - 2],
                  v = (y << 15 | y >>> 17) ^ (y << 13 | y >>> 19) ^ y >>> 10;
                u[h] = g + u[h - 7] + v + u[h - 16];
              }
              var m = r & o ^ r & i ^ o & i,
                _ = (r << 30 | r >>> 2) ^ (r << 19 | r >>> 13) ^ (r << 10 | r >>> 22),
                w = d + ((c << 26 | c >>> 6) ^ (c << 21 | c >>> 11) ^ (c << 7 | c >>> 25)) + (c & l ^ ~c & f) + s[h] + u[h];
              d = f, f = l, l = c, c = a + w | 0, a = i, i = o, o = r, r = w + (_ + m) | 0;
            }
            n[0] = n[0] + r | 0, n[1] = n[1] + o | 0, n[2] = n[2] + i | 0, n[3] = n[3] + a | 0, n[4] = n[4] + c | 0, n[5] = n[5] + l | 0, n[6] = n[6] + f | 0, n[7] = n[7] + d | 0;
          },
          _doFinalize: function () {
            var t = this._data,
              n = t.words,
              r = 8 * this._nDataBytes,
              o = 8 * t.sigBytes;
            return n[o >>> 5] |= 128 << 24 - o % 32, n[14 + (o + 64 >>> 9 << 4)] = e.floor(r / 4294967296), n[15 + (o + 64 >>> 9 << 4)] = r, t.sigBytes = 4 * n.length, this._process(), this._hash;
          },
          clone: function () {
            var e = i.clone.call(this);
            return e._hash = this._hash.clone(), e;
          }
        });
      t.SHA256 = i._createHelper(l), t.HmacSHA256 = i._createHmacHelper(l);
    }(Math), n.SHA256);
  }),
  Ua = n(function (e) {
    !function (t) {
      if ("undefined" != typeof window) {
        var n,
          r = 0,
          o = !1,
          i = !1,
          a = "message".length,
          c = "[iFrameSizer]",
          s = c.length,
          u = null,
          l = window.requestAnimationFrame,
          f = {
            max: 1,
            scroll: 1,
            bodyScroll: 1,
            documentElementScroll: 1
          },
          d = {},
          h = null,
          p = {
            autoResize: !0,
            bodyBackground: null,
            bodyMargin: null,
            bodyMarginV1: 8,
            bodyPadding: null,
            checkOrigin: !0,
            inPageLinks: !1,
            enablePublicMethods: !0,
            heightCalculationMethod: "bodyOffset",
            id: "iFrameResizer",
            interval: 32,
            log: !1,
            maxHeight: 1 / 0,
            maxWidth: 1 / 0,
            minHeight: 0,
            minWidth: 0,
            mouseEvents: !0,
            resizeFrom: "parent",
            scrolling: !1,
            sizeHeight: !0,
            sizeWidth: !1,
            warningTimeout: 5e3,
            tolerance: 0,
            widthCalculationMethod: "scroll",
            onClose: function () {
              return !0;
            },
            onClosed: function () {},
            onInit: function () {},
            onMessage: function () {
              O("onMessage function not defined");
            },
            onMouseEnter: function () {},
            onMouseLeave: function () {},
            onResized: function () {},
            onScroll: function () {
              return !0;
            }
          },
          g = {};
        window.jQuery && ((n = window.jQuery).fn ? n.fn.iFrameResize || (n.fn.iFrameResize = function (e) {
          return this.filter("iframe").each(function (t, n) {
            N(n, e);
          }).end();
        }) : k("", "Unable to bind to jQuery, it is not fully loaded.")), e.exports = B(), window.iFrameResize = window.iFrameResize || B();
      }
      function y() {
        return window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
      }
      function v(e, t, n) {
        e.addEventListener(t, n, !1);
      }
      function m(e, t, n) {
        e.removeEventListener(t, n, !1);
      }
      function _(e) {
        return c + "[" + function (e) {
          var t = "Host page: " + e;
          return window.top !== window.self && (t = window.parentIFrame && window.parentIFrame.getId ? window.parentIFrame.getId() + ": " + e : "Nested host page: " + e), t;
        }(e) + "]";
      }
      function w(e) {
        return d[e] ? d[e].log : o;
      }
      function b(e, t) {
        x("log", e, t, w(e));
      }
      function k(e, t) {
        x("info", e, t, w(e));
      }
      function O(e, t) {
        x("warn", e, t, !0);
      }
      function x(e, t, n, r) {
        !0 === r && "object" == typeof window.console && console[e](_(t), n);
      }
      function I(e) {
        function t() {
          o("Height"), o("Width"), M(function () {
            C(j), P(N), _("onResized", j);
          }, j, "init");
        }
        function n(e) {
          return "border-box" !== e.boxSizing ? 0 : (e.paddingTop ? parseInt(e.paddingTop, 10) : 0) + (e.paddingBottom ? parseInt(e.paddingBottom, 10) : 0);
        }
        function r(e) {
          return "border-box" !== e.boxSizing ? 0 : (e.borderTopWidth ? parseInt(e.borderTopWidth, 10) : 0) + (e.borderBottomWidth ? parseInt(e.borderBottomWidth, 10) : 0);
        }
        function o(e) {
          var t = Number(d[N]["max" + e]),
            n = Number(d[N]["min" + e]),
            r = e.toLowerCase(),
            o = Number(j[r]);
          b(N, "Checking " + r + " is in range " + n + "-" + t), o < n && (o = n, b(N, "Set " + r + " to min value")), o > t && (o = t, b(N, "Set " + r + " to max value")), j[r] = "" + o;
        }
        function i(e) {
          return I.substr(I.indexOf(":") + a + e);
        }
        function l(e, t) {
          var n, r, o;
          n = function () {
            var n, r;
            F("Send Page Info", "pageInfo:" + (n = document.body.getBoundingClientRect(), r = j.iframe.getBoundingClientRect(), JSON.stringify({
              iframeHeight: r.height,
              iframeWidth: r.width,
              clientHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
              clientWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
              offsetTop: parseInt(r.top - n.top, 10),
              offsetLeft: parseInt(r.left - n.left, 10),
              scrollTop: window.pageYOffset,
              scrollLeft: window.pageXOffset,
              documentHeight: document.documentElement.clientHeight,
              documentWidth: document.documentElement.clientWidth,
              windowHeight: window.innerHeight,
              windowWidth: window.innerWidth
            })), e, t);
          }, r = 32, g[o = t] || (g[o] = setTimeout(function () {
            g[o] = null, n();
          }, r));
        }
        function f(e) {
          var t = e.getBoundingClientRect();
          return T(N), {
            x: Math.floor(Number(t.left) + Number(u.x)),
            y: Math.floor(Number(t.top) + Number(u.y))
          };
        }
        function h(e) {
          var t = e ? f(j.iframe) : {
              x: 0,
              y: 0
            },
            n = {
              x: Number(j.width) + t.x,
              y: Number(j.height) + t.y
            };
          b(N, "Reposition requested from iFrame (offset x:" + t.x + " y:" + t.y + ")"), window.top !== window.self ? window.parentIFrame ? window.parentIFrame["scrollTo" + (e ? "Offset" : "")](n.x, n.y) : O(N, "Unable to scroll to requested position, window.parentIFrame not found") : (u = n, p(), b(N, "--"));
        }
        function p() {
          !1 !== _("onScroll", u) ? P(N) : R();
        }
        function y(e) {
          var t = {};
          if (0 === Number(j.width) && 0 === Number(j.height)) {
            var n = i(9).split(":");
            t = {
              x: n[1],
              y: n[0]
            };
          } else t = {
            x: j.width,
            y: j.height
          };
          _(e, {
            iframe: j.iframe,
            screenX: Number(t.x),
            screenY: Number(t.y),
            type: j.type
          });
        }
        function _(e, t) {
          return S(N, e, t);
        }
        var w,
          x,
          I = e.data,
          j = {},
          N = null;
        "[iFrameResizerChild]Ready" === I ? function () {
          for (var e in d) F("iFrame requested init", L(e), d[e].iframe, e);
        }() : c === ("" + I).substr(0, s) && I.substr(s).split(":")[0] in d ? (j = function () {
          var e = I.substr(s).split(":"),
            t = e[1] ? parseInt(e[1], 10) : 0,
            o = d[e[0]] && d[e[0]].iframe,
            i = getComputedStyle(o);
          return {
            iframe: o,
            id: e[0],
            height: t + n(i) + r(i),
            width: e[2],
            type: e[3]
          };
        }(), N = j.id, d[N] && (d[N].loaded = !0), (x = j.type in {
          true: 1,
          false: 1,
          undefined: 1
        }) && b(N, "Ignoring init message from meta parent page"), !x && function (e) {
          var t = !0;
          return d[e] || (t = !1, O(j.type + " No settings for " + e + ". Message was: " + I)), t;
        }(N) && (b(N, "Received: " + I), w = !0, null === j.iframe && (O(N, "IFrame (" + j.id + ") not found"), w = !1), w && function () {
          var t,
            n = e.origin,
            r = d[N] && d[N].checkOrigin;
          if (r && "" + n != "null" && !(r.constructor === Array ? function () {
            var e = 0,
              t = !1;
            for (b(N, "Checking connection is from allowed list of origins: " + r); e < r.length; e++) if (r[e] === n) {
              t = !0;
              break;
            }
            return t;
          }() : (t = d[N] && d[N].remoteHost, b(N, "Checking connection is from: " + t), n === t))) throw new Error("Unexpected message received from: " + n + " for " + j.iframe.id + ". Message was: " + e.data + ". This error can be disabled by setting the checkOrigin: false option or by providing of array of trusted domains.");
          return !0;
        }() && function () {
          switch (d[N] && d[N].firstRun && d[N] && (d[N].firstRun = !1), j.type) {
            case "close":
              E(j.iframe);
              break;
            case "message":
              a = i(6), b(N, "onMessage passed: {iframe: " + j.iframe.id + ", message: " + a + "}"), _("onMessage", {
                iframe: j.iframe,
                message: JSON.parse(a)
              }), b(N, "--");
              break;
            case "mouseenter":
              y("onMouseEnter");
              break;
            case "mouseleave":
              y("onMouseLeave");
              break;
            case "autoResize":
              d[N].autoResize = JSON.parse(i(9));
              break;
            case "scrollTo":
              h(!1);
              break;
            case "scrollToOffset":
              h(!0);
              break;
            case "pageInfo":
              l(d[N] && d[N].iframe, N), function () {
                function e(e, r) {
                  function o() {
                    d[n] ? l(d[n].iframe, n) : t();
                  }
                  ["scroll", "resize"].forEach(function (t) {
                    b(n, e + t + " listener for sendPageInfo"), r(window, t, o);
                  });
                }
                function t() {
                  e("Remove ", m);
                }
                var n = N;
                e("Add ", v), d[n] && (d[n].stopPageInfo = t);
              }();
              break;
            case "pageInfoStop":
              d[N] && d[N].stopPageInfo && (d[N].stopPageInfo(), delete d[N].stopPageInfo);
              break;
            case "inPageLink":
              n = i(9).split("#")[1] || "", r = decodeURIComponent(n), (o = document.getElementById(r) || document.getElementsByName(r)[0]) ? (e = f(o), b(N, "Moving to in page link (#" + n + ") at x: " + e.x + " y: " + e.y), u = {
                x: e.x,
                y: e.y
              }, p(), b(N, "--")) : window.top !== window.self ? window.parentIFrame ? window.parentIFrame.moveToAnchor(n) : b(N, "In page link #" + n + " not found and window.parentIFrame not found") : b(N, "In page link #" + n + " not found");
              break;
            case "reset":
              A(j);
              break;
            case "init":
              t(), _("onInit", j.iframe);
              break;
            default:
              0 === Number(j.width) && 0 === Number(j.height) ? O("Unsupported message received (" + j.type + "), this is likely due to the iframe containing a later version of iframe-resizer than the parent page") : t();
          }
          var e, n, r, o, a;
        }())) : k(N, "Ignored: " + I);
      }
      function S(e, t, n) {
        var r = null,
          o = null;
        if (d[e]) {
          if ("function" != typeof (r = d[e][t])) throw new TypeError(t + " on iFrame[" + e + "] is not a function");
          o = r(n);
        }
        return o;
      }
      function j(e) {
        var t = e.id;
        delete d[t];
      }
      function E(e) {
        var t = e.id;
        if (!1 !== S(t, "onClose", t)) {
          b(t, "Removing iFrame: " + t);
          try {
            e.parentNode && e.parentNode.removeChild(e);
          } catch (e) {
            O(e);
          }
          S(t, "onClosed", t), b(t, "--"), j(e);
        } else b(t, "Close iframe cancelled by onClose event");
      }
      function T(e) {
        null === u && b(e, "Get page position: " + (u = {
          x: window.pageXOffset !== t ? window.pageXOffset : document.documentElement.scrollLeft,
          y: window.pageYOffset !== t ? window.pageYOffset : document.documentElement.scrollTop
        }).x + "," + u.y);
      }
      function P(e) {
        null !== u && (window.scrollTo(u.x, u.y), b(e, "Set page position: " + u.x + "," + u.y), R());
      }
      function R() {
        u = null;
      }
      function A(e) {
        b(e.id, "Size reset requested by " + ("init" === e.type ? "host page" : "iFrame")), T(e.id), M(function () {
          C(e), F("reset", "reset", e.iframe, e.id);
        }, e, "reset");
      }
      function C(e) {
        function t(t) {
          i || "0" !== e[t] || (i = !0, b(r, "Hidden iFrame detected, creating visibility listener"), function () {
            function e() {
              function e(e) {
                function t(t) {
                  return "0px" === (d[e] && d[e].iframe.style[t]);
                }
                function n(e) {
                  return null !== e.offsetParent;
                }
                d[e] && n(d[e].iframe) && (t("height") || t("width")) && F("Visibility change", "resize", d[e].iframe, e);
              }
              Object.keys(d).forEach(function (t) {
                e(t);
              });
            }
            function t(t) {
              b("window", "Mutation observed: " + t[0].target + " " + t[0].type), z(e, 16);
            }
            function n() {
              var e = document.querySelector("body"),
                n = {
                  attributes: !0,
                  attributeOldValue: !1,
                  characterData: !0,
                  characterDataOldValue: !1,
                  childList: !0,
                  subtree: !0
                };
              new r(t).observe(e, n);
            }
            var r = y();
            r && n();
          }());
        }
        function n(n) {
          !function (t) {
            e.id ? (e.iframe.style[t] = e[t] + "px", b(e.id, "IFrame (" + r + ") " + t + " set to " + e[t] + "px")) : b("undefined", "messageData id not set");
          }(n), t(n);
        }
        var r = e.iframe.id;
        d[r] && (d[r].sizeHeight && n("height"), d[r].sizeWidth && n("width"));
      }
      function M(e, t, n) {
        n !== t.type && l && !window.jasmine ? (b(t.id, "Requesting animation frame"), l(e)) : e();
      }
      function F(e, t, n, r, o) {
        var i,
          a = !1;
        r = r || n.id, d[r] && (n && "contentWindow" in n && null !== n.contentWindow ? (i = d[r] && d[r].targetOrigin, b(r, "[" + e + "] Sending msg to iframe[" + r + "] (" + t + ") targetOrigin: " + i), n.contentWindow.postMessage(c + t, i)) : O(r, "[" + e + "] IFrame(" + r + ") not found"), o && d[r] && d[r].warningTimeout && (d[r].msgTimeout = setTimeout(function () {
          !d[r] || d[r].loaded || a || (a = !0, O(r, "IFrame has not responded within " + d[r].warningTimeout / 1e3 + " seconds. Check iFrameResizer.contentWindow.js has been loaded in iFrame. This message can be ignored if everything is working, or you can set the warningTimeout option to a higher value or zero to suppress this warning."));
        }, d[r].warningTimeout)));
      }
      function L(e) {
        return e + ":" + d[e].bodyMarginV1 + ":" + d[e].sizeWidth + ":" + d[e].log + ":" + d[e].interval + ":" + d[e].enablePublicMethods + ":" + d[e].autoResize + ":" + d[e].bodyMargin + ":" + d[e].heightCalculationMethod + ":" + d[e].bodyBackground + ":" + d[e].bodyPadding + ":" + d[e].tolerance + ":" + d[e].inPageLinks + ":" + d[e].resizeFrom + ":" + d[e].widthCalculationMethod + ":" + d[e].mouseEvents;
      }
      function N(e, n) {
        function i(e) {
          var t = e.split("Callback");
          if (2 === t.length) {
            var n = "on" + t[0].charAt(0).toUpperCase() + t[0].slice(1);
            this[n] = this[e], delete this[e], O(a, "Deprecated: '" + e + "' has been renamed '" + n + "'. The old method will be removed in the next major version.");
          }
        }
        var a = function (t) {
          return "" === t && (e.id = t = function () {
            var e = n && n.id || p.id + r++;
            return null !== document.getElementById(e) && (e += r++), e;
          }(), o = (n || {}).log, b(t, "Added missing iframe ID: " + t + " (" + e.src + ")")), t;
        }(e.id);
        a in d && "iFrameResizer" in e ? O(a, "Ignored iFrame, already setup.") : (!function (t) {
          var n;
          t = t || {}, d[a] = {
            firstRun: !0,
            iframe: e,
            remoteHost: e.src && e.src.split("/").slice(0, 3).join("/")
          }, function (e) {
            if ("object" != typeof e) throw new TypeError("Options is not an object");
          }(t), Object.keys(t).forEach(i, t), function (e) {
            for (var t in p) Object.prototype.hasOwnProperty.call(p, t) && (d[a][t] = Object.prototype.hasOwnProperty.call(e, t) ? e[t] : p[t]);
          }(t), d[a] && (d[a].targetOrigin = !0 === d[a].checkOrigin ? "" === (n = d[a].remoteHost) || null !== n.match(/^(about:blank|javascript:|file:\/\/)/) ? "*" : n : "*");
        }(n), function () {
          switch (b(a, "IFrame scrolling " + (d[a] && d[a].scrolling ? "enabled" : "disabled") + " for " + a), e.style.overflow = !1 === (d[a] && d[a].scrolling) ? "hidden" : "auto", d[a] && d[a].scrolling) {
            case "omit":
              break;
            case !0:
              e.scrolling = "yes";
              break;
            case !1:
              e.scrolling = "no";
              break;
            default:
              e.scrolling = d[a] ? d[a].scrolling : "no";
          }
        }(), function () {
          function t(t) {
            var n = d[a][t];
            1 / 0 !== n && 0 !== n && (e.style[t] = "number" == typeof n ? n + "px" : n, b(a, "Set " + t + " = " + e.style[t]));
          }
          function n(e) {
            if (d[a]["min" + e] > d[a]["max" + e]) throw new Error("Value for min" + e + " can not be greater than max" + e);
          }
          n("Height"), n("Width"), t("maxHeight"), t("minHeight"), t("maxWidth"), t("minWidth");
        }(), "number" != typeof (d[a] && d[a].bodyMargin) && "0" !== (d[a] && d[a].bodyMargin) || (d[a].bodyMarginV1 = d[a].bodyMargin, d[a].bodyMargin = d[a].bodyMargin + "px"), function (n) {
          var r = y();
          r && function (t) {
            e.parentNode && new t(function (t) {
              t.forEach(function (t) {
                Array.prototype.slice.call(t.removedNodes).forEach(function (t) {
                  t === e && E(e);
                });
              });
            }).observe(e.parentNode, {
              childList: !0
            });
          }(r), v(e, "load", function () {
            var r, o;
            F("iFrame.onload", n, e, t, !0), r = d[a] && d[a].firstRun, o = d[a] && d[a].heightCalculationMethod in f, !r && o && A({
              iframe: e,
              height: 0,
              width: 0,
              type: "init"
            });
          }), F("init", n, e, t, !0);
        }(L(a)), d[a] && (d[a].iframe.iFrameResizer = {
          close: E.bind(null, d[a].iframe),
          removeListeners: j.bind(null, d[a].iframe),
          resize: F.bind(null, "Window resize", "resize", d[a].iframe),
          moveToAnchor: function (e) {
            F("Move to anchor", "moveToAnchor:" + e, d[a].iframe, a);
          },
          sendMessage: function (e) {
            F("Send Message", "message:" + (e = JSON.stringify(e)), d[a].iframe, a);
          }
        }));
      }
      function z(e, t) {
        null === h && (h = setTimeout(function () {
          h = null, e();
        }, t));
      }
      function D() {
        "hidden" !== document.visibilityState && (b("document", "Trigger event: Visiblity change"), z(function () {
          H("Tab Visable", "resize");
        }, 16));
      }
      function H(e, t) {
        Object.keys(d).forEach(function (n) {
          (function (e) {
            return d[e] && "parent" === d[e].resizeFrom && d[e].autoResize && !d[e].firstRun;
          })(n) && F(e, t, d[n].iframe, n);
        });
      }
      function U() {
        v(window, "message", I), v(window, "resize", function () {
          var e;
          b("window", "Trigger event: " + (e = "resize")), z(function () {
            H("Window " + e, "resize");
          }, 16);
        }), v(document, "visibilitychange", D), v(document, "-webkit-visibilitychange", D);
      }
      function B() {
        function e(e, t) {
          t && (!function () {
            if (!t.tagName) throw new TypeError("Object is not a valid DOM element");
            if ("IFRAME" !== t.tagName.toUpperCase()) throw new TypeError("Expected <IFRAME> tag, found <" + t.tagName + ">");
          }(), N(t, e), n.push(t));
        }
        var n;
        return function () {
          var e,
            t = ["moz", "webkit", "o", "ms"];
          for (e = 0; e < t.length && !l; e += 1) l = window[t[e] + "RequestAnimationFrame"];
          l ? l = l.bind(window) : b("setup", "RequestAnimationFrame not supported");
        }(), U(), function (r, o) {
          switch (n = [], function (e) {
            e && e.enablePublicMethods && O("enablePublicMethods option has been removed, public methods are now always available in the iFrame");
          }(r), typeof o) {
            case "undefined":
            case "string":
              Array.prototype.forEach.call(document.querySelectorAll(o || "iframe"), e.bind(t, r));
              break;
            case "object":
              e(r, o);
              break;
            default:
              throw new TypeError("Unexpected data type (" + typeof o + ")");
          }
          return n;
        };
      }
    }();
  });
function Ba(e) {
  const t = {};
  return e.replace("#", "").replace("?", "").split("&").map(e => {
    const n = e.split("=");
    t[n[0]] = n[1];
  }), t;
}
function Wa(e) {
  return Object.keys(e).filter(t => !!e[t]).map(t => encodeURIComponent(t) + "=" + encodeURIComponent(e[t])).join("&");
}
function $a(e) {
  try {
    window.parent.postMessage(e, window.location.origin);
  } catch (e) {
    console.error("Error messaging window parent: ", e);
  }
}
function Ga(e) {
  return Ha(e).toString(Da).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}
function Va(e) {
  const t = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let n = "";
  for (let r = 0; r < e; r++) n += t.charAt(Math.floor(Math.random() * t.length));
  return n;
}
function qa(e) {
  let t = null;
  if (e && e.split(".")[1]) {
    const n = e.split(".")[1].replace("-", "+").replace("_", "/");
    t = JSON.parse(atob(n));
  }
  return t;
}
function Ja(e) {
  let {
    parentEl: t,
    attributes: n,
    listenFor: r,
    resizer: o
  } = e;
  const i = document.createElement("iframe");
  return Object.keys(n).forEach(e => {
    i.setAttribute(e, n[e]);
  }), t.appendChild(i), o && Ua({
    inPageLinks: !0
  }, i), new Promise(e => {
    window.addEventListener("message", t => {
      let {
        data: n
      } = t;
      var o;
      (null == n ? void 0 : n.type) === r && (null === (o = i.parentNode) || void 0 === o || o.removeChild(i), e(n));
    });
  });
}
var Ya = Object.is || function (e, t) {
  return e === t ? 0 !== e || 1 / e == 1 / t : e != e && t != t;
};
ca("search", function (e, t, n) {
  return [function (t) {
    var n = E(this),
      r = S(t) ? void 0 : Z(t, e);
    return r ? f(r, t, n) : new RegExp(t)[e](Ni(n));
  }, function (e) {
    var r = Re(this),
      o = Ni(e),
      i = n(t, r, o);
    if (i.done) return i.value;
    var a = r.lastIndex;
    Ya(a, 0) || (r.lastIndex = 0);
    var c = ka(r, o);
    return Ya(r.lastIndex, a) || (r.lastIndex = a), null === c ? -1 : c.index;
  }];
});
var Ka = "\t\n\v\f\r                　\u2028\u2029\ufeff",
  Xa = _("".replace),
  Qa = "[" + Ka + "]",
  Za = RegExp("^" + Qa + Qa + "*"),
  ec = RegExp(Qa + Qa + "*$"),
  tc = function (e) {
    return function (t) {
      var n = Ni(E(t));
      return 1 & e && (n = Xa(n, Za, "")), 2 & e && (n = Xa(n, ec, "")), n;
    };
  },
  nc = {
    start: tc(1),
    end: tc(2),
    trim: tc(3)
  }.trim,
  rc = a.parseInt,
  oc = a.Symbol,
  ic = oc && oc.iterator,
  ac = /^[+-]?0x/i,
  cc = _(ac.exec),
  sc = 8 !== rc(Ka + "08") || 22 !== rc(Ka + "0x16") || ic && !c(function () {
    rc(Object(ic));
  }) ? function (e, t) {
    var n = nc(Ni(e));
    return rc(n, t >>> 0 || (cc(ac, n) ? 16 : 10));
  } : rc;
Lt({
  global: !0,
  forced: parseInt != sc
}, {
  parseInt: sc
});
var uc = ve("match"),
  lc = TypeError,
  fc = function (e) {
    if (function (e) {
      var t;
      return F(e) && (void 0 !== (t = e[uc]) ? !!t : "RegExp" == k(e));
    }(e)) throw lc("The method doesn't accept regular expressions");
    return e;
  },
  dc = ve("match"),
  hc = _("".indexOf);
Lt({
  target: "String",
  proto: !0,
  forced: !function (e) {
    var t = /./;
    try {
      "/./"[e](t);
    } catch (n) {
      try {
        return t[dc] = !1, "/./"[e](t);
      } catch (e) {}
    }
    return !1;
  }("includes")
}, {
  includes: function (e) {
    return !!~hc(Ni(E(this)), Ni(fc(e)), arguments.length > 1 ? arguments[1] : void 0);
  }
});
var pc = {
    CSSRuleList: 0,
    CSSStyleDeclaration: 0,
    CSSValueList: 0,
    ClientRectList: 0,
    DOMRectList: 0,
    DOMStringList: 0,
    DOMTokenList: 1,
    DataTransferItemList: 0,
    FileList: 0,
    HTMLAllCollection: 0,
    HTMLCollection: 0,
    HTMLFormElement: 0,
    HTMLSelectElement: 0,
    MediaList: 0,
    MimeTypeArray: 0,
    NamedNodeMap: 0,
    NodeList: 1,
    PaintRequestList: 0,
    Plugin: 0,
    PluginArray: 0,
    SVGLengthList: 0,
    SVGNumberList: 0,
    SVGPathSegList: 0,
    SVGPointList: 0,
    SVGStringList: 0,
    SVGTransformList: 0,
    SourceBufferList: 0,
    StyleSheetList: 0,
    TextTrackCueList: 0,
    TextTrackList: 0,
    TouchList: 0
  },
  gc = xe("span").classList,
  yc = gc && gc.constructor && gc.constructor.prototype,
  vc = yc === Object.prototype ? void 0 : yc,
  mc = ze.f,
  _c = ve("unscopables"),
  wc = Array.prototype;
null == wc[_c] && mc(wc, _c, {
  configurable: !0,
  value: Lo(null)
});
var bc = function (e) {
    wc[_c][e] = !0;
  },
  kc = di.IteratorPrototype,
  Oc = function () {
    return this;
  },
  xc = We.PROPER,
  Ic = We.CONFIGURABLE,
  Sc = di.IteratorPrototype,
  jc = di.BUGGY_SAFARI_ITERATORS,
  Ec = ve("iterator"),
  Tc = "keys",
  Pc = "values",
  Rc = "entries",
  Ac = function () {
    return this;
  },
  Cc = ze.f,
  Mc = "Array Iterator",
  Fc = it.set,
  Lc = it.getterFor(Mc),
  Nc = function (e, t, n, r, o, i, a) {
    !function (e, t, n, r) {
      var o = t + " Iterator";
      e.prototype = Lo(kc, {
        next: g(+!r, n)
      }), Wt(e, o, !1), Xr[o] = Oc;
    }(n, t, r);
    var c,
      s,
      u,
      l = function (e) {
        if (e === o && v) return v;
        if (!jc && e in p) return p[e];
        switch (e) {
          case Tc:
          case Pc:
          case Rc:
            return function () {
              return new n(this, e);
            };
        }
        return function () {
          return new n(this);
        };
      },
      d = t + " Iterator",
      h = !1,
      p = e.prototype,
      y = p[Ec] || p["@@iterator"] || o && p[o],
      v = !jc && y || l(o),
      m = "Array" == t && p.entries || y;
    if (m && (c = Bo(m.call(new e()))) !== Object.prototype && c.next && (Bo(c) !== Sc && (Ht ? Ht(c, Sc) : C(c[Ec]) || ct(c, Ec, Ac)), Wt(c, d, !0)), xc && o == Pc && y && y.name !== Pc && (Ic ? De(p, "name", Pc) : (h = !0, v = function () {
      return f(y, this);
    })), o) if (s = {
      values: l(Pc),
      keys: i ? v : l(Tc),
      entries: l(Rc)
    }, a) for (u in s) (jc || h || !(u in p)) && ct(p, u, s[u]);else Lt({
      target: t,
      proto: !0,
      forced: jc || h
    }, s);
    return p[Ec] !== v && ct(p, Ec, v, {
      name: o
    }), Xr[t] = v, s;
  }(Array, "Array", function (e, t) {
    Fc(this, {
      type: Mc,
      target: T(e),
      index: 0,
      kind: t
    });
  }, function () {
    var e = Lc(this),
      t = e.target,
      n = e.kind,
      r = e.index++;
    return !t || r >= t.length ? (e.target = void 0, Jo(void 0, !0)) : Jo("keys" == n ? r : "values" == n ? t[r] : [r, t[r]], !1);
  }, "values"),
  zc = Xr.Arguments = Xr.Array;
if (bc("keys"), bc("values"), bc("entries"), s && "values" !== zc.name) try {
  Cc(zc, "name", {
    value: "values"
  });
} catch (e) {}
var Dc = ve("iterator"),
  Hc = ve("toStringTag"),
  Uc = Nc.values,
  Bc = function (e, t) {
    if (e) {
      if (e[Dc] !== Uc) try {
        De(e, Dc, Uc);
      } catch (t) {
        e[Dc] = Uc;
      }
      if (e[Hc] || De(e, Hc, t), pc[t]) for (var n in Nc) if (e[n] !== Nc[n]) try {
        De(e, n, Nc[n]);
      } catch (t) {
        e[n] = Nc[n];
      }
    }
  };
for (var Wc in pc) Bc(a[Wc] && a[Wc].prototype, Wc);
Bc(vc, "DOMTokenList");
var $c = Array.isArray || function (e) {
    return "Array" == k(e);
  },
  Gc = N("JSON", "stringify"),
  Vc = _(/./.exec),
  qc = _("".charAt),
  Jc = _("".charCodeAt),
  Yc = _("".replace),
  Kc = _(1..toString),
  Xc = /[\uD800-\uDFFF]/g,
  Qc = /^[\uD800-\uDBFF]$/,
  Zc = /^[\uDC00-\uDFFF]$/,
  es = !G || c(function () {
    var e = N("Symbol")();
    return "[null]" != Gc([e]) || "{}" != Gc({
      a: e
    }) || "{}" != Gc(Object(e));
  }),
  ts = c(function () {
    return '"\\udf06\\ud834"' !== Gc("\udf06\ud834") || '"\\udead"' !== Gc("\udead");
  }),
  ns = function (e, t) {
    var n = xn(arguments),
      r = t;
    if ((F(t) || void 0 !== e) && !J(e)) return $c(t) || (t = function (e, t) {
      if (C(r) && (t = f(r, this, e, t)), !J(t)) return t;
    }), n[1] = t, _n(Gc, null, n);
  },
  rs = function (e, t, n) {
    var r = qc(n, t - 1),
      o = qc(n, t + 1);
    return Vc(Qc, e) && !Vc(Zc, o) || Vc(Zc, e) && !Vc(Qc, r) ? "\\u" + Kc(Jc(e, 0), 16) : e;
  };
Gc && Lt({
  target: "JSON",
  stat: !0,
  arity: 3,
  forced: es || ts
}, {
  stringify: function (e, t, n) {
    var r = xn(arguments),
      o = _n(es ? ns : Gc, null, r);
    return ts && "string" == typeof o ? Yc(o, Xc, rs) : o;
  }
});
class os {
  constructor(t) {
    let {
      namespace: n,
      namespace_separator: r
    } = t;
    e(this, "namespace", void 0), e(this, "namespace_separator", void 0), this.namespace = n, this.namespace_separator = r;
  }
  get realm() {
    return [this.namespace, "realm"].join(this.namespace_separator);
  }
  get token_type() {
    return [this.namespace, "token_type"].join(this.namespace_separator);
  }
  get id_token() {
    return [this.namespace, "id_token"].join(this.namespace_separator);
  }
  get access_token() {
    return [this.namespace, "access_token"].join(this.namespace_separator);
  }
  get refresh_token() {
    return [this.namespace, "refresh_token"].join(this.namespace_separator);
  }
  get expiry_date() {
    return [this.namespace, "expiry_date"].join(this.namespace_separator);
  }
  get nonce() {
    return [this.namespace, "nonce"].join(this.namespace_separator);
  }
  get login_flow_config() {
    return [this.namespace, "login_flow_config"].join(this.namespace_separator);
  }
}
class is {
  constructor(t) {
    let {
      type: n = "localStorage",
      namespace: r,
      namespace_separator: o
    } = t;
    e(this, "store", void 0), e(this, "keys", void 0), e(this, "config", void 0), this.config = {
      type: n,
      namespace: r,
      namespace_separator: o
    }, this.keys = new os({
      namespace: r,
      namespace_separator: o
    }), this.store = window[n];
  }
  clear() {
    this.realm = null, this.token_type = null, this.id_token = null, this.access_token = null, this.refresh_token = null, this.expiry_date = null, this.nonce = null, this.login_flow_config = null;
  }
  get realm() {
    return this.store.getItem(this.keys.realm);
  }
  set realm(e) {
    e ? this.store.setItem(this.keys.realm, e) : this.store.removeItem(this.keys.realm);
  }
  get token_type() {
    return this.store.getItem(this.keys.token_type);
  }
  set token_type(e) {
    e ? this.store.setItem(this.keys.token_type, e) : this.store.removeItem(this.keys.token_type);
  }
  get id_token() {
    return this.store.getItem(this.keys.id_token);
  }
  set id_token(e) {
    e ? this.store.setItem(this.keys.id_token, e) : this.store.removeItem(this.keys.id_token);
  }
  get id_token_claims() {
    try {
      return qa(this.id_token);
    } catch (e) {
      return null;
    }
  }
  get access_token() {
    return this.store.getItem(this.keys.access_token);
  }
  set access_token(e) {
    e ? this.store.setItem(this.keys.access_token, e) : this.store.removeItem(this.keys.access_token);
  }
  get access_token_claims() {
    try {
      return qa(this.access_token);
    } catch (e) {
      return null;
    }
  }
  get refresh_token() {
    return this.store.getItem(this.keys.refresh_token);
  }
  set refresh_token(e) {
    e ? this.store.setItem(this.keys.refresh_token, e) : this.store.removeItem(this.keys.refresh_token);
  }
  get refresh_token_claims() {
    try {
      return qa(this.refresh_token);
    } catch (e) {
      return null;
    }
  }
  get expiry_date() {
    return this.store.getItem(this.keys.expiry_date);
  }
  set expiry_date(e) {
    e ? this.store.setItem(this.keys.expiry_date, e) : this.store.removeItem(this.keys.expiry_date);
  }
  get nonce() {
    return this.store.getItem(this.keys.nonce);
  }
  set nonce(e) {
    e ? this.store.setItem(this.keys.nonce, e) : this.store.removeItem(this.keys.nonce);
  }
  get login_flow_config() {
    try {
      const e = localStorage.getItem(this.keys.login_flow_config);
      return "string" == typeof e ? JSON.parse(e) : null;
    } catch (e) {
      return null;
    }
  }
  set login_flow_config(e) {
    e ? localStorage.setItem(this.keys.login_flow_config, JSON.stringify(e)) : localStorage.removeItem(this.keys.login_flow_config);
  }
}
class as {
  constructor(t) {
    let {
      client_id: n,
      authority: r,
      redirect_uri: o,
      storage: i
    } = t;
    e(this, "client_id", void 0), e(this, "authority", void 0), e(this, "redirect_uri", void 0), e(this, "storage", void 0), this.client_id = n, this.authority = r, this.redirect_uri = o, this.storage = i;
  }
  postToAccessToken(e) {
    const t = {
      decision: "allow",
      client_id: this.client_id,
      grant_type: e.grant_type,
      carrier: "alianca001" === this.client_id ? "ALIA" : e.carrier || "MAEU"
    };
    return "authorization_code" === e.grant_type ? (t.code = e.code, t.nonce = e.nonce, t.code_verifier = e.code_verifier, t.redirect_uri = e.redirect_uri) : "refresh_token" === e.grant_type && (t.refresh_token = e.refresh_token), e.country && (t.country = e.country), e.office && (t.office = e.office), e.oidc_id_token && (t.oidc_id_token = e.oidc_id_token), "customer" === e.entity_type && e.entity_code ? t.customer_code = e.entity_code : e.entity_type && e.entity_code && (t.entity_type = e.entity_type, t.entity_code = e.entity_code), new Promise((e, n) => {
      ((e, t) => {
        let {
          method: n = "GET",
          params: r,
          headers: o = new Headers(),
          body: i,
          credentials: a = "same-origin"
        } = t;
        return r && (e += e.indexOf("?") ? "&" : "?" + Wa(r)), ["POST"].includes(n) && !o.get("Content-Type") && o.set("Content-Type", "application/json"), new Promise((t, r) => {
          window.fetch(e, {
            method: n,
            credentials: a,
            headers: o,
            body: i
          }).then(e => {
            if (!e.ok) throw e;
            return e.json();
          }).then(e => t(e)).catch(e => {
            e.json().then(e => r(e)).catch(() => r(e.statusText));
          });
        });
      })(this.authority + "/acm/oauth2/realms" + this.storage.realm + "/access_token", {
        method: "POST",
        credentials: "include",
        body: Wa(t),
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        })
      }).then(t => e(t)).catch(n);
    });
  }
  getAccessToken(e) {
    let {
      nonce: t,
      code: n,
      code_verifier: r,
      entity_type: o,
      entity_code: i,
      carrier: a,
      office: c,
      country: s,
      oidc_id_token: u
    } = e;
    return new Promise((e, l) => {
      this.postToAccessToken({
        code: n,
        nonce: t,
        carrier: a,
        code_verifier: r,
        redirect_uri: this.redirect_uri,
        grant_type: "authorization_code",
        entity_type: o,
        entity_code: i,
        country: s,
        office: c,
        oidc_id_token: u
      }).then(n => {
        n.nonce === t ? e(n) : l({
          error: "nonce_mismatch",
          error_description: "There was some messing with the request"
        });
      }).catch(l);
    });
  }
  refreshTokens(e) {
    let {
      refresh_token: t,
      entity_type: n,
      entity_code: r,
      carrier: o,
      office: i,
      country: a,
      oidc_id_token: c
    } = e;
    return new Promise((e, s) => {
      this.postToAccessToken({
        refresh_token: t,
        grant_type: "refresh_token",
        carrier: o,
        entity_type: n,
        entity_code: r,
        country: a,
        office: i,
        oidc_id_token: c
      }).then(e).catch(s);
    });
  }
}
function cs(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), n.push.apply(n, r);
  }
  return n;
}
class ss {
  constructor(t) {
    let {
      client_id: n,
      authority: r,
      realm: o = "mau",
      redirect_uri: i = window.location.href,
      storage: a
    } = t;
    e(this, "ams", void 0), e(this, "DEFAULTS", {
      response_type: "code",
      scopes: ["openid", "profile", "email"]
    }), e(this, "storage", void 0), e(this, "client_id", void 0), e(this, "authority", void 0), e(this, "redirect_uri", void 0), e(this, "realm", void 0), this.client_id = n, this.authority = r, this.realm = o, this.redirect_uri = i, this.storage = new is({
      type: null == a ? void 0 : a.type,
      namespace: (null == a ? void 0 : a.namespace) || ["al", r, n].join("."),
      namespace_separator: (null == a ? void 0 : a.namespace_separator) || "."
    }), this.ams = new as({
      storage: this.storage,
      client_id: n,
      authority: r,
      redirect_uri: i
    });
  }
  checkIfUrlHasOAuth2Response() {
    if (window.location.hash) {
      const e = Ba(window.location.hash);
      return e.token_type && e.id_token && e.access_token && e.expires_in ? e : null;
    }
    if (window.location.search) {
      const e = Ba(window.location.search);
      return e.error && e.error_description || e.code && e.iss && e.client_id ? e : null;
    }
    return null;
  }
  storeAuthTokens(e) {
    e.id_token && (this.storage.id_token = e.id_token), this.storage.access_token = e.access_token, this.storage.refresh_token = e.refresh_token, this.storage.token_type = e.token_type, this.storage.expiry_date = (new Date().getTime() + 1e3 * parseInt(e.expires_in)).toString();
  }
  getLoginPayload(e) {
    let {
      scopes: t = this.DEFAULTS.scopes,
      response_type: n = this.DEFAULTS.response_type
    } = e;
    const r = {
      response_type: n,
      scope: t.join(" "),
      authority: this.authority,
      redirect_uri: this.redirect_uri,
      client_id: this.client_id,
      nonce: Va(20),
      state: Va(20)
    };
    return n.includes("code") && (r.code_verifier = Va(43), r.code_challenge = Ga(r.code_verifier), r.code_challenge_method = "S256"), r;
  }
  getTokenSilently(t) {
    let {
      carrier: n,
      scopes: r,
      response_type: o,
      entity_type: i,
      entity_code: a,
      office: c,
      country: s,
      oidc_id_token: u,
      iframe_attributes: l = {}
    } = t;
    const f = this.getLoginPayload({
      scopes: r,
      response_type: o
    });
    this.storage.login_flow_config = {
      entity_type: i,
      entity_code: a,
      carrier: n,
      office: c,
      country: s,
      oidc_id_token: u,
      type: "silent",
      nonce: f.nonce,
      code_verifier: f.code_verifier,
      response_type: f.response_type
    };
    const d = {
      nonce: f.nonce,
      scope: f.scope,
      state: f.state,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: f.response_type
    };
    d.response_type.includes("code") && (d.code_challenge = f.code_challenge, d.code_challenge_method = f.code_challenge_method);
    const h = Wa(d),
      p = function (t) {
        for (var n = 1; n < arguments.length; n++) {
          var r = null != arguments[n] ? arguments[n] : {};
          n % 2 ? cs(Object(r), !0).forEach(function (n) {
            e(t, n, r[n]);
          }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : cs(Object(r)).forEach(function (e) {
            Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
          });
        }
        return t;
      }({}, l);
    return p.src = `${this.authority}/acm/oauth2/realms/${this.realm}/authorize?${h}`, p.id = "authorization_iframe_el", p.style = p.style ? p.style + " display: none;" : "display: none;", new Promise((e, t) => {
      Ja({
        parentEl: document.body,
        attributes: p,
        listenFor: "login_silent"
      }).then(n => {
        n.code ? this.handleResponse(n).then(e).catch(t).finally(() => this.storage.login_flow_config = null) : n.error ? t(n) : t({
          error: "UnexpectedResponse",
          error_description: "Unexpected response from silent login iframe: " + n.toString()
        });
      });
    });
  }
  handleResponse(e) {
    const {
      error: t,
      error_description: n,
      code: r,
      client_id: o,
      iss: i,
      token_type: a,
      id_token: c,
      access_token: s,
      expires_in: u
    } = e;
    return t && n ? this.handleOAuth2Error({
      error: t,
      error_description: n
    }) : r && i && o ? o === this.client_id ? this.handleOAuth2PKCEResponse({
      code: r,
      iss: i
    }) : Promise.reject({
      error: "InvalidClient",
      error_description: "Response received from unexpected client id: " + o
    }) : a && c && s && u ? this.handleOAuth2ImplicitResponse({
      token_type: a,
      id_token: c,
      access_token: s,
      expires_in: u
    }) : Promise.reject({
      error: "InvalidResponse",
      error_description: "Invalid response received from OAuth2 server: " + e.toString()
    });
  }
  handleOAuth2Error(e) {
    let {
      error: t,
      error_description: n
    } = e;
    return Promise.reject({
      error: decodeURIComponent(t),
      error_description: decodeURIComponent(n)
    });
  }
  handleOAuth2ImplicitResponse(e) {
    let {
      token_type: t,
      id_token: n,
      access_token: r,
      expires_in: o
    } = e;
    return new Promise((e, i) => {
      var a, c;
      return this.storage.id_token = n, (null === (a = this.storage.login_flow_config) || void 0 === a ? void 0 : a.nonce) === (null === (c = this.storage.id_token_claims) || void 0 === c ? void 0 : c.nonce) ? (this.storeAuthTokens({
        token_type: t,
        id_token: n,
        access_token: r,
        expires_in: o,
        refresh_token: null
      }), e({
        token_type: t,
        id_token: n,
        access_token: r,
        expires_in: o,
        refresh_token: null
      })) : (this.storage.id_token = null, i({
        error: "NonceMissmatch",
        error_description: "Expected nonce doesn't match received one"
      }));
    });
  }
  handleOAuth2PKCEResponse(e) {
    let {
      code: t,
      iss: n
    } = e;
    return this.storage.realm = decodeURIComponent(n).split("/oauth2")[1] || "/mau", new Promise((e, n) => {
      var r, o, i, a, c, s, u, l;
      const f = {
        code: t,
        carrier: null === (r = this.storage.login_flow_config) || void 0 === r ? void 0 : r.carrier,
        nonce: null === (o = this.storage.login_flow_config) || void 0 === o ? void 0 : o.nonce,
        code_verifier: null === (i = this.storage.login_flow_config) || void 0 === i ? void 0 : i.code_verifier,
        entity_type: null === (a = this.storage.login_flow_config) || void 0 === a ? void 0 : a.entity_type,
        entity_code: null === (c = this.storage.login_flow_config) || void 0 === c ? void 0 : c.entity_code
      };
      null !== (s = this.storage.login_flow_config) && void 0 !== s && s.office && (f.office = this.storage.login_flow_config.office), null !== (u = this.storage.login_flow_config) && void 0 !== u && u.country && (f.country = this.storage.login_flow_config.country), null !== (l = this.storage.login_flow_config) && void 0 !== l && l.oidc_id_token && (f.oidc_id_token = this.storage.login_flow_config.oidc_id_token), this.ams.getAccessToken(f).then(t => {
        this.storeAuthTokens(t), e(t);
      }).catch(n);
    });
  }
  refreshTokens(e) {
    let {
      entity_type: t,
      entity_code: n,
      office: r,
      country: o,
      oidc_id_token: i,
      carrier: a
    } = e;
    return new Promise((e, c) => {
      var s, u;
      this.storage.refresh_token ? null !== (s = this.storage.refresh_token_claims) && void 0 !== s && s.exp && 1e3 * (null === (u = this.storage.refresh_token_claims) || void 0 === u ? void 0 : u.exp) < Date.now() + 6e4 ? c({
        error: "RefreshTokenExpired",
        error_description: "Refresh token has expired"
      }) : this.ams.refreshTokens({
        entity_type: t,
        entity_code: n,
        refresh_token: this.storage.refresh_token,
        office: r,
        country: o,
        oidc_id_token: i,
        carrier: a
      }).then(t => {
        this.storeAuthTokens(t), e(t);
      }).catch(c) : c({
        error: "RefreshTokenMissing",
        error_description: "No refresh token is available for the client"
      });
    });
  }
  logoutClient() {
    return new Promise(e => {
      this.storage.clear(), e();
    });
  }
}
function us(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), n.push.apply(n, r);
  }
  return n;
}
function ls(t) {
  for (var n = 1; n < arguments.length; n++) {
    var r = null != arguments[n] ? arguments[n] : {};
    n % 2 ? us(Object(r), !0).forEach(function (n) {
      e(t, n, r[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : us(Object(r)).forEach(function (e) {
      Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
    });
  }
  return t;
}
class fs extends ss {
  constructor(t) {
    let {
      client_id: n,
      authority: r,
      realm: o,
      redirect_uri: i,
      storage: a,
      window_namespace: c,
      post_logout_uri: s,
      login_redirect_cb: u
    } = t;
    super({
      client_id: n,
      authority: r,
      realm: o,
      redirect_uri: i,
      storage: a,
      window_namespace: c
    }), e(this, "post_logout_uri", void 0), e(this, "login_redirect_cb", void 0), this.post_logout_uri = s, this.login_redirect_cb = u, this.handleIfOngoingLogin();
  }
  handleIfOngoingLogin() {
    const e = ["redirect", "silent"].find(e => {
      var t;
      return e === (null === (t = this.storage.login_flow_config) || void 0 === t ? void 0 : t.type);
    });
    if (e) {
      const t = this.checkIfUrlHasOAuth2Response();
      if (t) if ("redirect" === e) {
        let e, n;
        this.handleResponse(t).then(t => {
          this.storage.login_flow_config = null, e = t;
        }).catch(e => {
          n = e;
        }).finally(() => {
          this.login_redirect_cb && this.login_redirect_cb(e, n);
        });
      } else "silent" === e && $a(ls({
        type: "login_silent"
      }, t));
    }
  }
  loginRedirect(e) {
    let {
      scopes: t,
      response_type: n,
      entity_type: r,
      entity_code: o,
      extra_params: i = {}
    } = e;
    const a = this.getLoginPayload({
      scopes: t,
      response_type: n
    });
    this.storage.login_flow_config = {
      entity_type: r,
      entity_code: o,
      type: "redirect",
      nonce: a.nonce,
      code_verifier: a.code_verifier,
      response_type: a.response_type
    };
    const c = Wa(ls({
      nonce: a.nonce,
      scope: a.scope,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: a.response_type,
      code_challenge: a.code_challenge
    }, i));
    window.location.assign(this.authority + "/auth/login?" + c);
  }
  logoutRedirect() {
    let {
      post_logout_uri: e = this.post_logout_uri
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    this.storage.clear();
    let t = this.authority + "/auth/logout";
    e && (t += "?" + Wa({
      redirect_uri: e
    })), window.location.assign(t);
  }
  setScopesRedirect(e) {
    let {
      scopes: t,
      response_type: n
    } = e;
    const r = document.createElement("FORM"),
      o = this.getLoginPayload({
        scopes: t,
        response_type: n
      });
    this.storage.login_flow_config = {
      type: "silent",
      nonce: o.nonce,
      code_verifier: o.code_verifier,
      response_type: o.response_type
    };
    const i = {
      nonce: o.nonce,
      scope: o.scope,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: o.response_type,
      code_challenge: o.code_challenge
    };
    r.name = "Authorization Form", r.method = "GET", r.action = this.authority + "/oauth2/v1.0/authorize";
    for (const e in i) {
      const t = document.createElement("INPUT");
      t.type = "HIDDEN", t.name = e, t.value = i[e], r.appendChild(t);
    }
    document.body.appendChild(r), r.submit();
  }
}
function ds(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), n.push.apply(n, r);
  }
  return n;
}
function hs(t) {
  for (var n = 1; n < arguments.length; n++) {
    var r = null != arguments[n] ? arguments[n] : {};
    n % 2 ? ds(Object(r), !0).forEach(function (n) {
      e(t, n, r[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r)) : ds(Object(r)).forEach(function (e) {
      Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e));
    });
  }
  return t;
}
class ps extends ss {
  constructor(t) {
    let {
      client_id: n,
      authority: r,
      realm: o,
      redirect_uri: i,
      storage: a,
      window_namespace: c,
      lang_code: s
    } = t;
    super({
      client_id: n,
      authority: r,
      realm: o,
      redirect_uri: i,
      storage: a,
      window_namespace: c
    }), e(this, "lang_code", void 0), this.lang_code = s, this.handleIfOngoingLogin();
  }
  handleIfOngoingLogin() {
    const e = ["iframe", "silent"].find(e => {
      var t;
      return e === (null === (t = this.storage.login_flow_config) || void 0 === t ? void 0 : t.type);
    });
    if (e) {
      const t = this.checkIfUrlHasOAuth2Response();
      t && $a(hs({
        type: "login_" + e
      }, t));
    }
  }
  loginIframe(e) {
    let {
      scopes: t,
      response_type: n,
      parent_el: r
    } = e;
    const o = this.getLoginPayload({
      scopes: t,
      response_type: n
    });
    this.storage.login_flow_config = {
      type: "iframe",
      nonce: o.nonce,
      code_verifier: o.code_verifier,
      response_type: o.response_type
    };
    const i = Wa({
      nonce: o.nonce,
      scope: o.scope,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: o.response_type,
      code_challenge: o.code_challenge,
      lang_code: this.lang_code
    });
    return new Promise((e, t) => {
      r ? Ja({
        parentEl: r,
        attributes: {
          src: this.authority + "/auth/login?" + i,
          id: "login_iframe_el"
        },
        resizer: !0,
        listenFor: "login_iframe"
      }).then(n => {
        this.handleResponse(n).then(e).catch(t);
      }) : t({
        error: "MissingRequiredParentEl",
        error_description: "Missing required parent_el parameter"
      });
    });
  }
  logoutIframe() {
    let {
      iframe_attributes: e = {}
    } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
    const t = this.authority + "/auth/logout",
      n = hs({}, e);
    return n.src = t, n.id = "logout_iframe_el", n.style = n.style ? n.style + " display: none;" : "display: none;", new Promise((e, t) => {
      Ja({
        parentEl: document.body,
        attributes: n,
        listenFor: "logout_iframe"
      }).then(n => {
        n.success ? e(n) : n.error ? t(n) : t({
          error: "UnexpectedResponse",
          error_description: "Unexpected response from logout iframe"
        });
      }).finally(() => this.storage.clear());
    });
  }
  setScopesIframe(e) {
    let {
      scopes: t,
      response_type: n,
      parent_el: r
    } = e;
    const o = this.getLoginPayload({
      scopes: t,
      response_type: n
    });
    this.storage.login_flow_config = {
      type: "iframe",
      nonce: o.nonce,
      code_verifier: o.code_verifier,
      response_type: o.response_type
    };
    const i = Wa({
      nonce: o.nonce,
      scope: o.scope,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      response_type: o.response_type,
      code_challenge: o.code_challenge,
      lang_code: this.lang_code
    });
    return new Promise((e, t) => {
      r ? Ja({
        parentEl: r,
        attributes: {
          src: this.authority + "/oauth2/v1.0/authorize?" + i,
          id: "authorize_iframe_el",
          style: "display: none;"
        },
        listenFor: "login_iframe"
      }).then(n => {
        this.handleResponse(n).then(e).catch(t);
      }) : t({
        error: "MissingRequiredParentEl",
        error_description: "Missing required parent_el parameter"
      });
    });
  }
}
export { ps as UserAgentHomeApp, fs as UserAgentPublicApp, qa as decodeJwtBody, Ga as generateCodeChallenge, Va as generateNonce };