var p = Object.defineProperty;
var h = Object.getOwnPropertyDescriptor;
var s = (d, n, e, t) => {
  for (var r = t > 1 ? void 0 : t ? h(n, e) : n, o = d.length - 1, a; o >= 0; o--) (a = d[o]) && (r = (t ? a(n, e, r) : a(r)) || r);
  return t && r && p(n, e, r), r;
};
import { LitElement as y, html as b } from "lit";
import { classMap as E } from "lit/directives/class-map.js";
import { ifDefined as k } from "lit/directives/if-defined.js";
import { property as l, query as m } from "lit/decorators.js";
import { DisabledState as V, FormField as C, setPropsForSlottedComponents as N } from "@maersk-global/mds-components-utils";
import { unsafeCSS as g } from "lit";
import v from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import S from "@maersk-global/mds-components-utils/lib/host/input-disabled.styles.js";
import { css as f } from "lit-element/lit-element.js";
var u = f`.mc-multi-choice-fieldset{padding:0;margin:0;border:0}.mc-multi-choice-fieldset legend{padding:0}.mc-multi-choice-fieldset .hiddenlegend{height:0}.mc-multi-choice-fieldset.small .slot{gap:var(--mds_core_multi-choice-fieldset_small_items_vertical_gap);margin-top:var(--mds_core_multi-choice-fieldset_small_items_margin-top)}.mc-multi-choice-fieldset.small.horizontal .slot{column-gap:var(--mds_core_multi-choice-fieldset_small_items_horizontal_gap)}.mc-multi-choice-fieldset.small.no-feedback .slot{margin-top:0}.mc-multi-choice-fieldset.medium .slot{gap:var(--mds_core_multi-choice-fieldset_medium_items_vertical_gap);margin-top:var(--mds_core_multi-choice-fieldset_medium_items_margin-top)}.mc-multi-choice-fieldset.medium.horizontal .slot{column-gap:var(--mds_core_multi-choice-fieldset_medium_items_horizontal_gap)}.mc-multi-choice-fieldset.medium.no-feedback .slot{margin-top:0}.mc-multi-choice-fieldset.large .slot{gap:var(--mds_core_multi-choice-fieldset_large_items_vertical_gap);margin-top:var(--mds_core_multi-choice-fieldset_large_items_margin-top)}.mc-multi-choice-fieldset.large.horizontal .slot{column-gap:var(--mds_core_multi-choice-fieldset_large_items_horizontal_gap)}.mc-multi-choice-fieldset.large.no-feedback .slot{margin-top:0}.mc-multi-choice-fieldset.hidden-legend legend{display:none}.mc-multi-choice-fieldset,.mc-multi-choice-fieldset .slot{display:flex;flex-direction:column}.mc-multi-choice-fieldset.horizontal .slot{flex-direction:row}@media only screen and (max-width:599px){.mc-multi-choice-fieldset.autolayout .slot{flex-direction:column}}`;
import _ from "@maersk-global/mds-design-tokens/implementation/core/multi-choice-fieldset/js/design-tokens-px.css.js";
var c = [v, S, _, g(u)];
import "@maersk-global/mds-components-core-error";
import "@maersk-global/mds-components-core-hint";
import "@maersk-global/mds-components-core-label";
var i = class extends V(C(y)) {
  constructor() {
    super(...arguments);
    this.groupFirstUpdated = !1;
    this.autolayoutdisabled = !1;
    this.fit = "medium";
    this.hiddenlegend = !1;
    this.invalid = !1;
    this.legend = "Legend";
    this.name = "";
    this.orientation = "vertical";
    this.inputsSlotIsReady = () => {
      let e = this.pruneTextNodesFromSlottedElements();
      this.inputs = e?.filter(t => t.nodeName !== "#text" && t.nodeName.toLowerCase() !== "input"), !this.groupFirstUpdated && this.inputs && this.inputs.length > 0 && (this.groupFirstUpdated = !0, this.value ? this.setCheckedStatusBySuppliedValue() : this.value = this.getSelectedValues(), this.setTheNamePropertyFromSlottedComponents(), this.addMirroredHiddenInput());
    };
  }
  static get styles() {
    return c;
  }
  render() {
    let e = {
      "no-feedback": this.hiddenlegend && !this.invalid && !this.hintElement?.visible,
      autolayout: !this.autolayoutdisabled,
      [`${this.orientation}`]: !0,
      [`${this.fit}`]: !0
    };
    return b`<div role="group" aria-labelledby="label" class="mc-multi-choice-fieldset ${E(e)}"><legend class="${this.hiddenlegend ? "hiddenlegend" : ""}" aria-label="${k(this.hiddenlegend ? this.legend : void 0)}"><mc-label id="label" .label="${this.legend}" .fit="${this.fit}" ?hiddenlabel="${this.hiddenlegend}"><slot name="legend">${this.legend}</slot></mc-label></legend><mc-error id="invalid" .errormessage="${this.errormessage}" .fit="${this.fit}" ?invalid="${this.invalid}"><slot name="errormessage">${this.errormessage}</slot></mc-error><mc-hint id="hint" .hint="${this.hint}" .fit="${this.fit}"><slot name="hint">${this.hint}</slot></mc-hint><div class="slot"><slot></slot></div></div>`;
  }
  firstUpdated(e) {
    super.firstUpdated(e), this.initializeElements(), this.fieldset?.addEventListener("change", t => this.onChange(t));
  }
  updated(e) {
    super.updated(e), this.inputs && (N(this.inputs, "fit", this.fit), e.has("value") && this.setCheckedStatusBySuppliedValue());
  }
  connectedCallback() {
    super.connectedCallback(), this.setAttribute("role", "group");
  }
  disconnectedCallback() {
    this.inputsSlot?.removeEventListener("slotchange", this.inputsSlotIsReady), super.disconnectedCallback();
  }
  onChange(e) {
    e.stopPropagation(), this.value = this.getSelectedValues(), this.dispatchEvent(new CustomEvent("change", {
      detail: this.value
    }));
  }
  setCheckedStatusBySuppliedValue() {
    if (!this.inputs || !this.value && this.value !== "") return;
    let e = this.getValues();
    this.inputs?.forEach(t => e.find(r => {
      let o = this.getStringifiedValue(r),
        a = this.getStringifiedValue(t.localName === "mc-radio" ? t.value : t.cachedValue);
      return o === a;
    }) !== void 0 ? (t.checked = !0, !0) : (t.checked = !1, !1));
  }
  getStringifiedValue(e) {
    return e && (Array.isArray(e) || typeof e == "object") ? JSON.stringify(e) : `${e}`;
  }
  getValues() {
    return typeof this.value == "string" ? this.value.split(",") : Array.isArray(this.value) ? this.value : [this.value];
  }
  getSelectedValues() {
    if (this.inputs) return this.inputs[0].localName === "mc-radio" ? this.inputs.find(e => e.checked)?.value : this.inputs.reduce((e, t) => t.checked ? [t.value, ...e] : e, []);
  }
  initializeElements() {
    this.inputsSlot = this.shadowRoot?.querySelector("slot:not([name])"), this.inputsSlot?.addEventListener("slotchange", this.inputsSlotIsReady);
  }
  setTheNamePropertyFromSlottedComponents() {
    if (this.inputs && this.inputs.length !== 0) {
      let e = this.inputs[0];
      this.setAttribute("name", e.getAttribute("name") || "");
    }
  }
  pruneTextNodesFromSlottedElements() {
    let e = this.inputsSlot?.assignedElements();
    return e?.filter(t => t.nodeName === "#text").forEach(t => t.remove()), e;
  }
};
s([l({
  type: Boolean
})], i.prototype, "autolayoutdisabled", 2), s([m(".mc-multi-choice-fieldset")], i.prototype, "fieldset", 2), s([m("mc-hint")], i.prototype, "hintElement", 2), s([m("mc-error")], i.prototype, "errorElement", 2), s([l({
  type: String
})], i.prototype, "errormessage", 2), s([l({
  type: String
})], i.prototype, "fit", 2), s([l({
  type: Boolean
})], i.prototype, "hiddenlegend", 2), s([l({
  type: String
})], i.prototype, "hint", 2), s([l({
  type: Boolean,
  reflect: !0
})], i.prototype, "invalid", 2), s([l({
  type: String
})], i.prototype, "legend", 2), s([l({
  type: String,
  reflect: !0
})], i.prototype, "name", 2), s([l({
  type: String
})], i.prototype, "orientation", 2), s([l({
  type: String,
  reflect: !0
})], i.prototype, "value", 2);
export { i as McMultiChoiceFieldset };