<form  class="list-work-order-container">
    <div class="pure-g">
        <div class="pure-u-md-10-24 pure-u-lg-14-24">
            <h3 class="inline m-r-4" i18n>Master Data Configuration</h3>
        </div>
    </div>
<app-master-data-header [masterHeaderForm]="masterFormGroup.controls.masterHeaderForm" (valueChange)="onValueChange($event)" ></app-master-data-header>
<app-master-data-filter [masterFormGroup]="masterFormGroup" [receivedFileChangeValue]="fileChangeValue"  ></app-master-data-filter>
<!-- @if(fileChangeValue!=0){ -->
<app-master-data-grid [masterFormGroup]="masterFormGroup" class="table-container"  [receivedFileChangeValue]="fileChangeValue" [receivedValue]="valueClick"></app-master-data-grid>
<!-- } -->
</form>
