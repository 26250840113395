import { ApplicationConfig } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { addHeadersInterceptor } from './interceptors/add-headers.interceptor';
import { http401Interceptor } from './interceptors/http401.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(APP_ROUTES, withComponentInputBinding()),
  provideHttpClient(withInterceptors([addHeadersInterceptor, http401Interceptor])),
  provideAnimations(),
  ]

};

