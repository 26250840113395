<div class="aems-bread-crumb m-b-16">
  <a class="m-r-4" routerLink="/workorders">Work Orders</a><span>/ {{isEditMode()?"Edit":"Create"}}</span>
</div>
<h3 #top class="m-b-12">
  {{isEditMode()?"Edit Work Order-"+workorderId:"Create Work Order"}}</h3>
<form [formGroup]="createWorkOrderForm">
  @if(formSubmitErrors().length>0){
  <div class="error m-b-8">
    <mc-icon class="error-icon" icon="exclamation-octagon" size="20"></mc-icon>
    <div class="title">Errors</div>
    <ul>
      @for (error of formSubmitErrors(); track $index) {
      <li>{{error}}</li>
      }
    </ul>
  </div>
  }
  <div class="flex-column">
    @if(formSubmitErrors().length === 0){
    <app-error [formContainer]="createWorkOrderForm"></app-error>
    }
  </div>
  <!-- If Id exist then its edit mode-->
    @if(workorderId){
  <app-edit-work-order-header [headerForm]="createWorkOrderForm.controls.headerForm" [workorderId]="workorderId" (headerFormValid)="headerFormValidChange($event)"
  (principalPropertyChanged)="principalPropertyChanged($event)" (shopChanged)="shopChanged($event)"
  (equipmentChanged)="equipmentChanged($event)" (modeChanged)="modeChanged($event)"></app-edit-work-order-header>
  }@else{
  <app-cwo-header [headerForm]="createWorkOrderForm.controls.headerForm" (headerFormValid)="headerFormValidChange($event)"
    (principalPropertyChanged)="principalPropertyChanged($event)" (shopChanged)="shopChanged($event)"
    (equipmentChanged)="equipmentChanged($event)" (modeChanged)="modeChanged($event)"></app-cwo-header>
  }
  @if(headerFormValid()){
  <app-cwo-container-details [equipment]="equipment" [shopLocationCode]="shop.locationCode"></app-cwo-container-details>
  <app-cwo-attachments [attachmentForm]="createWorkOrderForm.controls.attachmentForm"
    [shopCode]="shop.maintenanceShopCode" [files]="this.workorder?.blobResolvedFiles??[]"></app-cwo-attachments>

  <!--Show Alarms components only when alarms are present and selected repair mode is non structural-->
  @if(equipment.alarms && equipment.alarms.length > 0 && mode.damageType==1 ){
  <app-cwo-alarm-details [equipment]="equipment"></app-cwo-alarm-details>
  }
  @if(gettingDataFromAPIForRepairLineItemsForm()){
  <div class="aems-card m-b-12">
    <div class="pure-g">
      <div class="pure-u-md-8-24 p-t-12 pure-u-lg-6-24 p-b-16 p-r-8">
        <ngx-skeleton-loader [theme]="{ height: '30px' }" count="1" appearance="line" />
      </div>
      <hr>
    </div>
    <div class="pure-g">
      <div class="pure-u-md-3-24 p-t-12 pure-u-lg-2-24 p-b-16 p-r-8">
        <ngx-skeleton-loader [theme]="{ height: '30px' }" count="1" appearance="line" />
      </div>
    </div>
  </div>
  }@else{
  <app-cwo-line-items [damageAndRepairForm]="createWorkOrderForm.controls.damageAndRepairForm"
    [containerGrades]="containerGrades" [shop]="shop" [mode]="mode" [equipment]="equipment" [repairs]="repairs"
    [repairLocations]="repairLocations" [damages]="damages" [tpiCodes]="tpiCodes" [repairExclusions]="repairExclusions"
    [existingRepairLineItems]="this.workorder?.maintenanceOrderLines" 
    [workOrderCurrency]="this.workOrderCurrency">
  </app-cwo-line-items>
  <app-cwo-man-hours [manHoursThatQualifyForPrepTime]="manHoursThatQualifyForPrepTime"
    [manHoursForm]="createWorkOrderForm.controls.manHoursForm" [mode]="mode"
    [totalManHoursWithoutPrepTime]="totalManhoursWithoutPrepTime" [isPrepTimeApplicable]="isPrepTimeApplicable"
    [existingManHours]="workorder?.maintenanceOrderManHours??[]"></app-cwo-man-hours>
  }

  @if(cost!=null){
  <app-cwo-total-cost [apiCallStatus]="calculateTotalApiCallStatus" [totalCostForm]="createWorkOrderForm.controls.totalCostForm" [cost]="cost" [repairLineItemsAndManhourFormValid]="repairLineItemsAndManhourFormValid"></app-cwo-total-cost>
  }
  <app-cwo-remarks [workOrderRemarks]="workorder?.maintenanceOrderRemarks??[]"  [remarkForm]="createWorkOrderForm.controls.remarkForm" [isNewWorkOrder]=true></app-cwo-remarks>
  <div style="text-align: right">
    <mc-button label="Cancel" variant="outlined" appearance="primary" fit="medium" padding="default" class="m-r-12"
      (click)="onCancelClicked()" [disabled]="submitLoader">
    </mc-button>
    <mc-button data-cy="submit" variant="filled" appearance="primary" fit="medium" padding="default"
      [disabled]="createWorkOrderForm.invalid" (click)="onSubmitClicked(top)" [loading]="submitLoader">
      {{workorderId?'Re-submit':'Submit'}}
    </mc-button>
  </div>
  }
</form>
<mc-modal heading="Cancel Work Order" fit="small" dimension="small" [open]="openCancelConfirmation"
  (closed)="onCancelConfirmationClosed()">
  <p style="font-size: 0.8rem;">The work order details will be deleted permanently. Are you sure you want to cancel?</p>
  <mc-button slot="secondaryAction" dialogaction="cancel" appearance="neutral" variant="outlined"
    (click)="onCancelRejection()">Cancel</mc-button>
  <mc-button slot="primaryAction" appearance="primary" (click)="onCancelConfirmation()">Yes</mc-button>
</mc-modal>