import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FlagOnOffFilterForm } from '../../../form-model/flag-on-off-filter.form.model';
import { MasterDataService } from '../../../services/master-data.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { RuleValidation } from '../../../models/rule-validation.model';
import { Cluster, Loc, LocCluster, ShopCode } from '../../../models/loc.model';
import { catchError, firstValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { ToastrService } from '../../../services/toastr-service';
import { FlagOnOffConfigService } from '../flag-on-off-config.service';
import { User } from '../../../models/user.model';
import { AuthHelper } from '../../../auth/auth-helper';
import { APICallStatus } from '../../../models/api-call-status.mode';

@Component({
    selector: 'app-flag-on-off-advance-filter',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    templateUrl: './flag-on-off-advance-filter.component.html',
    styleUrl: './flag-on-off-advance-filter.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class FlagOnOffAdvanceFilterComponent {
  @Output() private onClickEvents = new EventEmitter<boolean>();

  @Input({ required: true }) flagFilterForm!: FormGroup<FlagOnOffFilterForm>;
  validation: RuleValidation[] = [];
  countrycluster: Cluster[] = [];
  shop: ShopCode[] = [];
  location: Loc[] = [];
  locList: Loc[] = [];
  clusterList: Cluster[] = [];
  shopList: ShopCode[] = [];
  cluster: Cluster[] = [];
  loggedinUser: User | null = AuthHelper.user;
  addClick: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  getLocApiCall: APICallStatus = "NOT_STARTED";
  getRuleApiCall: APICallStatus = "NOT_STARTED";
  getClusterApiCall: APICallStatus = "NOT_STARTED";
  getShopApiCall: APICallStatus = "NOT_STARTED";
  saveValidationApiCall: APICallStatus = "NOT_STARTED";
  constructor(private masterDataService: MasterDataService,
    private formBuilder: FormBuilder, private flagConfigService: FlagOnOffConfigService,
    private toastrService: ToastrService, private router: Router) {
  }

  ngOnInit() {
    this.addControlsToTheFormGroup();
    this.subscribeToMasterDataApiCalls();
  }

  private addControlsToTheFormGroup() {
    this.flagFilterForm.addControl('location', this.formBuilder.control<Loc[] | null>(null));
    this.flagFilterForm.addControl('validation', this.formBuilder.control<RuleValidation | null>(null));
    this.flagFilterForm.addControl('countrycluster', this.formBuilder.control<Cluster[] | null>(null));
    this.flagFilterForm.addControl('shop', this.formBuilder.control<ShopCode[] | null>(null));
    this.flagFilterForm.addControl('applyFlagFilter', this.formBuilder.control<boolean | null>(null));
  }

  isControlInErrorState(control: FormControl): boolean | null {
    if (control.invalid && control.touched) {
      return true;
    }
    return null;
  }

  private subscribeToMasterDataApiCalls() {
    this.getAllLoc();
    this.getAllCluster();
    this.getAllShop();
    this.getAllRuleValidation();

    this.location = this.locList;
    this.countrycluster = this.clusterList;
    this.shop = this.shopList;

  }
  async getAllLoc() {
    this.locList = await firstValueFrom(this.flagConfigService.getAllLocList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getLocApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get LOC details", "error");
          throw err;
        })));
    this.location = this.locList;

  }
  async getAllCluster() {
    this.clusterList = await firstValueFrom(this.flagConfigService.getAllClusterList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getClusterApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get LOC details", "error");
          throw err;
        })));
    this.countrycluster = this.clusterList;

  }
  async getAllShop() {
    this.shopList = await firstValueFrom(this.flagConfigService.getAllShopList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getShopApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get LOC details", "error");
          throw err;
        })));
    this.shop = this.shopList;
  }

  async getAllRuleValidation() {
    this.validation = await firstValueFrom(this.flagConfigService.getAllValidationList()
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getRuleApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Validation details", "error");
          throw err;
        })));
  }
  async getClusterByLoc(region: string) {
    let selectedRegion = {};
    selectedRegion = {
      locs: region
    }
    this.cluster = await firstValueFrom(this.flagConfigService.getClusterByRegion(selectedRegion)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getClusterApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Cluster details", "error");
          throw err;
        })));
    this.countrycluster = this.cluster

  }
  async getShopsByCluster(cluster: string) {
    let requestBody: {};
    requestBody = {
      clusterCodes: this.flagFilterForm.controls.countrycluster.value,
      locs: this.flagFilterForm.controls.location.value,
    }
    this.shop = await firstValueFrom(this.flagConfigService.getShopsByCluster(requestBody)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.getClusterApiCall = "FAILED";
          this.toastrService.showToastr("Unable to get Cluster details", "error");
          throw err;
        })));
  }
  applyFilters() {
    this.flagFilterForm.controls.applyFlagFilter.setValue(true);
    this.addClick = true;
    this.onClickEvents.emit(this.addClick);
  }

  clearFilters() {
    this.flagFilterForm.reset();
    this.countrycluster = this.clusterList
    this.shop = this.shopList
    this.flagFilterForm.controls.location.enable();
    this.flagFilterForm.controls.countrycluster.enable();
    this.flagFilterForm.controls.shop.enable();

  }
  onRegionSelected(event: Event) {
    let customEvent = event.target as HTMLInputElement;

    if (customEvent.value?.length !== 0) {
      this.getClusterByLoc(customEvent.value);
      this.flagFilterForm.controls.countrycluster.setValue(null);
      this.flagFilterForm.controls.shop.setValue(null);
      this.countrycluster = this.cluster
      this.flagFilterForm.controls.shop.disable();
    } else {
      this.flagFilterForm.controls.countrycluster.setValue(null);
      this.flagFilterForm.controls.shop.setValue(null);
      this.flagFilterForm.controls.shop.enable();
      this.countrycluster = this.clusterList;
      this.shop = this.shopList;

    }
  }
  onClusterSelected(event: Event) {
    let customEvent = event.target as HTMLInputElement;
    if (customEvent.value &&
      (this.flagFilterForm?.controls.location.value != null && this.flagFilterForm?.controls.location?.value.length != 0)) {
      this.getShopsByCluster(customEvent.value)
      this.flagFilterForm.controls.shop.setValue(null);
      this.flagFilterForm.controls.shop.enable();
    }
    else {
      this.flagFilterForm.controls.shop.setValue(null);
      this.shop = this.shopList
      this.flagFilterForm.controls.shop.disable();
    }
  }


}