import { inject, Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { catchError, map, Observable, of } from "rxjs";
import { AemsValidationError } from "../models/aems-validation-error.model";
import { WorkOrderService } from "../services/work-order.service";
import { MaintenanceOrderFilter } from "../models/maintenance-order-filter.model";
import { BulkWorkOrderSharedDataService } from "../services/bulk-work-order-shared-data.service";
import { Mode } from "../models/mode.model";
import { Shop } from "../models/shop.model";
import { MaintenanceOrderStatusCode } from "../models/maintenance-order-status.model";

@Injectable({ providedIn: 'root' })
export class EquipmentWorkorderExistValidator implements AsyncValidator {
    private workorderService: WorkOrderService
    private sharedDataService: BulkWorkOrderSharedDataService
    private selectedMode: Mode | null = null;
    private selectedShop: Shop | null = null;
    constructor() {
        this.workorderService = inject(WorkOrderService);
        this.sharedDataService = inject(BulkWorkOrderSharedDataService);
        this.sharedDataService.Mode.subscribe(mode => this.selectedMode = mode);
        this.sharedDataService.Shop.subscribe(shop => this.selectedShop = shop);

    }
    validate(control: AbstractControl): Observable<AemsValidationError | null> {
        if (control.value) {
            const statusTypes=`${MaintenanceOrderStatusCode.PendingApproval},${MaintenanceOrderStatusCode.SuspendToCENELM},${MaintenanceOrderStatusCode.SuspendForTTLCheck},${MaintenanceOrderStatusCode.PendingSpecialistApproval},${MaintenanceOrderStatusCode.DeclaredAsTTL},${MaintenanceOrderStatusCode.Approved}`
            const filter: MaintenanceOrderFilter = {
                equipmentNumber: control.value,
                maintenanceRepairModes: this.selectedMode?.code,
                maintenanceShopCodes: this.selectedShop?.maintenanceShopCode,
                maintenanceOrderStatusTypes: statusTypes
            }
            return this.workorderService.getWorkOrders(1, 1, "", filter).pipe(
                map(res => {
                    if (res && res.body) {
                        if (res.body.length > 0) {
                            return { equipmentWorkorderExist: { error: `Equipment has open workorder for mode ${this.selectedMode?.code} and shop ${this.selectedShop?.maintenanceShopCode}` } };
                        }
                        return null;
                    } else {
                        return null;
                    }
                }),
                catchError(err => of(null))
            )
        } else {
            return of(null);
        }
    }

}