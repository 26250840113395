import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ToastrService } from '../../../../services/toastr-service';
import { ApplicationtTextService } from '../../../../services/application-text.service';

export function duplicateRepairCodeValidator(
  pastRepairs: string[],toastrService:ToastrService,appTextSvc:ApplicationtTextService): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    let duplicateRepair = pastRepairs.find(x => x === repairCode)
    if (duplicateRepair) {
      toastrService.showToastr(
        appTextSvc.getDuplicateRepairCodeValidators(repairCode),
        'warning',
        60000
      );
    }
    return null;
  }
}
