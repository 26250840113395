import { a } from "../../chunk-SDBWQ4EE.js";
import { property as s } from "lit/decorators.js";
const l = n => {
  class t extends n {
    constructor() {
      super(...arguments);
      this.disabled = !1;
    }
    connectedCallback() {
      super.connectedCallback(), this.addEventListener("mousedown", this.onMouseDown.bind(this));
    }
    onMouseDown(e) {
      this.disabled && (e.detail > 1 && (e.preventDefault(), e.stopPropagation(), e.stopImmediatePropagation()), this.addEventListener("click", this.captureClick, !0));
    }
    captureClick(e) {
      e.stopPropagation(), this.removeEventListener("click", this.captureClick, !0);
    }
  }
  return a([s({
    type: Boolean,
    reflect: !0
  })], t.prototype, "disabled", 2), t;
};
export { l as DisabledState };