import "../../chunk-SDBWQ4EE.js";
import { getSelectedLocale as f } from "./utils.js";
const b = (r, n, o) => {
  const t = f(new Intl.NumberFormat().resolvedOptions().locale || "en-GB"),
    {
      type: e,
      options: c
    } = n || {};
  if (e === "number") {
    const i = c?.currency;
    return i ? `${y(+r, i || "USD", !0)}` : new Intl.NumberFormat(o || t, c).format(+r);
  }
  return r.toString();
};
function y(r, n, o = !1, t = {}) {
  const e = f("en");
  return g(r, n, e, o, t);
}
function g(r, n, o, t = !1, e = {}) {
  let c = "floor";
  e?.maximumFractionDigits === 0 && (c = e.roundingMode || "halfExpand");
  const m = new Intl.NumberFormat(`${o}-u-nu-latn`, {
    ...e,
    style: "currency",
    currency: n,
    currencyDisplay: "code",
    roundingMode: c
  }).formatToParts(r);
  let l = "",
    u = "";
  return m.forEach((a, s) => {
    a.type === "currency" && s === 0 ? l = a.value : !t && s === 1 && l !== "" || (a.type === "currency" && s === m.length - 1 ? (l = a.value + m[s - 1].value, u = u.slice(0, u.length - 1)) : u += a.value);
  }), `${t ? l : ""}${u}`;
}
export { b as format, y as formatCurrency, g as formatCurrencyWithLocale };