import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, Routes } from '@angular/router';
import { ListWorkOrdersComponent } from './views/list-work-orders/list-work-orders.component';
import { AuthCallback } from './auth/auth-callback.component';
import { ShellComponent } from './components/shell/shell.component';
import { CreateWorkOrderComponent } from './views/create-work-order/create-work-order.component';
import { AuthHelper } from './auth/auth-helper';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { AuthPolicies } from './auth/auth-policies';
import { inject } from '@angular/core';
import { ViewWorkOrderComponent } from './views/view-work-order/view-work-order.component';
import { MasterDataConfigComponent } from './views/master-data-config/master-data-config.component';
import { FlagOnOffConfigComponent } from './views/flag-on-off-config/flag-on-off-config.component';

import { BulkWorkOrderComponent } from './views/bulk-work-order/bulk-work-order.component';
 
 
 
const userAuthenticatedRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if (!AuthHelper.isAccessTokenPresentAndValid()) {
    AuthHelper.redirectToLogin();
  }
  return AuthHelper.isAccessTokenPresentAndValid();
}
 
const isUserShopOrAdminRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let authPolicies: AuthPolicies = inject(AuthPolicies);
  if (authPolicies.canCreateWorkOrder) {
    return true;
  }
  window.location.href = "/forbidden";
  return false;
}

const isUserAppSupportAdminRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let authPolicies: AuthPolicies = inject(AuthPolicies);
  if (authPolicies.isAppSupportAdmin) {
    return true;
  }
  window.location.href = "/forbidden";
  return false;
}
 
 
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [userAuthenticatedRouteGuard],
    children: [
      {
        path: 'workorders/edit/:workorderId',
        component: CreateWorkOrderComponent,
        canActivate: [userAuthenticatedRouteGuard, isUserShopOrAdminRouteGuard]
      },
      {
        path: 'workorders/create',
        component: CreateWorkOrderComponent,
        canActivate: [userAuthenticatedRouteGuard, isUserShopOrAdminRouteGuard]
      },
      {
        path: 'workorders',
        component: ListWorkOrdersComponent,
        canActivate: [userAuthenticatedRouteGuard]
      },
      {
        path: 'workorders/bulk-create',
        component: BulkWorkOrderComponent,
        canActivate: [userAuthenticatedRouteGuard]
      },
      {
        path: 'workorders/:id',
        component: ViewWorkOrderComponent,
        canActivate: [userAuthenticatedRouteGuard]
 
      },
      {
        path: 'reports/workorders-export',
        loadComponent: () => import('./views/report/report.component').then(mod => mod.ReportComponent),
        canActivate: [userAuthenticatedRouteGuard]
      },
      {
        path: 'reports/vsc',
        loadComponent: () => import('./views/vsc/vsc.component').then(mod => mod.VscComponent),
        canActivate: [userAuthenticatedRouteGuard]  
      },     
      {
        path: 'approvals',
        component: ListWorkOrdersComponent,
        canActivate: [userAuthenticatedRouteGuard]
      },
      {
        path: 'approvals/:id',
        loadComponent: () => import('./views/manual-approval-view-work-order/manual-approval-view-work-order.component').then(mod => mod.ManualApprovalViewWorkOrderComponent),
        canActivate: [userAuthenticatedRouteGuard]
      },
      {
        path: 'approvalSettings',
        canActivate: [userAuthenticatedRouteGuard],
        children: [
          {
            path: 'disableValidation',
            canActivate: [userAuthenticatedRouteGuard],
            component:FlagOnOffConfigComponent
            // loadComponent: () => import('./views/flag-on-off-config/flag-on-off-config.component').then(mod => mod.FlagOnOffConfigComponent),
          },
          {
            path: 'masterDataConfig',
            canActivate: [userAuthenticatedRouteGuard],
             component: MasterDataConfigComponent
            // loadComponent: () => import('./views/master-data-config/master-data-config.component').then(mod => mod.MasterDataConfigComponent),
          }
        ]
      },
      {
        path: 'Retrigger',
        loadComponent: () => import('./views/retrigger-work-order/retrigger-work-order.component').then(mod => mod.RetriggerWorkOrderComponent),
        canActivate: [userAuthenticatedRouteGuard,isUserAppSupportAdminRouteGuard]
      },
      {
        path: '',
        redirectTo: '/workorders',
        pathMatch: 'full'
      },
    ],
  },
  {
    path: 'oauth2/callback',
    component: AuthCallback,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  }
];
 
 