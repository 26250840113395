import { Component, Input, OnInit } from '@angular/core';
import { MaintenanceOrderResponse } from '../../../models/maintenance-order-response.model';
import { getDamageCodeDisplayText } from '../../../models/damage-cause.model';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-work-order-header',
    imports: [CommonModule],
    templateUrl: './work-order-header.component.html',
    styleUrl: './work-order-header.component.scss'
})
export class WorkOrderHeaderComponent implements OnInit{
ngOnInit(): void {
  this.workOrderHeader.maintenanceOrderCauseDisplayText=getDamageCodeDisplayText(this.workOrderHeader.maintenanceOrderCause)
}
@Input() workOrderHeader!:MaintenanceOrderResponse;
}
