var u = Object.defineProperty;
var h = Object.getOwnPropertyDescriptor;
var r = (l, o, t, e) => {
  for (var a = e > 1 ? void 0 : e ? h(o, t) : o, n = l.length - 1, p; n >= 0; n--) (p = l[n]) && (a = (e ? p(o, t, a) : p(a)) || a);
  return e && a && u(o, t, a), a;
};
import { LitElement as S, html as m } from "lit";
import { classMap as v } from "lit/directives/class-map.js";
import { property as s, queryAssignedElements as I, state as x } from "lit/decorators.js";
import { unsafeCSS as y } from "lit";
import b from "@maersk-global/mds-components-utils/lib/host/host.styles.js";
import { css as f } from "lit-element/lit-element.js";
var d = f`:host([fit=small]) .mc-step-indicator{gap:var(--mds_core_step-indicator_small_gap)}:host([fit=medium]) .mc-step-indicator{gap:var(--mds_core_step-indicator_medium_gap)}:host([fit=large]) .mc-step-indicator{gap:var(--mds_core_step-indicator_large_gap)}:host([fit=x-large]) .mc-step-indicator{gap:var(--mds_core_step-indicator_x-large_gap)}@media only screen and (max-width:599px){:host([orientation=horizontal]:not([autolayoutdisabled])) .mc-step-indicator{height:100%}}@media only screen and (max-width:599px) and (max-width:599px){:host([orientation=horizontal]:not([autolayoutdisabled])) .mc-step-indicator{flex-direction:column}}:host([orientation=vertical]) .mc-step-indicator{height:100%}@media only screen and (max-width:599px){:host([orientation=vertical]) .mc-step-indicator{flex-direction:column}}.mc-step-indicator{display:flex;height:100%;margin:0;padding:0}.mc-step-indicator.vertical{flex-direction:column}`;
import g from "@maersk-global/mds-design-tokens/implementation/core/step-indicator/js/design-tokens-px.css.js";
var c = [b, g, y(d)];
import "@maersk-global/mds-components-core-step-indicator-item";
var i = class extends S {
  constructor() {
    super(...arguments);
    this.autolayoutdisabled = !1;
    this.alignitemsdisabled = !1;
    this.currentindex = 0;
    this.fit = "medium";
    this.labels = [];
    this.orientation = "horizontal";
    this.initializeLabels = () => {
      this.slottedElements && (this.stepIndicatorItems = this.getOnlyStepIndicatorItems(), this.updateLabelsProps());
    };
    this.updateLabelsProps = t => {
      if (this.stepIndicatorItems && (!t || t.get("autolayoutdisabled") || t.get("fit") || t.get("orientation"))) for (let e of this.stepIndicatorItems) e.autolayoutdisabled = this.autolayoutdisabled, e.fit = this.fit, e.orientation = this.orientation;
    };
  }
  static get styles() {
    return c;
  }
  render() {
    let t = {
      [`${this.orientation}`]: !0
    };
    return m`<ol role="list" class="mc-step-indicator ${v(t)}" aria-label="steps progress indicator">${this.renderSteps()}</ol>`;
  }
  renderSteps() {
    return m`<slot @slotchange="${this.initializeLabels}">${this.labels && this.labels.map((t, e) => m`<mc-step-indicator-item ?autolayoutdisabled="${this.autolayoutdisabled}" ?alignitemsdisabled="${this.alignitemsdisabled && (e === 0 || e === this.labels.length - 1)}" orientation="${this.orientation}" fit="${this.fit}" state="${this.getStateName(e)}" label="${t}"></mc-step-indicator-item>`)}</slot>`;
  }
  async firstUpdated(t) {
    super.firstUpdated(t), await this.updateComplete, this.initializeLabels();
  }
  updated(t) {
    super.updated(t), this.updateLabelsProps(t);
  }
  getOnlyStepIndicatorItems() {
    return this.slottedElements?.filter(t => this.isStepIndicatorItem(t));
  }
  isStepIndicatorItem(t) {
    return t.tagName?.toLowerCase() === "mc-step-indicator-item";
  }
  getStateName(t) {
    let e = t === this.currentindex,
      a = t < this.currentindex;
    return e ? "current" : a ? "completed" : "pending";
  }
};
r([x()], i.prototype, "stepIndicatorItems", 2), r([I({
  slot: "",
  flatten: !0
})], i.prototype, "slottedElements", 2), r([s({
  type: Boolean,
  attribute: !0,
  reflect: !0
})], i.prototype, "autolayoutdisabled", 2), r([s({
  type: Boolean
})], i.prototype, "alignitemsdisabled", 2), r([s({
  type: Number
})], i.prototype, "currentindex", 2), r([s({
  type: String,
  attribute: !0,
  reflect: !0
})], i.prototype, "fit", 2), r([s({
  type: Array
})], i.prototype, "labels", 2), r([s({
  type: String,
  attribute: !0,
  reflect: !0
})], i.prototype, "orientation", 2);
customElements.get("mc-step-indicator") || customElements.define("mc-step-indicator", i);
export { i as McStepIndicator };