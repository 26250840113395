import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, interval, Observable, Subscription } from 'rxjs';
import { utils } from '../helper/utils';
import { BulkWorkOrderDetailJobRepsone, BulkWorkOrderJobResponse } from '../models/bulk-work-order/bulk-work-order-job-response';
import { NotificationItem } from '../models/notification-item.model';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private httpClient: HttpClient) { }
    private showWorkInProgress$ = new BehaviorSubject<boolean>(false);
    get ShowWorkInProgress(): Observable<boolean> {
        return this.showWorkInProgress$.asObservable();
    }
    private set ShowWorkInProgress(value: boolean) {
        this.showWorkInProgress$.next(value);
    }

    notificationApiPollingSchedule: Subscription | undefined;
    private scheduleNotificationPolling() {
        if (!this.notificationApiPollingSchedule) {
            this.notificationApiPollingSchedule = interval(30 * 1000).subscribe(() => {
                this.checkLongrunningJobsForLoggedinUser();
            });
        }
    }

    private notifications$ = new BehaviorSubject<NotificationItem[]>([]);
    get Notifications(): Observable<NotificationItem[]> {
        return this.notifications$.asObservable();
    }
    private updateNotifications(notifications: NotificationItem[]): void {
        this.notifications$.next(notifications);
    };

    private newNotificationsCount$ = new BehaviorSubject<number>(0);
    get NewNotificationsCount(): Observable<number> {
        return this.newNotificationsCount$.asObservable();
    }
    set NewNotificationsCount(value: number) {
        this.newNotificationsCount$.next(value);
    }
    private inProgressJobsCount$=new BehaviorSubject<number>(0);
    get InProgressJobsCount():number{
        return this.inProgressJobsCount$.value;
    }
    private set InProgressJobsCount(value:number){
        this.inProgressJobsCount$.next(value);
    }

    public addNotification(notification: NotificationItem): void {
        const notifications = this.notifications$.getValue();
        const isDuplicate = notifications.some(x => x.id === notification.id);
        if (isDuplicate)
            return;
        notifications.unshift(notification);
        this.updateNotifications(notifications);
        if (this.notifications$.getValue().some(x => x.state === 1 || x.state === 2)) {
            this.ShowWorkInProgress = true;
        } else {
            this.ShowWorkInProgress = false;
        }
        if(!this.notificationApiPollingSchedule){
        this.scheduleNotificationPolling();
        }
        this.NewNotificationsCount = this.newNotificationsCount$.getValue() + 1;
    }

    checkLongrunningJobsForLoggedinUser(): void {
        const todaysDate = new Date();
        const dateThreeDaysAgo = new Date(todaysDate.setDate(todaysDate.getDate() - 3));
        const dateStringThreeDaysAgo = utils.formatDateYYYYMMDD(dateThreeDaysAgo);
        forkJoin([
            this.httpClient.get<BulkWorkOrderJobResponse[]>(utils.GetUrl(`maintenance-orders/bulk-upload?fromDate=${dateStringThreeDaysAgo}`))
        ]).subscribe(([bulkUploadResponse]) => {
            const existingInProgressOrQueuedCreateJobs = localStorage.getItem("inProgressOrQueuedCreateJobs");
            const latestInProgressOrQueuedCreateJobs = bulkUploadResponse.filter(x => x.state === 1 || x.state === 2).length;
            this.InProgressJobsCount=latestInProgressOrQueuedCreateJobs;
            if (existingInProgressOrQueuedCreateJobs) {
                this.NewNotificationsCount = Math.abs(parseInt(existingInProgressOrQueuedCreateJobs) - latestInProgressOrQueuedCreateJobs);
            } else {
                this.NewNotificationsCount = latestInProgressOrQueuedCreateJobs;
            }
           

            if (latestInProgressOrQueuedCreateJobs > 0) {
                this.ShowWorkInProgress = true;
                if (!this.notificationApiPollingSchedule) {
                    this.scheduleNotificationPolling();
                }

            }
            else {
                this.ShowWorkInProgress = false;
                this.notificationApiPollingSchedule?.unsubscribe();
            }
            let x = bulkUploadResponse.map(x => {
                const appearance = x.state === 1 ? "info" : x.state === 2 ? "info" : x.state === 3 ? "error" : "success";
                const icon = x.state === 1 ? "info-circle" : x.state === 2 ? "info-circle" : x.state === 3 ? "exclamation-octagon" : "check-circle";
                const title= x.state === 1 ? "Create bulk work order in progress" : x.state === 2 ? "Create bulk work order in progress" : x.state === 3 ? "Bulk work order creation failed" : "Bulk work order created successfully";
                const daysBefore = new Date().getDate() - new Date(x.createdAt).getDate();  
                return {
                    id: x.id,
                    state: x.state,
                    title: title ,
                    associatedTaskCreatedAtDateRelative: utils.getDateInRelativeFormat(new Date(x.createdAt)),
                    associateTaskCreatedAtTime: new Date(x.createdAt).toLocaleTimeString(),
                    appearance: appearance,
                    icon: icon,
                    daysBefore: daysBefore,
                    associatedTaskCreatedAt: new Date(x.createdAt),
                    notificationType: "CREATE_WO"
                } as NotificationItem
            });
            this.notifications$.next(x.reverse());
        });
    }

    getLongRunningJobDetails(jobId: string): Observable<BulkWorkOrderDetailJobRepsone> {
        return this.httpClient.get<BulkWorkOrderDetailJobRepsone>(utils.GetUrl(`maintenance-orders/bulk-upload/${jobId}`))
    };
}