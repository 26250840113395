@if(receivedFileChangeValue != 0 ){
<div class="pure-g m-t-16">
    <div class="pure-u-md-4-24 pure-u-lg-3-24 right">
    </div>
</div>

<div class="pure-g m-t-8 quick-filter-box">
    <div class="pure-g pure-u-md-21-24" style="display: flex;align-items: center;">
        <div class="pure-u-md-24-24 p-l-4 applied-filter-holder">
            @for (taggedFilter of taggedFilters; track taggedFilter){
            @if(taggedFilter.key!=="applyMasterFilter"){
            {{taggedFilter.key}}
            <mc-tag class="m-r-4 extra-height" appearance="neutral-inverse" fit="small" withaction
                (click)="unselectSpecificFilter(taggedFilter)"> {{taggedFilter.value}}</mc-tag>

            }
            }
        </div>
    </div>
    <div class="pure-u-md-3-24 utility-buttons">
        <div>
            <mc-button class="utility-button download" label="Button " variant="filled" appearance="neutral"
                fit="medium" icon="tray-arrow-down" [loading]="downloadLoader" hiddenlabel (click)="onDownloadClick()"
                padding="default">
            </mc-button>
            <mc-button class="utility-button upload" label="Button " variant="filled" appearance="neutral" fit="medium"
                icon="tray-arrow-up" hiddenlabel (click)="uploadDialog()" padding="default">
            </mc-button>

        </div>



        <div class="pure-g">
            <mc-tooltip appearance="neutral-default" fit="small" position="bottom-center">
                <mc-button class="utility-button" slot="trigger" label="Button" icon="sliders-horizontal"
                    variant="filled" appearance="neutral" hiddenlabel padding="default" fit="medium"  [loading]="filterLoader"
                    (click)="showAdvanceFiltersModal()">
                </mc-button>
                <span>Filters</span>
            </mc-tooltip>
            <mc-modal [open]="showAdvanceFilters" (closed)="closeAdvanceFiltersModal()" heading="Filters" fit="small"
                dimension="medium">
                <app-master-data-advanced-filter [formList]="formList"
                    [masterFilterForm]="masterFormGroup.controls.masterFilterForm"
                    (onClickEvents)="onClickEvents($event)"></app-master-data-advanced-filter>
            </mc-modal>
        </div>
    </div>
</div>
<mc-modal heading="Upload File"  [open]="openUploadDialog" (closed)="closeUploadDialog()">
    <p style="font-size: 14px;">Only .xlsx format allowed.</p>
    <mc-file-upload class="mc-file-upload_drag-drop" variant="drag-drop" name="files"  accept=".xlsx"
        (input)="onFileChange($event)"></mc-file-upload>
    @if(displayErrMsg){
    <div class="err-container">
        <p><u>Error Message(s)</u></p>
        <ng-container *ngFor="let row of uploadErrList;let i=index">
            <div class="err-msg">{{row.error}}</div>
        </ng-container>
    </div>
    }
    <mc-button label="Done" slot="primaryAction" appearance="primary" fit="small" (click)="onUploadClick()"
        [loading]="submitLoader" [disabled]="!showDoneBtn"></mc-button>
</mc-modal>
}