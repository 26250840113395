import "../../chunk-SDBWQ4EE.js";
import { padStart as e } from "../index.js";
const f = t => {
  (!t.includes(":") || t[2] !== ":") && (t = t.replace(/[^0-9]/g, ""), t = [t.slice(0, 2), ":", t.slice(2)].join(""));
  const n = t.split(":");
  let i = +n[0],
    r = +n[1];
  i > 23 && (i = 23), i < 0 && (i = 0), r > 59 && (r = 59), r < 0 && (r = 0);
  const o = e(i),
    s = e(r);
  return [`${o}:${s}`, o, s];
};
export { f as timeFormatter };