import {Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable({
    providedIn: 'root',
  })
export class ControlValidationService {
    isControlInErrorState(control: FormControl): boolean | null {
		if (control.invalid && control.touched) {
			return true;
		}
		return null;
	}
}