import { a as i } from "../../chunk-SDBWQ4EE.js";
import { isServer as t } from "lit";
import { state as s } from "lit/decorators.js";
const p = l => {
  class o extends l {
    constructor(...e) {
      super(...e);
      this.viewportResizeHandlerInFullScreenModal = () => {
        this.viewport === "small" ? window.scrollTo(0, 0) : this.resetBodyUponClosingInFullScreenModal();
      };
      this.handleResize = () => {
        const e = window.innerWidth;
        e <= 599 ? this.viewport = "x-small" : e <= 600 ? this.viewport = "small" : e <= 900 ? this.viewport = "medium" : e <= 1200 ? this.viewport = "large" : this.viewport = "x-large";
      };
      t || (window.addEventListener("resize", this.handleResize), this.handleResize());
    }
    togglePageLevelScrollingInFullScreenModal(e) {
      this.viewport === "small" && (e ? (this.bodyOverflow = document.body.style.overflow, this.bodyPostion = document.body.style.position, this.windowScrollTop = document.documentElement.scrollTop, document.body.style.overflow = "hidden", document.body.style.position = "fixed", window.visualViewport?.addEventListener("resize", this.viewportResizeHandlerInFullScreenModal)) : this.resetBodyUponClosingInFullScreenModal());
    }
    resetBodyUponClosingInFullScreenModal() {
      document.body.style.overflow = this.bodyOverflow || "", document.body.style.position = this.bodyPostion || "", window.visualViewport?.removeEventListener("resize", this.viewportResizeHandlerInFullScreenModal), requestAnimationFrame(() => document.documentElement.scrollTop = this.windowScrollTop || 0);
    }
    disconnectedCallback() {
      super.disconnectedCallback(), t || window.removeEventListener("resize", this.handleResize);
    }
  }
  return i([s()], o.prototype, "viewport", 2), o;
};
export { p as Responsive };