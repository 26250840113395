import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { flagOnOffHeader } from '../../../models/flag-on-off.model';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { catchError, debounceTime, distinctUntilChanged, firstValueFrom, ReplaySubject, takeUntil, timer } from 'rxjs';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import '@maersk-global/mds-components-core/mc-modal';
import { FlagConfigFIlter } from '../../../models/flag-config-filter.model';
import { FlagOnOffFilterForm } from '../../../form-model/flag-on-off-filter.form.model';
import { ValidationTagInputGridComponent } from '../validation-tag-input-grid/validation-tag-input-grid.component';
import { FlagOnOffConfigService } from '../flag-on-off-config.service';
import { ToastrService } from '../../../services/toastr-service';
import { CreateValidation } from '../../../models/create-validation.model';
import { AuthHelper } from '../../../auth/auth-helper';
import { User } from '../../../models/user.model';
import { LocCluster, ShopCode } from '../../../models/loc.model';
import { FlagOnOffEditForm } from '../../../form-model/edit-validation.form.model';
import { LoaderComponent } from '../../../shared-components/loader/loader.component';
@Component({
    selector: 'app-flag-on-off-grid',
    imports: [NgxSkeletonLoaderModule, RouterModule, LoaderComponent, CommonModule, ValidationTagInputGridComponent, FormsModule, ReactiveFormsModule],
    templateUrl: './flag-on-off-grid.component.html',
    styleUrl: './flag-on-off-grid.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FlagOnOffGridComponent {
  data: flagOnOffHeader[] = [];
  mockDataForLoadingAnimation: { id: number }[] = [...Array(1).keys()].map(i => ({ id: i }))
  firstPage: number | undefined;
  previousPage: number | undefined;
  currentPage: number = 1;
  nextPage: number | undefined;
  lastPage: number | undefined;
  totalItemCount: number | undefined;
  apiVersion: string | undefined;
  pageLimit: number = 20;
  sort: string | undefined;
  getRuleConfigApiCallStatus: APICallStatus = "NOT_STARTED";
  hasData: boolean = false;
  showEditModal: boolean = false;
  openEditDialog: boolean = false;
  rowData!:flagOnOffHeader;
  selectedShopCodes: Array<string>|undefined;
  shop: ShopCode[]| undefined
  getShopApiCall: APICallStatus = "NOT_STARTED";
  editValidationApiCall: APICallStatus = "NOT_STARTED";
  filterParam: FlagConfigFIlter | undefined;
  shopcodes: Array<string> = [];
  isShopSelected: boolean = false;
  @Input({ required: true }) filterFormGroup!: FormGroup<{
    flagFilterForm: FormGroup<FlagOnOffFilterForm>;
  }>;
  flagEditForm : FormGroup;
  columns: Array<TableColumn> = [
    {
      id: 'ruleName',
      label: 'Validation',
      width: "40%"
    },
    {
      id: 'loc',
      label: 'Region',
      width: "10%"
    },
    {
      id: 'clusterCode',
      label: 'Cluster',
      width: "10%"
    },
    {
      id: 'shopCodes',
      label: 'Shops excluded',
      sortDisabled: true,
      width: "20%"
    },
    {
      id: 'actions',
      label: 'Actions',
      sortDisabled: true,
      width: "10%"
    },
  ];
  loggedinUser: User | null = AuthHelper.user;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private formBuilder: FormBuilder, private toastrService: ToastrService, private flagOnOffConfigService: FlagOnOffConfigService) {
    this.flagEditForm=this.formBuilder.group({
      shop:[[]]
    })
  }
  ngOnInit() {
    this.sort = `loc:asc`;
    this.getRuleConfig();
    this.subscribeToFormChanges();
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  onSortDirectionChange(event: Event) {
    let customEvent = event as CustomEvent;
    let sortColumn: string = this.sortColumnName(customEvent.detail.column)
    let sortDirection: string = customEvent.detail.direction == "ascending" ? "asc" : "desc"
    if (sortColumn != "id") {
      this.sort = `${sortColumn}:${sortDirection}`;
      this.currentPage = 1;
      this.getRuleConfig()
    }
  };
  private sortColumnName(gridColumnName: string): string {
    return gridColumnName
  }

  onPageLimitChange(event: Event) {
    let customEvent = event as CustomEvent;
    this.currentPage = 1;
    this.pageLimit = customEvent.detail.value;
    this.getRuleConfig()
  }

  onPageChange(event: Event) {
    let customEvent = event as CustomEvent;
    let changedPage = customEvent.detail as number;
    if (changedPage != this.currentPage) {
      this.currentPage = changedPage;
      this.getRuleConfig()
    }
  }
  getRuleConfig(): void {
    this.getRuleConfigApiCallStatus = "IN_PROGRESS";
    this.flagOnOffConfigService.getValidationsList(this.currentPage, this.pageLimit, this.sort, this.filterParam).pipe(takeUntil(this.destroyed$)).subscribe({
      next: response => {
        const headers = response.headers;
        const body = response.body || [];
        this.data = body;
        this.firstPage = headers.get('First-Page') ? +headers.get('First-Page')! : undefined;
        this.previousPage = headers.get('Previous-Page') ? +headers.get('Previous-Page')! : undefined;
        this.currentPage = +headers.get('Current-Page')!;
        this.nextPage = headers.get('Next-Page') ? +headers.get('Next-Page')! : undefined;
        this.lastPage = headers.get('Last-Page') ? +headers.get('Last-Page')! : undefined;
        this.totalItemCount = headers.get('Total-Item-Count') ? +headers.get('Total-Item-Count')! : undefined;
        this.apiVersion = headers.get('API-Version') ?? undefined;
         this.getRuleConfigApiCallStatus = "COMPLETED_SUCCESFULLY"
      },
      error: err => {
        this.getRuleConfigApiCallStatus = "FAILED"
        this.data = []
      }
    }
    );
  }

  async showEditModalDialog(row: flagOnOffHeader) {
    this.isShopSelected = false;
    this.shopcodes = [];
    this.rowData = row;
    this.openEditDialog = true;
  await this.getShopsByCluster(this.rowData);
    this.selectedShopCodes =this.rowData.shopCodes;
    if(this.selectedShopCodes)
      this.flagEditForm.patchValue({
        shop:this.selectedShopCodes
      })
     

  }
  closeEditDialog() {
    this.selectedShopCodes=[];
    this.openEditDialog = false;
  }
  onShopSelected(event: Event) {
    this.isShopSelected = true;
    this.shopcodes = [];
    let customEvent = event as CustomEvent;
    for (let i = 0; i < customEvent.detail.length; i++) {
      this.shopcodes.push(customEvent.detail[i].value);
    }
  }
  onEditValidation(rowData: flagOnOffHeader) {
    this.editValidationApiCall = "IN_PROGRESS"
    let requestBody: CreateValidation;
    requestBody = {
      loc: rowData.loc,
      shopCodes: this.shopcodes,
      validationRuleId: rowData.validationRuleId,
      ruleName: rowData.ruleName,
      clusterCode: rowData.clusterCode,
      userId: this.loggedinUser?.userId
    }
    this.flagOnOffConfigService.editValidation(requestBody)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.editValidationApiCall = "FAILED";
          this.toastrService.showToastr("Error while editing details", "error");
          throw err;
        })).subscribe(() => {
          this.editValidationApiCall = "COMPLETED_SUCCESFULLY";
          this.getRuleConfig();
          this.toastrService.showToastr("Validation updated successfully", "success");
        });
    this.openEditDialog = false;
  }

  async getShopsByCluster(rowData:flagOnOffHeader) {
    let requestBody: LocCluster;
    requestBody = {
      clusterCodes: [rowData.clusterCode],
      locs: [rowData.loc],
    }
    this.shop = await firstValueFrom(this.flagOnOffConfigService.getShopsByCluster(requestBody)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.toastrService.showToastr("Unable to get Shop details", "error");
          throw err;
        })));
       
  }

  private subscribeToFormChanges() {
    this.filterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.value)
          this.onApplyFilter()
        this.filterFormGroup.controls.flagFilterForm.controls.applyFlagFilter.setValue(false);
      });
  }

  onApplyFilter() {
    if (this.filterFormGroup.controls.flagFilterForm.valid) {
      const filters: FlagConfigFIlter = {};
      if (this.filterFormGroup.controls.flagFilterForm.controls.shop.value && this.filterFormGroup.controls.flagFilterForm.controls.shop.value.length > 0) {
        filters.shopCodes = this.filterFormGroup.controls.flagFilterForm.controls.shop.value.join(',');
      }

      if (this.filterFormGroup.controls.flagFilterForm.controls.location.value && this.filterFormGroup.controls.flagFilterForm.controls.location.value.length > 0) {
        filters.locs = this.filterFormGroup.controls.flagFilterForm.controls.location.value.join(',');
      }

      if (this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value && this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value.length > 0) {
        filters.clusterCodes = this.filterFormGroup.controls.flagFilterForm.controls.countrycluster.value.join(',');
      }

      if (this.filterFormGroup.controls.flagFilterForm.controls.validation.value) {
        filters.ruleName = this.filterFormGroup.controls.flagFilterForm.controls.validation.value.ruleName;
      }

      this.filterParam = filters;
      this.currentPage = 1;
      this.getRuleConfig();
    }
  }
}