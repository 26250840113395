import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, firstValueFrom, ReplaySubject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { MasterDataConfigService } from '../master-data-config.service';
import { ToastrService } from '../../../services/toastr-service';
import { MasterAdvanceFilterForm } from '../../../form-model/master-advance-filter.form.model';

@Component({
    selector: 'app-master-data-advanced-filter',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    templateUrl: './master-data-advanced-filter.component.html',
    styleUrl: './master-data-advanced-filter.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MasterDataAdvancedFilterComponent {

  @Input({ required: true }) masterFilterForm!: FormGroup;
  @Input({ required: true }) formList! : MasterAdvanceFilterForm[]
  @Output() private onClickEvents = new EventEmitter<boolean>();

  addClick: boolean = false;

  getfilterApiCall: APICallStatus = "NOT_STARTED";
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  formDetails: { label: string; type: string }[] = [];
  constructor(private masterDataConfigService: MasterDataConfigService,
    private toastrService: ToastrService,
    private fb: FormBuilder) {

  }
  applyFilters() {
    this.masterFilterForm.controls['applyMasterFilter'].setValue(true);
    this.addClick = true;
    this.onClickEvents.emit(this.addClick);
  }

  clearFilters() {
    this.masterFilterForm.reset();
   
  }



}

